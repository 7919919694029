import React from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ProcurementStrategyHighlightsSlideField, ProcurementStrategyHighlightsSlideTooltip } from '../../helpers/DossierFieldConstants'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} ProcurementStrategyHighlightsSlideProps

 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
  * @property {number} dossierRefreshCount
 */

/**
 * @param {ProcurementStrategyHighlightsSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ProcurementStrategyHighlightsSlide(props) {

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, procurementStrategyHighlightsSlide: { ...props.cpscRequest.dossier.procurementStrategyHighlightsSlide, [name]: value } })
}

  return (
   
    <form style={{ fontSize: '12px' }}>
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ProcurementStrategyHighlightsSlideTooltip.details)}Highlights of Agreed Procurement Strategy  <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.procurementStrategyHighlightsSlide.details} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ProcurementStrategyHighlightsSlideField.details, editorHtmlString)} />
      </div>
    </form>
  )
}

export default ProcurementStrategyHighlightsSlide