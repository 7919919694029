import React, { useRef, useState } from 'react';
import { callServerAPI } from '../../helpers/Helpers';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Button, Grid } from '@mui/material';
import { Sort } from '@mui/icons-material';
import Table from 'react-bootstrap/Table';
import CustomDialog from '../../components/dialogs/CustomDialog';

axios.defaults.withCredentials = true;
/**
 * @typedef {Object} UpdateSlideSequenceFormProps
 * @property {CpscRequest} cpscRequest
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequest>>} setCpscRequest
 * @property {boolean} openUpdateSlideSequenceForm
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenUpdateSlideSequenceForm
 */

/**
 * @param {UpdateSlideSequenceFormProps}  props
 * @return {import('react').ReactElement}
 */

function UpdateSlideSequenceForm(props) {

    /** @type [DossierSlidePosition[], import('react').Dispatch<import('react').SetStateAction<DossierSlidePosition[]>>] */
    const [dossierSlideSequence, setDossierSlideSequence] = useState(props.cpscRequest.dossierSlideSequence)
    const { enqueueSnackbar } = useSnackbar();

    //save reference for dragItem and dragOverItem
    const draggedItemIndex = useRef(null);
    const draggedOverItemIndex = useRef(null);
    //handle drag sorting
    const handleSort = () => {
        //duplicate agendaitems
        let _dossierSlideSequence = [...dossierSlideSequence]
        //remove and save the dragItem content
        const dragItemContent = _dossierSlideSequence.splice(draggedItemIndex.current, 1)[0]
        //switch position
        _dossierSlideSequence.splice(draggedOverItemIndex.current, 0, dragItemContent)
        //reset positon
        draggedItemIndex.current = null
        draggedOverItemIndex.current = null
        //update agenda
        setDossierSlideSequence(_dossierSlideSequence.map((e, index) => ({ ...e, slideNumber: index + 1 })))
    }

    const handleUpdateDossierSlideSequence = () => {
        callServerAPI('PATCH', `/api/CpscRequest/UpdateDossierSlideSequence?requestId=${props.cpscRequest.id}`, dossierSlideSequence, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                props.setCpscRequest(response.data.data)
                enqueueSnackbar("Dossier Slide sequence updated", { variant: 'success' });
                props.setOpenUpdateSlideSequenceForm(false)
            })
    }

    return (
        <CustomDialog dialogTitle='Dossier Slide Sequence' setOpenDialog={props.setOpenUpdateSlideSequenceForm} openDialog={props.openUpdateSlideSequenceForm}>
            <div className='col-12 ' style={{ margin: '0px !important' }}>
                <div  >
                    <span className=' d-flex d-flex flex-row-reverse'>
                        <div >
                            <Button variant="contained" color='secondary' onClick={handleUpdateDossierSlideSequence}  ><Sort />&nbsp; Update Slide Sequence</Button>
                        </div>
                    </span>
                    <br />
                    <div style={{ fontSize: 12 }}>
                       Please drag any slide to move it to your desired position in the dossier
                    </div>
                    <br />
                    <div>
                        <span style={{ color: 'red' }}><u>Note:</u></span> All slides after the Recommendation slide will be in the Backup Slides section
                    </div>
                    <br />
                    <div>
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table responsive striped bordered>
                                    <thead className='thead-text'>
                                        <tr style={{ fontSize: '12px', }}>
                                            <th >SN</th>
                                            <th>Slide </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dossierSlideSequence.sort((a, b) => a.slideNumber > b.slideNumber ? 1 : -1).map((slide, index) =>
                                            <tr style={{ fontSize: '12px', }} className='agendaRow trtext' key={index} draggable
                                                onDragStart={(e) => draggedItemIndex.current = index}
                                                onDragEnter={(e) => draggedOverItemIndex.current = index}
                                                onDragEnd={handleSort}
                                                onDragOver={(e) => e.preventDefault}>
                                                <td title='Drag to move slide'>{slide.slideNumber}</td>
                                                <td title='Drag to move slide' >{slide.dossierSlideName}</td>
                                            </tr>
                                        )}
                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </CustomDialog>
    )
}

export default UpdateSlideSequenceForm
