import React from 'react'
import { ContractualEvaluationCriteriaSlideField, ContractualEvaluationCriteriaSlideTooltip } from '../../helpers/DossierFieldConstants';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} ContractualEvaluationCriteriaSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
  * @property {number} dossierRefreshCount
 * @property {Boolean} readOnly
 */

/**
 * @param {ContractualEvaluationCriteriaSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ContractualEvaluationCriteriaSlide(props) {

  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, contractualEvaluationCriteriaSlide: { ...props.cpscRequest.dossier.contractualEvaluationCriteriaSlide, [name]: value } })
  }

  return (

    <form style={{ fontSize: '12px' }}>
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ContractualEvaluationCriteriaSlideTooltip.details)}Contractual Evaluation Criteria Details <span className='important'>*</span></label>
       
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.contractualEvaluationCriteriaSlide.details} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ContractualEvaluationCriteriaSlideField.details, editorHtmlString)} />
         
      </div>
    </form>
      )
}

      export default ContractualEvaluationCriteriaSlide