import React, { useEffect, useState } from 'react'
import { Button, MenuItem, TextField } from '@mui/material';
import { callServerAPI } from '../../helpers/Helpers';
import { preloader, FormEditMode, AgendaItemType } from '../../helpers/Constants';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import '../../css/meeting.css'
import CustomDialog from '../../components/dialogs/CustomDialog';

const MySwal = withReactContent(Swal);

const AgendaItemTypeOptions = [{ value: AgendaItemType.SAFETY_MOMENT, label: "Sustainability/Saftey Moment" }, { value: AgendaItemType.CPSC_REQUEST, label: "CPSC Request" }, { value: AgendaItemType.AGGREGATED_CPSC_REQUEST, label: "Aggregated CPSC Request" }]

/**
 * @typedef {Object} AddUpdateAgendaItemProps
 * @property {string} meetingId
 * @property {AgendaItem[]} agenda
 * @property {Boolean} openAgendaItemForm
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>}  setOpenAgendaItemForm
 * @property {updateAgendaCallback}  updateAgenda
 * @property {Number} formEditMode
 * @property {Function} setFormEditMode
 * @property {String} agendaItemId
 */

/**
 * @param {AddUpdateAgendaItemProps}  props
 * @return {import('react').ReactElement}
 */
function AddUpdateAgendaItem(props) {
    /** @type AgendaItem */
    const emptyAgendaItem = {
        id: '',
        title: '',
        serialNumber: 0,
        cpscRequestId: 'None',
        aggregatedCpscRequestId: 'None',
        plannedDurationMinute: 0,
        safetyOrSustainabilityMomentSlideUploaded: undefined,
        commentsAndConsiderations: '',
        followOnAction: '',
        agendaItemType: AgendaItemType.SAFETY_MOMENT,
    }
    const error = null;

    const { enqueueSnackbar } = useSnackbar();
    /** @type [AgendaItem,import('react').Dispatch<import('react').SetStateAction<AgendaItem>>] */
    const [agendaItem, setAgendaItem] = useState(emptyAgendaItem);

    /** @type [CpscRequest[],import('react').Dispatch<import('react').SetStateAction<CpscRequest[]>>] */
    const [cpscRequests, setCPSCRequests] = useState([]);

    /** @type [AggregatedCpscRequest[],import('react').Dispatch<import('react').SetStateAction<AggregatedCpscRequest[]>>] */
    const [aggregatedCpscRequest, setAggregatedCpscRequest] = useState([]);

    /** @type [BusinessArea[],import('react').Dispatch<import('react').SetStateAction<BusinessArea[]>>] ] */
    const [businessAreas, setBusinessAreas] = useState(null);

    const [businessAreaFilter, setBusinessAreaFilter] = useState('None');

    useEffect(() => {
        callServerAPI('GET', '/api/CpscRequest/GetCandidateRequestsForScheduling', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest[]>> } */ response) {
                setCPSCRequests(response.data.data);
            })

        callServerAPI('GET', '/api/BusinessArea/GetAllBusinessAreas', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<BusinessArea[]>> } */ response) {
                setBusinessAreas(response.data.data);
            })

        callServerAPI('GET', '/api/CpscRequest/GetCandidateAggregatedRequestsForScheduling', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse< AggregatedCpscRequest[]>> } */ response) {
                setAggregatedCpscRequest(response.data.data);
            })

    }, [])


    useEffect(() => {
        if (props.formEditMode === FormEditMode.EDIT) {
            var agendaItem = props.agenda.find(e => e.id === props.agendaItemId)
            setAgendaItem(agendaItem)

        }
    }, [props.formEditMode, props.agendaItemId, props.agenda])


    function createAgendaItem(e) {
        /** @type AgendaItem*/ const dataToSend = {
            ...agendaItem,
            cpscRequestId: agendaItem.cpscRequestId === 'None' ? null : agendaItem.cpscRequestId,
            aggregatedCpscRequestId: agendaItem.aggregatedCpscRequestId === 'None' ? null : agendaItem.aggregatedCpscRequestId,
            serialNumber: props.agenda == null || props.agenda.length === 0 ? 1 : props.agenda.length + 1
        }
        MySwal.fire(preloader);

        callServerAPI('POST', `/api/Meeting/CreateAgendaItem?meetingId=${props.meetingId}`, dataToSend, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<AgendaItem>> } */ response) {
                props.setOpenAgendaItemForm(false)
                props.updateAgenda([...props.agenda, response.data.data].sort((a, b) => (a.serialNumber > b.serialNumber) ? 1 : -1));
                MySwal.close();
                enqueueSnackbar("Agenda Item Added", { variant: 'success' })
            })
            .catch(function (/** @type { import("axios").AxiosError<any> } */error) {
                console.log(error)
                enqueueSnackbar(error.response.data.message, { variant: 'error' })
                // props.setOpenAgendaItemForm(false);
            })

    }
    function updateAgendaItemPlannedDuration(e) {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/Meeting/UpdateAgendaItemPlannedDuration?meetingId=${props.meetingId}&agendaItemId=${props.agendaItemId}&plannedDurationMinute=${agendaItem.plannedDurationMinute}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<AgendaItem>> } */ response) {
                props.setOpenAgendaItemForm(false)
                props.setFormEditMode(FormEditMode.CREATE);
                props.updateAgenda(props.agenda.map(e => e.id === props.agendaItemId ? { ...e, plannedDurationMinute: agendaItem.plannedDurationMinute } : e));
                MySwal.close();
                enqueueSnackbar(response.data.message, { variant: 'success' })
            })
            .catch(function (/** @type { import("axios").AxiosError<any> } */error) {
                console.log(error)
                //enqueueSnackbar(error.response.data, { variant: 'error' })
            })
    }

    function updateDataField(name, value) {

        const newdata = { ...agendaItem }
        newdata[name] = value
        setAgendaItem(newdata)
    }

    /** @param {FormData} formData */
    async function UploadSustainabilityMoment(formData) {

        callServerAPI('PATCH', `/api/Meeting/UploadSafetyOrSustainabilityMomentPdf?meetingId=${props.meetingId}&agendaItemId=${props.agendaItemId}`, formData, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                const sustainabilityPdfUploadStatus = { ...agendaItem }
                sustainabilityPdfUploadStatus.safetyOrSustainabilityMomentSlideUploaded = true
                setAgendaItem(sustainabilityPdfUploadStatus)
                enqueueSnackbar(response.data.message, { variant: 'success' })
            })

    }
    async function DeleteSustainabilityMoment() {
        callServerAPI('DELETE', `/api/Meeting/DeleteSafetyOrSustainabilityMomentPdf?meetingId=${props.meetingId}&agendaItemId=${props.agendaItemId}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                const sustainabilityPdfUploadStatus = { ...agendaItem }
                sustainabilityPdfUploadStatus.safetyOrSustainabilityMomentSlideUploaded = false
                setAgendaItem(sustainabilityPdfUploadStatus)
                enqueueSnackbar(response.data.message, { variant: 'success' })

            })

    }
    const handleFileChange = (event) => {
        const file = event.target.files[0]
        let document = {}
        document.name = file.name;
        if (event.target.files.length === 0) return
        const formData = new FormData()
        formData.append("uploadedFile", event.target.files[0]);
        UploadSustainabilityMoment(formData);


    }

    /** @param {AgendaItem}  agendaItem, @return {Boolean} */
    const validate = (agendaItem) => {
        if (agendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT) {
            if (!(Boolean(agendaItem.agendaItemType) && Boolean(agendaItem.plannedDurationMinute))) {
                return false
            } else {
                return true
            }
        }
        if (agendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST) {
            if (!(Boolean(agendaItem.agendaItemType) && Boolean(agendaItem.aggregatedCpscRequestId) && Boolean(agendaItem.plannedDurationMinute))) {
                return false
            } else {
                return true
            }
        }
        if (agendaItem.agendaItemType === AgendaItemType.CPSC_REQUEST) {
            if (!(Boolean(agendaItem.agendaItemType) && Boolean(agendaItem.cpscRequestId) && Boolean(agendaItem.plannedDurationMinute))) {
                return false
            } else {
                return true
            }
        }
        return true
    }
    return (
        aggregatedCpscRequest && agendaItem && businessAreas && cpscRequests &&
        <>
            <CustomDialog dialogTitle={props.formEditMode === FormEditMode.CREATE ? 'Add New Agenda Item' : 'Update Agenda Item'} setOpenDialog={props.setOpenAgendaItemForm} openDialog={props.openAgendaItemForm}>
                <div className='col-12 page' style={{ margin: '0px !important' }}>
                    <div >
                        <div >
                            <div >
                                <div >
                                    <label className='title-label'>Agenda Item Type <span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField fullWidth id="fullWidth" select disabled={props.formEditMode === FormEditMode.EDIT} variant="outlined" size="small" name="cpDomainId" value={agendaItem.agendaItemType}
                                            onChange={(event) => setAgendaItem(agendaItem => ({ ...agendaItem, agendaItemType: event.target.value }))} >

                                            {AgendaItemTypeOptions.map((agendaItemType, index) => {
                                                return <MenuItem key={index + 1} value={agendaItemType.value}>{agendaItemType.label}</MenuItem >
                                            })}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {(agendaItem.agendaItemType !== AgendaItemType.SAFETY_MOMENT && props.formEditMode === FormEditMode.CREATE) &&
                                <>
                                    <div>
                                        <div >
                                            <label className='title-label'>Business Area <span className='important'>*</span></label>
                                        </div>
                                        <div className=''  >
                                            <div className="form-group mb-0" >
                                                <TextField fullWidth id="fullWidth" disabled={props.formEditMode === FormEditMode.EDIT} select variant="outlined" size="small" name="businessAreaFilter" value={businessAreaFilter}
                                                    onChange={(event) => setBusinessAreaFilter(event?.target.value)} >
                                                    <MenuItem value='None' defaultValue={"Select Business Area"} disabled>Select Business Area</MenuItem >
                                                    {businessAreas.map((businessArea, index) => {
                                                        return <MenuItem key={index} value={businessArea.id}>{businessArea.title}</MenuItem >
                                                    })}
                                                </TextField>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </>

                            }
                            {agendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST &&
                                <>
                                    <div >
                                        <label className='title-label'>Aggregated Cpsc Request<span className='important'>*</span></label>
                                    </div>
                                    <div className="form-group mb-0">
                                        {props.formEditMode === FormEditMode.CREATE &&
                                            <TextField fullWidth select variant="outlined" disabled={props.formEditMode === FormEditMode.EDIT} size="small" id="aggregatedCpscRequestId" value={agendaItem.aggregatedCpscRequestId}
                                                onChange={(event) => setAgendaItem(agendaItem => ({ ...agendaItem, aggregatedCpscRequestId: event.target.value }))} {...(error && { error: true })} required>

                                                {aggregatedCpscRequest.filter(e => e.businessArea.id === businessAreaFilter).map((aggregatedCpscRequest, index) => {
                                                    return <MenuItem key={index + 1} value={aggregatedCpscRequest.id}>{aggregatedCpscRequest.aggregatedTitle}</MenuItem >
                                                })}
                                            </TextField>
                                        }
                                        {props.formEditMode === FormEditMode.EDIT &&
                                            <TextField size="small" fullWidth disabled value={agendaItem.title} />


                                        }
                                    </div>
                                    <br />
                                </>
                            }
                            {agendaItem.agendaItemType === AgendaItemType.CPSC_REQUEST &&
                                <>
                                    <div >
                                        <label className='title-label'> Cpsc Request <span className='important'>*</span></label>
                                    </div>
                                    <div className="form-group mb-0">
                                        {props.formEditMode === FormEditMode.CREATE &&
                                            <TextField fullWidth select variant="outlined" size="small" id="cpscRequestId" value={agendaItem.cpscRequestId}
                                                onChange={(event) => setAgendaItem(agendaItem => ({ ...agendaItem, cpscRequestId: event.target.value }))} >

                                                {cpscRequests.filter(e => e.businessArea.id === businessAreaFilter).map((cpscRequest, index) => {
                                                    return <MenuItem key={index + 1} value={cpscRequest.id}>{cpscRequest.contractTitle}</MenuItem >
                                                })}
                                            </TextField>
                                        }
                                        {props.formEditMode === FormEditMode.EDIT &&
                                            <TextField fullWidth disabled={props.formEditMode === FormEditMode.EDIT} value={agendaItem.title} />


                                        }
                                    </div>
                                    <br />
                                </>
                            }
                            {agendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT &&

                                <>
                                    {!agendaItem.safetyOrSustainabilityMomentSlideUploaded && props.formEditMode === FormEditMode.EDIT &&
                                        <div className="file-card">

                                            <div className="file-inputs">
                                                <input className='upload-input' id="safetyOrSustainabilityMomentSlideUploaded" onChange={handleFileChange} type="file" accept="application/pdf" />
                                                <Button variant='contained' color='secondary' className='upload-btn ' >
                                                    &nbsp; Upload
                                                </Button>

                                            </div>

                                            <p className="main">Supported files</p>
                                            <p className="info">PDF</p>

                                        </div>

                                    }
                                    {agendaItem.safetyOrSustainabilityMomentSlideUploaded &&

                                        <div>

                                            <div className='files' >
                                                <div style={{ fontStyle: 'italics', fontSize: '12px', fontWeight: '500' }}>Sustainability Moment</div>

                                                <div className='del-file'>
                                                    <Button variant='contained' color='error' onClick={() => { DeleteSustainabilityMoment() }}>
                                                        <DeleteSweepOutlinedIcon /> </Button>
                                                </div>
                                            </div>



                                        </div>
                                    }
                                </>}
                            <div >
                                <div >
                                    <label className='title-label'>Planned Duration Minute <span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <input type="number" onChange={(e) => updateDataField(e.target.id, e.target.value)} id="plannedDurationMinute" value={agendaItem.plannedDurationMinute} className="form-control input-font bold" placeholder="" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />




                        <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
                            <Button variant="contained" color='secondary' disabled={!validate(agendaItem)} onClick={props.formEditMode === FormEditMode.CREATE ? createAgendaItem : updateAgendaItemPlannedDuration} ><Add />&nbsp;{props.formEditMode === FormEditMode.CREATE ? 'Create Agenda Item' : 'Update Agenda Item PlannedDuration '} </Button>
                        </div>
                        <br />


                    </div>
                </div>
            </CustomDialog>

        </>
    )
}

export default AddUpdateAgendaItem