import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Box, Button, ButtonGroup, Chip, Grid, IconButton, Paper, Radio, Stack, StepButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Replay from '@mui/icons-material/Replay';
import { callServerAPI, checkUserCanValidateDossier, getValidationRoleForCpscRequestStatus, getAcceptDossierButtonLabel, isTripletForRequest, setPdfUploadStatus, isCostControllerForRequest, isEntityRepForRequest, isContractEngineerForRequest, rejectionCommentExistsForUser, getSlideName, alertError } from '../../helpers/Helpers';
import { isDossierCompleted, isDossierSlideCompleted } from '../../helpers/DossierSlideCompletionRules';
import { preloader, CpscRequestStatus, GlobalRole, globalStore, DossierSlideName, DossierValidationRole, DossierPdfFieldName, ENVIRONMENT, Environment, DossierSlideRequirement } from '../../helpers/Constants';
import { useNavigate, useParams } from 'react-router-dom';
import { PictureAsPdf, RemoveRedEye, Save, ArrowBackIos, ArrowForwardIos, Send, ThumbDownAlt, ThumbUpAlt, Dataset, PostAdd, Sort, KeyboardReturn } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import FlexBetween from '../../components/FlexBetween';
import DossierCommentsEditor from '../../components/comments/DossierCommentsEditor';
import SlideDisabled from '../../components/slides/SlideDisabled';
import CfrInitiativesSlide from '../../components/slides/CfrInitiativesSlide';
import BudgetPlanningSlide from '../../components/slides/BudgetPlanningSlide';
import SavingsSlide from '../../components/slides/SavingsSlide';
import MarketIntelligenceSlide from '../../components/slides/MarketIntelligenceSlide';
import NigerianContentSlide from '../../components/slides/NigerianContentSlide';
import TechnicalEvaluationSlide from '../../components/slides/TechnicalEvaluationSlide';
import CommercialEvaluationSlide from '../../components/slides/CommercialEvaluationSlide';
import RiskAssesmentSlide from '../../components/slides/RiskAssesmentSlide';
import BudgetConfirmationSlide from '../../components/slides/BudgetConfirmationSlide';
import DueDiligenceHumanRightsSlide from '../../components/slides/DueDiligenceSlide';
import HighRiskIdentificationSlide from '../../components/slides/HighRiskIdentificationSlide';
import PurposeAndDescriptionSlide from '../../components/slides/PurposeAndDescriptionSlide';
import ProjectOperationsPlanningSlide from '../../components/slides/ProjectOperationsPlanningSlide';
import ProcurementStrategyHighlightsSlide from '../../components/slides/ProcurementStrategyHighlightsSlide';
import ContractualEvaluationSlide from '../../components/slides/ContractualEvaluationSlide';
import ContractSpendEstimationSlide from '../../components/slides/ContractSpendEstimationSlide';
import ContractualProvisionsSlide from '../../components/slides/ContractualProvisionsSlide';
import RecommendationSlide from '../../components/slides/RecommendationSlide';
import ApprovalGovernanceSlide from '../../components/slides/ApprovalGovernanceSlide';
import TechnicalEvaluationCriteria from '../../components/slides/TechnicalEvaluationCriteria';
import HseEvaluationCriteria from '../../components/slides/HseEvaluationCriteria';
import ProcurementStrategyDetailsSlide from '../../components/slides/ProcurementStrategyDetailsSlide';
import ProposedBiddersList from '../../components/slides/ProposedBiddersSlide';
import RisksAndOpportunitiesSlide from '../../components/slides/RisksAndOpportunities';
import HseEvaluationSlide from '../../components/slides/HseEvaluationSlide';
import CurrentContractHistorySlide from '../../components/slides/CurrentContractHistorySlide';
import ContractualEvaluationCriteriaSlide from '../../components/slides/ContractualEvaluationCriteria';
import { getDossierSlideRequirement } from '../../helpers/DossierSlideRequirementRules';
import CatManInvolvementSlide from '../../components/slides/CatManInvolvementSlide';
import AdhocDossierSlide from '../../components/slides/AdhocDossierSlide';
import AdhocDossierSlideCreateForm from '../../components/slides/AdhocDossierSlideCreateForm';
import UpdateSlideSequenceForm from './UpdateSlideSequenceForm';

const MySwal = withReactContent(Swal);
function DossierTemplate() {
    const theme = useTheme();
    const isDesktop = useMediaQuery('(min-width: 600px)');
    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    const { id } = useParams();
    const navigate = useNavigate();
    const handleHistory = () => { navigate('/dossier') }

    const { enqueueSnackbar } = useSnackbar();
    /** @type [CpscRequest, import('react').Dispatch<import('react').SetStateAction<CpscRequest>>] */
    const [cpscRequest, setCpscRequest] = useState(null)
    const [dossierRefreshCount, setDossierRefreshCount] = useState(0)
    const [showStepper, setShowStepper] = useState(true);
    const [showComments, setShowComments] = useState(false);
    ///** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    //const [dossierSlideNames, setDossierSlideNames] = useState(null);
    const [currentSlideNumber, setCurrentSlideNumber] = useState(1);
    const [tripletConfirmationBoxChecked, setTripletConfirmationBoxChecked] = useState(false)
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [tripletValidationRole, setTripletValidationRole] = useState(null)
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [dossierPDFObjectUrl, setDossierPDFObjectUrl] = useState(null);
    const [openAdhocDossierSlideCreateForm, setOpenAdhocDossierSlideCreateForm] = React.useState(false);
    const [openUpdateSlideSequenceForm, setOpenUpdateSlideSequenceForm] = React.useState(false);

    useEffect(() => {
        const getCpscRequest = async () => {
            callServerAPI('GET', `/api/CpscRequest/GetARequest?id=${id}`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                    setCpscRequest(response.data.data);
                })
        }
        getCpscRequest();

    }, [id])

    function active() {
        if (cpscRequest.status !== CpscRequestStatus.DossierPreparationOngoing) {
            setShowComments(true);
            setShowStepper(false)
        }

    }

    function downloadDocumentPdf() {
        MySwal.fire(preloader)
        callServerAPI('GET', `/api/CpscRequest/GetDossierPdf?requestId=${id}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                window.open(URL.createObjectURL(response.data));
                MySwal.close();
            })
    }

    function recallDossier(e) {
        MySwal.fire(preloader);
        //recall dossier
        callServerAPI('PATCH', `/api/CpscRequest/RecallDossier?requestId=${id}`, null, true)
            .then(async function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                MySwal.close()
                setCpscRequest(e => ({ ...e, status: CpscRequestStatus.DossierPreparationOngoing }));
                enqueueSnackbar("Dossier recalled", { variant: 'success' });
            })
    }

    /** @param {FormData} formData, @param {string} fieldName, @param {string} [contractorName]  */
    async function UploadPdf(formData, fieldName, contractorName) {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/CpscRequest/UploadDossierFieldDocument?requestId=${id}&fieldName=${fieldName}&contractorName=${contractorName ?? ''}`, formData, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setCpscRequest(cpscRequest => setPdfUploadStatus(cpscRequest, fieldName, true, contractorName))
                MySwal.close();

                enqueueSnackbar("Upload Successful", { variant: 'success' });
            })

    }

    /**  @param {string} fieldName , @param {string} [contractorName] */
    async function DownloadPdf(fieldName, contractorName) {
        let url = undefined
        await callServerAPI('GET', `/api/CpscRequest/DownloadDossierFieldDocument?requestId=${id}&fieldName=${fieldName}&contractorName=${contractorName ?? ''}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                if (fieldName === DossierPdfFieldName.HumanRights || fieldName === DossierPdfFieldName.DueDiligence)
                    window.open(URL.createObjectURL(response.data));
                else
                    url = URL.createObjectURL(response.data)
            })
        return url
    }

    /**  @param {string} fieldName , @param {string} [contractorName] */
    async function mobileDownloadPdf(fieldName, contractorName) {
        MySwal.fire(preloader)
        await callServerAPI('GET', `/api/CpscRequest/DownloadDossierFieldDocument?requestId=${id}&fieldName=${fieldName}&contractorName=${contractorName ?? ''}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                window.open(URL.createObjectURL(response.data));
                MySwal.close();
            })

    }
    /**  @param {string} fieldName, @param {string} [contractorName]   */
    async function DeletePdf(fieldName, contractorName) {
        callServerAPI('DELETE', `/api/CpscRequest/DeleteDossierFieldDocument?requestId=${id}&fieldName=${fieldName}&contractorName=${contractorName ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setCpscRequest(cpscRequest => setPdfUploadStatus(cpscRequest, fieldName, false, contractorName))
            })

    }

    function updateDossier(showPreloader) {
        MySwal.fire(preloader)
        //update dossier
        callServerAPI('PATCH', `/api/CpscRequest/UpdateDossier?requestId=${id}`, cpscRequest.dossier, true)
            .then(async function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                MySwal.close()
                enqueueSnackbar('Draft Saved Successfully', { variant: 'success' });
            })
    }


    function autoSaveDossier(e) {

        //update dossier
        callServerAPI('PATCH', `/api/CpscRequest/UpdateDossier?requestId=${id}`, cpscRequest.dossier, true)
            .then(async function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                MySwal.close()
                enqueueSnackbar('Draft Saved Successfully', { variant: 'success' });
            })
    }

    function completeDossierWithFakeData(e) {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/CpscRequest/CompleteDossierWithFakeData?requestId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                refreshDossier()
                if (response?.data?.message) enqueueSnackbar("Dossier completed with Sample Data", { variant: 'success' });
            })
    }

    function requestDossierValidation(e) {
        MySwal.fire(preloader);
        //update dossier
        callServerAPI('PATCH', `/api/CpscRequest/UpdateDossier?requestId=${id}`, cpscRequest.dossier, true)
            .then(async function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                callServerAPI('PATCH', `/api/CpscRequest/RequestDossierValidation?requestId=${id}`, null, true)
                    .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                        MySwal.close()
                        setCpscRequest(response.data.data);
                        if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });
                    })
            })
    }


    function tripletDossierConfirmationAccept(e) {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/CpscRequest/DossierAccept?requestId=${id}&validationRole=${tripletValidationRole ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                setTripletValidationRole(null)
                setTripletConfirmationBoxChecked(false)
                if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }

    function tripletDossierConfirmationReject(e) {
        MySwal.fire(preloader);
        active();
        callServerAPI('PATCH', `/api/CpscRequest/DossierReject?requestId=${id}&validationRole=${tripletValidationRole ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                setTripletValidationRole(null)
                setTripletConfirmationBoxChecked(false)
                if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }
    const handleAcceptDossier = (e) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small>Are you sure you want to ${getAcceptDossierButtonLabel(cpscRequest.status)} the dossier?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: '#02b702',
            confirmButtonText: `Yes`
        })
            .then((result) => {
                if (result.isConfirmed) acceptDossier(e);
            })
    };
    function acceptDossier(e) {
        MySwal.fire(preloader);
        const validationRole = getValidationRoleForCpscRequestStatus(cpscRequest.status)
        callServerAPI('PATCH', `/api/CpscRequest/DossierAccept?requestId=${id}&validationRole=${validationRole ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }

    const handleRejectDossier = (e) => {
        if (!rejectionCommentExistsForUser(cpscRequest, loggedOnTepngUser)) {
            MySwal.fire(alertError("Comments are required to reject this dossier"));
            return
        }
        MySwal.fire({
            title: 'Alert',
            html: `<small>Are you sure you want to reject the dossier?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'
        })
            .then((result) => {
                if (result.isConfirmed) rejectDossier(e);
            })
    };
    function rejectDossier(e) {
        MySwal.fire(preloader);
        active();
        const validationRole = getValidationRoleForCpscRequestStatus(cpscRequest.status)
        callServerAPI('PATCH', `/api/CpscRequest/DossierReject?requestId=${id}&validationRole=${validationRole ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });

            })

    }

    /**  @param {number} slideNumber */
    const handleStep = (slideNumber) => () => {
        setCurrentSlideNumber(slideNumber);
        //scroll to top
        window.scrollTo(0, 0);
        autoSaveDossier();
    };

    function previousSlide(e) {
        setCurrentSlideNumber(num => num - 1)
        autoSaveDossier();
    }

    function nextSlide(e) {
        setCurrentSlideNumber(num => num + 1)
        autoSaveDossier();
    }

    function isDossierEditable() {
        return cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing &&
            (
                isTripletForRequest(cpscRequest, loggedOnTepngUser) ||
                cpscRequest.auxiliaryEntityRepresentativesList.findIndex(e => e.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) > -1
            )
    }

    function canRecallDossier() {
        return cpscRequest.status === CpscRequestStatus.DossierAwaitingTripletConfirmation ||
            cpscRequest.status === CpscRequestStatus.DossierAwaitingPreliminaryQAQC ||
            cpscRequest.status === CpscRequestStatus.DossierAwaitingCpDomainManager ||
            cpscRequest.status === CpscRequestStatus.DossierAwaitingGM ||
            cpscRequest.status === CpscRequestStatus.DossierAwaitingBudgetOwner ||
            cpscRequest.status === CpscRequestStatus.DossierAwaitingEntityDirector ||
            (cpscRequest.status === CpscRequestStatus.DossierFullyEndorsed && cpscRequest.aggregatedCpscRequestId == null && !cpscRequest.hasBeenScheduled)

    }

    function canViewSlide() {
        if (isTripletForRequest(cpscRequest, loggedOnTepngUser))
            return true
        if (cpscRequest.auxiliaryEntityRepresentativesList.findIndex(e => e.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) > -1)
            return true
        if ((cpscRequest.budgetEntity ?? cpscRequest.businessArea).directorsList.findIndex(e => e.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) > -1)
            return true
        if ((cpscRequest.budgetEntity ?? cpscRequest.businessArea).budgetOwnersList.findIndex(e => e.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) > -1)
            return true
        if (cpscRequest.businessArea.cpDomain.domainManagersList.findIndex(e => e.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) > -1)
            return true
        if ((cpscRequest.budgetEntity ?? cpscRequest.businessArea).costControllersList.findIndex(e => e.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) > -1)
            return true
        if (cpscRequest.businessArea.cpDomain.contractEngineersList.findIndex(e => e.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) > -1)
            return true
        if (loggedOnTepngUser.roles.includes(GlobalRole.CpscSecretary) || loggedOnTepngUser.roles.includes(GlobalRole.GCC) || loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember))
            return true
        return false
    }

    function viewRequest() {
        navigate(`/edit-request/${cpscRequest.id}`)
    }

    function refreshDossier() {
        MySwal.fire(preloader);
        callServerAPI('GET', `/api/CpscRequest/GetARequest?id=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                setCpscRequest(response.data.data);
                setDossierRefreshCount(count => Number(count) + 1)
                MySwal.close();
            })

    }

    useEffect(() => {
        const loadDossierPdf = async () => {
            MySwal.fire(preloader);
            callServerAPI('GET', `/api/CpscRequest/GetDossierPdf?requestId=${id}`, null, true, 'blob')
                .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                    setDossierPDFObjectUrl(URL.createObjectURL(response.data));
                    MySwal.close();
                })
        }

        if (cpscRequest?.status != null && cpscRequest?.status !== CpscRequestStatus.DossierPreparationOngoing) {
            loadDossierPdf();
        }
    }, [id, cpscRequest?.status, dossierRefreshCount])

    /**  @param {Dossier} dossier  */
    const setDossier = (dossier) => {
        setCpscRequest(cpscRequest => ({ ...cpscRequest, dossier: dossier }));
    }


    /**
     * @param {string} dossierSlideName
     * @param {CpscRequest} cpscRequest
     * @return {string}
     */
    function getDossierStepperSlideIconClass(dossierSlideName, cpscRequest) {
        if (isDossierSlideCompleted(dossierSlideName, cpscRequest)) return "stepperCompletedStepIcon"
        if (getDossierSlideRequirement(cpscRequest, dossierSlideName) === DossierSlideRequirement.Optional) return "stepperOptionalStepIcon"
        return "stepperRequiredStepIcon"
    }


    function getCurrentSlideRender() {
        const currentSlideName = getSlideName(cpscRequest, currentSlideNumber)
        if (!canViewSlide() || currentSlideName == null) return <SlideDisabled />
        switch (currentSlideName) {
            case DossierSlideName.PurposeAndDescription: return <PurposeAndDescriptionSlide readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} dossierRefreshCount={dossierRefreshCount} />
            case DossierSlideName.ProjectOperationsPlanning: return <ProjectOperationsPlanningSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} mobileDownloadPdf={mobileDownloadPdf} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} />
            case DossierSlideName.ApprovalGovernance: return <ApprovalGovernanceSlide readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.CfrInitiatives: return <CfrInitiativesSlide dossierRefreshCount={dossierRefreshCount} mobileDownloadPdf={mobileDownloadPdf} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.HseEvaluationCriteria: return <HseEvaluationCriteria dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.TechnicalEvaluationCriteria: return <TechnicalEvaluationCriteria dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ContractualEvaluation: return <ContractualEvaluationSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ContractualEvaluationCriteria: return <ContractualEvaluationCriteriaSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ContractSpendEstimation: return <ContractSpendEstimationSlide dossierRefreshCount={dossierRefreshCount} mobileDownloadPdf={mobileDownloadPdf} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ContractualProvisions: return <ContractualProvisionsSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.Recommendation: return <RecommendationSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ProcurementStrategyHighlights: return <ProcurementStrategyHighlightsSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ProcurementStrategy: return <ProcurementStrategyDetailsSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.BudgetPlanningInformation: return <BudgetPlanningSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.BudgetConfirmation: return <BudgetConfirmationSlide readOnly={!isDossierEditable()} cpscRequest={cpscRequest} dossier={cpscRequest} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} mobileDownloadPdf={mobileDownloadPdf} deletePdf={DeletePdf} />
            case DossierSlideName.SavingsCostOptimization: return <SavingsSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} mobileDownloadPdf={mobileDownloadPdf} setDossier={setDossier} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} />
            case DossierSlideName.MarketIntelligence: return <MarketIntelligenceSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} mobileDownloadPdf={mobileDownloadPdf} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ProposedBidders: return <ProposedBiddersList dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} mobileDownloadPdf={mobileDownloadPdf} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.ContractHistory: return <CurrentContractHistorySlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.NigerianContent: return <NigerianContentSlide readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.TechnicalEvaluation: return <TechnicalEvaluationSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} mobileDownloadPdf={mobileDownloadPdf} cpscRequest={cpscRequest} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} setDossier={setDossier} />
            case DossierSlideName.HseEvaluation: return <HseEvaluationSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} mobileDownloadPdf={mobileDownloadPdf} cpscRequest={cpscRequest} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} setDossier={setDossier} />
            case DossierSlideName.CommercialEvaluation: return <CommercialEvaluationSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} setDossier={setDossier} />
            case DossierSlideName.DueDiligenceHumanRights: return <DueDiligenceHumanRightsSlide readOnly={!isDossierEditable()} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.HighRiskServices: return <HighRiskIdentificationSlide readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.RisksAndOpportunities: return <RisksAndOpportunitiesSlide dossierRefreshCount={dossierRefreshCount} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setDossier={setDossier} />
            case DossierSlideName.CatManInvolvement: return <CatManInvolvementSlide readOnly={!isDossierEditable()} cpscRequest={cpscRequest} dossier={cpscRequest} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} mobileDownloadPdf={mobileDownloadPdf} deletePdf={DeletePdf} />
            case DossierSlideName.RiskLevelAssessment: return <RiskAssesmentSlide readOnly={!isDossierEditable()} cpscRequest={cpscRequest} dossier={cpscRequest} mobileDownloadPdf={mobileDownloadPdf} uploadPdf={UploadPdf} downloadPdf={DownloadPdf} deletePdf={DeletePdf} />
            default: return <AdhocDossierSlide slideName={currentSlideName} readOnly={!isDossierEditable()} cpscRequest={cpscRequest} setCpscRequest={setCpscRequest} setCurrentSlideNumber={setCurrentSlideNumber} />
        }
    }

    return (
        cpscRequest &&
        <Box m="1.0rem 1.0rem">
            <Box sx={{ width: '100%' }}>
                <Stack >
                    <Paper>
                        <FlexBetween>
                            <FlexBetween>
                                <IconButton onClick={handleHistory}>
                                    <ArrowBackIos sx={{ fontSize: '13px' }} />
                                </IconButton>
                                <Typography variant="h6" color={theme.palette.text.secondary} fontWeight="bold" sx={{ margin: '1.0rem', }}>
                                    {cpscRequest?.cpscCategory} Template: {cpscRequest?.contractTitle}
                                </Typography>
                            </FlexBetween>

                        </FlexBetween>
                    </Paper>
                </Stack>
                <br />
                <Stack >
                    <Paper>
                        {isDesktop &&
                            <ButtonGroup fullWidth style={{ borderColor: 'red', width: '100%', height: '40px', textTransform: 'none' }} variant="text">
                                <Button onClick={refreshDossier} > <Replay />&nbsp;Refresh</Button>
                                <Button onClick={viewRequest} > <RemoveRedEye />&nbsp; Request</Button>
                                {isDossierEditable() && <Button onClick={() => setOpenAdhocDossierSlideCreateForm(true)} > <PostAdd />&nbsp; Add Adhoc Slide</Button>}
                                {isDossierEditable() && <Button onClick={() => setOpenUpdateSlideSequenceForm(true)} > <Sort />&nbsp; Slide Sequence</Button>}
                                <Button onClick={downloadDocumentPdf} > <PictureAsPdf />&nbsp; View PDF</Button>
                                {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && canRecallDossier() && <Button onClick={recallDossier} > <KeyboardReturn />&nbsp; Recall</Button>}
                                {cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing &&
                                    <>
                                        <Button color='secondary' disabled={currentSlideNumber === 1} onClick={previousSlide}> <ArrowBackIos />&nbsp;Back</Button>
                                        <Button color='secondary' disabled={currentSlideNumber === cpscRequest.dossierSlideSequence.length} onClick={nextSlide} >Next&nbsp;<ArrowForwardIos /> </Button>
                                    </>
                                }
                                {isDossierEditable() && <Button style={{ display: 'block', borderColor: '#d2deef' }} onClick={updateDossier} type='submit' ><Save />&nbsp;Save</Button>}
                            </ButtonGroup>
                        }
                    </Paper>
                </Stack>
                {!isDesktop &&
                    <>
                        <br />
                        <Stack>
                            <Paper>
                                <ButtonGroup style={{ borderColor: 'red', height: '40px', textTransform: 'none' }} variant="text">
                                    <Button onClick={refreshDossier} > Refresh</Button>
                                    <Button onClick={viewRequest} > &nbsp; Request</Button>
                                    {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && canRecallDossier() && <Button onClick={recallDossier} > <KeyboardReturn />&nbsp; Recall</Button>}
                                    {cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing &&
                                        <>
                                            <Button disabled={currentSlideNumber === 1} onClick={previousSlide}> <ArrowBackIos />&nbsp;Back</Button>
                                            <Button disabled={currentSlideNumber === cpscRequest.dossierSlideSequence.length} onClick={nextSlide} >Next&nbsp;<ArrowForwardIos /> </Button>
                                        </>
                                    }
                                    {isDossierEditable() && <Button style={{ display: 'block', borderColor: '#d2deef' }} onClick={updateDossier} type='submit' ><Save />&nbsp;Save</Button>}
                                </ButtonGroup>
                            </Paper>
                        </Stack>
                        <br />
                        <Stack>
                            <Paper>
                                <ButtonGroup style={{ borderColor: 'red', height: '40px', textTransform: 'none' }} variant="text">
                                    <Button onClick={downloadDocumentPdf} > &nbsp; PDF</Button>
                                    {isDossierEditable() && <Button onClick={() => setOpenAdhocDossierSlideCreateForm(true)} > <PostAdd />&nbsp; Adhoc Slide</Button>}
                                    {isDossierEditable() && <Button onClick={() => setOpenUpdateSlideSequenceForm(true)} > <Sort />&nbsp; Slide Sequence</Button>}

                                </ButtonGroup>
                            </Paper>
                        </Stack>
                    </>

                }

            </Box>
            {!isDesktop && (
                <div style={{ marginTop: 10, width: '100%', padding: '5px' }}>
                    <Chip color='default' style={{ fontWeight: 500, fontSize: 14, borderRadius: 5, backgroundColor: 'white', color: '#b77f01', width: '100%' }} variant='outlined' title={cpscRequest.status} label={cpscRequest.status} />
                </div>
            )}
            <Box sx={{ width: '100%', height: '100%' }}>
                <>
                    <Box mt="20px" gap="20px">
                        {cpscRequest.status === CpscRequestStatus.DossierAwaitingTripletConfirmation &&

                            <div className='col-12 px-4 mt-1 py-4' style={{ margin: '0px !important' }}>
                                <div className='mt-4 mb-4 shadow-sm bg-white px-4 py-4' style={{ paddingTop: '0px !important' }}>
                                    <div className=''>

                                        <Table striped bordered hover size="sm">
                                            <thead style={{ fontSize: '12px' }}>
                                                <tr><td className='role'>Role</td><td>Assignee</td><td>Dossier Completion Confirmation Status</td></tr>
                                            </thead>
                                            <tbody style={{ fontSize: '12px' }}>
                                                <tr>
                                                    <td className='role' > <Radio disabled={cpscRequest.contractEngineerConfirmationDate != null || !isContractEngineerForRequest(cpscRequest, loggedOnTepngUser)} checked={tripletValidationRole === DossierValidationRole.ContractEngineer} onClick={() => setTripletValidationRole(DossierValidationRole.ContractEngineer)} /> Contract Engineer</td>
                                                    <td>{cpscRequest.contractEngineer}</td>
                                                    <td>{cpscRequest.contractEngineerConfirmationDate != null ? 'Confirmed' : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td className='role' > <Radio disabled={cpscRequest.entityRepresentativeConfirmationDate != null || !isEntityRepForRequest(cpscRequest, loggedOnTepngUser)} checked={tripletValidationRole === DossierValidationRole.EntityRepresentative} onClick={() => setTripletValidationRole(DossierValidationRole.EntityRepresentative)} />Technical Administrator</td>
                                                    <td>{cpscRequest.entityRepresentative}</td>
                                                    <td>{cpscRequest.entityRepresentativeConfirmationDate != null ? 'Confirmed' : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td className='role' > <Radio disabled={cpscRequest.costControllerConfirmationDate != null || !isCostControllerForRequest(cpscRequest, loggedOnTepngUser)} checked={tripletValidationRole === DossierValidationRole.CostController} onClick={() => setTripletValidationRole(DossierValidationRole.CostController)} />Cost Controller</td>
                                                    <td>{cpscRequest.costController}</td>
                                                    <td>{cpscRequest.costControllerConfirmationDate != null ? 'Confirmed' : ''}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        {tripletValidationRole &&
                                            <>
                                                <input type="checkbox" checked={tripletConfirmationBoxChecked} onChange={() => setTripletConfirmationBoxChecked(!tripletConfirmationBoxChecked)} /> <span style={{ fontSize: '12px' }}><i>I hereby confirm as the {tripletValidationRole} that this dossier is complete and is ready to be routed for Preliminary QA/QC</i></span>
                                                <br />
                                                &nbsp;&nbsp;
                                                <div className='complete'>
                                                    <Button variant='contained' size='large' color='success' style={{ fontSize: 14, textTransform: 'none' }} disabled={!tripletConfirmationBoxChecked} onClick={tripletDossierConfirmationAccept} ><ThumbUpAlt /> &nbsp;Confirm Dossier Completion</Button>
                                                    &nbsp;&nbsp;
                                                    <Button variant='contained' size='large' color='secondary' style={{ fontSize: 14, textTransform: 'none' }} disabled={!rejectionCommentExistsForUser(cpscRequest, loggedOnTepngUser)} onClick={tripletDossierConfirmationReject} ><ThumbDownAlt /> &nbsp;Reject Dossier Completion</Button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <Grid container>
                            <Grid item xs={12} sm={12} lg={9} style={{ padding: '2px' }}>
                                <div className=' ' style={{ margin: '0px !important' }}>

                                    {/* location if you want header to be just above slide but not affecting navigation */}
                                    <div className='content-desktop'>
                                        <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex' }}>
                                            <div style={{ color: '#023361', fontSize: 13, fontWeight: 'bold', marginTop: '5px', marginBottom: '6px' }}
                                                title={cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing ? getSlideName(cpscRequest, currentSlideNumber) : ""}>
                                                <em> {cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing ? getSlideName(cpscRequest, currentSlideNumber) + ' Slide' : ""}</em>
                                            </div>
                                            <div style={{ flexGrow: 1 }} />

                                            <div className='dossier_stepper' style={{ width: '50%' }} >
                                                <Chip color='default' style={{ fontWeight: 600, fontSize: 12, borderRadius: 5, backgroundColor: 'white', color: '#023361', width: '100%' }} variant='outlined' title={cpscRequest.status} label={`Status: ${cpscRequest.status}`} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='content-mobile'>
                                        {cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing &&
                                            <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex' }}>
                                                <div style={{ color: '#0a58ca', fontSize: 14, fontWeight: 'bold', marginTop: '5px', marginBottom: '6px' }} title={getSlideName(cpscRequest, currentSlideNumber)}>
                                                    {getSlideName(cpscRequest, currentSlideNumber) + ' Slide'}
                                                </div>
                                                <div style={{ flexGrow: 1 }} />
                                                <div>
                                                    <Chip style={{ width: 'fitContent' }} variant='outlined' color='primary' label={currentSlideNumber} />
                                                    <span style={{ color: 'gray' }}>/</span>
                                                    <Chip variant='outlined' color='primary' label={cpscRequest.dossierSlideSequence.length} />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className=' shadow-sm bg-white px-2 py-4' style={{ paddingTop: '0px !important' }}>
                                        <div className='pagelayout'>
                                            {cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing && getCurrentSlideRender()}
                                            {cpscRequest.status !== CpscRequestStatus.DossierPreparationOngoing && dossierPDFObjectUrl != null &&
                                                <>
                                                    <div className='content-desktop'>
                                                        <div style={{ overflowY: 'auto', height: 'calc(100vh - 250px)' }}>
                                                            <iframe loading="lazy" src={dossierPDFObjectUrl + '#view=FitH'} height="98%" width="100%" title="DossierPDF" ></iframe>
                                                        </div>
                                                    </div>
                                                    <div className='content-mobile'>
                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                            <Button color='success' onClick={() => window.open(dossierPDFObjectUrl)} variant="contained" ><PictureAsPdf fontSize='medium' />&nbsp;View Dossier Pdf</Button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={3} style={{ padding: '2px' }}>
                                {openAdhocDossierSlideCreateForm &&
                                    <AdhocDossierSlideCreateForm cpscRequest={cpscRequest} setCpscRequest={setCpscRequest} openAdhocDossierSlideCreateForm={openAdhocDossierSlideCreateForm} setOpenAdhocDossierSlideCreateForm={setOpenAdhocDossierSlideCreateForm} />
                                }
                                {openUpdateSlideSequenceForm &&
                                    <UpdateSlideSequenceForm cpscRequest={cpscRequest} setCpscRequest={setCpscRequest} openUpdateSlideSequenceForm={openUpdateSlideSequenceForm} setOpenUpdateSlideSequenceForm={setOpenUpdateSlideSequenceForm} />
                                }
                                <div className=' ' style={{ margin: '0px !important' }}>
                                    <div style={{ marginTop: 10, width: '100%' }}>
                                        {(ENVIRONMENT === Environment.Dev || ENVIRONMENT === Environment.Test) && isTripletForRequest(cpscRequest, loggedOnTepngUser) && cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing &&
                                            <>
                                                <Button size='small' variant="contained" onClick={completeDossierWithFakeData} style={{ fontSize: 13, width: '100%', textTransform: 'none', marginBottom: '10px' }} color='secondary'> <Dataset />&nbsp;Complete With Sample Data</Button>
                                            </>
                                        }
                                        {loggedOnTepngUser.email.toUpperCase() === cpscRequest.contractEngineer.toUpperCase() && cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing && isDossierCompleted(cpscRequest) &&
                                            <Button size='small' variant="contained" onClick={requestDossierValidation} style={{ fontSize: 13, width: '100%', textTransform: 'none' }} color='secondary'> <Send />&nbsp;Request Dossier Confirmation</Button>
                                        }
                                        {checkUserCanValidateDossier(cpscRequest, loggedOnTepngUser) &&
                                            <>
                                                <ButtonGroup style={{ width: '100%' }} variant="contained" size='small'>
                                                    <Button onClick={handleAcceptDossier} style={{ fontSize: 12, width: '50%', textTransform: 'none' }} color='success'><ThumbUpAlt /> &nbsp;{getAcceptDossierButtonLabel(cpscRequest.status)} Dossier</Button>
                                                    <Button onClick={handleRejectDossier} style={{ fontSize: 12, width: '50%', textTransform: 'none' }} color='secondary'> <ThumbDownAlt /> &nbsp;Reject  Dossier</Button>
                                                </ButtonGroup>
                                            </>
                                        }
                                    </div>
                                    {(cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing) &&
                                        <>
                                            <div className='dossier_stepper' >
                                                <div className='tab' style={{ width: '100%' }}>
                                                    <div>
                                                        <button className={showComments === false ? 'slides_active' : ' toggle'} onClick={() => { setShowStepper(true); setShowComments(false); }}> Slides</button>
                                                        <button className={showComments === true ? 'slides_active' : ' toggle'} onClick={() => { setShowStepper(false); setShowComments(true); }}> Comments</button>
                                                    </div>
                                                </div>
                                                <div hidden={!showComments}>
                                                    <div className='comment-section mt-4 mb-4' style={{ paddingTop: '0px !important' }}>
                                                        <DossierCommentsEditor cpscRequest={cpscRequest} dossier={cpscRequest} currentSlideNumber={currentSlideNumber} dossierRefreshCount={dossierRefreshCount} setCpscRequest={setCpscRequest} />
                                                    </div>
                                                </div>
                                                <div hidden={!showStepper} className='dossier_stepper mt-4 mb-4 shadow-sm bg-white px-4 py-4 ' style={{ paddingTop: '0px !important' }}>
                                                    <Stepper className='' orientation="vertical" activeStep={currentSlideNumber - 1} nonLinear >
                                                        {cpscRequest.dossierSlideSequence.sort((a, b) => a.slideNumber > b.slideNumber ? 1 : -1).map((slide, index) => (
                                                            <Step key={slide.slideNumber} /* completed={isDossierSlideCompleted(slide, cpscRequest) && getDossierSlideRequirement(cpscRequest, slide) !== DossierSlideRequirement.Optional} */ >
                                                                <StepButton color="inherit" onClick={handleStep(slide.slideNumber)} >
                                                                    <StepLabel StepIconProps={{ classes: { text: "stepperIconText", root: getDossierStepperSlideIconClass(slide.dossierSlideName, cpscRequest) } }}
                                                                        classes={{ active: 'stepperActiveStepLabel', label: 'stepperStepLabel', labelContainer: 'stepperStepLabelContainer', completed: 'stepperCompletedStepLabel' }}> {slide.dossierSlideName}</StepLabel>
                                                                </StepButton>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </div>
                                            </div>
                                            <div className='content-mobile'>
                                                <div className='tab' style={{ width: '100%' }}>
                                                    <div>
                                                        <button className='slides_active_comment'> Comments</button>
                                                    </div>
                                                </div>
                                                <div >
                                                    <div className='comment-section mt-4 mb-4' style={{ paddingTop: '0px !important' }}>
                                                        <DossierCommentsEditor cpscRequest={cpscRequest} dossier={cpscRequest} currentSlideNumber={currentSlideNumber} dossierRefreshCount={dossierRefreshCount} setCpscRequest={setCpscRequest} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {(cpscRequest.status !== CpscRequestStatus.DossierPreparationOngoing) &&
                                        <>
                                            <div className='tab' style={{ width: '100%' }}>
                                                <div>
                                                    <button className='slides_active_comment'> Comments</button>
                                                </div>
                                            </div>
                                            <div >
                                                <div className='comment-section mt-4 mb-4' style={{ paddingTop: '0px !important', overflowY: 'auto', height: 'calc(100vh - 225px)' }}>
                                                    <DossierCommentsEditor cpscRequest={cpscRequest} dossier={cpscRequest} currentSlideNumber={currentSlideNumber} dossierRefreshCount={dossierRefreshCount} setCpscRequest={setCpscRequest} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            </Box>
        </Box >
    )
}

export default DossierTemplate