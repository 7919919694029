import React, { useState } from 'react'
import { Button, TextField } from '@mui/material';
import { FormEditMode } from '../../helpers/Constants';
import MyNumericFormat from '../MyNumericFormat';
import { formatDateyyyymmdd } from '../../helpers/Helpers';



/**
 * @typedef {Object} CurrentContractHistoryFormProps
 * @property {CpscRequest} cpscRequest
 * @property {Number} formEditMode
 * @property {ContractHistories} contractHistory
 * @property {updateDossierCallback} setDossier
 * @property {Function} setDisplayAddContractHistory
 * @property {Function} setDisplayContractHistoryForm
 */

/**
 * @param {CurrentContractHistoryFormProps}  props
 * @return {import('react').ReactElement}
 */

function CurrentContractHistoryForm(props) {
    /** @type [ContractHistories, Function] */
    const [data, setData] = useState(props.contractHistory)


    const addContractHistory = (event) => {
        event.preventDefault();
        props.setDossier({ ...props.cpscRequest.dossier, contractHistorySlide: { ...props.cpscRequest.dossier.contractHistorySlide, contractHistories: [...props.cpscRequest.dossier.contractHistorySlide.contractHistories, data] } })
        props.setDisplayContractHistoryForm(false);
        props.setDisplayAddContractHistory(true);
    }

    const updateContractHistory = (event) => {
        event.preventDefault();
        props.setDossier({ ...props.cpscRequest.dossier, contractHistorySlide: { ...props.cpscRequest.dossier.contractHistorySlide, contractHistories: props.cpscRequest.dossier.contractHistorySlide.contractHistories.map(item => item.serialNo === props.contractHistory.serialNo ? data : item) } })
        props.setDisplayContractHistoryForm(false);
        props.setDisplayAddContractHistory(true);
    }

    /** @param {string} name, @param {any} value  */
    const updateDataFieldValue = (name, value) => {
        setData({ ...data, [name]: value })
    }

    /** @param {string} name, @param {any} value  */
    const updateDataFieldDateValue = (name, value) => {
        setData({ ...data, [name]: value === "" ? null : value })
    }

    const validate = (data) => {
        let notValid = (Boolean(data.contractor) === false || Boolean(data.requestedCeilingIncrease) === false || Boolean(data.service) === false || Boolean(data.contractStartDate) === false || Boolean(data.contractEndDate) === false || Boolean(data.initialApprovedContractCeiling) === false || Boolean(data.totalCeilingIncrease) === false || Boolean(data.spendToDate) === false)
        return !notValid
    }
    return (
        <form>
            <div className='row'>
                <div className='col1'>
                    <div >
                        <label className='title-label'>Contractor <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" value={data.contractor} onChange={(e) => updateDataFieldValue('contractor', e.target.value)} id='contractor' placeholder="  Contractor " className='form-control input-font bold' />

                        </div>
                    </div>
                </div>
                <div className='col2'>
                    <div >
                        <label className='title-label'>Service  <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" value={data.service} onChange={(e) => updateDataFieldValue('service', e.target.value)} id='service' placeholder=" Service " className='form-control input-font bold' />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col1'>
                    <div >
                        <label className='title-label'>Contract Start Date <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" type='date' value={formatDateyyyymmdd(data.contractStartDate)} onChange={(e) => updateDataFieldDateValue('contractStartDate', e.target.value)} placeholder="  " id='contractStartDate' className='form-control input-font bold' />

                        </div>
                    </div>
                </div>
                <div className='col2'>
                    <div >
                        <label className='title-label'>Contract End Date <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" type='date' value={formatDateyyyymmdd(data.contractEndDate)} onChange={(e) => updateDataFieldDateValue('contractEndDate', e.target.value)} id='contractEndDate' className='form-control input-font bold' />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col1'>

                    <div >
                        <label className='title-label'>Initial Approved Contract Ceiling Value <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" InputProps={{ inputComponent: MyNumericFormat }} value={data.initialApprovedContractCeiling} onChange={(e) => updateDataFieldValue('initialApprovedContractCeiling', e.target.value)} id='initialApprovedContractCeiling' placeholder=" Initial Approved Contract Ceiling Value  " className='form-control input-font bold' />
                        </div>
                    </div>
                </div>
                <div className='col2'>

                    <div >
                        <label className='title-label'>Total Ceiling value increase <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" InputProps={{ inputComponent: MyNumericFormat }} value={data.totalCeilingIncrease} onChange={(e) => updateDataFieldValue('totalCeilingIncrease', e.target.value)} id='totalCeilingIncrease' placeholder=" total Ceiling Increase " className='form-control input-font bold' />
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div className='row'>
                <div className='col1'>

                    <div >
                        <label className='title-label'>Spend to Date   <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" InputProps={{ inputComponent: MyNumericFormat }} value={data.spendToDate} onChange={(e) => updateDataFieldValue('spendToDate', e.target.value)} id='spendToDate' placeholder="   " className='form-control input-font bold' />
                        </div>
                    </div>
                </div>
                <div className='col2'>

                    <div >
                        <label className='title-label'>Requested Ceiling Value Increase (current) <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" InputProps={{ inputComponent: MyNumericFormat }} value={data.requestedCeilingIncrease} onChange={(e) => updateDataFieldValue('requestedCeilingIncrease', e.target.value)} id='requestedCeilingIncrease' placeholder="  " className='form-control input-font bold' />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div>
                <Button variant='contained' disabled={!validate(data)} color='success' onClick={props.formEditMode === FormEditMode.CREATE ? addContractHistory : updateContractHistory} >
                    {props.formEditMode === FormEditMode.CREATE ? "Save New Contract History record" : "Update Contract History record"}
                </Button>
            </div>
        </form>
    )
}

export default CurrentContractHistoryForm