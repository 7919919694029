import { RemunerationModel } from '../../helpers/Constants';
import { PurposeAndDescriptionSlideField, PurposeAndDescriptionSlideTooltip } from '../../helpers/DossierFieldConstants';
import React from 'react'
import { MenuItem, Select } from '@mui/material';
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';


const remunerationModelOptions = [{ value: RemunerationModel.UnitRate, label: RemunerationModel.UnitRate }, { value: RemunerationModel.DayRate, label: RemunerationModel.DayRate }, { value: RemunerationModel.Lumpsum, label: RemunerationModel.Lumpsum }, { value: RemunerationModel.Reimbursible, label: RemunerationModel.Reimbursible }]


/**
 * @typedef {Object} PurposeAndDescriptionSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 * @property {number} dossierRefreshCount
 */

/**
 * @param {PurposeAndDescriptionSlideProps}  props
 * @return {import('react').ReactElement}
 */


function PurposeAndDescriptionSlide(props) {

  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, purposeAndDescriptionSlide: { ...props.cpscRequest.dossier.purposeAndDescriptionSlide, [name]: value } })
  }


  return (
    <div>
      <form style={{ fontSize: '12px' }}>

        <div className="form-group">
          <label className=' title-label'>{getSlideTooltip(props.cpscRequest, PurposeAndDescriptionSlideTooltip.objective)}CPSC Objective <span className='important'>*</span></label>
          <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.purposeAndDescriptionSlide.objective} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(PurposeAndDescriptionSlideField.objective, editorHtmlString)} />
        </div>
        <br />
        <div className="form-group">
          <label className=' title-label' >{getSlideTooltip(props.cpscRequest, PurposeAndDescriptionSlideTooltip.detailedGeographicScope)}Detailed geographic scope<span className='important'>*</span></label>
          <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.purposeAndDescriptionSlide.detailedGeographicScope} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(PurposeAndDescriptionSlideField.detailedGeographicScope, editorHtmlString)} />
        </div>
        <br />
        <div className="form-group">
          <label className=' title-label'>{getSlideTooltip(props.cpscRequest, PurposeAndDescriptionSlideTooltip.background)}Background / Reason for need<span className='important'>*</span></label>
          <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.purposeAndDescriptionSlide.background} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(PurposeAndDescriptionSlideField.background, editorHtmlString)} />
        </div>
        <br />
        <div className="form-group">
          <label className=' title-label'>{getSlideTooltip(props.cpscRequest, PurposeAndDescriptionSlideTooltip.sowOrMaterialDescription)}Detailed description of services or goods and their functions<span className='important'>*</span></label>
          <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.purposeAndDescriptionSlide.sowOrMaterialDescription} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(PurposeAndDescriptionSlideField.sowOrMaterialDescription, editorHtmlString)} />
        </div>
        <br />
        <div className=''>
          <div >
            <label className='title-label'>{getSlideTooltip(props.cpscRequest, PurposeAndDescriptionSlideTooltip.remunerationModel)}Remuneration Model <span className='important'>*</span></label>
          </div>
          <div className="form-group mb-0">
            <Select disabled={props.readOnly} fullWidth variant="outlined" size="small" name={PurposeAndDescriptionSlideField.remunerationModel} value={props.cpscRequest.dossier.purposeAndDescriptionSlide.remunerationModel}
              onChange={(e) => updateDataFieldValue(PurposeAndDescriptionSlideField.remunerationModel, e.target.value)} >
              {remunerationModelOptions.map((options, index) => {
                return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
              })}
            </Select>
          </div>
        </div>
        <br />
      </form>
    </div>
  )
}

export default PurposeAndDescriptionSlide