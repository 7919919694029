import React, { useEffect, useState } from 'react';
import { Button, Stack, Pagination, Grid, MenuItem, TextField, Box, Paper, } from '@mui/material';
import Swal from 'sweetalert2'
import { getNameFromTotalEnergiesEmail, callServerAPI, canEditBusinessArea } from '../../helpers/Helpers';
import { preloader, FormEditMode, GlobalRole, globalStore } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import Table from 'react-bootstrap/Table';
import Replay from '@mui/icons-material/Replay';
import { Add } from '@mui/icons-material';
import Header from '../../components/TitleHeader';
import CreateEditBusinessArea from './CreateEditBusinessArea';
const MySwal = withReactContent(Swal);

function BusinessArea() {
    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [businessAreaIdToEdit, setBusinessAreaIdToEdit] = useState(null)
    /** @type [BusinessArea[],import('react').Dispatch<import('react').SetStateAction<BusinessArea[]>>] ] */
    const [businessAreas, setBusinessAreas] = useState([]);
    const [businessAreaEditMode, setBusinessAreaEditMode] = useState(FormEditMode.CREATE);
    /** @type [BusinessArea[],import('react').Dispatch<import('react').SetStateAction<BusinessArea[]>>] ] */
    const [filteredBusinessAreas, setFilteredBusinessAreas] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [numOfPages, setNumOfPages] = useState(0);
    /** @type [BusinessArea[],import('react').Dispatch<import('react').SetStateAction<BusinessArea[]>>] ] */
    const [currentPageRecords, setCurrentPageRecords] = useState([]);

    const [openCreateBusinessAreaForm, setOpenCreateBusinessAreaForm] = React.useState(false);

    const handleOpenCreateBusinessAreaForm = () => {
        setBusinessAreaEditMode(FormEditMode.CREATE);
        setOpenCreateBusinessAreaForm(true);
    };
    const getAllBusinessAreas = async () => {
        MySwal.fire(preloader);
        callServerAPI('GET', '/api/BusinessArea/GetAllBusinessAreas', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<BusinessArea[]>> } */ response) {
                setBusinessAreas(response.data.data.sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : 1));
                setFilteredBusinessAreas(response.data.data);
                MySwal.close();
            })

    }
    function refreshRequests() {
        getAllBusinessAreas();
    }
    useEffect(() => {
        getAllBusinessAreas();
    }, [])

    useEffect(() => {
        setFilteredBusinessAreas(businessAreas.filter(e =>

            (searchQuery === "" || e.title.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, businessAreas])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredBusinessAreas.length / recordsPerPage))
    }, [recordsPerPage, filteredBusinessAreas])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredBusinessAreas.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredBusinessAreas])

    /** @param {BusinessArea} businessArea */
    const handleEdit = (businessArea) => {
        setBusinessAreaIdToEdit(businessArea.id)
        setBusinessAreaEditMode(FormEditMode.EDIT);
        setOpenCreateBusinessAreaForm(true);
    }
    return (
        <Box m="1.0rem 1.0rem">
            <Header title="Business Areas " />
            <Box>
                <Paper>
                    <Box mt="20px" p='1.0rem' gap="20px">
                        <Grid container className=' d-flex  justify-content-between'>

                            <Grid item xs={12} sm={6} lg={8}  >
                                <div className='mb-2 mt-1' >
                                    <input type="text" className="form-control input-font bold" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search by Title...' />
                                </div>

                            </Grid>
                            <Grid style={{ float: 'right' }} item xs={12} sm={4} lg={4} >
                                <div className=' d-flex  justify-content-end'>
                                    <Button variant="contained" onClick={refreshRequests} size='medium' style={{ textTransform: 'none', fontSize: 13 }}> <Replay />&nbsp; Refresh</Button>&nbsp;
                                    <Button disabled={!loggedOnTepngUser?.roles?.includes(GlobalRole.Admin)} variant="contained" color='secondary' style={{ textTransform: 'none', fontSize: 13 }} onClick={handleOpenCreateBusinessAreaForm} > <Add /> &nbsp;New Business Area</Button>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table className='table-res' responsive striped bordered >
                                    <thead >
                                        <tr style={{ fontSize: '11px', }}>
                                            <th >SN</th>
                                            <th>Business Area</th>
                                            <th>C&P Domain</th>
                                            <th>Cost Controllers</th>
                                            <th>Budget Owners</th>
                                            <th>Directors</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageRecords.map((businessArea, index) => {
                                            return <tr style={{ fontSize: '11px', }} key={index}>
                                                <td>{index + 1 + ((currentPage - 1) * recordsPerPage)}</td>
                                                <td >{businessArea.title}</td>
                                                <td>{businessArea.cpDomain.title}</td>
                                                <td>{businessArea.costControllersList.map((name, index) => {
                                                    return (
                                                        <div key={index}>{getNameFromTotalEnergiesEmail(name)}</div>
                                                    )
                                                })}</td>
                                                <td>{businessArea.budgetOwnersList.map((name, index) => {
                                                    return (
                                                        <div key={index}>{getNameFromTotalEnergiesEmail(name)}</div>
                                                    )
                                                })}</td>
                                                <td>{businessArea.directorsList.map((name, index) => {
                                                    return (
                                                        <div key={index}>{getNameFromTotalEnergiesEmail(name)}</div>
                                                    )
                                                })}</td>

                                                <td> <Button disabled={!canEditBusinessArea(businessArea,loggedOnTepngUser)} variant='contained' size='small' color="secondary" onClick={() => handleEdit(businessArea)} >Edit</Button>&nbsp;&nbsp;</td>
                                            </tr>
                                        })}
                                        {currentPageRecords.length === 0 ? <tr>
                                            <td colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No records....</p></td>
                                        </tr> : ''}


                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>

                        <br />
                        <nav>
                            <div className=" d-flex justify-content-between ">
                                <div className=" d-flex justify-content-between ">
                                    <div className='rowperpage'>Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} style={{ width: "60%" }}>
                                        <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </TextField>
                                    </Grid>
                                </div>
                                <Stack spacing={2}>
                                    <Pagination color="primary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>
                            </div>
                            <br />


                        </nav>

                        {openCreateBusinessAreaForm &&
                            <CreateEditBusinessArea getAllBusinessAreas={getAllBusinessAreas} updateBusinessAreas={businessAreas => setBusinessAreas(businessAreas)} businessAreas={businessAreas} businessAreaIdToEdit={businessAreaIdToEdit} formEditMode={businessAreaEditMode} openCreateBusinessAreaForm={openCreateBusinessAreaForm} setOpenCreateBusinessAreaForm={setOpenCreateBusinessAreaForm} />
                        }


                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}
export default BusinessArea