import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content';
import { Button, ButtonGroup, Radio } from '@mui/material'
import Table from 'react-bootstrap/Table';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MyRichTextEditor from '../../components/MyRichTextEditor';
import { formatShortDateString, callServerAPI, getNameFromTotalEnergiesEmail } from '../../helpers/Helpers';
import { AgendaItemType, preloader, MoMStatus, GlobalRole, globalStore } from '../../helpers/Constants';
import { useParams } from 'react-router-dom';
import { Save, Send, PictureAsPdf, ThumbDownAlt, Diversity3, ThumbUpAlt } from '@mui/icons-material';
import DOMPurify from 'dompurify';

const MySwal = withReactContent(Swal);
axios.defaults.withCredentials = true;

/**
 * @typedef {Object}  MinutesOfMeetingProps
 * @property {Meeting} meeting
 * @property {Function} setMeeting
 * @property {Function} enqueueSnackbar
 * @property {CpscRequestsForMeetingDto} meetingCpscRequestList
 */

/**
 * @param { MinutesOfMeetingProps}props
 * @return {import('react').ReactElement}
 */
function MinutesOfMeeting(props) {

    const { id } = useParams();
    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");

    /** @type [UpdateAgendaItemMoMRequestDto[],import('react').Dispatch<import('react').SetStateAction<UpdateAgendaItemMoMRequestDto[]>>] */
    const [agendaItemMoMRequest, setAgendaItemMoMRequest] = useState(props.meeting.agenda.map(
        agendaItem => ({
            agendaItemId: agendaItem.id,
            commentsAndConsiderations: agendaItem.commentsAndConsiderations,
            followOnAction: agendaItem.followOnAction
        })
    ))
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [momReviewFieldName, setMomReviewFieldName] = useState(null)

    const [momReviewBoxChecked, setMomReviewBoxChecked] = useState(false)
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [momApprovalFieldName, setMomApprovalFieldName] = useState(null)

    const [momApprovalBoxChecked, setMomApprovalBoxChecked] = useState(false)
    const [showRejectionCommentBox, setShowRejectionCommentBox] = useState(false)

    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [momPdfObjectUrl, setMoMPdfObjectUrl] = useState(null);


    useEffect(() => {
        setShowRejectionCommentBox((!momReviewBoxChecked && momReviewFieldName != null) || (!momApprovalBoxChecked && momApprovalFieldName != null))
    }, [momReviewBoxChecked, momApprovalBoxChecked, momReviewFieldName, momApprovalFieldName])


    function handleCPSCSecretaryReviewRejection() {
        /** @param {Meeting}  meeting, @return {Boolean} */
        const validate = (meeting) => {
            if (!(Boolean(meeting.momRejectedComments))) {
                return false
            }
            return true
        }
        if (!validate(props.meeting)) {
            MySwal.fire({
                title: 'Alert',
                html: `<small> You have to add a rejection comment </small>`,
                icon: 'info',
                confirmButtonColor: '#02b702',
                confirmButtonText: 'Ok'
            })

        } else {
            MySwal.fire({
                title: 'Alert',
                html: `<small>Are you sure you want to reject this Minutes of Meeting?</small>`,
                icon: 'info',
                cancelButtonColor: '#DC143C',
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonColor: '#02b702',
                confirmButtonText: 'Yes'
            })
                .then((result) => {
                    if (result.isConfirmed) cpscSecretaryMoMReviewReject();
                })
        }

    }

    function handleCPSCChairmanRejection() {
        /** @param {Meeting}  meeting, @return {Boolean} */
        const validate = (meeting) => {
            if (!(Boolean(meeting.momRejectedComments))) {
                return false
            }
            return true
        }
        if (!validate(props.meeting)) {
            MySwal.fire({
                title: 'Alert',
                html: `<small> You have to add a rejection comment </small>`,
                icon: 'info',
                confirmButtonColor: '#02b702',
                confirmButtonText: 'Ok'
            })

        } else {
            MySwal.fire({
                title: 'Alert',
                html: `<small>Are you sure you want to reject this Minutes of Meeting?</small>`,
                icon: 'info',
                cancelButtonColor: '#DC143C',
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonColor: '#02b702',
                confirmButtonText: 'Yes'
            })
                .then((result) => {
                    if (result.isConfirmed) CpscChairmanRejectMoM();
                })
        }

    }

    function updateDataField(name, value) {
        const newdata = { ...props.meeting }
        newdata[name] = value
        props.setMeeting(newdata)
    }
    /**
     * @param {string} agendaItemId
     * @param {string} fieldName
     * @param {string} value
     */
    function updateAgendaItemMom(agendaItemId, fieldName, value) {
        setAgendaItemMoMRequest(previousState =>
            previousState.map(agendaItemMoMRequest => agendaItemMoMRequest.agendaItemId === agendaItemId ? { ...agendaItemMoMRequest, [fieldName]: value } : agendaItemMoMRequest)
        );
    }


    function getMinuteOfMeetingPdf() {
        MySwal.fire(preloader)
        callServerAPI('GET', `/api/MinuteOfMeeting/GetMinuteOfMeetingPdf?meetingId=${id}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                window.open(URL.createObjectURL(response.data));

                MySwal.close();
            })

    }
    useEffect(() => {
        function displayMinuteOfMeetingPdf() {
            MySwal.fire(preloader)
            callServerAPI('GET', `/api/MinuteOfMeeting/GetMinuteOfMeetingPdf?meetingId=${id}`, null, true, 'blob')
                .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                    setMoMPdfObjectUrl(URL.createObjectURL(response.data));
                    MySwal.close();
                })
        }
        if (props.meeting.momStatus !== MoMStatus.Draft) { displayMinuteOfMeetingPdf() }

    }, [props.meeting.momStatus, id])

    function updateMinuteOfMeeting() {
        MySwal.fire(preloader)
        callServerAPI('PATCH', `/api/MinuteOfMeeting/UpdateAgendaItemMoM?meetingId=${id}`, agendaItemMoMRequest, true, 'json')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                MySwal.close()
                props.enqueueSnackbar(response.data.message, { variant: 'success' })

            })

    }

    function sendMinuteOfMeetingforReview() {
        MySwal.fire(preloader)
        callServerAPI('PATCH', `/api/MinuteOfMeeting/UpdateAgendaItemMoM?meetingId=${id}`, agendaItemMoMRequest, true, 'json')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                callServerAPI('PATCH', `/api/MinuteOfMeeting/GCCSendMoMToCPSCSecretaryForReview?meetingId=${id}`, null, true)
                    .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                        props.setMeeting(response.data.data)
                        props.setMeeting(oldMeeting => ({ ...oldMeeting, momPreparedBy: loggedOnTepngUser.email }))
                        props.enqueueSnackbar(response.data.message, { variant: 'success' })
                        MySwal.close()
                    })
            })
    }

    function cpscSecretaryMomReviewAccept(e) {
        MySwal.fire(preloader)
        callServerAPI('PATCH', `/api/MinuteOfMeeting/CpscSecretaryAcceptMoM?meetingId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                props.setMeeting(response.data.data);
                setMomReviewFieldName(null)
                setMomReviewBoxChecked(false)
                MySwal.close()
                if (response?.data?.message) props.enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }
    function cpscSecretaryMoMReviewReject(e) {
        MySwal.fire(preloader)
        /** @type RejectMomDto*/
        const momRejectionDto = {
            meetingId: `${id}`,
            momRejectedComments: props.meeting.momRejectedComments
        }
        callServerAPI('PATCH', `/api/MinuteOfMeeting/CpscSecretaryRejectMoM?meetingId=${id}`, momRejectionDto, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                props.setMeeting(response.data.data);
                setMomReviewFieldName(null)
                setMomReviewBoxChecked(false)
                MySwal.close()
                if (response?.data?.message) props.enqueueSnackbar(response?.data?.message, { variant: 'success' });;
            })

    }

    function CpscChairmanAcceptMoM(e) {
        MySwal.fire(preloader)
        callServerAPI('PATCH', `/api/MinuteOfMeeting/CpscChairmanAcceptMoM?meetingId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                props.setMeeting(response.data.data);
                setMomReviewFieldName(null)
                setMomReviewBoxChecked(false)
                MySwal.close()
                if (response?.data?.message) props.enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }
    function CpscChairmanRejectMoM(e) {
        MySwal.fire(preloader)

        /** @type RejectMomDto*/
        const momRejectionDto = {
            meetingId: `${id}`,
            momRejectedComments: props.meeting.momRejectedComments
        }
        callServerAPI('PATCH', `/api/MinuteOfMeeting/CpscChairmanRejectMoM?meetingId=${id}`, momRejectionDto, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                props.setMeeting(response.data.data);
                setMomReviewFieldName(null)
                setMomReviewBoxChecked(false)
                MySwal.close()
                if (response?.data?.message) props.enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }

    return (
        <>
            <div className='content-desktop'>
                <div className='d-flex flex-row-reverse content-desktop'>

                    <ButtonGroup >
                        {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && props.meeting.momStatus === MoMStatus.Draft &&

                            <>
                                <Button variant="contained" color='success' onClick={getMinuteOfMeetingPdf}> <PictureAsPdfIcon /> &nbsp;View Minutes of Meeting PDF</Button> &nbsp;
                                <Button variant="contained" color='primary' onClick={updateMinuteOfMeeting}> <Save /> &nbsp;Update Minutes of Meeting</Button>
                                &nbsp;
                                <Button variant="contained" color='secondary' onClick={sendMinuteOfMeetingforReview}> <Send /> &nbsp;Request For CPSC Secretary Review </Button>
                            </>
                        }
                    </ButtonGroup>
                </div>
            </div>


            <div className='content-mobile'>
                <div >
                    {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && props.meeting.momStatus === MoMStatus.Draft &&

                        <>
                            <Button style={{ width: '100%' }} variant="contained" color='success' onClick={getMinuteOfMeetingPdf}> <PictureAsPdfIcon /> &nbsp;View Minutes of Meeting PDF</Button> &nbsp;

                            <Button style={{ width: '100%' }} variant="contained" color='primary' onClick={updateMinuteOfMeeting}> <Save /> &nbsp;Update Minutes of Meeting</Button>
                            &nbsp;
                            <Button style={{ width: '100%' }} variant="contained" color='secondary' onClick={sendMinuteOfMeetingforReview}> <Send /> &nbsp;Request For CPSC Secretary Review </Button>
                        </>
                    }
                </div>
            </div>
            <br />
            {loggedOnTepngUser.roles.includes(GlobalRole.CpscSecretary) && props.meeting.momStatus === MoMStatus.AwaitingReview &&

                <div className='col-12 px-4 mt-1 py-2' style={{ margin: '0px !important' }}>
                    <div className='mt-4 mb-4 shadow-sm bg-white px-4 py-2' style={{ paddingTop: '0px !important' }}>
                        <div className=''>

                            <Table striped bordered hover size="sm">
                                <thead style={{ fontSize: '15px' }}>
                                    <tr><td className='role'>Role</td><td className='role'> Minutes Of Meeting Status</td></tr>
                                </thead>
                                <tbody style={{ fontSize: '15px' }}>
                                    <tr>
                                        <td  > <Radio checked={momReviewFieldName === GlobalRole.CpscSecretary} onClick={() => setMomReviewFieldName(GlobalRole.CpscSecretary)} /> CPSC Secretary</td>

                                        <td>{props.meeting.momStatus}</td>
                                    </tr>


                                </tbody>
                            </Table>
                            {momReviewFieldName &&
                                <>
                                    {showRejectionCommentBox &&
                                        <div className='row'>
                                            <div >
                                                <div >
                                                    <label className=' title-label'>Comments <span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className=''>
                                                    <div className="form-group mb-0">
                                                        <textarea className='form-control' onChange={(e) => updateDataField(e.target.id, e.target.value)} id="momRejectedComments" value={props.meeting.momRejectedComments} style={{ width: '100%', height: '80px' }} name='Objective' required />


                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    }

                                    <input type="checkbox" checked={momReviewBoxChecked} onChange={() => { setMomReviewBoxChecked(!momReviewBoxChecked); }} /> <span style={{ fontSize: '15px', fontWeight: '400' }}><i>I hereby validate this Minutes of Meeting as the {momReviewFieldName} </i></span>
                                    <br />
                                    &nbsp;&nbsp;
                                    <div className='complete'>
                                        <Button variant='contained' size='large' color='success' style={{ fontSize: 14, textTransform: 'none' }} disabled={!momReviewBoxChecked} onClick={cpscSecretaryMomReviewAccept}><ThumbUpAlt /> &nbsp;Validate Minutes of Meeting</Button>
                                        &nbsp;&nbsp;
                                        <Button variant='contained' size='large' color='secondary' style={{ fontSize: 14, textTransform: 'none' }} disabled={momReviewBoxChecked} onClick={handleCPSCSecretaryReviewRejection} ><ThumbDownAlt /> &nbsp;Reject  Minutes of Meeting</Button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
            {loggedOnTepngUser.roles.includes(GlobalRole.CpscChairman) && props.meeting.momStatus === MoMStatus.AwaitingApproval &&

                <div className='col-12 px-4 mt-1 py-2' style={{ margin: '0px !important' }}>
                    <div className='mt-4 mb-4 shadow-sm bg-white px-4 py-2' style={{ paddingTop: '0px !important' }}>
                        <div className=''>

                            <Table striped bordered hover size="sm">
                                <thead style={{ fontSize: '15px' }}>
                                    <tr><td className='role' >Role</td><td className='role'>Minutes Of Meeting Status</td></tr>
                                </thead>
                                <tbody style={{ fontSize: '15px' }}>
                                    <tr>
                                        <td  > <Radio checked={momApprovalFieldName === GlobalRole.CpscChairman} onClick={() => setMomApprovalFieldName(GlobalRole.CpscChairman)} /> CPSC Chairman</td>

                                        <td >{props.meeting.momStatus}</td>
                                    </tr>


                                </tbody>
                            </Table>
                            {momApprovalFieldName &&
                                <>
                                    {showRejectionCommentBox &&
                                        <div className='row'>
                                            <div >
                                                <div >
                                                    <label className=' title-label'>Comments <span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <div className=''>
                                                    <div className="form-group mb-0">
                                                        <textarea className='form-control' onChange={(e) => updateDataField(e.target.id, e.target.value)} id="momRejectedComments" value={props.meeting.momRejectedComments} style={{ width: '100%', height: '80px' }} name='momRejectedComments' required />


                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    }
                                    <input type="checkbox" checked={momApprovalBoxChecked} onChange={() => { setMomApprovalBoxChecked(!momApprovalBoxChecked); }} /> <span style={{ fontSize: '15px', fontWeight: '400' }}><i>I hereby approve this Minutes of Meeting as the {momApprovalFieldName} </i></span>
                                    <br />
                                    &nbsp;&nbsp;
                                    <div className='complete'>
                                        <Button variant='contained' size='large' color='success' style={{ fontSize: 14, textTransform: 'none' }} disabled={!momApprovalBoxChecked} onClick={CpscChairmanAcceptMoM}><ThumbUpAlt /> &nbsp;Approve Minutes of Meeting</Button>
                                        &nbsp;&nbsp;
                                        <Button variant='contained' size='large' color='secondary' style={{ fontSize: 14, textTransform: 'none' }} disabled={momApprovalBoxChecked} onClick={handleCPSCChairmanRejection}><ThumbDownAlt /> &nbsp;Reject Minutes of Meeting</Button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
            {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && props.meeting.momStatus === MoMStatus.Draft &&
                <>
                    {props.meeting.momRejectedComments &&
                        <div className='col-12 px-4 mt-1 py-2' style={{ margin: '0px !important' }}>
                            <div className='mt-4 mb-4 shadow-sm bg-white px-4 py-2' style={{ paddingTop: '0px !important' }}>
                                <div className=''>

                                    <Table size="sm">
                                        <thead style={{ fontSize: '15px' }}>
                                            <tr><td className='role' >Reason for Minute  Of Meeting Rejection </td></tr>
                                        </thead>
                                        <tbody style={{ fontSize: '13px' }}>
                                            <tr>


                                                <td >{props.meeting.momRejectedComments}
                                                    <br />
                                                    <br />
                                                    Rejected By:&nbsp;<i style={{ fontWeight: '400' }}>{getNameFromTotalEnergiesEmail(props.meeting.momRejectedBy)}</i>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>
                    }
                    <div className='col-12 px-4 mt-1 py-2' style={{ border: '7px solid #0288d1', padding: '10px' }}>
                        <br />
                        <div >
                            <div style={{ textAlign: 'center', fontSize: " 18px", color: ' rgb(80 111 141)' }} > <b>CONTRACTS COMMITTEE MEETING TUCN, NIGERIA</b> </div>

                        </div>

                        <div>
                            <div style={{ fontSize: '14px' }} className='d-flex  justify-content-between'>
                                <div><b>Date:</b> {formatShortDateString(props.meeting.meetingDateTime)}  </div>

                            </div>
                            <div style={{ fontSize: '14px' }}><b>Location: </b>{props.meeting.venue}</div>

                            <br />

                            <table className="table font-12 mb-1 table-bordered">
                                <thead className='thead-text'>
                                    <tr>
                                        <th >ITEM    </th>
                                        <th> EXECUTIVE SUMMARY & DECISIONS   </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {props.meeting.agenda.sort((a, b) => (a.serialNumber > b.serialNumber) ? 1 : -1).map((agendaItem, index) => {

                                        return <tr className='trtext' key={index}>

                                            <td >{index + 1}</td>
                                            <td>

                                                <div  >
                                                    {agendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT ? <div className='mom-dossier-title'>Sustainability Moment</div>
                                                        :
                                                        (agendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST ?
                                                            <div>
                                                                <div className='mom-dossier-title' >{agendaItem.title} (Aggregate Presentation)</div>
                                                                <br />
                                                                <div>
                                                                    <br />
                                                                    <div className='mom-subtitle'>Background & Purpose</div>
                                                                    <ul>
                                                                        <li className='mom-richText' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.meetingCpscRequestList.aggregatedCpscRequestList.find(e => e.id === agendaItem.aggregatedCpscRequestId).background) }}>

                                                                        </li>
                                                                    </ul>

                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <br />
                                                                    <div className='mom-subtitle'>Objective</div>
                                                                    <ul>
                                                                        <li className='mom-richText' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.meetingCpscRequestList.aggregatedCpscRequestList.find(e => e.id === agendaItem.aggregatedCpscRequestId).objective) }}>

                                                                        </li>
                                                                    </ul>

                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <br />
                                                                    <div className='mom-subtitle'>Request To CPSC</div>
                                                                    <ul>
                                                                        <li className='mom-richText' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.meetingCpscRequestList.aggregatedCpscRequestList.find(e => e.id === agendaItem.aggregatedCpscRequestId).recommendation) }}>

                                                                        </li>
                                                                    </ul>

                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div className='mom-subtitle'>CPSC COMMENTS AND CONSIDERATIONS: </div>
                                                                    <div>
                                                                        <MyRichTextEditor editorHtmlString={agendaItem.commentsAndConsiderations} updateEditorHtmlString={(editorHtmlString) => updateAgendaItemMom(agendaItem.id, 'commentsAndConsiderations', editorHtmlString)} />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <br />
                                                                    <div className='mom-subtitle'>CPSC DECISION: </div>
                                                                    <ul>

                                                                        <div>{props.meetingCpscRequestList.aggregatedCpscRequestList.find(e => e.id === agendaItem.aggregatedCpscRequestId).cpscRequests.map((requests, index) => {
                                                                            return (
                                                                                <div key={index}>
                                                                                    <li>
                                                                                        <div>{requests.contractTitle}&nbsp;- &nbsp;{props.meeting.cpscDecisions.find(e => e.cpscRequestId === requests.id)?.decision ?? "Not Presented"}</div>

                                                                                    </li>

                                                                                </div>
                                                                            )
                                                                        })}</div>
                                                                    </ul>
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div className='mom-subtitle'>FOLLOW-ON ACTIONS: </div>
                                                                    <div>
                                                                        <MyRichTextEditor editorHtmlString={agendaItem.followOnAction} updateEditorHtmlString={(editorHtmlString) => updateAgendaItemMom(agendaItem.id, 'followOnAction', editorHtmlString)} />
                                                                    </div>
                                                                </div>





                                                            </div>
                                                            :
                                                            <div>
                                                                <div className='mom-dossier-title' >{agendaItem.title} ({props.meetingCpscRequestList.cpscRequestList.find(e => e.id === agendaItem.cpscRequestId).cpscCategory} Presentation)</div>
                                                                <div>
                                                                    <br />
                                                                    <div className='mom-subtitle'>Background & Purpose</div>
                                                                    <ul>
                                                                        <li className='mom-richText' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.meetingCpscRequestList.cpscRequestList.find(e => e.id === agendaItem.cpscRequestId).dossier.purposeAndDescriptionSlide.background) }}>

                                                                        </li>
                                                                    </ul>

                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div className='mom-subtitle'>Objective</div>
                                                                    <div>
                                                                        <ul>
                                                                            <li > {props.meetingCpscRequestList.cpscRequestList.find(e => e.id === agendaItem.cpscRequestId).dossier.purposeAndDescriptionSlide.objective}</li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <br />
                                                                    <div className='mom-subtitle'>Request To CPSC</div>
                                                                    <div>

                                                                        <ul>
                                                                            <li style={{ fontSize: '11px', }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.meetingCpscRequestList.cpscRequestList.find(e => e.id === agendaItem.cpscRequestId).dossier.recommendationSlide.recommendation) }}>
                                                                            </li>
                                                                        </ul>

                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div className='mom-subtitle'>CPSC COMMENTS AND CONSIDERATIONS: </div>
                                                                    <div>
                                                                        <MyRichTextEditor editorHtmlString={agendaItem.commentsAndConsiderations} updateEditorHtmlString={(editorHtmlString) => updateAgendaItemMom(agendaItem.id, 'commentsAndConsiderations', editorHtmlString)} />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <br />
                                                                    <div className='mom-subtitle'>CPSC DECISION: </div>
                                                                    <ul>
                                                                        <li>
                                                                            <div>{props.meeting.cpscDecisions.find(e => e.cpscRequestId === agendaItem.cpscRequestId)?.decision}</div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div className='mom-subtitle'>FOLLOW-ON ACTIONS: </div>
                                                                    <div>
                                                                        <MyRichTextEditor editorHtmlString={agendaItem.followOnAction} updateEditorHtmlString={(editorHtmlString) => updateAgendaItemMom(agendaItem.id, 'followOnAction', editorHtmlString)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>


                                            </td>
                                        </tr>


                                    })}


                                </tbody>

                            </table>
                        </div>
                    </div>
                </>
            }
            {loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember) && props.meeting.momStatus === MoMStatus.Draft &&
                <div className='col-12 px-4 mt-1 py-2' style={{ border: '7px solid #0288d1', padding: '10px' }}>
                    <br />


                    <div style={{ textAlign: 'center' }}>
                        <Diversity3 style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                        <div> Minutes of Meeting Preparation Ongoing</div>


                    </div>
                </div>
            }

            {props.meeting.momStatus !== MoMStatus.Draft &&
                <>
                    <div className='content-desktop'>
                        <iframe src={momPdfObjectUrl + '#view=FitH'} height="700" width="100%" title="MoMPDF" style={{ paddingBottom: '15px' }}></iframe>
                    </div>
                    <div className='content-mobile'>
                        <br />
                        <Button color='success' onClick={() => window.open(momPdfObjectUrl)} variant="contained" ><PictureAsPdf fontSize='medium' />&nbsp;View Minutes of Meeting Pdf</Button>
                    </div>
                </>
            }
        </>

    )
}

export default MinutesOfMeeting