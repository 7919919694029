import React from 'react'
import { ContractualEvaluationSlideField, ContractualEvaluationSlideTooltip } from '../../helpers/DossierFieldConstants';
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

/**
 * @typedef {Object} ContractualEvaluationSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
  * @property {number} dossierRefreshCount
 */

/**
 * @param {ContractualEvaluationSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ContractualEvaluationSlide(props) {

  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, contractualEvaluationSlide: { ...props.cpscRequest.dossier.contractualEvaluationSlide, [name]: value } })
  }


  return (
   
    <form style={{ fontSize: '12px' }}>
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ContractualEvaluationSlideTooltip.details)}Contractual Evaluation Details <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.contractualEvaluationSlide.details} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ContractualEvaluationSlideField.details, editorHtmlString)} />

      </div>
    </form>
      )
}

      export default ContractualEvaluationSlide