import React, { useState } from 'react'
import { DossierPdfFieldName } from '../../helpers/Constants';
import { Button } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
/**
/**
 * @typedef {Object} CommercialEvaluationUploadProps

  * @property {CpscRequest} cpscRequest
  * @property {downloadPdfCallback} downloadPdf
  * @property {uploadPdfCallback} uploadPdf
  * @property {deletePdfCallback} deletePdf
  * @property {boolean} readOnly
 */

/**
 * @param {CommercialEvaluationUploadProps}  props
 * @return {import('react').ReactElement}
 */
function CommercialEvaluationUpload(props) {
  const [commercialEvaluationObjectUrl, setCommercialEvaluationObjectUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    let document = {}
    document.name = file.name;
    if (event.target.files.length === 0) return
    const formData = new FormData()
    formData.append("uploadedFile", event.target.files[0]);
    props.uploadPdf(formData, DossierPdfFieldName.CommercialEvaluation);
    setCommercialEvaluationObjectUrl(undefined)
  }

  const previewFile = async (event) => {
    const url = await props.downloadPdf(DossierPdfFieldName.CommercialEvaluation)
    setCommercialEvaluationObjectUrl(url)
  }

  const deleteFile = async (event) => {
    await props.deletePdf(DossierPdfFieldName.CommercialEvaluation)
    setCommercialEvaluationObjectUrl(undefined)

  }

  return (
    <>
      <div className="file-card">

        <div className="file-inputs">
          <input className='upload-input' id="commercialEvaluationSlideUploaded" type="file" onChange={handleFileChange} accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={props.readOnly} />
          <Button variant='contained' color='secondary' className='upload-btn ' disabled={props.readOnly} >
          <CloudUploadOutlinedIcon />&nbsp; Upload
          </Button>
        </div>

        <p className="main">Supported files</p>
        <p className="info">PDF, Excel</p>

      </div>
      <div>
        {props.cpscRequest.commercialEvaluationSlideUploaded &&

          <div>

            <div className='files' >
              <div className='file-name'>Commercial Evaluation Slide</div>
              <div className='dld-file'><Button variant='contained' color='success'  disabled={!props.cpscRequest.commercialEvaluationSlideUploaded}
                onClick={previewFile}><RemoveRedEyeOutlinedIcon/> &nbsp;View</Button></div>
              <div className='del-file'>
              <Button variant='contained' color='error'  onClick={() => { deleteFile() }}
                  disabled={!props.cpscRequest.commercialEvaluationSlideUploaded || props.readOnly}>
                <DeleteSweepOutlinedIcon/> &nbsp;Delete</Button>
              </div>
            </div>


            {commercialEvaluationObjectUrl && <iframe src={commercialEvaluationObjectUrl} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>}
          </div>
        }
      </div>

    </>
  )
}

export default CommercialEvaluationUpload