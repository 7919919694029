//use https://onlinehtmleditor.dev/ to edit html

/**  @readonly @enum {string} */
export const MarketIntelligenceSlideField = {
    benchmark: "benchmark",
    costDetails: "costDetails",
    keyCostDrivers: "keyCostDrivers",
}
/**  @readonly @enum {string} */
export const ProposedBiddersSlideField = {
    additionalComments: "additionalComments",
}

/**  @readonly @enum {string} */
export const PurposeAndDescriptionSlideField = {
    objective: "objective",
    detailedGeographicScope: "detailedGeographicScope",
    background: "background",
    sowOrMaterialDescription: "sowOrMaterialDescription",
    remunerationModel: "remunerationModel"
}

/**  @readonly @enum {DossierFieldTooltip} */
export const PurposeAndDescriptionSlideTooltip = {
    objective: {
        strategy:
            `<p>State all you need approved by CPSC - Strategy</p>
            <ul>
                <li>The objective of this presentation is to obtain CPSC approval for the strategy for a Call For Tender for XXXX services and the proposed bidder list</li>
            </ul>`,
        award:
            `<p>State all you need approved by CPSC - RTA</p>
            <ul>
                <li>The objective of this presentation is to obtain CPSC/CATBOARD/CCEP approval for the Award of the Contract to XXX for a total value of YYY and for a period of ZZZ</li>
            </ul>`,
        postAward:
            `<p>State all you need approved by CPSC - Amendment, Extension, Target Value Increase, Adv payment etc</p>
            <ul>
                <li>The objective of this presentation is to obtain CPSC/CATBOARD/CCEP approval for the extension of the Contract to XXX for a total value of YYY and for a period of ZZZ</li>
            </ul>`
    },
    detailedGeographicScope: {
        allCategories: `<ul><li>Blocks / Units / Fields / Wells / Development / Exploration / Other</li></ul>`,
    },
    background: {
        strategy:
            `<ul>
                <li>What background led to this request?</li>
                <li>Why do you need this &ndash;Strategy?</li>
                <li>Why can&rsquo;t company do without this request?</li>
                <li>What is the risk to company of not proceeding with this request?</li>
                <li>What is the planned Contract Duration (include proposed Contract Start &amp; End Dates)? (Material Purchase Lead Time &amp; Projected Delivery Date? )</li>
                <li>What is the Market situation (few or many providers)?</li>
            </ul>`,
        award:
            `<ul>
                    <li>What background led to this request?</li>
                    <li>Did CPSC approve the strategy? When? What was the decision at Strategy Level and have there been any deviations from the decision? (Extensive details to be provided in Slide 5)</li>
                    <li>Why do you need this &ndash; RTA??</li>
                    <li>Why can&rsquo;t company do without this request?</li>
                    <li>What is the risk to company of not proceeding with this request?</li>
                    <li>What is the planned Contract Duration (include proposed Contract Start &amp; End Dates)? (Material Purchase Lead Time &amp; Projected Delivery Date? )</li>
                    <li>For existing Services, provide details of the previous or existing Incumbents and their participation in the Purchase Process leading to RTA.</li>
            </ul>`,
        postAward:
            `<ul>
                <li>What background led to this request?</li>
                <li>Why do you need this &ndash; Amendment, Extension, Target Value Increase, Adv payment etc??</li>
                <li>Why can&rsquo;t company do without this request?</li>
                <li>What is the risk to company of not proceeding with this request?</li>
                <li>Give the history of the past CPSC decisions related to this Contract/Subject.</li>
                <li>What are the Initial/Original/Existing Contract&rsquo;s Details (Start &amp; End Dates of Firm and Optional Periods, Ceiling Value approved at Contract Start, etc.) ?</li>
                <li>What is the planned Contract Extension, etc. Duration (include proposed Start &amp; End Dates)? (Claim, Variation, Other Amendment details?)</li>
                <li>Is there planned Replacement CFT to forestall perpetual Contract extensions or Post-Award Addenda/Amendments ? NUIMS CFT or Internal CFT? State the CFT Status (Strategy, Opportunity, Technical, Commercial, RTA) &amp; provide CFT Timeline</li>
            </ul>`
    },
    sowOrMaterialDescription:
    {
        strategy:
            `<ul>
                <li>Detailed description of scope &ndash; Key points</li>
                <li>Is there a Budget confirmation? (To be provided in Backup section)</li>
            </ul>`,
        award:
            `<ul>
                <li>Detailed description of scope &ndash; Key points</li>
                <li>Is there a Budget confirmation? (To be provided in Backup section)</li>
                <li>Any potential Community Issues?</li>
            </ul>`,
        postAward:
            `<ul>
                <li>Detailed description of scope &ndash; Key points</li>
                <li>What is the Contract value?</li>
                <li>Is this the 1st, 2nd, 3rd &hellip;extension?</li>
                <li>State the Cumulative Contract Value&nbsp;</li>
                <li>Is there a Budget confirmation? (To be provided in Backup Section)</li>
                <li>Any potential Community Issues?</li>
            </ul>`
    },
    remunerationModel: null
}

/**  @readonly @enum {DossierFieldTooltip} */
export const ProjectOperationsPlanningSlideTooltip = {
    details: {
        allCategories: `<p>In General, Describe/Provide the need with respect to planning</p>
        <ul>
          <li>For Drilling Subjects, Insert Drilling Plan and where request falls within overall plan</li>
          <li>Include Asset Maintenance Plan as required</li>
          <li>For LSO Subjects (Vessels, …), include full TUCN Fleet Vessel Planning and where request falls within overall fleet and strategy</li>
          <li>Show overall Project Planning and if relevant Procurement strategies retained/envisaged for other packages in the Project</li>
          <li>Planning also expected to show key dates (FID, Coval/Comex, etc…)</li>
        </ul>`,
    },

}
/**  @readonly @enum {DossierFieldTooltip} */
export const ProcurementStrategySlideTooltip = {
    details: {
        strategy: `
        <ul>
         <li>What Contractual Strategy are you proposing and reasons ( NUIMS CFT / TEPNG or TUPNI CFT / Waiver ) ?</li>

         <li>List the various strategy options with pros/cons </li>

         <li>Background & Drivers, Killing factors (go/no go)</li>

         <li>Single Source justification << single Source Only>></li>

         <li>Cost optimization opportunities
                <ul>
                    <li>Identify opportunities for cost optimization e.g. regional synergies, sub-lets, alternative remuneration mechanisms, performance incentives, alternative technologies, operational efficiencies, resource sharing with other operators etc, etc
                    </li>
                </ul>
         </li>

         <li>Negotiation Strategy << single/sole Source Only>>
                <ul>
                    <li>Summarize the negotiation strategy e.g., leverage used for negotiations, duration of negotiation, use of market intelligence.</li>
                </ul>
         </li>

         <li>Remuneration Model (Unit Rate /Day Rate /Lumpsum / Reimbursible) </li>

         <li>Key Milestones / Dates </li>

         <li>Bid Reception Strategy: Simultaneous (Double Envelope) or Sequential (Single Envelope, 2 Stage)?</li>

         <li>NUIMS/NCDMB Engagement</li>
        </ul>`,
    },

}
/**  @readonly @enum {DossierFieldTooltip} */
export const ProcurementStrategyHighlightsSlideTooltip = {
    details: {
        award: `
        <ul>
         <li>Strategy
                <ul>
                    <li>Summarise approved strategy from Contracts Committee</li>
                </ul>
         </li>
         <li>Remuneration Model
                <ul>
                    <li>Summarize remuneration model</li>
                </ul>
         </li>
         <li>Remind Bidders List</li>
        </ul>`,
    },

}
/**  @readonly @enum {DossierFieldTooltip} */
export const ProposedBiddersSlideTooltip = {
    additionalComments: {
        allCategories: `<ul><li>Performance feedback for incumbent suppliers / Additional Comments on Bidder List </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const MarketIntelligenceSlideTooltip = {
    keyCostDrivers: {
        allCategories: `
        <ul>
          <li>Key cost components drivers</li>
        </ul>`,
    },
    costDetails: {
        allCategories: `
    <ul>
      <li>Cost details requested in Contract</li>
    </ul>`,
    },
    benchmark: {
        allCategories: `
    <ul>
      <li>Benchmark with current Contracts</li>
    </ul>`,
    }

}
/**  @readonly @enum {DossierFieldTooltip} */
export const RecommendationSlideTooltip = {
    recommendation: {
        strategy: `
           <ul>
                <li>We request Contracts Committee to approve:
                    <ul>
                        <li>The strategy for a Call For Tender for MMO Wildlife Monitoring, for 2 years + 2 x 1 year options, and the proposed bidder list.</li>
                    </ul>
                </li>
          </ul>`,
        award: `
                <ul>
                <li>We request Contracts Committee to approve:
                        <ul>
                            <li>The Award of the Contract to XXX for a total value of YYY and for a period of ZZZ [Award Presentation only]</li>
                        </ul>
                    </li>
                </ul>
            `,
        postAward: `
                <ul>
                <li>We request the CPSC to approve:
                        <ul>
                            <li>The Extension of the Contract to XXX for a total value of YYY and for a period of ZZZ [Post-Award Presentation only]</li>
                        </ul>
                    </li>
                </ul>
            `,
    },
    nextSteps: {
        strategy: `
                <ul>
                    <li>Strategy approval</li>
                    <li>Partner Approval </li>
                    <li>NCDMB Approval</li>
                    <li>Launch CFT</li>
                </ul>`,
        award: `
            <ul>
                <li>Strategy approval ?</li>
                <li>CATMAN/CATBOARD/CCEP approval ?</li>
                <li>Partner Approval if Extension is included ?</li>
                <li>Launch CFT ?</li>
            </ul> `,
        postAward: `
            <ul>
                <li>Strategy approval ?</li>
                <li>CATMAN/CATBOARD/CCEP approval ?</li>
                <li>Partner Approval if Extension is included ?</li>
                <li>Launch CFT ?</li>
            </ul> `,
    },


}
/**  @readonly @enum {DossierFieldTooltip} */
export const CfrInitiativesSlideTooltip = {
    details: {
        strategy: `
        <ul>
            <li>Energy Efficiency / CFR Initiatives
                    <ul>
                        <li>Describe the Energy Efficiency considerations incorporated in the ITT and the target CO2 savings or Carbon Emission reduction amount
                        </li>
                    </ul>
            </li>
             <li>Insert GHG evaluation strategy – planned monitoring and consideration as part of TCO evaluation</li>
            <li>Other Info to be provided
                    <ul>
                        <li>Technical solutions considered or thought</li>
                        <li>Key Performance Indicators to track and measure the Supplier performan</li>
                    </ul>
            </li>
             <li>Split between Scopes considered if possible </li>

        </ul>`,
        award: `
            <ul>
            <li>Insert GHG evaluation tables including the climate aspects</li>
            <li>Estimated direct emissions of CO2 anticipated to cover the Scope of Work, including methodology</li>
            <li>Existing emissions or emissions from similar procurement</li>
            <li>If available use the sectorial approach information</li>
            <li>Other Energy Efficiency actions proposed as part of the Contract either by Company or by Contractor</li>
            <li>New technologies thoughts</li>
            <li>Information to split where possible between Scope 1&2 and Scope 3</li>
            </ul>
        `,
        postAward: `
        <ul>
        <li>Insert GHG evaluation tables including the climate aspects</li>
        <li>Estimated direct emissions of CO2 anticipated to cover the Scope of Work, including methodology</li>
        <li>Existing emissions or emissions from similar procurement</li>
        <li>If available use the sectorial approach information</li>
        <li>Other Energy Efficiency actions proposed as part of the Contract either by Company or by Contractor</li>
        <li>New technologies thoughts</li>
        <li>Information to split where possible between Scope 1&2 and Scope 3</li>
        </ul>
    `,
    },

}
/**  @readonly @enum {DossierFieldTooltip} */
export const ContractHistorySlideTooltip = {
    currentPerformance: {
        postAward: `
        <p>Current performance including operational risks AND previous governance approval (if any) </p>
        <ul>
          <li>Details</li>
          <li>Feedback from SQM</li>
        </ul>`,
    },
    additionalComments: {
        postAward: `
    <ul>
    <li>Details</li>
    </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const HseEvaluationCriteriaSlideTooltip = {
    additionalComments: {
        strategy: `
        <ul>
          <li>Details</li>
        </ul>`,
    },
    supportingInformation: {
        strategy: `
    <ul>
        <li>Insert HSE evaluation tables including:
            <ul>
                <li> HSE evaluation minimum required %</li>
                <li> Criteria, sub criteria, weights, minimum required</li>
                <li> Killing factors</li>
                <li> Preliminary HSE Risk Level Assessment to be put in backup slides</li>
            </ul>
        </li>
    </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const TechnicalEvaluationCriteriaSlideTooltip = {
    supportingInformation: {
        strategy: `
    <ul>
        <li>Insert Technical evaluation details/tables including:
            <ul>
                <li> Technical evaluation minimum required %</li>
                <li> Criteria, sub criteria, weights, minimum required</li>
                <li> Killing factors</li>
                <li> Cyber Security Risk evaluation (if relevant/applicable</li>
            </ul>
        </li>
    </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const BudgetPlanningInformationSlideTooltip = {
    budgetLineRelevantPart: {
        allCategories: `
        <ul>
         <li>If the Budget is within a Budget line that has other costs, please specify the relevant amount for this contract</li>
        </ul>`,
    },

}
/**  @readonly @enum {DossierFieldTooltip} */
export const ContractualEvaluationCriteriaSlideTooltip = {
    details: {
        strategy: `
    <ul>
        <li>Contract Model/Template to be used</li>

        <li> Status of Contractual Qualifications</li>

        <li>Key Contractual terms among which Termination Provisions to be used</li>

        <li>Deviations from Contracting Principles</li>

        <li>Performance Management
            <ul>
                <li> Commercial & Technical KPIs in Contract?</li>
                <li> Sustainable Performance monitoring KPIs</li>
                <li> Incentives in Contract for Contractor to invest in energy efficient technologies.</li>
            </ul>
        </li>
        <li>  Commercial Award Scenario  (Award based on lowest cost? etc. ) </li>
        <li>Remuneration Model to be used  (Lumpsum, Milestone, unit rate, a mix …) </li>
        <li>Estimated Quantities </li>
        <li>Russia/ESEC Due Diligence Compliance </li>
    </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const ContractualEvaluationSlideTooltip = {
    details: {
        award: `
        <ul>
            <li>Contract Model/Template</li>

            <li> Status of Contractual Qualifications</li>

            <li>Key Contractual terms among which Termination Provisions </li>

            <li>Deviations from Contracting Principles</li>

            <li>Performance Management
                <ul>
                    <li> Commercial & Technical KPIs in Contract?</li>
                    <li> Sustainable Performance monitoring KPIs</li>
                    <li> Incentives in Contract for Contractor to invest in energy efficient technologies.</li>
                </ul>
            </li>
        </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const ContractualProvisionsSlideTooltip = {
    details: {
        postAward: `
        <ul>
            <li>
              Key Contractual terms among which Validity, Escalation Mechanism (rates/price increase) provision, Termination Provisions , etc.
            </li>
        </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const ContractSpendEstimationSlideTooltip = {
    contractSpendEstimation: {
        postAward: `
            <ul>
                <li> For Single Source, Extensions, Amendments and Budget Increase </li>
                <li>  [Insert tables and commercial analysis including:] </li>
                <li>   [Future Cost Simulation scenario] </li>
                <li> Amount Requested for approval (considering how much is remaining in SAP) </li>
                <li>  Approved Budget for year and Cost Code </li>
                <li>  [Local Content ] </li>
                <li>  [Savings] savings are on next slide </li>
            </ul>`,
    },
    additionalComments: {
        postAward: `
            <ul>
                <li> Details, inform the approved budget (BU) </li>
            </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const HseEvaluationSlideTooltip = {

    supportingInformation: {
        award: `
        <ul>
            <li>In line with CR GR HSE 501</li>

            <li>Insert HSE evaluation tables including:
                <ul>
                    <li> HSE evaluation minimum required %</li>

                    <li> Criteria, sub criteria, weights, minimum required</li>

                    <li> Killing factors</li>

                </ul>
            </li>
        </ul>`,
    },
}
/**  @readonly @enum {DossierFieldTooltip} */
export const TechnicalEvaluationSlideTooltip = {
    supportingInformation: {
        strategy: `
    <ul>
    <li>Framework for technical evaluation</li>

        <li>Insert Technical evaluation details/tables including:
            <ul>
                <li> Technical evaluation minimum required %</li>

                <li> Criteria, sub criteria, weights, minimum required</li>

                <li> Killing factors</li>

                <li> Cyber Security Risk evaluation (if relevant/applicable</li>
            </ul>
        </li>
    </ul>`,
    },
}

/**  @readonly @enum {DossierFieldTooltip} */
export const SavingsCostOptimizationSlideTooltip = {
    savingsContext: {
        allCategories: `
            <ul>
            <li>Provide Savings Context.</li>
            <li>If no Savings to Report ? Please state so.</li>
            </ul>`,
    },
}

/**  @readonly @enum {string} */
export const RisksAndOpportunitiesSlideField = {
    riskType: "riskType",
    risksIdentified: "risksIdentified",
    possibleMitigations: "possibleMitigations",
    additionalComments: "additionalComments",
}

/**  @readonly @enum {string} */
export const HseEvaluationSlideField = {
    supportingInformation: "supportingInformation",
    additionalComments: "additionalComments",
}
/**  @readonly @enum {string} */
export const TechnicalEvaluationSlideField = {
    supportingInformation: "supportingInformation",
    additionalComments: "additionalComments",
}


/**  @readonly @enum {string} */
export const CfrInitiativesSlideField = {
    details: "details",

}

/**  @readonly @enum {string} */
export const ContractualEvaluationSlideField = {
    details: "details",

}
/**  @readonly @enum {string} */
export const ContractualEvaluationCriteriaSlideField = {
    details: "details",

}


/**  @readonly @enum {string} */
export const ContractualProvisionsSlideField = {
    details: "details",

}
/**  @readonly @enum {string} */
export const ContractHistorySlideField = {
    currentPerformance: "currentPerformance",
    additionalComments: "additionalComments",
}

/**  @readonly @enum {string} */
export const ContractSpendEstimationSlideField = {
    contractSpendEstimation: "contractSpendEstimation",
    additionalComments: 'additionalComments'

}

/**  @readonly @enum {string} */
export const ProjectOperationsPlanningSlideField = {
    details: "details",

}
/**  @readonly @enum {string} */
export const ProcurementStrategyHighlightsSlideField = {
    details: "details",

}
/**  @readonly @enum {string} */
export const ProcurementStrategyDetailsSlideField = {
    details: "details",

}
/**  @readonly @enum {string} */
export const RecommendationSlideField = {
    recommendation: "recommendation",
    nextSteps: 'nextSteps'

}
/**  @readonly @enum {string} */
export const SavingsCostOptimizationSlideField = {
    savingsContext: 'savingsContext',

}

/**  @readonly @enum {string} */
export const HseEvaluationCriteriaSlideField = {
    additionalComments: "additionalComments",
    supportingInformation: "supportingInformation",

}
/**  @readonly @enum {string} */
export const TechnicalEvaluationCriteriaSlideField = {
    supportingInformation: "supportingInformation",

}
/**  @readonly @enum {string} */
export const BudgetPlanningSlideField = {
    currentYearNUIMSBudget: "currentYearNUIMSBudget",
    currentYearCorporateBudget: "currentYearCorporateBudget",
    noBudget: "noBudget",
    budgetLine: "budgetLine",
    budgetLineRelevantPart: "budgetLineRelevantPart",
    firstYearSpendProjection: "firstYearSpendProjection",
    phasingPerYearOfContractSpend: "phasingPerYearOfContractSpend",
}

/**  @readonly @enum {string} */
export const RequestRecommendationField = {
    requestAndRecommendation: "requestAndRecommendation",
}


/**  @readonly @enum {string} */
export const DueDiligenceHumanRightsSlideField = {
    dueDiligenceStatus: "dueDiligenceStatus",
    dueDiligenceMitigations: "dueDiligenceMitigations",
    humanRightsAuditStatus: "humanRightsAuditStatus"
}

/**  @readonly @enum {string} */
export const CommercialEvaluationSlideField = {
    supportingInformation: "supportingInformation",
    additionalComments: "additionalComments"
}

/**  @readonly @enum {string} */
export const AggregatedCpscRequestField = {
    requestToCpsc: "requestToCpsc",
    backgroundAndPurpose: "backgroundAndPurpose"
}

/**  @readonly @enum {string} */
export const CpscRequestFields = {
    contractTitle: "Title of the Contract",
    businessArea: "Business Area",
    budgetEntity: "Select if there is a separate entity (besides the Business Area) providing the budget",
    isAbridgedDossier: "Select if the Dossier is an Abridged Dossier. Abridged Dossiers have fewer mandatory slides",
    cpscCategory: "Proposed Dossier Template &ndash; Strategy Template, Award Template, Post-Award Template ",
    contractingStrategy: "What Contractual Strategy are you proposing (NUIMS CFT, TEPNG CFT, NUIMS OEM/Single Source,TEPNG OEM/Single Source) ? ",
    contractModificationRequest: "Specify if you are asking for an extension, variation or both extension and variation",
    initialSowOrMaterialDescription: "Detailed description of scope / Material Description &ndash; Key points",
    contractCurrency: "What is the Contract value Currency",
    contractValue: "What is the Overall Contract value",
    otherContractCurrency: "Is there an additional Contract Currency e.g. a split currency contract",
    otherContractCurrencyValue: "What is the Additional Contract value",
    budgetCurrency: "What is the Budget Currency ",
    budget: "What is the Budget value",
    assetShareSplitType: "Specify if the contract is for a specific Asset (e.g. DW &amp; Corporate, JV) or if contract is a synergy between both assets",
    dwCorporateSharePercent: "DW &amp; Corporate Percentage Share – applies to Synergy between Assets",
    jvSharePercent: "JV Percentage Share – applies to Synergy between Assets",
    initialContractStartDate: "What is the initial Contract Start Date",
    initialContractEndDate: " What is the initial Contract End Date ",
    newContractEndDate: " What is the  new Contract End Date – applies to contract extension requests",
    optionalExtensions: "Specify any optional Extensions",
    extensionConditions: "Specify extension Conditions",
    additionalValue: "Additional Contract Value Requested – applies to contract variation requests",
    contractorName: "What is the Contractor's Name ",
    contractorContractValue: "What is the Contract Value allocated to the Contractor",
    AgoraRef: "What is the Agora Ref ",
    contractNumber: "What is the Contract Number (SAP 466 Number)",
}
