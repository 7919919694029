import React, { useState } from 'react'
import { FormEditMode } from '../../helpers/Constants';
import { ContractHistorySlideField, ContractHistorySlideTooltip } from '../../helpers/DossierFieldConstants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'
import Table from 'react-bootstrap/esm/Table';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { formatShortDateString, getSlideTooltip } from '../../helpers/Helpers';
import CurrentContractHistoryForm from './CurrentContractHistoryForm';
import { Delete, Edit } from '@mui/icons-material';
import MyRichTextEditor from '../MyRichTextEditor';
import { useSnackbar } from 'notistack';

const MySwal = withReactContent(Swal);


/**
 * @typedef {Object} CurrentContractHistorySlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 * @property {number} dossierRefreshCount
 */

/**
 * @param {CurrentContractHistorySlideProps}  props
 * @return {import('react').ReactElement}
 */
function CurrentContractHistorySlide(props) {

    /** @type ContractHistories */ let emptyContractHistory = {
    serialNo: props.cpscRequest.dossier.contractHistorySlide.contractHistories == null || props.cpscRequest.dossier.contractHistorySlide.contractHistories.length === 0 ? 1 : Math.max.apply(Math, props.cpscRequest.dossier.contractHistorySlide.contractHistories.map(function (o) { return o.serialNo; })) + 1,
    contractor: "",
    service: "",
    contractStartDate: undefined,
    contractEndDate: undefined,
    initialApprovedContractCeiling: undefined,
    totalCeilingIncrease: undefined,
    spendToDate: undefined,
    requestedCeilingIncrease: undefined,

  }

  const { enqueueSnackbar } = useSnackbar();
  /** @type [Boolean, Function] */
  const [displayContractHistoryForm, setDisplayContractHistoryForm] = useState(false);
  /** @type [Boolean, Function] */
  const [displayAddContractHistory, setDisplayAddContractHistory] = useState(true);
  /** @type [Number, Function] */
  const [contractHistoryFormEditMode, setContractHistoryFormEditMode] = useState(FormEditMode.CREATE);
  /** @type [ContractHistories, Function] */
  const [contractHistoryToEdit, setContractHistoryToEdit] = useState(emptyContractHistory);

  let cumulativeContractValue = props.cpscRequest.dossier.contractHistorySlide.contractHistories.reduce((partialSum, a) => partialSum + Number(a.initialApprovedContractCeiling) + Number(a.totalCeilingIncrease), 0);
  let residualContractCeiling = props.cpscRequest.dossier.contractHistorySlide.contractHistories.reduce((partialSum, a) => partialSum + Number(cumulativeContractValue) - Number(a.spendToDate), 0);
  let totalContractValueRequired = props.cpscRequest.dossier.contractHistorySlide.contractHistories.reduce((partialSum, a) => partialSum + Number(residualContractCeiling) + Number(a.requestedCeilingIncrease), 0);
  let revisedCumulativeContractValue = props.cpscRequest.dossier.contractHistorySlide.contractHistories.reduce((partialSum, a) => partialSum + Number(cumulativeContractValue) + Number(a.requestedCeilingIncrease), 0);


  const cancelRecordCreation = () => {
    MySwal.fire({
      title: 'Alert',
      html: `<small> Do you want to cancel this record creation?</small>`,
      icon: 'info',
      cancelButtonColor: '#DC143C',
      showCancelButton: true,
      confirmButtonColor: '#07bf69',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.isConfirmed) {
          setDisplayAddContractHistory(true);
          setDisplayContractHistoryForm(false);
          enqueueSnackbar('Record Creation Canceled', { variant: 'success' });

        }
      })
  };

  const handleDelete = (serialNo) => {
    MySwal.fire({
      title: 'Alert',
      html: `<small> Do you want to delete this record?</small>`,
      icon: 'info',
      cancelButtonColor: '#DC143C',
      showCancelButton: true,
      confirmButtonColor: '#07bf69',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.isConfirmed) {
          props.setDossier({
            ...props.cpscRequest.dossier, highRiskServicesSlide: {
              ...props.cpscRequest.dossier.highRiskServicesSlide, highRisks: props.cpscRequest.dossier.highRiskServicesSlide.highRisks.filter(inst => inst.serialNo !== serialNo).map((inst, index) => ({ ...inst, serialNo: index + 1 }))
            }
          })
          enqueueSnackbar('Record Deleted', { variant: 'success' });

        }
      })
  };

/** @param {string} name, @param {any} value  */
const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, contractHistorySlide: { ...props.cpscRequest.dossier.contractHistorySlide, [name]: value } })
}
  return (
    <div>
      <span style={{ float: 'right' }}>
        {displayAddContractHistory ?
          <Button onClick={() => { setDisplayAddContractHistory(false); setDisplayContractHistoryForm(true); setContractHistoryFormEditMode(FormEditMode.CREATE); setContractHistoryToEdit(emptyContractHistory); }}
            variant='contained' color='secondary' disabled={props.readOnly} >Add new Contract History </Button> : ""}&nbsp;&nbsp;
        {displayContractHistoryForm ? <button onClick={() => { cancelRecordCreation() }} className=' clsbtn' >Cancel</button> : ""}

      </span>
      <br />
      <br />
      {displayContractHistoryForm &&
        <CurrentContractHistoryForm setDisplayAddContractHistory={setDisplayAddContractHistory} setDossier={props.setDossier} cpscRequest={props.cpscRequest} contractHistory={contractHistoryToEdit} formEditMode={contractHistoryFormEditMode} setDisplayContractHistoryForm={setDisplayContractHistoryForm} />
      }

      <br />
      <br />
      <Grid container>
        <Grid className='newtable' item xs={12} sm={12} lg={12} >
          <Table responsive striped bordered>
            <thead style={{ fontSize: '10px' }}>
              <tr>
                <th >SN</th>
                <th>Contractor </th>
                <th>Service </th>
                <th title='Contract Start Date'> Contract Start Date  </th>
                <th title='Contract End Date'> Contract End Date</th>
                <th title='Initial Approved Contract Ceiling Value'>Initial Approved Contract Ceiling Value  </th>
                <th title='Total Ceiling value increase'>Total Ceiling value increase  </th>
                <th title='Cumulative Contract Value'> Cumulative Contract Value     </th>
                <th title='Spend to Date'> Spend to Date  </th>
                <th title='Residual Contract Ceiling Value'>Residual Contract Ceiling Value  </th>
                <th title='Requested Ceiling Value Increase (current)'> Requested Ceiling Value Increase (current)    </th>
                <th title='Total Contract Value  Required for this Service'> Total Contract Value  Required for this Service   </th>
                <th title='Revised Cumulative Contract Value (if approved)'> Revised Cumulative Contract Value (if approved)   </th>
                <th style={{ minWidth: 100, textAlign: 'center' }}>Actions</th>

              </tr>
            </thead>
            <tbody>
              {props.cpscRequest.dossier.contractHistorySlide.contractHistories.sort((a, b) => a.serialNo < b.serialNo ? -1 : 1).map((item, index) => {
                return <tr style={{ fontSize: '11px' }} key={index}>
                  <td>{index + 1}</td>
                  <td>{item.contractor}</td>
                  <td>{item.service}</td>
                  <td>{formatShortDateString(item.contractStartDate)}</td>
                  <td>{formatShortDateString(item.contractEndDate)}</td>
                  <td>{item.initialApprovedContractCeiling}</td>
                  <td>{item.totalCeilingIncrease}</td>
                  <td>{cumulativeContractValue}</td>
                  <td>{item.spendToDate}</td>
                  <td>{residualContractCeiling}</td>
                  <td>{item.requestedCeilingIncrease}</td>
                  <td>{totalContractValueRequired}</td>
                  <td style={{ width: 'fitContent' }}>{revisedCumulativeContractValue}</td>

                  <td style={{ minWidth: 100, textAlign: 'center' }}>
                    <ButtonGroup>
                      <Button style={{ height: '30px' }} variant='contained' color="primary" size='small' disabled={props.readOnly} onClick={() => { setDisplayAddContractHistory(false); setDisplayContractHistoryForm(true); setContractHistoryFormEditMode(FormEditMode.EDIT); setContractHistoryToEdit(item); }}><Edit /></Button>
                      <Button style={{ height: '30px' }} variant='contained' color="secondary" size='small' disabled={props.readOnly} onClick={() => handleDelete(item.serialNo)}><Delete /></Button>
                    </ButtonGroup>
                  </td>
                </tr>


              })}
              {props.cpscRequest.dossier.highRiskServicesSlide.highRisks.length === 0 ? <tr>
                <td colSpan={6}><p className=' text-center'> No record available...</p></td>


              </tr> : ''}

            </tbody>

          </Table>
        </Grid>
      </Grid>
      <br />
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ContractHistorySlideTooltip.currentPerformance)}Current performance  <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.contractHistorySlide.currentPerformance} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ContractHistorySlideField.currentPerformance, editorHtmlString)} />

      </div>
      <br />
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ContractHistorySlideTooltip.additionalComments)}Additional Comments <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.contractHistorySlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ContractHistorySlideField.additionalComments, editorHtmlString)} />

      </div>
    </div>
  )
}

export default CurrentContractHistorySlide