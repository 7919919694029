import React, { useEffect, useState } from 'react'
import { Autocomplete, Button, TextField } from '@mui/material';
import { getNameFromTotalEnergiesEmail, callServerAPI } from '../../helpers/Helpers';
import { preloader, FormEditMode } from '../../helpers/Constants';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CustomDialog from '../../components/dialogs/CustomDialog';



const MySwal = withReactContent(Swal);


/**
 * @typedef {Object} CreateEditCPDomainProps
 * @property {Number} formEditMode
 * @property {string} cpDomainsIdToEdit
 * @property {CPDomain[]} cpDomains
 * @property {updateCPDomainCallback}  updateCPDomains
 * @property {Boolean} openCreateCpDomainsForm
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenCreateCpDomainsForm
 */


/**
 * @param {CreateEditCPDomainProps}  props
 * @return {import('react').ReactElement}
 */
function CreateEditCPDomain(props) {
    /** @type CPDomain */
    const emptyCPDomain = {
        id: '',
        title: '',
        contractEngineersList: [],
        domainManagersList: [],
        disabled: false,
        createdDate: undefined,
        lastUpdatedDate: undefined,
        createdBy: '',
        lastUpdatedBy: '',
        generalManagersList: []
    }

    const { enqueueSnackbar } = useSnackbar();
    /** @type [CPDomain,import('react').Dispatch<import('react').SetStateAction<CPDomain>>] */
    const [cpDomainItem, setCPDomainItem] = useState(emptyCPDomain);
    /** @type [string[],import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [tepngEmails, setTepngEmails] = useState([]);

    useEffect(() => {
        callServerAPI('GET', '/api/User/GetAllTepngUsersEmail', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
                setTepngEmails(["", ...response.data.data])
            })
    }, [])


    useEffect(() => {
        if (props.formEditMode === FormEditMode.EDIT) {
            var cpDomains = props.cpDomains.find(e => e.id === props.cpDomainsIdToEdit)
            setCPDomainItem(cpDomains)
        }
    }, [props.formEditMode, props.cpDomainsIdToEdit, props.cpDomains])


    function CreateCPDomain(e) {
        MySwal.fire(preloader);
        callServerAPI('POST', '/api/CPDomain/CreateCPDomain', cpDomainItem, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CPDomain>> } */ response) {
                props.updateCPDomains([...props.cpDomains, response.data.data]);
                MySwal.close();
                handleCloseCpDomainsForm()
                enqueueSnackbar(response?.data?.message ?? "C&P Domain  Created", { variant: 'success' });
            })
    }

    function UpdateCPDomain(e) {
        handleCloseCpDomainsForm();
        callServerAPI('PATCH', `/api/CPDomain/UpdateCPDomain?cpDomainId=${cpDomainItem.id}`, cpDomainItem, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CPDomain>> } */ response) {
                props.updateCPDomains(props.cpDomains.map(e => e.id === cpDomainItem.id ? cpDomainItem : e));
                enqueueSnackbar(response?.data?.message ?? "C&P Domain updated", { variant: 'success' });
            })
    }

    const handleCloseCpDomainsForm = () => {
        props.setOpenCreateCpDomainsForm(false);
    };


    /**@param {CPDomain}  cpDomainItem,  @return {Boolean} */
    const validate = (cpDomainItem) => {
        if (!(Boolean(cpDomainItem.title) && Boolean(cpDomainItem.contractEngineersList) && Boolean(cpDomainItem.domainManagersList)))
            return false


        return true
    }
    return (
        tepngEmails && cpDomainItem && props.cpDomains &&
        <>
             <CustomDialog dialogTitle={props.formEditMode === FormEditMode.CREATE ? 'Add New C&P Domain Area' : 'Update C&P Domain Area'} setOpenDialog={props.setOpenCreateCpDomainsForm} openDialog={props.openCreateCpDomainsForm}>
                <div className='col-12 page' style={{ margin: '0px !important' }}>
                    <div >
                        <div >
                            <div >
                                <div >
                                    <label className='title-label'>C&P Domain Title <span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <input type="text" onChange={(event) => setCPDomainItem(cpDomains => ({ ...cpDomains, title: event.target.value }))} id="title" value={cpDomainItem.title} className="form-control input-font bold" placeholder="" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div style={{ width: '100%', margin: '0px !important' }}>
                                <div >
                                    <label className='title-label'>General Manager <span className='important'>*</span></label>
                                </div>
                                <Autocomplete className='businessareaOptions' id="generalManagersList" options={tepngEmails} autoSelect multiple size="small"
                                    value={cpDomainItem.generalManagersList}
                                    onChange={(e, newValue) => setCPDomainItem(cpDomains => ({ ...cpDomains, generalManagersList: newValue }))}
                                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                                    getOptionDisabled={(option) => option === tepngEmails[0]}
                                    renderInput={(params) => (<TextField {...params} placeholder='Select Email' margin="none" variant="outlined" size='small' />)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div style={{ width: '100%', margin: '0px !important' }}>
                                <div >
                                    <label className='title-label'>Domain Managers <span className='important'>*</span></label>
                                </div>
                                <Autocomplete className='businessareaOptions' id="domainManagersList" options={tepngEmails} autoSelect multiple size="small"
                                    value={cpDomainItem.domainManagersList}
                                    onChange={(e, newValue) => setCPDomainItem(cpDomains => ({ ...cpDomains, domainManagersList: newValue }))}
                                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                                    getOptionDisabled={(option) => option === tepngEmails[0]}
                                    renderInput={(params) => (<TextField {...params} placeholder='Select Email' margin="none" variant="outlined" size='small' />)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div style={{ width: '100%', margin: '0px !important' }}>
                                <div >
                                    <label className='title-label'>Contract Engineers<span className='important'>*</span></label>
                                </div>
                                <Autocomplete className='businessareaOptions' id="contractEngineersList" options={tepngEmails} autoSelect multiple size="small"
                                    value={cpDomainItem.contractEngineersList}
                                    onChange={(e, newValue) => setCPDomainItem(cpDomains => ({ ...cpDomains, contractEngineersList: newValue }))}
                                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                                    getOptionDisabled={(option) => option === tepngEmails[0]}
                                    renderInput={(params) => (<TextField {...params} placeholder='Select Email' margin="none" variant="outlined" size='small' />)}
                                />
                            </div>
                        </div>


                        <br />
                        <div style={{ width: '100%' }} >


                            <div >
                                <label className='title-label'>Enabled <span className='important'>*</span></label>
                            </div>


                            <div className=''  >
                                <div className="form-group mb-0" >
                                    <div className='d-flex'>
                                        <div className='related_req_opt'> Yes &nbsp;<input name="enabled" type="radio" value='true' checked={!cpDomainItem.disabled} onClick={() => setCPDomainItem(cpDomains => ({ ...cpDomains, disabled: false }))} /></div>&nbsp;&nbsp;
                                        <div className='related_req_opt'> No  &nbsp;<input name="enabled" type="radio" value='false' checked={cpDomainItem.disabled} onClick={() => setCPDomainItem(cpDomains => ({ ...cpDomains, disabled: true }))} /></div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
                            <Button variant="contained" disabled={!validate(cpDomainItem)} color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? CreateCPDomain : UpdateCPDomain} ><Add />&nbsp;{props.formEditMode === FormEditMode.CREATE ? 'Create CPDomain' : 'Update CP Domain'} </Button>
                        </div>
                        <br />


                    </div>
                </div>
            </CustomDialog>

        </>
    )
}

export default CreateEditCPDomain