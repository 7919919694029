import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CpscCategory, DossierAddendumStatus, CpscRequestStatus, DossierPdfFieldName, DossierValidationRole, GlobalRole, SERVER_URL, globalStore } from "./Constants";
import { IconButton } from "@mui/material";
import React from "react";
import { HelpOutline } from "@mui/icons-material";


export function initializeGlobalStates() {
    axios.defaults.withCredentials = true
    if (!globalStore.value.has("accessToken")) globalStore.setState("accessToken", null)
    if (!globalStore.value.has("loggedOnTepngUser")) globalStore.setState("loggedOnTepngUser", null)
}


/** @param {CpscRequest} cpscRequest, @param {string} fieldName, @param {string} [contractorName] @return {boolean} */
export function getPdfUploadStatus(cpscRequest, fieldName, contractorName) {
    switch (fieldName) {
        case DossierPdfFieldName.BudgetConfirmation: return cpscRequest.budgetConfirmationSlideUploaded
        case DossierPdfFieldName.RiskLevelAssessment: return cpscRequest.riskLevelAssessmentSlideUploaded
        case DossierPdfFieldName.CostAndMarketTrendChart: return cpscRequest.costAndMarketTrendChartUploaded
        case DossierPdfFieldName.MarketIntelligence: return cpscRequest.marketIntelligenceSlideUploaded
        case DossierPdfFieldName.DueDiligence: return cpscRequest.contractors.find(e => e.contractorName === contractorName)?.dueDiligenceSlideUploaded ?? false
        case DossierPdfFieldName.HumanRights: return cpscRequest.contractors.find(e => e.contractorName === contractorName)?.humanRightsSlideUploaded ?? false
        case DossierPdfFieldName.SavingsCostOptimization: return cpscRequest.savingsCostOptimizationSlideUploaded
        case DossierPdfFieldName.HseEvaluation: return cpscRequest.hseEvaluationSlideUploaded
        case DossierPdfFieldName.TechnicalEvaluation: return cpscRequest.technicalEvaluationSlideUploaded
        case DossierPdfFieldName.CommercialEvaluation: return cpscRequest.commercialEvaluationSlideUploaded
        case DossierPdfFieldName.ProposedBidders: return cpscRequest.proposedBiddersSlideUploaded
        case DossierPdfFieldName.ProjectOperationsPlanning: return cpscRequest.projectOperationsPlanningSlideUploaded
        case DossierPdfFieldName.CfrInitiatives: return cpscRequest.cfrInitiativesSlideUploaded
        case DossierPdfFieldName.ProcurementStrategyHighlights: return cpscRequest.procurementStrategyHighlightsSlideUploaded
        case DossierPdfFieldName.ContractSpendEstimation: return cpscRequest.contractSpendEstimationSlideUploaded
        case DossierPdfFieldName.ContractualEvaluationCriteria: return cpscRequest.contractualEvaluationCriteriaSlideUploaded
        case DossierPdfFieldName.ContractualEvaluation: return cpscRequest.contractualEvaluationSlideUploaded
        case DossierPdfFieldName.ContractualProvisions: return cpscRequest.contractualProvisionsSlideUploaded
        default: alertError("Invalid field name to download file for")
    }
}

/** @param {CpscRequest} cpscRequest, @param {string} fieldName,  @param {boolean} value, @param {string} [contractorName] @returns {CpscRequest}*/
export function setPdfUploadStatus(cpscRequest, fieldName, value, contractorName) {
    switch (fieldName) {
        case DossierPdfFieldName.BudgetConfirmation:
            return { ...cpscRequest, budgetConfirmationSlideUploaded: value };
        case DossierPdfFieldName.RiskLevelAssessment:
            return { ...cpscRequest, riskLevelAssessmentSlideUploaded: value };
        case DossierPdfFieldName.CostAndMarketTrendChart:
            return { ...cpscRequest, costAndMarketTrendChartUploaded: value };
        case DossierPdfFieldName.MarketIntelligence:
            return { ...cpscRequest, marketIntelligenceSlideUploaded: value };
        case DossierPdfFieldName.DueDiligence:
            return { ...cpscRequest, contractors: cpscRequest.contractors.map(inst => inst.contractorName === contractorName ? { ...inst, dueDiligenceSlideUploaded: value } : inst) };
        case DossierPdfFieldName.HumanRights:
            return { ...cpscRequest, contractors: cpscRequest.contractors.map(inst => inst.contractorName === contractorName ? { ...inst, humanRightsSlideUploaded: value } : inst) };
        case DossierPdfFieldName.SavingsCostOptimization:
            return { ...cpscRequest, savingsCostOptimizationSlideUploaded: value };
        case DossierPdfFieldName.HseEvaluation:
            return { ...cpscRequest, hseEvaluationSlideUploaded: value };
        case DossierPdfFieldName.TechnicalEvaluation:
            return { ...cpscRequest, technicalEvaluationSlideUploaded: value };
        case DossierPdfFieldName.CommercialEvaluation:
            return { ...cpscRequest, commercialEvaluationSlideUploaded: value };
        case DossierPdfFieldName.ProposedBidders:
            return { ...cpscRequest, proposedBiddersSlideUploaded: value };
        case DossierPdfFieldName.CatManInvolvement:
            return { ...cpscRequest, catManInvolvementSlideUploaded: value };
        case DossierPdfFieldName.ProjectOperationsPlanning:
            return { ...cpscRequest, projectOperationsPlanningSlideUploaded: value };
        case DossierPdfFieldName.CfrInitiatives:
            return { ...cpscRequest, cfrInitiativesSlideUploaded: value };
        case DossierPdfFieldName.ProcurementStrategyHighlights:
            return { ...cpscRequest, procurementStrategyHighlightsSlideUploaded: value };
        case DossierPdfFieldName.ContractSpendEstimation:
            return { ...cpscRequest, contractSpendEstimationSlideUploaded: value };
        case DossierPdfFieldName.ContractualEvaluationCriteria:
            return { ...cpscRequest, contractualEvaluationCriteriaSlideUploaded: value };
        case DossierPdfFieldName.ContractualEvaluation:
            return { ...cpscRequest, contractualEvaluationSlideUploaded: value };
        case DossierPdfFieldName.ContractualProvisions:
            return { ...cpscRequest, contractualProvisionsSlideUploaded: value };
        default:
            alertError("Could not update Dossier. Invalid field name to upload file for");
    }
}


/**
 * @param {import("axios").Method} method
 * @param {string} url
 * @param {any} data *
 * @param {boolean} serverAuthenticationRequired
 * @param {import("axios").ResponseType} responseType
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<any>>>}
 */
export async function callServerAPI(method, url, data = null, serverAuthenticationRequired, responseType = 'json') {
    return await new Promise(async (resolve, reject) => {
        /** @type TepngUserAccessToken? */ let accessToken = globalStore.getState("accessToken").value
        /** @type import("axios").AxiosRequestConfig */ let config = {}

        // check if server authentication is required and get refresh Accesstoken if it is null or expired
        if (serverAuthenticationRequired && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
            if (await callServerRefreshToken(true)) accessToken = globalStore.getState("accessToken").value
        }

        // if previous authentication call did not fail or authentication call was not required
        config.headers = { ...config.headers, Authorization: 'Bearer ' + accessToken?.token }
        config.method = method; config.url = SERVER_URL + url; config.withCredentials = true; config.data = data
        if (responseType) config.responseType = responseType
        await axios.request(config)
            .then(function (/** @type import("axios").AxiosResponse<ServerAPIResponse<any>> */ response) {
                resolve(response)
            })
            .catch(function (/** @type import("axios").AxiosError<ServerAPIResponse<any>> */error) {
                console.log(error);
                fireAxiosErrorMySwal(error)
            });
    });
}

/**
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<LoginResponse>>>}
 */
export async function callServerTepngUserLogin() {
    return await new Promise(async (resolve, reject) => {
        /** @type import("axios").AxiosRequestConfig */ var config = {}
        config.method = 'POST'; config.data = null; config.url = SERVER_URL + '/api/AuthenticateUser/Login'; config.withCredentials = true
        await axios.request(config)
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<LoginResponse>> */ response) {
                const accessToken = response.data.data.tepngUserAccessToken
                globalStore.getState("accessToken").updateValue(state => accessToken)
                let loggedOnTepngUser = response.data.data.user
                loggedOnTepngUser.roles = response.data.data.roles
                globalStore.getState("loggedOnTepngUser").updateValue(state => loggedOnTepngUser)
                resolve(response)
            })
            .catch(function (/** @type import("axios").AxiosError */ error) {
                reject(error)
            });
    });
}

/**
 * @return {Promise<import("axios").AxiosResponse<ServerAPIResponse<string>>>}
 */
export async function callServerTepngUserLogout() {
    return await new Promise(async (resolve, reject) => {
        await callServerAPI('POST', '/api/AuthenticateUser/Logout', null, true)
            .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<string>> */ response) {
                globalStore.getState("accessToken").updateValue(state => null)
                globalStore.getState("loggedOnTepngUser").updateValue(state => null)
                let url = new URL(window.location.origin + '/logout') //@Ruth remember to change this url to our logout page
                // needed to logout of microsoft - the frond end url needs to be added to Azure App registration Redirect URIs to allow for logout redirect
                window.location.replace(SERVER_URL + '/.auth/logout?post_logout_redirect_uri=' + window.encodeURIComponent(url.href));
                resolve(response) // would not get called due to redirect
            })
            .catch(function (/** @type import("axios").AxiosError */error) {
                fireAxiosErrorMySwal(error)
            });
    });
}

/**
 * @return {Promise<boolean>}
 */
export async function callServerRefreshToken(redirectToLoginIfFailedRefreshToken) {
    let success = false
    await axios.post(SERVER_URL + '/api/AuthenticateUser/RefreshToken', { withCredentials: true })
        .then(async function (/** @type import("axios").AxiosResponse<ServerAPIResponse<LoginResponse>> */ response) {
            const accessToken = response.data.data.tepngUserAccessToken
            globalStore.getState("accessToken").updateValue(state => accessToken)
            let loggedOnTepngUser = response.data.data.user
            loggedOnTepngUser.roles = response.data.data.roles
            globalStore.getState("loggedOnTepngUser").updateValue(state => loggedOnTepngUser)
            success = true
        })
        .catch(function (error) {
            globalStore.getState("accessToken").updateValue(state => null)
            globalStore.getState("loggedOnTepngUser").updateValue(state => null)
        });

    if (success === false && redirectToLoginIfFailedRefreshToken) {
        window.alert("Your session has expired, you will be redirected to login again")
        window.location.href = "/login-tepnguser"
        return success
    }
    else
        return success //return await new Promise(async (resolve, reject) => { resolve(success) });
}

/** @param {String} email, @return {string} */
export function getNameFromTotalEnergiesEmail(email) {
    if (Boolean(email) === false) return ''
    let names = email.toLowerCase().replace("@external.totalenergies.com", '').replace("@totalenergies.com", '').split('.')
    names = names.map(txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
    return names.join(' ')
}

/** @param {String} email, @return {string} */
export function getNameInitialsFromTotalEnergiesEmail(email) {
    if (Boolean(email) === false) return ''
    let names = email.toLowerCase().replace("@external.totalenergies.com", '').replace("@totalenergies.com", '').split('.')
    names = names.map(txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
    let firstName = names[0]
    let secondName = names[1]

    let firstmatch = firstName.match(/\b(\w)/g);
    let secondmatch = secondName.match(/\b(\w)/g);

    let init = firstmatch[0] + secondmatch[0]
    return init
}

/** @param {String} cpscRequestStatus, @return {string} */
export function getValidationRoleForCpscRequestStatus(cpscRequestStatus) {
    switch (cpscRequestStatus) {
        case CpscRequestStatus.DossierAwaitingPreliminaryQAQC:
            return DossierValidationRole.GCC
        case CpscRequestStatus.DossierAwaitingCpDomainManager:
            return DossierValidationRole.CpDomainManager
        case CpscRequestStatus.DossierAwaitingGM:
            return DossierValidationRole.GM
        case CpscRequestStatus.DossierAwaitingBudgetOwner:
            return DossierValidationRole.BudgetOwner
        case CpscRequestStatus.DossierAwaitingEntityDirector:
            return DossierValidationRole.EntityDirector
        default:
            return null
    }
}


/** @param {String} cpscRequestStatus, @return {string} */
export function getAcceptDossierButtonLabel(cpscRequestStatus) {
    switch (cpscRequestStatus) {
        case CpscRequestStatus.DossierAwaitingPreliminaryQAQC:
            return 'Validate'
        case CpscRequestStatus.DossierAwaitingCpDomainManager:
            return 'Endorse'
        case CpscRequestStatus.DossierAwaitingGM:
            return 'Approve'
        case CpscRequestStatus.DossierAwaitingBudgetOwner:
            return 'Endorse'
        case CpscRequestStatus.DossierAwaitingEntityDirector:
            return 'Endorse'
        default:
            return null
    }
}

/** @param {String} dossierAddendumStatus, @return {string} */
export function getValidationRoleForDossierAddendumStatus(dossierAddendumStatus) {
    switch (dossierAddendumStatus) {
        case DossierAddendumStatus.DossierAddendumAwaitingPreliminaryQAQC:
            return DossierValidationRole.GCC
        case DossierAddendumStatus.DossierAddendumAwaitingCpDomainManager:
            return DossierValidationRole.CpDomainManager
        case DossierAddendumStatus.DossierAddendumAwaitingGM:
            return DossierValidationRole.GM
        case DossierAddendumStatus.DossierAddendumAwaitingBudgetOwner:
            return DossierValidationRole.BudgetOwner
        case DossierAddendumStatus.DossierAddendumAwaitingEntityDirector:
            return DossierValidationRole.EntityDirector
        case DossierAddendumStatus.DossierAddendumAwaitingCpscApproval:
            return DossierValidationRole.CpscChairman
        default:
            return null
    }
}


/** @param {String} dossierAddendumStatus, @return {string} */
export function getAcceptDossierAddendumButtonLabel(dossierAddendumStatus) {
    switch (dossierAddendumStatus) {
        case DossierAddendumStatus.DossierAddendumAwaitingPreliminaryQAQC:
            return 'Validate'
        case DossierAddendumStatus.DossierAddendumAwaitingCpDomainManager:
            return 'Endorse'
        case DossierAddendumStatus.DossierAddendumAwaitingGM:
            return 'Approve'
        case DossierAddendumStatus.DossierAddendumAwaitingBudgetOwner:
            return 'Endorse'
        case DossierAddendumStatus.DossierAddendumAwaitingEntityDirector:
            return 'Endorse'
        case DossierAddendumStatus.DossierAddendumAwaitingCpscApproval:
            return 'Approve'
        default:
            return null
    }
}
/** @param {CpscRequest} request, @param {TepngUser} user, @return {boolean} */
export function checkUserCanValidateDossierAddendum(request, user) {
    switch (request.dossierAddendum.status) {
        case DossierAddendumStatus.DossierAddendumAwaitingPreliminaryQAQC:
            return user.roles.includes(GlobalRole.GCC)
        case DossierAddendumStatus.DossierAddendumAwaitingCpDomainManager:
            return request.businessArea.cpDomain.domainManagersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case DossierAddendumStatus.DossierAddendumAwaitingGM:
            return request.businessArea.cpDomain.generalManagersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case DossierAddendumStatus.DossierAddendumAwaitingBudgetOwner:
            return (request.budgetEntity ?? request.businessArea).budgetOwnersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case DossierAddendumStatus.DossierAddendumAwaitingEntityDirector:
            return (request.budgetEntity ?? request.businessArea).directorsList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case DossierAddendumStatus.DossierAddendumAwaitingCpscApproval:
            return user.roles.includes(GlobalRole.CpscChairman)
        default:
            return false;
    }
}
/** @param {CpscRequest} request, @param {TepngUser} user, @return {boolean} */
export function checkUserCanValidateDossier(request, user) {
    switch (request.status) {
        case CpscRequestStatus.DossierAwaitingPreliminaryQAQC:
            return user.roles.includes(GlobalRole.GCC)
        case CpscRequestStatus.DossierAwaitingCpDomainManager:
            return request.businessArea.cpDomain.domainManagersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case CpscRequestStatus.DossierAwaitingGM:
            return request.businessArea.cpDomain.generalManagersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case CpscRequestStatus.DossierAwaitingBudgetOwner:
            return (request.budgetEntity ?? request.businessArea).budgetOwnersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case CpscRequestStatus.DossierAwaitingEntityDirector:
            return (request.budgetEntity ?? request.businessArea).directorsList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        default:
            return false;
    }
}

/** @param {CpscRequest} request, @param {TepngUser} user, @return {boolean} */
export function canPostNewComment(request, user) {
    switch (request.status) {
        case CpscRequestStatus.DossierPreparationOngoing:
            return true
        case CpscRequestStatus.DossierAwaitingTripletConfirmation:
            return isTripletForRequest(request, user)
        case CpscRequestStatus.DossierAwaitingPreliminaryQAQC:
            return user.roles.includes(GlobalRole.GCC)
        case CpscRequestStatus.DossierAwaitingCpDomainManager:
            return request.businessArea.cpDomain.domainManagersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case CpscRequestStatus.DossierAwaitingGM:
            return request.businessArea.cpDomain.generalManagersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case CpscRequestStatus.DossierAwaitingBudgetOwner:
            return (request.budgetEntity ?? request.businessArea).budgetOwnersList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        case CpscRequestStatus.DossierAwaitingEntityDirector:
            return (request.budgetEntity ?? request.businessArea).directorsList.findIndex(e => e.toUpperCase() === user.email.toUpperCase()) > -1
        default:
            return false;
    }
}

/** @param {CpscRequest} request, @param {TepngUser} user, @return {boolean} */
export function isEntityRepForRequest(request, user) {
    return request.entityRepresentative.toUpperCase() === user.email.toUpperCase()
}

/** @param {CpscRequest} request, @param {TepngUser} user, @return {boolean} */
export function isContractEngineerForRequest(request, user) {
    return request.contractEngineer.toUpperCase() === user.email.toUpperCase()
}

/** @param {CpscRequest} request, @param {TepngUser} user, @return {boolean} */
export function isCostControllerForRequest(request, user) {
    return request.costController.toUpperCase() === user.email.toUpperCase()
}

/** @param {CpscRequest} request, @param {TepngUser} user, @return {boolean} */
export function isTripletForRequest(request, user) {
    return (
        request.contractEngineer?.toUpperCase() === user.email.toUpperCase() ||
        request.entityRepresentative?.toUpperCase() === user.email.toUpperCase() ||
        request.costController?.toUpperCase() === user.email.toUpperCase()
    )
}

/** @param {BusinessArea} businessArea, @param {TepngUser} user, @return {boolean} */
export function canEditBusinessArea(businessArea, user) {
    return canEditCPDomain(businessArea.cpDomain, user)
}

/** @param {CPDomain} cpDomain, @param {TepngUser} user, @return {boolean} */
export function canEditCPDomain(cpDomain, user) {
    return user?.roles?.includes(GlobalRole.Admin) ||
        cpDomain.contractEngineersList.find(e => e.toUpperCase() === user.email.toUpperCase()) != null ||
        cpDomain.domainManagersList.find(e => e.toUpperCase() === user.email.toUpperCase()) != null
}

/** @param {CpscRequest} entity, @param {TepngUser} user, @return {boolean} */
export function rejectionCommentExistsForUser(entity, user) {
    var comments = entity.dossierComments.filter(e => e.email.toUpperCase() === user.email.toUpperCase())
    if (entity.status === CpscRequestStatus.DossierAwaitingTripletConfirmation) return comments.find(e => e.createdDate > entity.dossierCompletionDate) != null
    if (entity.status === CpscRequestStatus.DossierAwaitingPreliminaryQAQC) return comments.find(e => e.createdDate > entity.tripletConfirmationDate) != null
    if (entity.status === CpscRequestStatus.DossierAwaitingCpDomainManager) return comments.find(e => e.createdDate > entity.preliminaryQAQCDate) != null
    if (entity.status === CpscRequestStatus.DossierAwaitingGM) return comments.find(e => e.createdDate > entity.cpDomainManagerEndorsementDate) != null
    if (entity.status === CpscRequestStatus.DossierAwaitingBudgetOwner) return comments.find(e => e.createdDate > entity.gMApprovalDate) != null
    if (entity.status === CpscRequestStatus.DossierAwaitingEntityDirector) return comments.find(e => e.createdDate > entity.budgetOwnerEndorsementDate) != null
    return false;
}

/** @param {DossierComment} comment, @param {CpscRequest} cpscRequest, @param {TepngUser} user, @return {boolean} */
export function commentCanBeEditOrDeleted(comment, cpscRequest, user) {
    if (cpscRequest.dossierComments.find(e => e.parentId === comment.id) != null) return false //cannot edit/delete comment with replies
    if (user.email.toUpperCase() !== comment.email.toUpperCase()) return false //must be owner
    //if request changed status since comment posted the comment cannot be edite/deleted
    if (cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing) return cpscRequest.rejectedDate == null || comment.createdDate > cpscRequest.rejectedDate;
    if (cpscRequest.status === CpscRequestStatus.DossierAwaitingTripletConfirmation) return comment.createdDate > cpscRequest.dossierCompletionDate;
    if (cpscRequest.status === CpscRequestStatus.DossierAwaitingPreliminaryQAQC) return comment.createdDate > cpscRequest.tripletConfirmationDate;
    if (cpscRequest.status === CpscRequestStatus.DossierAwaitingCpDomainManager) return comment.createdDate > cpscRequest.preliminaryQAQCDate;
    if (cpscRequest.status === CpscRequestStatus.DossierAwaitingGM) return comment.createdDate > cpscRequest.cpDomainManagerEndorsementDate;
    if (cpscRequest.status === CpscRequestStatus.DossierAwaitingBudgetOwner) return comment.createdDate > cpscRequest.gMApprovalDate;
    if (cpscRequest.status === CpscRequestStatus.DossierAwaitingEntityDirector) return comment.createdDate > cpscRequest.budgetOwnerEndorsementDate;
    return false;
}

/** @param {CpscRequest} cpscRequest, @param {number} slideNumber */
export function getSlideName(cpscRequest, slideNumber) {
    return cpscRequest.dossierSlideSequence.find(e => e.slideNumber === slideNumber)?.dossierSlideName
}


/**
 * @param {String} message
 * @return {import("sweetalert2-react-content").ReactSweetAlertOptions}
 */
export function alertError(message) {
    return {
        icon: 'warning',
        html: `<small>${message}</small>`,
        showDenyButton: true,
        denyButtonText: `<small>Ok</small>`,
        showConfirmButton: false,
    }
}

/**
 * @param {String[]} messages
 * @return {import("sweetalert2-react-content").ReactSweetAlertOptions}
 */
export function alertErrors(messages) {
    return {
        icon: 'warning',
        html: `<small>${messages.join("<br/><br/>")}</small>`,
        width: 500,
        showDenyButton: true,
        denyButtonText: `<small>Ok</small>`,
        showConfirmButton: false,
    }
}

/**
 * @param {String} message
 * @return {import("sweetalert2-react-content").ReactSweetAlertOptions}
 */
export function alertSuccess(message) {
    return {
        icon: 'success',

        html: `<small>${message}</small>`
    }
}

export function formatDateyyyymmdd(date) {
    if (date == null) return ""
    date = new Date(date);
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
    const year = (date.getFullYear() + '').padStart(4, '0')
    return `${year}-${month}-${day}`;
}


export function formatDate(date, format) {
    let d = new Date(date);

    if (format === 'time') {
        return d.toLocaleTimeString('en-US');
    }
    else if (format === 'date') {
        return d.toDateString();
    }
    else {
        return d.toDateString() + ' ' + d.toLocaleTimeString('en-US');
    }
}

export function formatLongDateString(dtStr, includeTime = false) {
    if (dtStr == null) return null
    /** @type Intl.DateTimeFormatOptions */ var dateOptions = { year: 'numeric', month: 'long', day: '2-digit' };
    /** @type Intl.DateTimeFormatOptions */ var timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    var dt = new Date(dtStr)
    return dt.toLocaleDateString('en-GB', dateOptions) + (includeTime ? " " + dt.toLocaleTimeString('en-GB', timeOptions) : '')
}

export function formatShortDateString(dtStr, includeTime = false) {
    if (dtStr == null) return null
    /** @type Intl.DateTimeFormatOptions */ var dateOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    /** @type Intl.DateTimeFormatOptions */ var timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    var dt = new Date(dtStr)
    return dt.toLocaleDateString('en-GB', dateOptions) + (includeTime ? " " + dt.toLocaleTimeString('en-GB', timeOptions) : '')
}

export function formatTimeString(dtStr) {
    if (dtStr == null) return null
    /** @type Intl.DateTimeFormatOptions */ var timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    var dt = new Date(dtStr)
    return dt.toLocaleTimeString('en-GB', timeOptions)
}

export function getCurrentTimeString() {
    const date = new Date()
    return ('0' + (date.getUTCHours())).slice(-2) + ':' + ('0' + (date.getUTCMinutes())).slice(-2) + ':' + ('0' + (date.getUTCSeconds())).slice(-2) + ':' + date.getUTCMilliseconds()
}

export function convertToDateTimeLocalString(datetime) {
    const dt = new Date(datetime);
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt.toISOString().slice(0, 16);
}

/**
 * @param {String} str
 * @return {Boolean}
 */
export function isValidHttpUrl(str) {
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
}

export function storeLState(value) {
    localStorage.setItem('DCPSC_WEB_APP_LS', JSON.stringify(value));
    return true;
}
export function setStore(data) {
    localStorage.setItem('DCPSC_WEB_STORE', JSON.stringify(data));
    return true;
}


// password helper, color & pagination
export function passwordRules(password) {
    if (!password.match(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$/)) {
        return true;
    }
}
export function getHexColor(colorStr) {
    var a = document.createElement('div');
    a.style.color = colorStr;
    var colors = window.getComputedStyle(document.body.appendChild(a)).color.match(/\d+/g).map(function (a) { return parseInt(a, 10); });
    document.body.removeChild(a);
    return (colors.length >= 3) ? '#' + (((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)) : false;
}
export function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}


export function ajaxCall(method, endpoint, data, token) {
    return axios({
        method: method,
        url: SERVER_URL + endpoint,
        data: data,
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    })
}
export function ajaxReCall(method, endpoint, data, token) {
    return axios({
        method: method,
        url: SERVER_URL + endpoint,
        data: data,
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    })
}

/** @param {String} str */
export function isNullOrWhiteSpace(str) {
    return Boolean(str) === false || Boolean(str.trim()) === false
}

/**
 * @param {CpscRequest[]} requests
 * @return {string[]}
 */
export function getMeetingParticipantTriplets(requests) {
    var list = []
    requests.forEach(request => {
        list.push(`${getNameFromTotalEnergiesEmail(request.contractEngineer)} (Contract Engineer)`)
        list.push(`${getNameFromTotalEnergiesEmail(request.entityRepresentative)} (Technical Administrator)`)
        list.push(`${getNameFromTotalEnergiesEmail(request.costController)} (Cost Controller)`)
    });
    return Array.from(new Set(list))
}

/**
 * @param {import("axios").AxiosError<ServerAPIResponse<any>>} error
 */
export async function fireAxiosErrorMySwal(error) {
    const MySwal = withReactContent(Swal);
    MySwal.close();
    if (error?.response?.data instanceof Blob && error?.response?.data?.type && error?.response?.data?.type.toLowerCase().indexOf('json') !== -1) {
        var errorString = JSON.parse(await error.response.data.text());
        MySwal.fire(alertError(errorString.message));
    }
    else {
        if (error?.response == null)
            MySwal.fire(alertError(error.message))
        else if (error?.response?.data?.message == null)
            MySwal.fire(alertError(error.response.status + ' ' + error.response.statusText))
        else
            MySwal.fire(alertErrors(error?.response?.data?.message?.split("\n")))
    }
}

/**
 * @param {CpscRequest} cpscRequest
 * @param {DossierFieldTooltip} tooltip
 * @return {import("react").ReactElement}
 */
export function getSlideTooltip(cpscRequest, tooltip) {
    if (tooltip == null) return <></>
    let tooltipStr = ""
    switch (cpscRequest.cpscCategory) {
        case CpscCategory.Strategy:
            tooltipStr = tooltip.strategy; break;
        case CpscCategory.Award:
            tooltipStr = tooltip.award; break;
        case CpscCategory.PostAward:
            tooltipStr = tooltip.postAward; break;
        default:
            tooltipStr = "";
    }
    if (tooltip.allCategories != null)
        tooltipStr = tooltip.allCategories
    if (isNullOrWhiteSpace(tooltipStr)) {
        if (isNullOrWhiteSpace(tooltip.allCategories))
            return <></>
        else
            tooltipStr = tooltip.allCategories
    }

    const MySwal = withReactContent(Swal);
    /** @type {import("sweetalert2-react-content").ReactSweetAlertOptions} */ const options = {
        html: `<div style='text-align:left'><span style="font-size:13px;">${tooltipStr}</span></div>`,
        title: "<span style='font-size:18px;'>Guidelines</span>",
        showConfirmButton: false,
        width: 700,
        showCloseButton: true,
    }
    return <span><IconButton color='secondary' onClick={() => MySwal.fire(options)}><HelpOutline /></IconButton></span>
}

/**

 * @param {string} tooltip
 * @return {import("react").ReactElement}
 */
export function getCPSCRequestTooltip(tooltip) {
    if (tooltip == null) return <></>
    let tooltipStr = tooltip

    const MySwal = withReactContent(Swal);
    /** @type {import("sweetalert2-react-content").ReactSweetAlertOptions} */ const options = {
        html: `<div style='text-align:left'><span style="font-size:13px;">${tooltipStr}</span></div>`,
        title: "<span style='font-size:18px;'>Guidelines</span>",
        showConfirmButton: false,
        width: 700,
        showCloseButton: true,
    }
    return <span><IconButton color='secondary' onClick={() => MySwal.fire(options)}><HelpOutline /></IconButton></span>
}