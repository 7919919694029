import React from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BidReceptionStrategy } from '../../helpers/Constants'
import { ProcurementStrategyDetailsSlideField, ProcurementStrategySlideTooltip } from '../../helpers/DossierFieldConstants'
import { MenuItem, Select, TextField } from '@mui/material';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import { formatDateyyyymmdd, getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

const bidReceptionStrategyOptions = [{ value: BidReceptionStrategy.Simultaneous, label: BidReceptionStrategy.Simultaneous }, { value: BidReceptionStrategy.Sequential, label: BidReceptionStrategy.Sequential, }]

/**
 * @typedef {Object} ProcurementStrategyDetailsSlideProps

 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 * @property {number} dossierRefreshCount
 */

/**
 * @param {ProcurementStrategyDetailsSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ProcurementStrategyDetailsSlide(props) {
 
  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, procurementStrategySlide: { ...props.cpscRequest.dossier.procurementStrategySlide, [name]: value } })
  }

  /** @param {string} name, @param {any} value  */
  const updateDataFieldDateValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, procurementStrategySlide: { ...props.cpscRequest.dossier.procurementStrategySlide, [name]: value === "" ? null : value } })
  }

  return (
    props.cpscRequest &&
    <form style={{ fontSize: '12px' }}>
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ProcurementStrategySlideTooltip.details)}Need with Respect to Planning <span className='important'>*</span></label>
        
          <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.procurementStrategySlide.details} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ProcurementStrategyDetailsSlideField.details, editorHtmlString)} />

      </div>
      <br />
      <div className='row'>
        <div className='col1'>
          <div >
            <label className='title-label'>Contracting Strategy </label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <TextField size="small" value={props.cpscRequest.contractingStrategy} disabled className='form-control input-font bold' />

            </div>
          </div>
        </div>
        <div className='col2'>
          <div >
            <label className='title-label'>Remuneration Model </label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <TextField size="small" value={props.cpscRequest.dossier.purposeAndDescriptionSlide.remunerationModel} disabled className='form-control input-font bold' />

            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col1'>
          <div >
            <label className='title-label'>Bid Reception Strategy  <span className='important'>*</span></label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <Select disabled={props.readOnly} fullWidth variant="outlined" size="small" name="bidReceptionStrategy" value={props.cpscRequest.dossier.procurementStrategySlide.bidReceptionStrategy}
                onChange={(e) => updateDataFieldValue("bidReceptionStrategy", e.target.value)} >
                {bidReceptionStrategyOptions.map((options, index) => {
                  return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                })}
              </Select>

            </div>
          </div>
        </div>
        <div className='col2'>
          <div >
            <label className='title-label'>Bidder's List Submission Date</label>
          </div>

          <div className=''>
            <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='biddersListSubmissionDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.biddersListSubmissionDate)} onChange={(e) => updateDataFieldDateValue("biddersListSubmissionDate", e.target.value)} />
          </div>

        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col1'>
          <div >
            <label className='title-label'>Bidder's List Approval Date </label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='biddersListApprovalDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.biddersListApprovalDate)} onChange={(e) => updateDataFieldDateValue("biddersListApprovalDate", e.target.value)} />

            </div>
          </div>
        </div>
        <div className='col2'>
          <div >
            <label className='title-label'>CFT Launch Date </label>
          </div>
          <div className=''>

            <div className="form-group mb-0">
              <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='cftLaunchDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.cftLaunchDate)} onChange={(e) => updateDataFieldDateValue("cftLaunchDate", e.target.value)} />

            </div>

          </div>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col1'>
          <div >
            <label className='title-label'>CFT Closing Date  </label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='cftClosingDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.cftClosingDate)} onChange={(e) => updateDataFieldDateValue("cftClosingDate", e.target.value)} />

            </div>
          </div>
        </div>
        <div className='col2'>
          <div >
            <label className='title-label'>Technical Recommendation Date </label>
          </div>

          <div className=''>
            <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='technicalRecommendationDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.technicalRecommendationDate)} onChange={(e) => updateDataFieldDateValue("technicalRecommendationDate", e.target.value)} />
          </div>

        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col1'>
          <div >
            <label className='title-label'>Commercial Recommendation Date  </label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='commercialRecommendationDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.commercialRecommendationDate)} onChange={(e) => updateDataFieldDateValue("commercialRecommendationDate", e.target.value)} />

            </div>
          </div>
        </div>
        <div className='col2'>
          <div >
            <label className='title-label'>CPSC Approval Date </label>
          </div>

          <div className=''>
            <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='cpscApprovalDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.cpscApprovalDate)} onChange={(e) => updateDataFieldDateValue("cpscApprovalDate", e.target.value)} />
          </div>

        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col1'>
          <div >
            <label className='title-label'>Catboard / CCEP Approval Date  </label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='catboardCcepApprovalDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.catboardCcepApprovalDate)} onChange={(e) => updateDataFieldDateValue("catboardCcepApprovalDate", e.target.value)} />

            </div>
          </div>
        </div>
        <div className='col2'>
          <div >
            <label className='title-label'>Partner Approval Request Date </label>
          </div>

          <div className=''>
            <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='partnerApprovalRequestDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.partnerApprovalRequestDate)} onChange={(e) => updateDataFieldDateValue("partnerApprovalRequestDate", e.target.value)} />
          </div>

        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col1'>
          <div >
            <label className='title-label'>NCC Certificate Date  </label>
          </div>
          <div className=''>
            <div className="form-group mb-0">
              <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='nccCertificateDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.nccCertificateDate)} onChange={(e) => updateDataFieldDateValue("nccCertificateDate", e.target.value)} />

            </div>
          </div>
        </div>
        <div className='col2'>
          <div >
            <label className='title-label'>Estimated Contract Signature Date </label>
          </div>

          <div className=''>
            <TextField fullWidth type='Date' className={props.readOnly ? "react-select-disabled" : ""} size="small" disabled={props.readOnly} name='estimatedContractSignatureDate' value={formatDateyyyymmdd(props.cpscRequest.dossier.procurementStrategySlide.estimatedContractSignatureDate)} onChange={(e) => updateDataFieldDateValue("estimatedContractSignatureDate", e.target.value)} />
          </div>

        </div>
      </div>
      <br />
    </form>


  )
}

export default ProcurementStrategyDetailsSlide