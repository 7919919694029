
import React from 'react'
import CustomTabPanel from '../tabs/CustomTabPanel';
import { Tab, Tabs } from '@mui/material';
import { CommercialEvaluationSlideField } from '../../helpers/DossierFieldConstants';
import CommercialEvaluationUpload from './CommercialEvaluation';
import CommercialEvaluationForm from './CommercialEvaluationForm';
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} CommercialEvaluationSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 * @property {downloadPdfCallback} downloadPdf
 * @property {uploadPdfCallback} uploadPdf
 * @property {deletePdfCallback} deletePdf
  * @property {number} dossierRefreshCount
 */

/**
 * @param {CommercialEvaluationSlideProps}  props
 * @return {import('react').ReactElement}
 */
function CommercialEvaluationSlide(props) {
  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);
  

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, [name]: value } })
}

 

  return (
    
    <>
      <div className='content-desktop'>
        <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Commercial Evaluation " value={1} />

          <Tab label="Commercial Evaluation Upload" value={2} />

        </Tabs>
      </div>
      <div className='content-mobile'>
        <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Commercial Evaluation " value={1} />

          <Tab label="Commercial Evaluation Upload" value={2} />

        </Tabs>
      </div>
      <CustomTabPanel value={selectedTabValue} index={1}>
        <CommercialEvaluationForm setDossier={props.setDossier} cpscRequest={props.cpscRequest} readOnly={props.readOnly} />
        <br />
        <div>

          <div className="form-group">
            <label className=' title-label' >Supporting Information<span className='important'>*</span></label>
            <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.commercialEvaluationSlide.supportingInformation} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(CommercialEvaluationSlideField.supportingInformation, editorHtmlString)} />  
            
          </div>
          <br />

          <div className="form-group">
            <label className=' title-label' >Additional Comments<span className='important'>*</span></label>
            <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.commercialEvaluationSlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(CommercialEvaluationSlideField.additionalComments, editorHtmlString)} />  
            
          </div>
        </div>
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabValue} index={2} >
        <CommercialEvaluationUpload readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
      </CustomTabPanel>
    </>
  )
}

export default CommercialEvaluationSlide