import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { alertError } from '../../helpers/Helpers';
import withReactContent from 'sweetalert2-react-content';
import { Button, Grid, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import Table from 'react-bootstrap/esm/Table';
import { Delete, MoreHoriz, MoreVert, Edit } from '@mui/icons-material';
import { AnticipatedContractorMarginScopeName } from '../../helpers/Constants';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MyNumericFormat from '../../components/MyNumericFormat';

const MySwal = withReactContent(Swal);
/**
 * @typedef {Object} CommercialEvaluationFormSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly

 */

/**
 * @param {CommercialEvaluationFormSlideProps}  props
 * @return {import('react').ReactElement}
 */
function CommercialEvaluationForm(props) {
    const vendors = new Set(props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.map(r => r.vendor).filter(e => e != null))
    const scopes = new Set(props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.map(r => r.scope))
    const [newScopeName, setNewScopeName] = useState("");
    const [newVendorName, setNewVendorName] = useState("");
    const [vendorNameToEdit, setVendorNameToEdit] = useState("");
    const [scopeNameToEdit, setScopeNameToEdit] = useState("");
    const addScope = () => {
        if ([...scopes].find(s => s.toUpperCase() === newScopeName.toUpperCase()) != null)
            MySwal.fire(alertError(newScopeName + " exists already"));
        let ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings
        //add initial company estimate record
        ratings.push({ scope: newScopeName, isCompanyEstimate: true, value: 0 })
        //add initial vendor records for scope
        vendors.forEach(vendorName => {
            ratings.push({ scope: newScopeName, isCompanyEstimate: false, value: 0, vendor: vendorName })
        });
        //update commercialEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
        setNewScopeName('')
    }

    const editScope = () => {
        if ([...scopes].find(d => d.toUpperCase() === newScopeName.toUpperCase()) != null && newScopeName !== scopeNameToEdit) {
            MySwal.fire(alertError(newScopeName + " exists already"));
            setNewScopeName('')
            setScopeNameToEdit("")
            return
        }
        const ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.map(e => e.scope === scopeNameToEdit ? { ...e, scope: newScopeName } : e)
        //update technicalEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
        setNewScopeName('')
        setScopeNameToEdit("")
    }


    const deleteScope = (scopeName) => {
        const ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.filter(e => e.scope !== scopeName)
        //update commercialEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
    }

    const addVendor = () => {
        if ([...vendors].find(s => s.toUpperCase() === newVendorName.toUpperCase()) != null)
            MySwal.fire(alertError(newVendorName + " exists already"));
        let ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings
        //add initial vendor records for each scope
        scopes.forEach(scopeName => {
            ratings.push({ scope: scopeName, isCompanyEstimate: false, value: 0, vendor: newVendorName })
        });
        //update commercialEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
        setNewVendorName('')
    }

    const editVendor = () => {
        if ([...vendors].find(d => d.toUpperCase() === newVendorName.toUpperCase()) != null && newVendorName !== vendorNameToEdit) {
            MySwal.fire(alertError(newVendorName + " exists already"));
            setNewVendorName('')
            setVendorNameToEdit("")
            return
        }
        const ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.map(e => e.vendor === vendorNameToEdit ? { ...e, vendor: newVendorName } : e)
        //update technicalEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
        setNewVendorName('')
        setVendorNameToEdit("")
    }
    const deleteVendor = (vendorName) => {
        const ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.filter(e => e.vendor !== vendorName)
        //update commercialEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
    }

    /** @param {string} scopeName, @param {string} vendorName, @param {number} newValue  */
    const updateVendorValue = (scopeName, vendorName, newValue) => {
        const ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.map(inst => inst.scope === scopeName && inst.vendor === vendorName ? { ...inst, value: newValue } : inst)
        //update commercialEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
    }

    /** @param {string} scopeName, @param {number} newValue  */
    const updateCompanyEstimate = (scopeName, newValue) => {
        const ratings = props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.map(inst => inst.scope === scopeName && inst.isCompanyEstimate ? { ...inst, value: newValue } : inst)
        //update commercialEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, commercialEvaluationSlide: { ...props.cpscRequest.dossier.commercialEvaluationSlide, commercialEvaluationRatings: ratings } })
    }

    return (
        <div>

            <div>


                <Grid container className=' d-flex  '>

                    <Grid item xs={12} sm={6} lg={6}  >
                        <div className=' d-flex  justify-content-between'>
                            <input id='newScope' onChange={(e) => setNewScopeName(e.target.value)} value={newScopeName} className='form-control ' style={{ width: '100%', height: '31px' }} name='newScope' required disabled={props.readOnly} />
                            &nbsp;
                            <Button variant='contained' size='small' style={{ height: '31px', width: '200px', marginTop: '0px' }} onClick={scopeNameToEdit === '' ? addScope : editScope} disabled={props.readOnly || newScopeName === ''}>{scopeNameToEdit === '' ? 'Add' : 'Update'} Scope</Button>
                        </div>

                    </Grid> &nbsp;&nbsp;
                    <Grid style={{}} item xs={12} sm={4} lg={5.5} >
                        <div className=' d-flex  justify-content-between'>
                            <input id='newVendor' onChange={(e) => setNewVendorName(e.target.value)} value={newVendorName} className='form-control' style={{ width: '100%', height: '31px' }} name='newVendor' required disabled={props.readOnly} />
                            &nbsp;
                            <Button variant='contained' size='small' style={{ height: '31px', width: '200px', marginTop: '0px' }} onClick={vendorNameToEdit === '' ? addVendor : editVendor} disabled={props.readOnly || newVendorName === ''}>{vendorNameToEdit === '' ? 'Add' : 'Update'} Vendor</Button>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <br />

                <Grid container>
                    <Grid className='' item xs={12} sm={12} lg={12} >
                        <Table responsive striped bordered>
                            <thead className='thead-text'>
                                <tr style={{ fontSize: '10px' }} >
                                    <th style={{ verticalAlign: 'top' }}>Actions</th>

                                    <th style={{ verticalAlign: 'top' }}>Scope</th>
                                    <th style={{ verticalAlign: 'top' }}>Company Estimate</th>
                                    {[...vendors].map(vendorName =>
                                        <th key={'btnDel' + vendorName}>
                                            <div className=' d-flex  justify-content-between'>
                                                <span title={vendorName}>{vendorName}</span>  &nbsp;

                                                <PopupState variant="popover" popupId="demo-popup-menu">
                                                    {(popupState) => (
                                                        <React.Fragment>

                                                            <IconButton color='inherit' {...bindTrigger(popupState)}><MoreVert /></IconButton>

                                                            <Menu {...bindMenu(popupState)}>
                                                                <MenuItem key={'btnDelete' + vendorName} onClick={() => deleteVendor(vendorName)}><Delete color='error' />&nbsp; &nbsp;Delete Vendor</MenuItem>
                                                                <MenuItem key={'btnEdit' + vendorName} onClick={() => { setNewVendorName(vendorName); setVendorNameToEdit(vendorName) }}> <Edit color='primary' />&nbsp; &nbsp;Edit Vendor</MenuItem>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </div>
                                        </th>
                                    )}

                                </tr>

                            </thead>
                            <tbody style={{ fontSize: '11px' }}>
                                {[...scopes].filter(scope => scope.toUpperCase() !== AnticipatedContractorMarginScopeName.toUpperCase())
                                    .sort((a, b) => a < b ? -1 : 1).map((scopeName, index) => {
                                        return <tr key={index}>
                                            <td style={{ maxWidth: '20px' }}>
                                                <PopupState variant="popover" popupId="demo-popup-menu">
                                                    {(popupState) => (
                                                        <React.Fragment>

                                                            <IconButton color='inherit' {...bindTrigger(popupState)}><MoreHoriz /></IconButton>

                                                            <Menu {...bindMenu(popupState)}>
                                                                <MenuItem key={'btnDel' + scopeName} onClick={() => deleteScope(scopeName)}><Delete color='error' />&nbsp; &nbsp;Delete Scope </MenuItem>
                                                                <MenuItem key={'btnEdit' + scopeName} onClick={() => { setNewScopeName(scopeName); setScopeNameToEdit(scopeName) }}> <Edit color='primary' />&nbsp; &nbsp;Edit Scope</MenuItem>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </td>

                                            <td style={{ minWidth: 100, }}>{scopeName}</td>
                                            <td style={{ minWidth: 100, }} key={'companyEstimate' + index} >
                                                <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }}
                                                    onChange={(e) => updateCompanyEstimate(scopeName, Number(e.target.value))}
                                                    value={Number(props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.find(e => e.scope === scopeName && e.isCompanyEstimate).value)}
                                                    className="form-control input-font bold" disabled={props.readOnly} style={{ fontSize: '11px' }} />
                                            </td>
                                            {[...vendors].map(vendorName =>
                                                <td key={scopeName + vendorName + index} >
                                                    <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }}
                                                        onChange={(e) => updateVendorValue(scopeName, vendorName, Number(e.target.value))}
                                                        value={Number(props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.find(e => e.scope === scopeName && e.vendor === vendorName).value)}
                                                        className="form-control input-font bold" disabled={props.readOnly} style={{ fontSize: '11px' }} />
                                                </td>
                                            )}

                                        </tr>

                                    })}
                            </tbody>
                            <tfoot>

                                <tr style={{ fontSize: '12px' }}>
                                    <td colSpan={2} style={{ fontSize: '10px' }} ><b>Total Commercial Evaluation Result</b></td>
                                    <td > {props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.filter(e => e.isCompanyEstimate).reduce((partialSum, a) => partialSum + Number(a.value), 0)}</td>
                                    {[...vendors].map(vendorName =>
                                        <td key={vendorName} >{props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.filter(e => e.vendor === vendorName && e.scope.toUpperCase() !== AnticipatedContractorMarginScopeName.toUpperCase()).reduce((partialSum, a) => partialSum + Number(a.value), 0)}</td>
                                    )}
                                </tr>
                                <tr style={{ fontSize: '12px' }}>
                                    <td colSpan={2} style={{ fontSize: '10px' }} ><b>{AnticipatedContractorMarginScopeName}</b></td>
                                    <td > *</td>
                                    {[...vendors].map(vendorName =>
                                        <td key={vendorName} >
                                            <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }}
                                                onChange={(e) => updateVendorValue(AnticipatedContractorMarginScopeName, vendorName, Number(e.target.value))}
                                                value={Number(props.cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.find(e => e.scope === AnticipatedContractorMarginScopeName && e.vendor === vendorName).value)}
                                                className="form-control input-font bold" disabled={props.readOnly} style={{ fontSize: '12px' }} />
                                        </td>
                                    )}
                                </tr>
                            </tfoot>
                        </Table>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default CommercialEvaluationForm