import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { callServerAPI, formatShortDateString, convertToDateTimeLocalString, isValidHttpUrl } from '../../helpers/Helpers';
import { MoMStatus, MeetingFormat, FormEditMode, MeetingStatus } from '../../helpers/Constants';
import Select from 'react-select'
import Button from '@mui/material/Button';
import { Box, Grid, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import FlexBetween from '../../components/FlexBetween';
import { ArrowBackIos } from '@mui/icons-material';
import AgendaList from './AgendaList';
import '../../css/meeting.css'

const formatOptions = [{ value: MeetingFormat.Physical, label: MeetingFormat.Physical }, { value: MeetingFormat.Virtual, label: MeetingFormat.Virtual }]


/** @type Meeting */ const emptyMeeting = {
    format: MeetingFormat.Physical,
    venue: '',
    meetingDateTime: new Date(),
    id: undefined,
    status: undefined,
    momStatus: MoMStatus.Draft,
    inviteStatusSent: false,
    agenda: [],
    cpscVotes: [],
    cpscDecisions: [],
    participants: [],
    momRejectedBy: "",
    momRejectedComments: "",
    momPreparedBy: "",
    momReviewedBy: "",
    momApprovedBy: "",

}
/**
 * @typedef {Object} CreateMeetingsProps
 * @property {Number} formEditMode
 */

/**
 * @param {CreateMeetingsProps}  props
 * @return {import('react').ReactElement}
 */
function CreateMeetings(props) {
    /** @type [Meeting,import('react').Dispatch<import('react').SetStateAction<Meeting>>] */
    const [data, setData] = useState(emptyMeeting)
    const [editMeeting, setEditMeeting] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const handleClickVariant = (variant) => () => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar('This is a success message!', { variant });
    };

    const { id } = useParams();
    const theme = useTheme();
   

    useEffect(() => {

        const getAScheduledMeeting = async () => {
            callServerAPI('GET', `/api/Meeting/GetAScheduledMeeting?meetingId=${id}`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                    setData(response.data.data);
                })
        }

        if (props.formEditMode === FormEditMode.EDIT) {
            getAScheduledMeeting();
        }
    }, [props.formEditMode, id])

    function createMeeting(e) {
        callServerAPI('POST', '/api/Meeting/CreateMeeting', data, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                handleClickVariant('success')

                enqueueSnackbar(' Meeting Created Successfully', { variant: 'success' })
                navigate('/meeting-list')
            })
    }

    function updateMeeting(e) {
        callServerAPI('PATCH', `/api/Meeting/UpdateMeeting?meetingId=${id}`, data, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                enqueueSnackbar(' Meeting Updated Successfully', { variant: 'success' })

            })
    }

    function updateDataField(name, value) {
        const newdata = { ...data }
        newdata[name] = value
        setData(newdata)
    }

    /** @param {string} name, @param {any} value  */
    const updateDataFieldValue = (name, value) => {
        setData(data => ({ ...data, [name]: value }))
    }
    const navigate = useNavigate();

    const handleHistory = () => {
        navigate('/meeting-list')
    }

    return (
        <Box m="1.0rem 1.0rem">
            <Box sx={{ width: '100%' }}>
                <Stack >
                    <Paper>
                        <FlexBetween>
                            <FlexBetween>
                                <IconButton onClick={handleHistory}>
                                    <ArrowBackIos sx={{ fontSize: '13px' }} />
                                </IconButton>
                                <Typography variant="h6" color={theme.palette.text.secondary} fontWeight="bold" sx={{ margin: '1.0rem', }}>
                                    {props.formEditMode === FormEditMode.CREATE ? "Create Meeting" : `Digital CPSC Meeting - ${formatShortDateString(data.meetingDateTime)}`}
                                </Typography>
                            </FlexBetween>
                            {props.formEditMode === FormEditMode.EDIT && (
                                <Button variant='outlined' onClick={() => setEditMeeting(!editMeeting)}>Edit Meeting Details</Button>
                            )
                            }
                        </FlexBetween>
                    </Paper>
                </Stack>


            </Box>
            <Paper>
                <Box mt="20px" p='1.0rem' gap="20px">
                    <form >
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>
                                <div >
                                    <label className=' title-label'>Meeting Date <span className='important'>*</span></label>
                                </div>

                                <div className=''>
                                    <div className="form-group mb-0">
                                        <input type='datetime-local' onChange={(e) => updateDataField(e.target.id, e.target.value)}
                                            id="meetingDateTime" value={convertToDateTimeLocalString(data.meetingDateTime)} className="form-control input-font bold" placeholder='Date' required />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>
                                <div >
                                    <label className='title-label'>Meeting Format<span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <Select name='format' value={{ label: data.format, value: data.format }}
                                            onChange={(newValue) => updateDataFieldValue("format", newValue?.value)} required options={formatOptions}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    fontSize: '12px',
                                                }),
                                            }} />

                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <br />



                        <div className='row'>
                            <div >
                                <div >
                                    <label className=' title-label'>Venue <span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <input onChange={(e) => updateDataField(e.target.id, e.target.value)} id="venue"
                                            value={data.venue} type="text" className="form-control input-font bold" placeholder={data.format === MeetingFormat.Physical ? "Enter Meeting Room location" : "Enter Teams/Online link"} required />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <br />
                        <div className=' d-flex d-flex flex-row-reverse' >
                            <Button disabled={Boolean(data.venue) === false || (data.format === MeetingFormat.Virtual && !isValidHttpUrl(data.venue)) || (props.formEditMode === FormEditMode.EDIT && !editMeeting)} variant="contained" color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? createMeeting : updateMeeting}>&nbsp; {data.status !== MeetingStatus.Scheduled ? "Create Meeting" : "Update Meeting"}</Button>
                        </div>
                        <br />


                    </form>
                </Box>
            </Paper>
            {props.formEditMode === FormEditMode.EDIT &&
                <>

                    <div>
                        <AgendaList meeting={data} />
                    </div>
                </>
            }
        </Box>

    )
}

export default CreateMeetings