import React, { useState } from 'react'
import { Button, } from '@mui/material';

import { useSnackbar } from 'notistack';
import { callServerAPI } from '../../helpers/Helpers';
import { useParams } from 'react-router-dom';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { preloader } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'



const MySwal = withReactContent(Swal);
/**
 * @typedef {Object} DossierAddendumUploadProps
 * @property {CpscRequest} cpscRequest
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequest>>} setCpscRequest
 */

/**
 * @param {DossierAddendumUploadProps}  props
 * @return {import('react').ReactElement}
 */
function DossierAddendumUpload(props) {

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [dossierAddendumObjectUrl, setdossierAddendumObjectUrlObjectUrl] = useState(null);

  /** @param {FormData} formData */
  async function UploadDossierAddendum(formData) {

    callServerAPI('PATCH', `/api/DossierAddendum/UploadPdf?requestId=${id}`, formData, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        props.setCpscRequest(previousCpscRequest => ({ ...previousCpscRequest, dossierAddendum: { ...previousCpscRequest.dossierAddendum, pdfUploaded: true } }))
        enqueueSnackbar("Dossier Addendum Uploaded successfully", { variant: 'success' })
      })
  }
  function viewDossierAddendum() {
    MySwal.fire(preloader)
    callServerAPI('GET', `/api/DossierAddendum/DownloadPdf?requestId=${id}`, null, true, 'blob')
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        setdossierAddendumObjectUrlObjectUrl(URL.createObjectURL(response.data));
        MySwal.close();
      })

  }

  function viewDossierAddendumMobile() {
    MySwal.fire(preloader)
    callServerAPI('GET', `/api/DossierAddendum/DownloadPdf?requestId=${id}`, null, true, 'blob')
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        window.open(URL.createObjectURL(response.data));
        MySwal.close();
      })

  }
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    let document = {}
    document.name = file.name;
    if (event.target.files.length === 0) return
    const formData = new FormData()
    formData.append("uploadedFile", event.target.files[0]);
    UploadDossierAddendum(formData);


  }
  return (
    <>
      <div className='content-desktop'>
       
          <div className="file-card">
            <div className="file-inputs">
              <input className='upload-input' id="dossierAddendumPdfUploaded" type="file" onChange={handleFileChange} accept="application/pdf" />
              <Button variant='contained' color='secondary' className='upload-btn '>
                <CloudUploadOutlinedIcon />&nbsp; Upload
              </Button>
            </div>
            <p className="main">Supported files</p>
            <p className="info">PDF</p>
          
        </div>
        <div>
          {props.cpscRequest.dossierAddendum.pdfUploaded &&

            <div>
              <div className='files' >
                <div className='file-name'>Dossier Addendum File</div>
                <div className='dld-file'> <Button variant='contained' color='success' disabled={!props.cpscRequest.dossierAddendum.pdfUploaded} onClick={viewDossierAddendum}> <RemoveRedEyeOutlinedIcon /> &nbsp;View</Button></div>

              </div>
              {dossierAddendumObjectUrl && <iframe src={dossierAddendumObjectUrl} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>}
            </div>
          }
        </div>
      </div>
      <div className='content-mobile'>
        <div className="mobile-file-card">

          <div className="file-inputs">
            <input className='upload-input' id="dossierAddendumPdfUploaded" onChange={handleFileChange} type="file" accept="application/pdf" />
            <Button variant='contained' color='secondary' className='upload-btn ' >
              <CloudUploadOutlinedIcon />  &nbsp; Upload
            </Button>

          </div>

          <p className="main">Supported files</p>
          <p className="info">PDF</p>

        </div>
        <div>
          {props.cpscRequest.dossierAddendum.pdfUploaded &&

            <div>
              <div className='files' >
                <div className='mobile-file-name'>Dossier Addendum File</div>
                <div className='dld-file'>

                  <Button variant='contained' color='success' disabled={!props.cpscRequest.dossierAddendum.pdfUploaded} onClick={viewDossierAddendumMobile}> <RemoveRedEyeOutlinedIcon /> &nbsp;View</Button></div>

              </div>


            </div>
          }
        </div>
      </div>

    </>
  )
}

export default DossierAddendumUpload