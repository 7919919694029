import React from 'react'


function SlideDisabled() {
  return (
    <div className="slide-disabled">
      <img src='../assets/images/noaccess.png' alt='noaccess'/>
    <p>Not Authorized</p>  
    <p>You do not have acess to this view</p>  
    </div>
  )
}

export default SlideDisabled