import { Delete, Edit, MoreHoriz, MoreVert } from '@mui/icons-material';
import { Button, Grid, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { alertError } from '../../helpers/Helpers';
import Table from 'react-bootstrap/Table';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import MyNumericFormat from '../MyNumericFormat';


const MySwal = withReactContent(Swal);


/**
 * @typedef {Object} HseEvaluationFormSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly

 */

/**
 * @param {HseEvaluationFormSlideProps}  props
 * @return {import('react').ReactElement}
 */
function HseEvaluationFormSlide(props) {

    const vendors = new Set(props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.map(r => r.vendor).filter(e => e != null))
    const descriptions = new Set(props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.map(r => r.description))
    const [newDescriptionName, setNewDescriptionName] = useState("");
    const [newVendorName, setNewVendorName] = useState("");
    const [vendorNameToEdit, setVendorNameToEdit] = useState("");
    const [descriptionNameToEdit, setDescriptionNameToEdit] = useState("");




    const addDescription = () => {
        if ([...descriptions].find(d => d.toUpperCase() === newDescriptionName.toUpperCase()) != null) {
            MySwal.fire(alertError(newDescriptionName + " exists already"));
            return
        }
        let ratings = props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings
        if ([...vendors].length === 0)
            ratings.push({ description: newDescriptionName, score: 0, vendor: "Vendor 1" })
        else {
            vendors.forEach(vendorName => {
                ratings.push({ description: newDescriptionName, score: 0, vendor: vendorName })
            });
        }

        //update hseEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, hseEvaluationRatings: ratings } })
        setNewDescriptionName('')
    }


    const editDescription = () => {
        if ([...descriptions].find(d => d.toUpperCase() === newDescriptionName.toUpperCase()) != null && newDescriptionName !== descriptionNameToEdit) {
            MySwal.fire(alertError(newDescriptionName + " exists already"));
            setNewDescriptionName('')
            setDescriptionNameToEdit("")
            return
        }
        const ratings = props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.map(e => e.description === descriptionNameToEdit ? { ...e, description: newDescriptionName } : e)
        //update hseEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, hseEvaluationRatings: ratings } })
        setNewDescriptionName('')
        setDescriptionNameToEdit("")
    }

    const deleteDescription = (descriptionName) => {
        const ratings = props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.filter(e => e.description !== descriptionName)
        //update technicalEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, hseEvaluationRatings: ratings } })
    }

    const addVendor = () => {
        if ([...vendors].find(d => d.toUpperCase() === newVendorName.toUpperCase()) != null) {
            MySwal.fire(alertError(newVendorName + " exists already"));
            return
        }
        let ratings = props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings
        //add initial vendor records for each description
        if ([...descriptions].length === 0)
            ratings.push({ description: "Hse Criteria 1", score: 0, vendor: newVendorName })
        else {
            descriptions.forEach(descriptionName => {
                ratings.push({ description: descriptionName, score: 0, vendor: newVendorName })
            });
        }
        //update hseEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, hseEvaluationRatings: ratings } })
        setNewVendorName('')
    }

    const editVendor = () => {
        if ([...vendors].find(d => d.toUpperCase() === newVendorName.toUpperCase()) != null && newVendorName !== vendorNameToEdit) {
            MySwal.fire(alertError(newVendorName + " exists already"));
            setNewVendorName('')
            setVendorNameToEdit("")
            return
        }
        const ratings = props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.map(e => e.vendor === vendorNameToEdit ? { ...e, vendor: newVendorName } : e)
        //update technicalEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, hseEvaluationRatings: ratings } })
        setNewVendorName('')
        setVendorNameToEdit("")
    }

    const deleteVendor = (vendorName) => {
        const ratings = props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.filter(e => e.vendor !== vendorName)
        //update commercialEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, hseEvaluationRatings: ratings } })
    }

    /** @param {string} descriptionName, @param {string} vendorName, @param {number} newValue  */
    const updateVendorValue = (descriptionName, vendorName, newValue) => {
        const ratings = props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.map(inst => inst.description === descriptionName && inst.vendor === vendorName ? { ...inst, score: newValue } : inst)
        //update technicalEvaluationRatings in dossier
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, hseEvaluationRatings: ratings } })
    }




    return (


        <div>
            <div>

                <Grid container className=' d-flex  '>

                    <Grid item xs={12} sm={6} lg={6}  >
                        <div className=' d-flex  justify-content-between'>
                            <input id='newDescription' onChange={(e) => setNewDescriptionName(e.target.value)}
                                value={newDescriptionName} className='form-control ' style={{ height: '31px' }} name='newDescription' required disabled={props.readOnly} />
                            &nbsp;<Button variant='contained' size='small' style={{ height: '31px', width: '200px', marginTop: '0px' }} onClick={descriptionNameToEdit === '' ? addDescription : editDescription} disabled={props.readOnly || newDescriptionName === ''}>{descriptionNameToEdit === '' ? 'Add' : 'Update'} Description</Button>
                        </div>

                    </Grid> &nbsp;&nbsp;
                    <Grid style={{}} item xs={12} sm={4} lg={5.5} >
                        <div className=' d-flex  justify-content-between'>
                            <input id='newHseVendor' onChange={(e) => setNewVendorName(e.target.value)}
                                value={newVendorName} className='form-control' style={{ height: '31px' }} name='newHseVendor' required disabled={props.readOnly} />
                            &nbsp;<Button variant='contained' size='small' style={{ height: '31px', marginTop: '0px', width: '150px' }} onClick={vendorNameToEdit === '' ? addVendor : editVendor} disabled={props.readOnly || newVendorName === ''}>{vendorNameToEdit === '' ? 'Add' : 'Update'} Vendor</Button>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <Table responsive striped bordered>
                    <thead className='thead-text'>
                        <tr style={{ fontSize: '10px' }}>
                            <th style={{ verticalAlign: 'top' }}>Actions</th>
                            <th style={{ verticalAlign: 'top' }}>Description</th>
                            {[...vendors].map(vendorName =>
                                <th key={'btnDel' + vendorName}>
                                    <div className=' d-flex  justify-content-between'>
                                        <span title={vendorName}>{vendorName}</span>  &nbsp;

                                        <PopupState variant="popover" popupId="demo-popup-menu">
                                            {(popupState) => (
                                                <React.Fragment>

                                                    <IconButton color='inherit' {...bindTrigger(popupState)}><MoreVert /></IconButton>

                                                    <Menu {...bindMenu(popupState)}>
                                                        <MenuItem key={'btnDelete' + vendorName} onClick={() => deleteVendor(vendorName)}><Delete color='error' />&nbsp; &nbsp;Delete Vendor</MenuItem>
                                                        <MenuItem key={'btnEdit' + vendorName} onClick={() => { setNewVendorName(vendorName); setVendorNameToEdit(vendorName) }}> <Edit color='primary' />&nbsp; &nbsp;Edit Vendor</MenuItem>
                                                    </Menu>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: '11px' }}>
                        {[...descriptions].sort((a, b) => a < b ? -1 : 1).map((descriptionName, index) => {
                            return <tr className='trtext' key={index}>

                                <td style={{ maxWidth: '20px' }}>
                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                        {(popupState) => (
                                            <React.Fragment>

                                                <IconButton color='inherit' {...bindTrigger(popupState)}><MoreHoriz /></IconButton>

                                                <Menu {...bindMenu(popupState)}>
                                                    <MenuItem key={'btnDel' + descriptionName} onClick={() => deleteDescription(descriptionName)}><Delete color='error' />&nbsp; &nbsp;Delete Description </MenuItem>
                                                    <MenuItem key={'btnEdit' + descriptionName} onClick={() => { setNewDescriptionName(descriptionName); setDescriptionNameToEdit(descriptionName) }}> <Edit color='primary' />&nbsp; &nbsp;Edit Description</MenuItem>
                                                </Menu>
                                            </React.Fragment>
                                        )}
                                    </PopupState>
                                </td>
                                <td style={{ minWidth: 100, textAlign: 'center' }}>{descriptionName}</td>

                                {[...vendors].map(vendorName =>
                                    <td key={descriptionName + vendorName + index} >
                                        <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }}
                                            onChange={(e) => updateVendorValue(descriptionName, vendorName, Number(e.target.value))}
                                            value={Number(props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.find(e => e.description === descriptionName && e.vendor === vendorName).score)}
                                            className="form-control input-font bold" disabled={props.readOnly} style={{ fontSize: '11px' }} />
                                    </td>
                                )}

                            </tr>

                        })}
                    </tbody>
                    <tfoot>
                        <tr style={{ fontSize: '11px' }} >
                            <td > *</td>
                            <td style={{ fontSize: '10px' }}><b>Total Hse Evaluation Result</b></td>
                            {[...vendors].map(vendorName =>
                                <td key={vendorName} >{props.cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.filter(e => e.vendor === vendorName).reduce((partialSum, a) => partialSum + Number(a.score), 0)}</td>
                            )}
                        </tr>
                    </tfoot>
                </Table>
            </div>
            <br />

        </div>



    )
}

export default HseEvaluationFormSlide