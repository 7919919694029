import { MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import Table from 'react-bootstrap/Table';
import '../../css/template.css'
import { YesNoNA, HseRiskLevel } from '../../helpers/Constants'
import { formatDateyyyymmdd, formatShortDateString } from '../../helpers/Helpers';

const highRiskOptions = [{ value: HseRiskLevel.High, label: HseRiskLevel.High }, { value: HseRiskLevel.Low, label: HseRiskLevel.Low }, { value: HseRiskLevel.Medium, label: HseRiskLevel.Medium }]
const statusOptions = [{ value: YesNoNA.Yes, label: YesNoNA.Yes }, { value: YesNoNA.No, label: YesNoNA.No }, { value: YesNoNA.NA, label: YesNoNA.NA }]
/**
 * @typedef {Object} ApprovalGovernanceSlideProps
* @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 */

/**
 * @param {ApprovalGovernanceSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ApprovalGovernanceSlide(props) {

    /** @param {string} name, @param {any} value  */
    const updateDataFieldValue = (name, value) => {
        props.setDossier({ ...props.cpscRequest.dossier, approvalGovernanceSlide: { ...props.cpscRequest.dossier.approvalGovernanceSlide, [name]: value } })
    }

    /** @param {string} name, @param {any} value  */
    const updateDataFieldDateValue = (name, value) => {
        props.setDossier({ ...props.cpscRequest.dossier, approvalGovernanceSlide: { ...props.cpscRequest.dossier.approvalGovernanceSlide, [name]: value === "" ? null : value } })
    }

    return (
        <div>
            <form>
                <fieldset className='' style={{ border: '1px solid #b9b9b9' }}>
                    <legend className='sectionTitle'>Cat Man Validation</legend>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <div className='row'>
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Category Mananager Status<span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <Select disabled={props.readOnly} fullWidth variant="outlined" size="small" name="catManStatus" value={props.cpscRequest.dossier.approvalGovernanceSlide.catManStatus}
                                            onChange={(e) => updateDataFieldValue("catManStatus", e.target.value)} >
                                            {statusOptions.map((options, index) => {
                                                return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Category Name {props.cpscRequest.dossier.approvalGovernanceSlide.catManStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>

                                <div className="form-group mb-0">
                                    <TextField size="small" value={props.cpscRequest.dossier.approvalGovernanceSlide.categoryName} name='categoryName' onChange={(e) => updateDataFieldValue("categoryName", e.target.value)} className='form-control input-font bold' />

                                </div>

                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Category Manager Name {props.cpscRequest.dossier.approvalGovernanceSlide.catManStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" value={props.cpscRequest.dossier.approvalGovernanceSlide.catManName} name='catManName' onChange={(e) => updateDataFieldValue("catManName", e.target.value)} className='form-control input-font bold' />

                                    </div>
                                </div>
                            </div>


                        </div>
                        <br />
                        <div className='row'>
                            <div >
                                <label className='title-label'>Comments</label>
                            </div>
                            <div className="form-group mb-0">
                                <TextField fullWidth multiline minRows={2} maxRows={6} className={props.readOnly ? "react-select-disabled" : ""} placeholder="catManComments " size="small" name='catManComments' value={props.cpscRequest.dossier.approvalGovernanceSlide.catManComments} onChange={(newValue) => updateDataFieldValue("catManComments", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />
                    </div>
                </fieldset>
                <br />
                <br />
                <fieldset className='' style={{ border: '1px solid #b9b9b9' }}>
                    <legend className='sectionTitle'>Procurement Approval Governance</legend>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <div className='row'>

                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Category Board Status<span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <Select disabled={props.readOnly} fullWidth variant="outlined" size="small" name="catBoardStatus" value={props.cpscRequest.dossier.approvalGovernanceSlide.catBoardStatus}
                                            onChange={(e) => updateDataFieldValue("catBoardStatus", e.target.value)} >
                                            {statusOptions.map((options, index) => {
                                                return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Approving Entity {props.cpscRequest.dossier.approvalGovernanceSlide.catBoardStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" value='CATBOARD' name='' disabled className='form-control input-font bold react-select-disabled' />

                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>CatBoard Planned Date {props.cpscRequest.dossier.approvalGovernanceSlide.catBoardStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" type='date' value={formatDateyyyymmdd(props.cpscRequest.dossier.approvalGovernanceSlide.catBoardPlannedDate)} name='catBoardPlannedDate' onChange={(e) => updateDataFieldDateValue("catBoardPlannedDate", e.target.value)} className='form-control input-font bold' />

                                    </div>
                                </div>
                            </div>

                        </div>
                        <br />
                        <div className='row'>
                            <div >
                                <label className='title-label'>Comments </label>
                            </div>
                            <div className="form-group mb-0">
                                <TextField fullWidth multiline minRows={2} maxRows={6} className={props.readOnly ? "react-select-disabled" : ""} placeholder="catBoardComments " size="small" name='catBoardComments' value={props.cpscRequest.dossier.approvalGovernanceSlide.catBoardComments} onChange={(newValue) => updateDataFieldValue("catBoardComments", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />
                        <hr style={{border:"solid 1px #023361"}} />
                        <br />
                        <div className='row'>

                            <div className='column1'>
                                <div >
                                    <label className='title-label'>CCEP Status<span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <Select disabled={props.readOnly} fullWidth variant="outlined" size="small" name="ccepStatus" value={props.cpscRequest.dossier.approvalGovernanceSlide.ccepStatus}
                                            onChange={(e) => updateDataFieldValue("ccepStatus", e.target.value)} >
                                            {statusOptions.map((options, index) => {
                                                return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Approving Entity {props.cpscRequest.dossier.approvalGovernanceSlide.ccepStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" value='CCEP' name='' disabled className='form-control input-font bold react-select-disabled' />

                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>CCEP Planned Date {props.cpscRequest.dossier.approvalGovernanceSlide.ccepStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" type='date' value={formatDateyyyymmdd(props.cpscRequest.dossier.approvalGovernanceSlide.ccepPlannedDate)} name='ccepPlannedDate' onChange={(e) => updateDataFieldDateValue("ccepPlannedDate", e.target.value)} className='form-control input-font bold' />

                                    </div>
                                </div>
                            </div>

                        </div>
                        <br />
                        <div>
                            <div >
                                <label className='title-label'>Comments </label>
                            </div>
                            <div className="form-group mb-0">
                                <TextField fullWidth multiline minRows={2} maxRows={6} className={props.readOnly ? "react-select-disabled" : ""} placeholder="ccepComments " size="small" name='ccepComments' value={props.cpscRequest.dossier.approvalGovernanceSlide.ccepComments} onChange={(newValue) => updateDataFieldValue("ccepComments", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />
                    </div>
                </fieldset>
                <br />
                <br />
                <fieldset className='' style={{ border: '1px solid #b9b9b9' }}>
                    <legend className='sectionTitle'>Partner & Regulator Governance</legend>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <div className=''>
                            <div >
                                <label className='title-label'>NUIMS Status<span className='important'>*</span></label>
                            </div>
                            <div className=''>
                                <div className="form-group mb-0">
                                    <Select disabled={props.readOnly} fullWidth variant="outlined" size="small" name="nuimsStatus" value={props.cpscRequest.dossier.approvalGovernanceSlide.nuimsStatus}
                                        onChange={(e) => updateDataFieldValue("nuimsStatus", e.target.value)} >
                                        {statusOptions.map((options, index) => {
                                            return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='row'>

                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Approving Entity {props.cpscRequest.dossier.approvalGovernanceSlide.nuimsStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" value='NUIMS' name='' disabled className='form-control input-font bold react-select-disabled' />

                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Planned Date - Strategy {props.cpscRequest.dossier.approvalGovernanceSlide.nuimsStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" type='date' value={formatDateyyyymmdd(props.cpscRequest.dossier.approvalGovernanceSlide.nuimsPlannedDateStrategy)} name='nuimsPlannedDateStrategy' onChange={(e) => updateDataFieldDateValue("nuimsPlannedDateStrategy", e.target.value)} className='form-control input-font bold' />

                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Planned Date - Award {props.cpscRequest.dossier.approvalGovernanceSlide.nuimsStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" type='date' value={formatDateyyyymmdd(props.cpscRequest.dossier.approvalGovernanceSlide.nuimsPlannedDateAward)} name='nuimsPlannedDateAward' onChange={(e) => updateDataFieldDateValue("nuimsPlannedDateAward", e.target.value)} className='form-control input-font bold' />

                                    </div>
                                </div>
                            </div>

                        </div>

                        <br />
                        <div className='row'>
                            <div >
                                <label className='title-label'>Comments </label>
                            </div>
                            <div className="form-group mb-0">
                                <TextField fullWidth multiline minRows={2} maxRows={6} className={props.readOnly ? "react-select-disabled" : ""} placeholder="nuimsComments " size="small" name='nuimsComments' value={props.cpscRequest.dossier.approvalGovernanceSlide.nuimsComments} onChange={(newValue) => updateDataFieldValue("nuimsComments", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />
                        <hr style={{border:"solid 1px #023361"}} />
                        <br />
                        <div className=''>
                                <div >
                                    <label className='title-label'>NCDMB Status<span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <Select disabled={props.readOnly} fullWidth variant="outlined" size="small" name="ncdmbStatus" value={props.cpscRequest.dossier.approvalGovernanceSlide.ncdmbStatus}
                                            onChange={(e) => updateDataFieldValue("ncdmbStatus", e.target.value)} >
                                            {statusOptions.map((options, index) => {
                                                return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <br />
                        <div className='row'>

                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Approving Entity {props.cpscRequest.dossier.approvalGovernanceSlide.ncdmbStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" value='NCDMB' name='' disabled className='form-control input-font bold react-select-disabled' />

                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Planned Date - Strategy {props.cpscRequest.dossier.approvalGovernanceSlide.ncdmbStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" type='date' value={formatDateyyyymmdd(props.cpscRequest.dossier.approvalGovernanceSlide.ncdmbPlannedDateStrategy)} name='ncdmbPlannedDateStrategy' onChange={(e) => updateDataFieldDateValue("ncdmbPlannedDateStrategy", e.target.value)} className='form-control input-font bold' />

                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='column1'>
                                <div >
                                    <label className='title-label'>Planned Date - Award {props.cpscRequest.dossier.approvalGovernanceSlide.ncdmbStatus === YesNoNA.Yes && <span className='important'>*</span>}</label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField size="small" type='date' value={formatDateyyyymmdd(props.cpscRequest.dossier.approvalGovernanceSlide.ncdmbPlannedDateAward)} name='ncdmbPlannedDateAward' onChange={(e) => updateDataFieldDateValue("ncdmbPlannedDateAward", e.target.value)} className='form-control input-font bold' />

                                    </div>
                                </div>
                            </div>

                        </div>
                        <br />

                        <div>
                            <div >
                                <label className='title-label'>Comments </label>
                            </div>
                            <div className="form-group mb-0">
                                <TextField fullWidth multiline minRows={2} maxRows={6} className={props.readOnly ? "react-select-disabled" : ""} placeholder="ncdmbComments " size="small" name='ncdmbComments' value={props.cpscRequest.dossier.approvalGovernanceSlide.ncdmbComments} onChange={(newValue) => updateDataFieldValue("ncdmbComments", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />
                    </div>
                </fieldset>
                <br />
                <br />
                <fieldset className='' style={{ border: '1px solid #b9b9b9' }}>
                    <legend className='sectionTitle'>Compliance Governance</legend>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <Table className='table-res' responsive striped bordered>
                            <thead>
                                <tr style={{ fontSize: '10px', }}>
                                    <th >Element</th>
                                    <th> Status</th>
                                    <th> Validity</th>
                                    <th> Comments</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td valign='middle'>HSE Risk Level</td>

                                    <td valign='middle'><Select style={{ height: '35px' }} disabled={props.readOnly} fullWidth variant="outlined" size="small" name="hseRiskLevelStatus" className='form-control input-font bold' value={props.cpscRequest.dossier.approvalGovernanceSlide.hseRiskLevelStatus}
                                        onChange={(e) => updateDataFieldValue("hseRiskLevelStatus", e.target.value)} >
                                        {highRiskOptions.map((options, index) => {
                                            return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                        })}
                                    </Select>
                                    </td>
                                    <td valign='middle'>
                                        <TextField size="small" value={props.cpscRequest.dossier.approvalGovernanceSlide.hseRiskLevelValidity} name='hseRiskLevelValidity' onChange={(e) => updateDataFieldValue("hseRiskLevelValidity", e.target.value)} className='form-control input-font bold' placeholder='Hse Risk Level Validity' />
                                    </td>
                                    <td valign='middle'>
                                        <TextField fullWidth multiline minRows={2} maxRows={6} className='form-control input-font bold' placeholder=" Hse Risk Level Comments" size="small" name='hseRiskLevelComments' value={props.cpscRequest.dossier.approvalGovernanceSlide.hseRiskLevelComments} onChange={(newValue) => updateDataFieldValue("hseRiskLevelComments", newValue?.target.value)} />
                                    </td>
                                </tr>
                                {props.cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td valign='middle'> Due Diligence({item.contractorName})</td>
                                            <td valign='middle'>{item.dueDiligenceStatus}</td>
                                            <td valign='middle'>{formatShortDateString(item.dueDiligenceExpiryDate)}</td>
                                            <td valign='middle'>{item.dueDiligenceComments}</td>

                                        </tr>
                                    )
                                })}
                                {props.cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.length === 0 ?
                                    <>
                                        <tr>
                                            <td align='center' colSpan={4}>No Contractor Specified for Due Diligence</td>
                                        </tr>
                                    </>
                                    :
                                    ''
                                }
                                {props.cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td valign='middle'> Human Rights({item.contractorName})</td>
                                            <td valign='middle'>{item.humanRightsAuditStatus}</td>
                                            <td valign='middle'>{formatShortDateString(item.dueDiligenceExpiryDate)}</td>
                                            <td valign='middle'>{item.humanRightsComments}</td>

                                        </tr>
                                    )
                                })}
                                {props.cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.length === 0 ?
                                    <>
                                        <tr>
                                            <td align='center' colSpan={4}>No Contractor Specified for Human Rights</td>
                                        </tr>
                                    </>
                                    :
                                    ''
                                }
                                <tr>
                                    <td valign='middle'>Synergy with Assets</td>

                                    <td valign='middle'><Select style={{ height: '35px' }} disabled={props.readOnly} fullWidth variant="outlined" size="small" name="synergyWithAssetsStatus" className='form-control input-font bold' value={props.cpscRequest.dossier.approvalGovernanceSlide.synergyWithAssetsStatus}
                                        onChange={(e) => updateDataFieldValue("synergyWithAssetsStatus", e.target.value)} >
                                        {statusOptions.map((options, index) => {
                                            return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                        })}
                                    </Select>
                                    </td>
                                    <td valign='middle'>
                                        <TextField size="small" value={props.cpscRequest.dossier.approvalGovernanceSlide.synergyWithAssetsValidity} name='synergyWithAssetsValidity' onChange={(e) => updateDataFieldValue("synergyWithAssetsValidity", e.target.value)} className='form-control input-font bold' placeholder='Synergy With Assets Validity' />
                                    </td>
                                    <td valign='middle'>
                                        <TextField fullWidth multiline minRows={2} maxRows={6} className='form-control input-font bold' placeholder=" Synergy With Assets Comments" size="small" name='synergyWithAssetsComments' value={props.cpscRequest.dossier.approvalGovernanceSlide.synergyWithAssetsComments} onChange={(newValue) => updateDataFieldValue("synergyWithAssetsComments", newValue?.target.value)} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}

export default ApprovalGovernanceSlide