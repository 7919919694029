
import React, { useEffect, useState } from 'react'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const emptyState = EditorState.createEmpty();

/**
 * @typedef {Object}  MyRichTextEditorProps
 * @property {string} editorHtmlString
 * @property {updateHtmlEditorString} updateEditorHtmlString
 * @property {boolean} [readOnly]
 */

/**
 * @param { MyRichTextEditorProps}  props
 * @return {import('react').ReactElement}
 */

function MyRichTextEditor(props) {
  /** @type [EditorState, import('react').Dispatch<import('react').SetStateAction<EditorState>>] */
  const [richTextEditorState, setRichTextEditorState] = useState(null)
  /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
  const [initialEditorHtmlString,] = useState(props.editorHtmlString)

  useEffect(() => {
    function loadRichTextEditorStateFromData() {
      if (initialEditorHtmlString == null || initialEditorHtmlString === '')
        setRichTextEditorState(emptyState)
      else
        setRichTextEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(initialEditorHtmlString).contentBlocks)))
    }
    loadRichTextEditorStateFromData()
  }, [initialEditorHtmlString])


  /** @param {EditorState} newState  */

  const updateRichTextEditorState = (newState) => {
    setRichTextEditorState(newState)
    if (newState == null || !newState.getCurrentContent().hasText() || newState.getCurrentContent().getPlainText().trim() === '') // check for htmlcontent without text (tags only)
      props.updateEditorHtmlString('')
    else
      props.updateEditorHtmlString(draftToHtml(convertToRaw(newState.getCurrentContent())))
  }



  return (
    richTextEditorState &&
    <div>
      <form>
        <div className="form-group">
          <Editor readOnly={props.readOnly ?? false} hashtag={{ separator: ' ', trigger: '#' }} editorState={richTextEditorState}
            onEditorStateChange={(state) => updateRichTextEditorState(state)} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName"
            editorStyle={{ border: '1 Solid #F0F0F0' }} stripPastedStyles={true}

            toolbar={{
              options: ['list', 'inline', 'colorPicker', 'link',],
              inline: { options: ['italic', 'bold', 'underline', 'superscript', 'subscript'], },
              list: { options: ['unordered', 'ordered', 'indent', 'outdent'], },
            }}

          />
        </div>
      </form>
    </div>
  )
}

export default MyRichTextEditor