import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // in StrictMode, react-draft-wysiwyg pop up dialogs (color, link etc.) do not work in dev environment
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
