import React, { useState } from 'react'
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { preloader, FormEditMode, CpscCategory } from '../../helpers/Constants';
import { useParams } from 'react-router-dom';
import { alertErrors, callServerAPI, getCPSCRequestTooltip } from '../../helpers/Helpers';
import MyNumericFormat from '../../components/MyNumericFormat';
import { getContractorValidationErrors } from '../../helpers/ValidationRules';
import { CpscRequestFields } from '../../helpers/DossierFieldConstants';
import { Add, Close, Edit } from '@mui/icons-material';

const MySwal = withReactContent(Swal);
/**
 * @typedef {Object} ContractorDetailsFormProps
 * @property {CpscRequest} cpscRequest
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequest>>} setCpscRequest
 * @property {Function} setDisplayContractorDetailsForm
 * @property {Function} setDisplayAddContractorDetails
 * @property {Number} cpscRequestFormEditMode
 * @property {Number} contractorFormEditMode
 * @property {string} contractorNameToEdit
 */

/**
 * @param {ContractorDetailsFormProps}  props
 * @return {import('react').ReactElement}
 */
function ContractorDetailsForm(props) {
    const { id } = useParams();
    /** @type Contractor */ let emptyContractor = {
        contractorName: "",
        contractNumber: "",
        contractValue: undefined,
        otherContractCurrencyValue: undefined,
        agoraRef: "",
        dueDiligenceSlideUploaded: undefined,
        humanRightsSlideUploaded: undefined,
        dueDiligenceSlideContentType: '',
        humanRightsSlideContentType: ''
    }
    /** @type [Contractor,import('react').Dispatch<import('react').SetStateAction<Contractor>>] ] */
    const [contractorData, setContractorData] = useState(props.contractorFormEditMode === FormEditMode.CREATE ? emptyContractor : props.cpscRequest.contractors.find(e => e.contractorName === props.contractorNameToEdit))

    const addContractor = (event) => {
        const errors = getContractorValidationErrors(props.cpscRequest, contractorData)
        if (errors.length > 0) {
            MySwal.fire(alertErrors(errors))
            return
        }
        event.preventDefault();
        if (props.cpscRequestFormEditMode === FormEditMode.CREATE) {
            props.setCpscRequest(data => ({ ...data, contractors: [...data.contractors, contractorData] }))
            props.setDisplayContractorDetailsForm(false);
            props.setDisplayAddContractorDetails(true);
        }
        else
            addContractorOnServer()
    }

    const updateContractor = (event) => {
        const errors = getContractorValidationErrors(props.cpscRequest, contractorData)
        if (errors.length > 0) {
            MySwal.fire(alertErrors(errors))
            return
        }
        event.preventDefault();
        if (props.cpscRequestFormEditMode === FormEditMode.CREATE) {
            props.setCpscRequest(data => ({ ...data, contractors: data.contractors.map(item => item.contractorName === props.contractorNameToEdit ? contractorData : item) }))
            props.setDisplayContractorDetailsForm(false);
            props.setDisplayAddContractorDetails(true);
        }
        else
            updateContractorOnServer()
    }

    function addContractorOnServer() {
        MySwal.fire(preloader)
        let params = `contractorName=${window.encodeURIComponent(contractorData.contractorName ?? '')}&contractNumber=${window.encodeURIComponent(contractorData.contractNumber ?? '')}&agoraRef=${window.encodeURIComponent(contractorData.agoraRef ?? '')}`
        params = params + `&contractValue=${contractorData.contractValue ?? 0}`
        params = params + `&otherContractCurrencyValue=${contractorData.otherContractCurrencyValue ?? 0}`
        callServerAPI('PATCH', `/api/CpscRequest/AddContractor?requestId=${id}&${params}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                MySwal.close()
                props.setCpscRequest(data => ({ ...data, contractors: [...data.contractors, contractorData] }))
                props.setDisplayContractorDetailsForm(false);
                props.setDisplayAddContractorDetails(true);
            })
    }

    function updateContractorOnServer() {
        MySwal.fire(preloader)
        let params = `oldContractorName=${window.encodeURIComponent(props.contractorNameToEdit ?? '')}&contractorName=${window.encodeURIComponent(contractorData.contractorName ?? '')}&contractNumber=${window.encodeURIComponent(contractorData.contractNumber ?? '')}&agoraRef=${window.encodeURIComponent(contractorData.agoraRef ?? '')}`
        params = params + `&contractValue=${contractorData.contractValue ?? 0}`
        params = params + `&otherContractCurrencyValue=${contractorData.otherContractCurrencyValue ?? 0}`
        callServerAPI('PATCH', `/api/CpscRequest/UpdateContractor?requestId=${id}&${params}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                MySwal.close()
                props.setCpscRequest(data => ({ ...data, contractors: data.contractors.map(item => item.contractorName === props.contractorNameToEdit ? contractorData : item) }))
                props.setDisplayContractorDetailsForm(false);
                props.setDisplayAddContractorDetails(true);
            })
    }

    function handleClose() {
        props.setDisplayContractorDetailsForm(false);
        props.setDisplayAddContractorDetails(true);
    }

    /** @param {string} name, @param {any} value  */
    const updateDataFieldValue = (name, value) => {
        setContractorData(previousContractorData => ({ ...previousContractorData, [name]: value }))
    }

    return (
        <>

            <div className='row'>
                <div className='col1'>
                    <div >
                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractorName)}Contractor Name <span className='important'>*</span></label>
                    </div>

                    <div className=''>
                        <TextField fullWidth size="small" id='contractorName' name='contractorName' value={contractorData.contractorName} placeholder="Contractor Name" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} />
                    </div>

                </div>
                <div className='col2'>
                    <div >
                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractNumber)}Contract Number  {props.cpscRequest.cpscCategory === CpscCategory.PostAward && <span className='important'>*</span>}</label>
                    </div>
                    <div className=''>
                        <TextField fullWidth size="small" id='contractNumber' name='contractNumber' value={contractorData.contractNumber} placeholder="Contract Number" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} />
                    </div>
                </div>
            </div>


            <br />
            <div className='row'>
                <div className='col1'>
                    <div >
                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractorContractValue)}Contract Value ({props.cpscRequest.contractCurrency}) <span className='important'>*</span></label>
                    </div>

                    <div className=''>
                        <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }} id='contractValue' name='contractValue' value={contractorData.contractValue} placeholder="Contract Value" onChange={(e) => updateDataFieldValue("contractValue", e.target.value)} />
                    </div>

                </div>
                {props.cpscRequest.otherContractCurrency != null &&
                    <>
                        <div className='col1'>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractorName)}Contract Value ({props.cpscRequest.otherContractCurrency}) <span className='important'>*</span></label>
                            </div>

                            <div className=''>
                                <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }} id='otherContractCurrencyValue' name='otherContractCurrencyValue' value={contractorData.otherContractCurrencyValue} placeholder="Contract Value" onChange={(e) => updateDataFieldValue('otherContractCurrencyValue', e.target.value)} />
                            </div>

                        </div>
                        <br />
                    </>

                }
                <div className='col2'>
                    <div >
                        <label className=' title-label'>{getCPSCRequestTooltip(CpscRequestFields.AgoraRef)}Agora Ref {props.cpscRequest.cpscCategory !== CpscCategory.Strategy && <span className='important'>*</span>}</label>
                    </div>
                    <div className=''>
                        <TextField fullWidth size="small" id='agoraRef' name='agoraRef' value={contractorData.agoraRef} placeholder="Agora Ref" onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} />
                    </div>
                </div>
            </div>
            <br />
            <div className=' d-flex flex-row-reverse'>
                {props.contractorFormEditMode === FormEditMode.CREATE && <Button variant='contained' onClick={addContractor} ><Add />  Add New Contractor</Button>}
                {props.contractorFormEditMode === FormEditMode.EDIT && <Button variant='contained' onClick={updateContractor} ><Edit />  Update Contractor </Button>}
                &nbsp;
                <Button variant='contained' color='secondary' onClick={handleClose} ><Close /> Close</Button>
            </div>

        </>
    )
}

export default ContractorDetailsForm