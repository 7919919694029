import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import Table from 'react-bootstrap/Table';
import { Tabs, Tab, Button, Radio, Chip, Box, useMediaQuery, useTheme, Stack, Paper, IconButton, Typography, ButtonGroup } from '@mui/material';
import { callServerAPI, checkUserCanValidateDossierAddendum, getAcceptDossierAddendumButtonLabel, getValidationRoleForDossierAddendumStatus, isContractEngineerForRequest, isCostControllerForRequest, isEntityRepForRequest } from '../../helpers/Helpers'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import DossierAddendumUpload from './DossierAddendumUpload';
import CustomTabPanel from '../../components/tabs/CustomTabPanel';
import { DossierAddendumStatus, DossierValidationRole, GlobalRole, globalStore, preloader } from '../../helpers/Constants';
import { useSnackbar } from 'notistack';
import { Send, ThumbDownAlt, PictureAsPdf, ThumbUpAlt, ArrowBackIos } from '@mui/icons-material';
import DossierAddendumVoting from './DossierAddendumVoting';
import FlexBetween from '../../components/FlexBetween';

const MySwal = withReactContent(Swal);

axios.defaults.withCredentials = true;
function DossierAddendum() {

    /** @type [CpscRequest, import('react').Dispatch<import('react').SetStateAction<CpscRequest>>] */
    const [cpscRequest, setCpscRequest] = useState(null)
    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isNonMobile = useMediaQuery('(min-width: 600px)');
    const { id } = useParams();
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [dossierPDFObjectUrl, setDossierPDFObjectUrl] = useState(null);
    /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
    const [selectedTabValue, setSelectedTabValue] = React.useState(1);
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [tripletValidationRole, setTripletValidationRole] = useState(null)
    const [tripletConfirmationBoxChecked, setTripletConfirmationBoxChecked] = useState(false)
    const [globalRolesValidationRejectionComments, setGlobalRolesValidationRejectionComments] = useState(false)

    useEffect(() => {
        const getCpscRequest = async () => {
            if (id == null) return
            callServerAPI('GET', `/api/CpscRequest/GetARequest?id=${id}`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                    setCpscRequest(response.data.data);
                })
        }
        getCpscRequest();

    }, [id])

    const loadDossierPdf = async () => {
        if (id == null) return
        callServerAPI('GET', `/api/CpscRequest/GetDossierPdf?requestId=${id}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                setDossierPDFObjectUrl(URL.createObjectURL(response.data));
            })
    }

    useEffect(() => {
        if (id == null) return
        callServerAPI('GET', `/api/CpscRequest/GetDossierPdf?requestId=${id}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                setDossierPDFObjectUrl(URL.createObjectURL(response.data));
            })
    }, [id])

    function requestDossierAddendumValidation(e) {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/DossierAddendum/RequestDossierAddendumValidation?requestId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                loadDossierPdf();
                enqueueSnackbar(response.data.message, { variant: 'success' });
            })
    }

    function tripletDossierAddendumConfirmationAccept(e) {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/DossierAddendum/DossierAddendumAccept?requestId=${id}&validationRole=${tripletValidationRole ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                loadDossierPdf();
                setTripletValidationRole(null)
                setTripletConfirmationBoxChecked(false)
                enqueueSnackbar('Dossier Addendum Confirmed', { variant: 'success' });
            })

    }

    function tripletDossierAddendumConfirmationReject(e) {
        MySwal.fire(preloader);

        callServerAPI('PATCH', `/api/DossierAddendum/DossierAddendumReject?requestId=${id}&validationRole=${tripletValidationRole ?? ''}&comments=${cpscRequest.dossierAddendum.rejectionComments ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                loadDossierPdf();
                setTripletValidationRole(null)
                setTripletConfirmationBoxChecked(false)
                if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }
    function dossierAddendumConfirmationAccept(e) {
        MySwal.fire(preloader);
        const validationRole = getValidationRoleForDossierAddendumStatus(cpscRequest.dossierAddendum.status)
        callServerAPI('PATCH', `/api/DossierAddendum/DossierAddendumAccept?requestId=${id}&validationRole=${validationRole ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                loadDossierPdf();
                setTripletValidationRole(null)
                setTripletConfirmationBoxChecked(false)
                if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }

    function dossierAddendumConfirmationReject(e) {
        MySwal.fire(preloader);
        const validationRole = getValidationRoleForDossierAddendumStatus(cpscRequest.dossierAddendum.status)

        callServerAPI('PATCH', `/api/DossierAddendum/DossierAddendumReject?requestId=${id}&validationRole=${validationRole ?? ''}&comments=${cpscRequest.dossierAddendum.rejectionComments ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                MySwal.close()
                setCpscRequest(response.data.data);
                loadDossierPdf();
                setTripletValidationRole(null)
                setTripletConfirmationBoxChecked(false)
                setGlobalRolesValidationRejectionComments(false)
                if (response?.data?.message) enqueueSnackbar(response?.data?.message, { variant: 'success' });
            })

    }
    const handleAcceptDossier = (e) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small>Are you sure you want to ${getAcceptDossierAddendumButtonLabel(cpscRequest.dossierAddendum.status)} the dossier addendum?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: '#02b702',
            confirmButtonText: `Yes`
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dossierAddendumConfirmationAccept(e);

                }
            })
    };
    const handleRejectDossier = (e) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small>Are you sure you want to reject the dossier addendum?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'
        })
            .then((result) => {
                if (result.isConfirmed) dossierAddendumConfirmationReject(e);
            })
    };


    function updateDossierAddendumField(name, value) {
        setCpscRequest((previous) => ({ ...previous, dossierAddendum: { ...previous.dossierAddendum, [name]: value } }))
    }

    const navigate = useNavigate();
    const handleHistory = () => { navigate('/dossier') }
    return (cpscRequest &&
        <Box m="1.0rem 1.0rem">
            <Box sx={{ width: '100%' }}>
                <Stack >
                    <Paper>
                        <FlexBetween>
                            <FlexBetween>
                                <IconButton onClick={handleHistory} >
                                    <ArrowBackIos sx={{ fontSize: '13px' }} />
                                </IconButton>
                                <Typography variant="h6" color={theme.palette.text.secondary} fontWeight="bold" sx={{ margin: '1.0rem', }}>
                                    Dossier Addendum - {cpscRequest.contractTitle}
                                </Typography>
                            </FlexBetween>
                            {isNonMobile &&
                                <div style={{ margin: '5px' }}>
                                    <Chip color='default' style={{ fontWeight: 500, fontSize: 14, borderRadius: 5, backgroundColor: 'white', color: '#b77f01', width: '100%' }} variant='outlined' title={cpscRequest.dossierAddendum.status} label={cpscRequest.dossierAddendum.status} />
                                </div>
                            }
                        </FlexBetween>
                    </Paper>
                </Stack>
                {!isNonMobile &&
                    <>
                        <div style={{ margin: '5px' }}>
                            <Chip color='default' style={{ fontWeight: 500, fontSize: 12, borderRadius: 5, backgroundColor: 'white', color: '#b77f01', width: '100%' }} variant='outlined' title={cpscRequest.dossierAddendum.status} label={cpscRequest.dossierAddendum.status} />
                        </div>
                    </>

                }

            </Box>
            <Box m="0.5rem 0.5rem">
                <div style={{ padding: '5px' }}>
                    {cpscRequest.dossierAddendum.status === DossierAddendumStatus.DossierAddendumAwaitingTripletConfirmation &&

                        <div className='col-12 px-2 mt-1 py-2' style={{ margin: '0px !important' }}>
                            <div className='mt-2 mb-2 shadow-sm bg-white px-2 py-2' style={{ paddingTop: '0px !important' }}>
                                <div className=''>

                                    <Table  responsive striped bordered>
                                        <thead style={{ fontSize: '12px' }}>
                                            <tr><td className='role'>Role</td><td>Assignee</td><td>Dossier Addendum Confirmation Status</td></tr>
                                        </thead>
                                        <tbody style={{ fontSize: '12px' }}>
                                            <tr>
                                                <td className='role' > <Radio disabled={cpscRequest.dossierAddendum.contractEngineerConfirmationDate != null || !isContractEngineerForRequest(cpscRequest, loggedOnTepngUser)} checked={tripletValidationRole === DossierValidationRole.ContractEngineer} onClick={() => setTripletValidationRole(DossierValidationRole.ContractEngineer)} /> Contract Engineer</td>
                                                <td>{cpscRequest.contractEngineer}</td>
                                                <td>{cpscRequest.dossierAddendum.contractEngineerConfirmationDate != null ? 'Confirmed' : ''}</td>
                                            </tr>
                                            <tr>
                                                <td className='role' > <Radio disabled={cpscRequest.dossierAddendum.entityRepresentativeConfirmationDate != null || !isEntityRepForRequest(cpscRequest, loggedOnTepngUser)} checked={tripletValidationRole === DossierValidationRole.EntityRepresentative} onClick={() => setTripletValidationRole(DossierValidationRole.EntityRepresentative)} />Technical Administrator</td>
                                                <td>{cpscRequest.entityRepresentative}</td>
                                                <td>{cpscRequest.dossierAddendum.entityRepresentativeConfirmationDate != null ? 'Confirmed' : ''}</td>
                                            </tr>
                                            <tr>
                                                <td className='role' > <Radio disabled={cpscRequest.dossierAddendum.costControllerConfirmationDate != null || !isCostControllerForRequest(cpscRequest, loggedOnTepngUser)} checked={tripletValidationRole === DossierValidationRole.CostController} onClick={() => setTripletValidationRole(DossierValidationRole.CostController)} />Cost Controller</td>
                                                <td>{cpscRequest.costController}</td>
                                                <td>{cpscRequest.dossierAddendum.costControllerConfirmationDate != null ? 'Confirmed' : ''}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {tripletValidationRole &&
                                        <>
                                            <input type="checkbox" checked={tripletConfirmationBoxChecked} onChange={() => setTripletConfirmationBoxChecked(!tripletConfirmationBoxChecked)} /> <span style={{ fontSize: '12px' }}><i>I hereby confirm as the {tripletValidationRole} that this dossier is complete and is ready to be routed for Preliminary QA/QC</i></span>
                                            <br />
                                            {!tripletConfirmationBoxChecked &&

                                                <>
                                                <br/>
                                                    <div >
                                                        <div >
                                                            <label className=' title-label'>Comments </label>
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <textarea className='form-control' onChange={(e) => updateDossierAddendumField(e.target.id, e.target.value)} id="rejectionComments" value={cpscRequest.dossierAddendum.rejectionComments} style={{ width: '100%', height: '80px' }} name='rejectionComments' required />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            &nbsp;&nbsp;
                                            <div className='complete'>
                                                <Button variant='contained' size='large' color='success' style={{ fontSize: 14, textTransform: 'none' }} disabled={!tripletConfirmationBoxChecked} onClick={tripletDossierAddendumConfirmationAccept} ><ThumbUpAlt /> &nbsp;Confirm</Button>
                                                &nbsp;&nbsp;
                                                <Button disabled={!cpscRequest.dossierAddendum.rejectionComments} variant='contained' size='large' color='secondary' style={{ fontSize: 14, textTransform: 'none' }} onClick={tripletDossierAddendumConfirmationReject} ><ThumbDownAlt /> &nbsp;Reject</Button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    }

                    {loggedOnTepngUser.email.toUpperCase() === cpscRequest.contractEngineer.toUpperCase() && cpscRequest.dossierAddendum.status === DossierAddendumStatus.Draft && cpscRequest.dossierAddendum.pdfUploaded &&
                        <>
                            <div className='d-flex flex-row-reverse' style={{ float: 'right', }} >
                                <Button size='small' variant="contained" onClick={requestDossierAddendumValidation} style={{ fontSize: 12, textTransform: 'none' }} color='secondary'> <Send />&nbsp;Request Triplet Confirmation</Button>
                            </div><br />
                        </>
                    }
                    {checkUserCanValidateDossierAddendum(cpscRequest, loggedOnTepngUser) &&
                        <>
                            <div className='d-flex flex-row-reverse' style={{ float: 'right', }} >
                                <ButtonGroup variant="contained" size='small'>
                                    <Button size='small' disabled={globalRolesValidationRejectionComments} onClick={handleAcceptDossier} style={{ fontSize: 14, width: '50%', textTransform: 'none' }} color='success'><ThumbUpAlt /> &nbsp;{getAcceptDossierAddendumButtonLabel(cpscRequest.dossierAddendum.status)} </Button>
                                    <Button size='small' onClick={() => { setGlobalRolesValidationRejectionComments(true) }} style={{ fontSize: 14, width: '50%', textTransform: 'none' }} color='secondary'> <ThumbDownAlt /> &nbsp;Reject</Button>
                                </ButtonGroup>
                            </div>
                            <br />
                        </>
                    }
                    {globalRolesValidationRejectionComments &&

                        <>
                            <div >
                                <div >
                                    <label className=' title-label'>Rejection Comments </label>
                                </div>
                                <div className="form-group mb-0">
                                    <textarea className='form-control' onChange={(e) => updateDossierAddendumField(e.target.id, e.target.value)} id="rejectionComments" value={cpscRequest.dossierAddendum.rejectionComments} style={{ width: '100%', height: '80px' }} name='rejectionComments' required />
                                </div>
                            </div>
                            &nbsp;&nbsp;
                            <div className='complete'>
                                <Button variant='contained' size='small' color='primary' style={{ fontSize: 14, textTransform: 'none' }} onClick={() => { setGlobalRolesValidationRejectionComments(false) }} >&nbsp;Cancel</Button>
                                &nbsp;&nbsp;
                                <Button disabled={!cpscRequest.dossierAddendum.rejectionComments} variant='contained' size='small' color='secondary' style={{ fontSize: 14, textTransform: 'none' }} onClick={handleRejectDossier}> &nbsp;Reject </Button>
                            </div>
                        </>
                    }
                </div>
                <br />
                <Box>
                    <Paper>
                        <Box style={{ minHeight: 200 }}>
                            {isNonMobile && (
                                <>
                                    <Tabs variant='fullWidth' value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
                                        allowScrollButtonsMobile>
                                        <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Dossier PDF" value={1} />
                                        {cpscRequest.dossierAddendum.status === DossierAddendumStatus.Draft &&
                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Dossier Addendum PDF" value={2} />
                                        }
                                        {cpscRequest.dossierAddendum.status === DossierAddendumStatus.DossierAddendumAwaitingCpscApproval && (loggedOnTepngUser.roles.includes(GlobalRole.GCC) || loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember)) &&
                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Votes" value={3} />}

                                    </Tabs>
                                    <CustomTabPanel value={selectedTabValue} index={1}>
                                        <div className='content-desktop'>
                                            <iframe src={dossierPDFObjectUrl + '#view=FitH'} height="700" width="100%" title="DossierPDF" style={{ paddingBottom: '15px' }}></iframe>
                                        </div>
                                    </CustomTabPanel>
                                    {cpscRequest.dossierAddendum.status === DossierAddendumStatus.Draft &&
                                        <CustomTabPanel value={selectedTabValue} index={2}>
                                            <div className='content-desktop'>
                                                <DossierAddendumUpload setCpscRequest={setCpscRequest} cpscRequest={cpscRequest} />
                                            </div>
                                        </CustomTabPanel>
                                    }
                                    {cpscRequest.dossierAddendum.status === DossierAddendumStatus.DossierAddendumAwaitingCpscApproval && (loggedOnTepngUser.roles.includes(GlobalRole.GCC) || loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember)) &&
                                        <CustomTabPanel value={selectedTabValue} index={3}>
                                            <div className='content-desktop'>
                                                <DossierAddendumVoting cpscRequest={cpscRequest} updateCpscRequest={cpscRequest => setCpscRequest(cpscRequest)} />
                                            </div>
                                        </CustomTabPanel>}
                                </>)}
                            {!isNonMobile && (
                                <>
                                    <Tabs variant='scrollable' value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
                                        allowScrollButtonsMobile>
                                        <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Dossier PDF" value={1} />
                                        {cpscRequest.dossierAddendum.status === DossierAddendumStatus.Draft &&
                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Dossier Addendum PDF" value={2} />
                                        }
                                        {cpscRequest.dossierAddendum.status === DossierAddendumStatus.DossierAddendumAwaitingCpscApproval && (loggedOnTepngUser.roles.includes(GlobalRole.GCC) || loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember))  &&
                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Votes" value={3} />}

                                    </Tabs>
                                    <CustomTabPanel value={selectedTabValue} index={1}>
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <br /><br />
                                            <Button color='primary' onClick={() => window.open(dossierPDFObjectUrl)} variant="contained" ><PictureAsPdf fontSize='medium' />&nbsp;View Dossier Pdf</Button>
                                            <br /><br />
                                        </div>
                                    </CustomTabPanel>
                                    {cpscRequest.dossierAddendum.status === DossierAddendumStatus.Draft &&
                                        <CustomTabPanel value={selectedTabValue} index={2}>
                                            <div >
                                                <DossierAddendumUpload setCpscRequest={setCpscRequest} cpscRequest={cpscRequest} />
                                            </div>
                                        </CustomTabPanel>
                                    }
                                    {cpscRequest.dossierAddendum.status === DossierAddendumStatus.DossierAddendumAwaitingCpscApproval && (loggedOnTepngUser.roles.includes(GlobalRole.GCC) || loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember))  &&
                                        <CustomTabPanel value={selectedTabValue} index={3}>
                                            <div >
                                                <DossierAddendumVoting cpscRequest={cpscRequest} updateCpscRequest={cpscRequest => setCpscRequest(cpscRequest)} />
                                            </div>
                                        </CustomTabPanel>
                                    }
                                </>
                            )}
                        </Box>
                    </Paper>
                    <br />
                </Box>
            </Box>
        </Box>
    )
}

export default DossierAddendum