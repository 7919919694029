import React, { useEffect, useState } from 'react'
import { callServerAPI } from '../../helpers/Helpers';
import { AgendaItemType } from '../../helpers/Constants';
import { useParams } from 'react-router-dom';
import { PictureAsPdf } from '@mui/icons-material';
import { Button } from '@mui/material';




function AgendaPDF() {
  const { meetingid } = useParams();
  const { agendaid } = useParams();

  /** @type [AgendaItem[], import('react').Dispatch<import('react').SetStateAction<AgendaItem[]>>] */
  let [agendaItem, setAgendaItem] = useState([]);
  /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
  const [agendaItemPdfObjectUrl, setAgendaItemPdfObjectUrl] = useState(null);

  useEffect(() => {
    console.log("i see 1")
    const getAScheduledMeeting = async () => {
      callServerAPI('GET', `/api/Meeting/GetAScheduledMeeting?meetingId=${meetingid}`, null, true)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
          let meetingResponse = response.data.data.agenda.filter(e => e.id === agendaid)
          setAgendaItem(meetingResponse)
        })
    }
    getAScheduledMeeting();


  }, [agendaid, meetingid])

  useEffect(() => {

    if (agendaItem.map(e => e.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST)[0]) {
      let aggregatedCpscRequestId = agendaItem.map(e => e.aggregatedCpscRequestId)[0]
      callServerAPI('GET', `/api/CpscRequest/GetAggregatedDossierPdf?aggregateRequestId=${aggregatedCpscRequestId}`, null, true, 'blob')
        .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {

          setAgendaItemPdfObjectUrl((URL.createObjectURL(response.data)))
        })
    }
    else if (agendaItem.map(e => e.agendaItemType === AgendaItemType.CPSC_REQUEST)[0]) {
      let cpscRequestId = agendaItem.map(e => e.cpscRequestId)[0]
      console.log(cpscRequestId)
      callServerAPI('GET', `/api/CpscRequest/GetDossierPdf?requestId=${cpscRequestId}`, null, true, 'blob')
        .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
          setAgendaItemPdfObjectUrl((URL.createObjectURL(response.data)))
        })
    }
    else if (agendaItem.map(e => e.agendaItemType === AgendaItemType.SAFETY_MOMENT)[0]) {
      callServerAPI('GET', `/api/Meeting/DownloadSafetyOrSustainabilityMomentPdf?meetingId=${meetingid}&agendaItemId=${agendaid}`, null, true, 'blob')
        .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
          setAgendaItemPdfObjectUrl((URL.createObjectURL(response.data)))

        })
    }

  }, [agendaItem, agendaid, meetingid])




  return (
    agendaItem &&
    <>
      <div>
        {agendaItemPdfObjectUrl &&
          <>
            <div className='content-desktop' style={{ overflowY: 'auto', height: 'calc(100vh - 200px)' }}>
              <iframe src={agendaItemPdfObjectUrl + '#view=FitH'} height="98%" width="100%" title="Presentation" ></iframe>
            </div>
            <div className='content-mobile' >

              <Button color='success' onClick={() => window.open(agendaItemPdfObjectUrl)} variant="contained" ><PictureAsPdf fontSize='medium' />&nbsp;View Agenda Pdf</Button>
            </div>
          </>
        }
      </div >
    </>

  )
}

export default AgendaPDF