import React from 'react'
import { CfrInitiativesSlideField, CfrInitiativesSlideTooltip } from '../../helpers/DossierFieldConstants';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import { Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../tabs/CustomTabPanel';
import CfrInitiativesUpload from './CfrInitiativesUpload';
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} CfrInitiativesSlideProps
 * @property {downloadPdfCallback} downloadPdf
 * @property {uploadPdfCallback} uploadPdf
 * @property {downloadPdfCallback} mobileDownloadPdf
 * @property {deletePdfCallback} deletePdf
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
  * @property {number} dossierRefreshCount
 */

/**
 * @param {CfrInitiativesSlideProps}  props
 * @return {import('react').ReactElement}
 */
function CfrInitiativesSlide(props) {


  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);
 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, cfrInitiativesSlide: { ...props.cpscRequest.dossier.cfrInitiativesSlide, [name]: value } })
}

  return (
   
    <>
      <div className='content-desktop'>
        <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label=" CFR Initiatives" value={1} />
          <Tab label="CFR Initiatives Upload" value={2} />
        </Tabs>
      </div>
      <div className='content-mobile'>
        <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label=" CFR Initiatives" value={1} />
          <Tab label="CFR Initiatives Upload" value={2} />

        </Tabs>
      </div>
      <CustomTabPanel value={selectedTabValue} index={1} >
        <form style={{ fontSize: '12px' }}>
          <div className="form-group">
            <label className=' title-label' >{getSlideTooltip(props.cpscRequest, CfrInitiativesSlideTooltip.details)}CFR Initiatives Details <span className='important'>*</span></label>
            <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.cfrInitiativesSlide.details} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(CfrInitiativesSlideField.details, editorHtmlString)} />  
          </div>
        </form>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabValue} index={2} >
        <div >
          <CfrInitiativesUpload mobileDownloadPdf={props.mobileDownloadPdf} readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
        </div>
      </CustomTabPanel>
    </>
  )
}

export default CfrInitiativesSlide