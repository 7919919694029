import { CpscCategory, ContractModificationRequest, AssetShareSplitType } from "./Constants";
import { isNullOrWhiteSpace } from "./Helpers";

/**
 * @param {CpscRequest} entity
 * @return {string[]}
 */
export function getCpscRequestValidationErrors(entity) {
    var errors = []
    if (isNullOrWhiteSpace(entity.entityRepresentative)) errors.push("Technical Administrator is required");
    if (isNullOrWhiteSpace(entity.contractEngineer)) errors.push("Contract Engineer is required");
    if (isNullOrWhiteSpace(entity.costController)) errors.push("Cost Controller is required");
    if (isNullOrWhiteSpace(entity.contractTitle)) errors.push("Contract Title is required");
    if (entity.businessArea == null) errors.push("Business Area is required");
    if (isNullOrWhiteSpace(entity.initialSowOrMaterialDescription)) errors.push("InitialSowOrMaterialDescription is required");
    if (entity.budget == null) errors.push("Budget cannot be empty");
    if (Number(entity.contractValue) === 0) errors.push((entity.cpscCategory === CpscCategory.Strategy ? "Estimated " : "") + "Contract Value is required");
    if (Number(entity.otherContractCurrencyValue) === 0 && entity.otherContractCurrency != null) errors.push(`"${entity.otherContractCurrency} Contract Value is required `);
    //Category dependent field rules
    if (entity.contractors.length === 0 && entity.cpscCategory !== CpscCategory.Strategy) errors.push("At least one Contractor is required for Award & Post-Award templates");
    if (isNullOrWhiteSpace(entity.contractingStrategy) && entity.cpscCategory !== CpscCategory.PostAward) errors.push("ContractingStrategy is required for Strategy & Award templates");
    if (isNullOrWhiteSpace(entity.contractModificationRequest) && entity.cpscCategory === CpscCategory.PostAward) errors.push("Contract Modification Request is required for Post-Award templates");
    if (entity.initialContractStartDate == null && entity.cpscCategory !== CpscCategory.Strategy) errors.push("InitialContractStartDate is required for Award & Post-Award templates");
    if (entity.initialContractEndDate == null && entity.cpscCategory !== CpscCategory.Strategy) errors.push("InitialContractEndDate is required for Award & Post-Award templates");
    if (entity.newContractEndDate == null && entity.cpscCategory === CpscCategory.PostAward && (entity.contractModificationRequest === ContractModificationRequest.Extension || entity.contractModificationRequest === ContractModificationRequest.ExtensionAndVariation)) errors.push("NewContractEndDate is required for Contract Extension");
    if (Number(entity.additionalValue) === 0 && entity.cpscCategory === CpscCategory.PostAward && (entity.contractModificationRequest === ContractModificationRequest.Variation || entity.contractModificationRequest === ContractModificationRequest.ExtensionAndVariation)) errors.push("AdditionalValue is required for Contract Variation");
    if (Number(entity.budget) === 0 && entity.cpscCategory !== CpscCategory.Strategy) errors.push("Budget is required for Award & Post-Award templates");
    if (entity.assetShareSplitType === AssetShareSplitType.Synergy && Number(entity.jvSharePercent) + Number(entity.dwCorporateSharePercent) !== 100.0) errors.push("Asset Share Split Type  total must be 100%");
    return errors;
}

/**
 * @param {CpscRequest} cpscRequest
 * @param {Contractor} contractor
 * @return {string[]}
 */
export function getContractorValidationErrors(cpscRequest, contractor) {
    var errors = []
    if (isNullOrWhiteSpace(contractor.contractorName)) errors.push("Contractor Name is required");
    if (cpscRequest.cpscCategory !== CpscCategory.Strategy && isNullOrWhiteSpace(contractor.agoraRef)) errors.push(`Agora Ref is required for Award & Post-Award templates`);
    if (cpscRequest.cpscCategory === CpscCategory.PostAward && isNullOrWhiteSpace(contractor.contractNumber)) errors.push(`Contract Number is required for Post-Award template`);
    if (cpscRequest.cpscCategory !== CpscCategory.Strategy && contractor.contractValue == null) errors.push(`${cpscRequest.contractCurrency} Contract Value is required for Award & Post-Award templates`);
    if (cpscRequest.cpscCategory !== CpscCategory.Strategy && cpscRequest.otherContractCurrency != null && contractor.otherContractCurrencyValue == null) errors.push(`${cpscRequest.otherContractCurrency} Contract Value for is required for Award & Post-Award templates`);
    return errors;
}