
import { createStore } from "state-pool";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_LAST_UPDATED = new Date(Number(process.env.REACT_APP_LAST_UPDATED) * 1000)

/** @type import('state-pool').Store */
export const globalStore = createStore();  // Create store for storing our global state


export const AnticipatedContractorMarginScopeName = "Anticipated Contractor Margin";

/**  @readonly @enum {string} */
export const Environment = {
    Dev: "dev",
    Test: "test",
    Prod: "live",
}

/**  @readonly @enum {number} */
export const FormEditMode = {
    CREATE: 1,
    EDIT: 2,
    VIEW: 3
}

/**  @readonly @enum {string} */
export const CpscRequestStatus = {
    Draft: "Draft",
    Cancelled: "Cancelled",
    DossierPreparationOngoing: "Preparation Ongoing",
    DossierAwaitingTripletConfirmation: 'Awaiting Triplet Confirmation',
    DossierAwaitingPreliminaryQAQC: "Awaiting Preliminary QA/QC",
    DossierAwaitingCpDomainManager: "Awaiting C&P Domain Manager Endorsement",
    DossierAwaitingGM: "Awaiting C&P GM Approval",
    DossierAwaitingBudgetOwner: "Awaiting Budget Owner Endorsement",
    DossierAwaitingEntityDirector: "Awaiting Entity Director Endorsement",
    DossierFullyEndorsed: "Fully Endorsed",
    CpscApproved: "CPSC Approved",
    CpscApprovalDeferred: "CPSC Approval Deferred"
}

/**  @readonly @enum {string} */
export const DossierAddendumStatus = {
    Draft: "Draft",
    DossierAddendumAwaitingTripletConfirmation: 'Awaiting Triplet Confirmation',
    DossierAddendumAwaitingPreliminaryQAQC: "Awaiting Preliminary QA/QC",
    DossierAddendumAwaitingCpDomainManager: "Awaiting C&P Domain Manager Endorsement",
    DossierAddendumAwaitingGM: "Awaiting C&P GM Approval",
    DossierAddendumAwaitingBudgetOwner: "Awaiting Budget Owner Endorsement",
    DossierAddendumAwaitingEntityDirector: "Awaiting Entity Director Endorsement",
    DossierAddendumAwaitingCpscApproval: "Awaiting CPSC Approval",
    DossierAddendumCpscApproved: "CPSC Approved",
}

/**  @readonly @enum {string} */
export const GlobalRole = {
    TepngUser: "TEPNGUSER",
    Admin: "Admin",
    GCC: "GCC",
    CpscSecretary: "CPSC Secretary",
    CpscChairman: "CPSC Chairman",
    CPSCMember: "CPSC Member",
}


/**  @readonly @enum {string} */
export const CftStage = {
    Opportunity: "Strategy/Opportunity",
    Technical: "Technical",
    Commercial: "Commercial",
    Award: "Award",
    NA: "N/A"
}


/**  @readonly @enum {string} */
export const BudgetStatus = {
    BudgetAvailable: "Budget Available",
    AdditionalBudgetRequired: "Additional Budget Required - Provision to be Made",
    BudgetNotAvailable: "Budget Not Available"
}


/**  @readonly @enum {string} */
export const DossierCommentType = {
    Comment: "Comment",
    Clarification: "Clarification",
    ChangeRequest: "Change Request"
}

/**  @readonly @enum {string} */
export const MarketIntelligenceActor = {
    Triplet: "Triplet",
    MarketIntelligenceRep: "Market Intelligence Representative",
}


/**  @readonly @enum {string} */
export const DossierSlideName = {
    PurposeAndDescription: "Purpose and Description",
    ProcurementStrategy: "Procurement Strategy Details",
    HseEvaluationCriteria: "HSE Evaluation Criteria",
    TechnicalEvaluationCriteria: "Technical Evaluation Criteria",
    CfrInitiatives: "CFR Initiatives",
    Recommendation: "Recommendation",
    BudgetPlanningInformation: "Budget Planning",
    BudgetConfirmation: "Budget Confirmation",
    SavingsCostOptimization: "Savings Reporting",
    MarketIntelligence: "Market Intelligence",
    ProposedBidders: "Proposed Bidders",
    RiskLevelAssessment: "HSE Risk Level Assessment",
    HighRiskServices: "High Risk Services",
    DueDiligenceHumanRights: "Due Diligence / Human Rights",
    NigerianContent: "Nigerian Content Considerations",
    HseEvaluation: "HSE Evaluation",
    TechnicalEvaluation: "Technical Evaluation",
    CommercialEvaluation: "Commercial Evaluation",
    ApprovalGovernance: "Approval Governance",
    ContractHistory: "Contract History",
    ContractSpendEstimation: "Contract Spend Estimation",
    ContractualEvaluationCriteria: "Contractual Evaluation Criteria",
    ContractualEvaluation: "Contractual Evaluation",
    ContractualProvisions: "Contractual Provisions",
    ProjectOperationsPlanning: "Project & Operations Planning",
    ProcurementStrategyHighlights: "Procurement Strategy Highlights",
    RisksAndOpportunities: "Risks and Opportunities",
    CatManInvolvement: "CATMAN Involvement",
}

/**  @readonly @enum {string} */
export const DossierSlideRequirement = {
    Mandatory: "Mandatory",
    Optional: "Optional",
    NotApplicable: "NotApplicable"
}

/**  @readonly @enum {string} */
export const BidReceptionStrategy = {
    Simultaneous: "Simultaneous (Double Envelope)",
    Sequential: "Sequential(Single Envelope, Two-Stage)",
}

/**  @readonly @enum {string} */
export const DossierPdfFieldName = {
    ProposedBidders: 'ProposedBidders',
    CostAndMarketTrendChart: 'CostAndMarketTrendChart',
    BudgetConfirmation: 'BudgetConfirmation',
    SavingsCostOptimization: 'SavingsCostOptimization',
    MarketIntelligence: 'MarketIntelligence',
    RiskLevelAssessment: 'RiskLevelAssessment',
    DueDiligence: 'DueDiligence',
    HumanRights: 'HumanRights',
    HseEvaluation: 'HseEvaluation',
    TechnicalEvaluation: 'TechnicalEvaluation',
    CommercialEvaluation: 'CommercialEvaluation',
    ProjectOperationsPlanning: "ProjectOperationsPlanning",
    CatManInvolvement: "CatManInvolvement",
    CfrInitiatives: 'CfrInitiatives',
    ProcurementStrategyHighlights: 'ProcurementStrategyHighlights',
    ContractSpendEstimation: 'ContractSpendEstimation',
    ContractualEvaluationCriteria: 'ContractualEvaluationCriteria',
    ContractualEvaluation: 'ContractualEvaluation',
    ContractualProvisions: 'ContractualProvisions',
}

/**  @readonly @enum {string} */
export const DossierValidationRole = {
    EntityRepresentative: "Technical Administrator",
    ContractEngineer: "Contract Engineer",
    CostController: "Cost Controller",
    GCC: GlobalRole.GCC,
    CpDomainManager: "CP Domain Manager",
    BudgetOwner: "Budget Owner",
    GM: "CP GM",
    EntityDirector: "Entity Director",
    CpscChairman: GlobalRole.CpscChairman
}


/**  @readonly @enum {string} */
export const AgendaItemType = {
    SAFETY_MOMENT: "Safety / Sustainability Moment",
    CPSC_REQUEST: "CPSC Request",
    AGGREGATED_CPSC_REQUEST: "Aggregated CPSC Request",

}

/**  @readonly @enum {string} */
export const ContractingStrategy = {
    NUIMSCFT: "NUIMS CFT",
    TEPNGCFT: "TEPNG CFT",
    NUIMSSingleSource: "NUIMS OEM/Single Source",
    TEPNGSingleSource: "TEPNG OEM/Single Source",
}

/**  @readonly @enum {string} */
export const CpscCategory = {
    Strategy: "Strategy",
    Award: "Award",
    PostAward: "Post Award"
}

/**  @readonly @enum {string} */
export const ContractValueCurrency = {
    NGN: "NGN",
    USD: "USD",
    EUR: "EUR",
    GBP: "GBP"
}

/**  @readonly @enum {string} */
export const ContractExtensionRound = {
    First: "First",
    Second: "Second",
    Third: "Third",
    Fourth: "Fourth",
    Fifth: "Fifth",
}

/**  @readonly @enum {string} */
export const DueDiligenceStatus = {
    Valid: "Valid",
    NotStarted: "Not Started",
    ReviewOngoing: "Review Ongoing",
    RenewalOngoing: "Renewal Ongoing",
    NA: "N/A"
}

/**  @readonly @enum {number} */
export const TopNvalues = {
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    25: 25,
    30: 30,
    35: 35,
    40: 40,
    45: 45,
    50: 50,
}

/**  @readonly @enum {string} */
export const HseRiskLevel = {
    Low: "Low",
    Medium: "Medium",
    High: "High"
}

/**  @readonly @enum {string} */
export const RemunerationModel = {
    UnitRate: "Unit Rate",
    DayRate: "Day Rate",
    Lumpsum: "Lumpsum",
    Reimbursible: "Reimbursible",
}

/**  @readonly @enum {string} */
export const RiskType = {
    HSE: "HSE",
    Schedule: "Schedule",
    Commercial: "Commercial",
    CyberSecurity: "Cyber Security",
    Costs: "Costs"
}

/**  @readonly @enum {string} */
export const AuditLogChangeType = {
    Create: "Create",
    Update: "Update",
    Delete: "Delete",

}

/**  @readonly @enum {string} */
export const YesNoOngoingNA = {
    Yes: "Yes",
    No: "No",
    Ongoing: "Ongoing",
    NA: "N/A"
}

/**  @readonly @enum {string} */
export const YesNoNA = {
    Yes: "Yes",
    No: "No",
    NA: "N/A"
}

/**  @readonly @enum {string} */
export const YesNo = {
    Yes: "Yes",
    No: "No"
}

/**  @readonly @enum {boolean} */
export const TrueFalse = {
    Yes: true,
    NO: false
}


/**  @readonly @enum {string} */
export const MeetingFormat = {
    Physical: "Physical",
    Virtual: "Virtual",
}

/**  @readonly @enum {string} */
export const MeetingStatus = {
    Completed: "Completed",
    Scheduled: "Scheduled",
    Ongoing: "Ongoing",
}

/**  @readonly @enum {string} */
export const MoMStatus = {
    Draft: "Draft",
    AwaitingReview: "Awaiting Review",
    AwaitingApproval: "Awaiting Approval",
    Approved: "Approved",
}


/**  @readonly @enum {string} */
export const ContractModificationRequest = {
    Variation: "Variation",
    Extension: "Extension",
    ExtensionAndVariation: "Extension and Variation",
    Others: "Others",
}
/**  @readonly @enum {string} */
export const AssetShareSplitType = {
    JV: "JV",
    DWCorporate: "DW & Corporate",
    Synergy: "Synergy",
}

/**  @readonly @enum {string} */
export const CpscDecisionType = {
    NotPresented: "Not Presented",
    Approved: "Approved",
    ApprovalDeferred: "Approval Deferred",
    Rejected: "Rejected",
}

/**  @readonly @enum {string} */
export const CpscVoteType = {
    Ok: "OK",
    OkClarificationsRequired: "OK - Actions/Clarifications Required",
    NotOk: "Not OK"
}


export const preloader = {
    title: "",
    html: '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100px;" src="../assets/images/loading-icon-red.gif" /><br> <strong> Please Wait... </strong> </div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false,

}

/**  @readonly @enum {string} */
export const CpscMeetingHubMessageType = {

    INITIAL_DATA: "INITIAL_DATA",
    PARTICIPANT_JOINED: "CPSC_PARTICIPANT_JOINED",
    PARTICIPANT_LEFT: "CPSC_PARTICIPANT_LEFT",
    CURRENT_AGENDA_ITEM_UPDATED: "CURRENT_AGENDA_ITEM_UPDATED",
    CURRENT_AGENDA_ITEM_STATUS_UPDATED: "CURRENT_AGENDA_ITEM_STATUS_UPDATED",
    VOTES_SUBMITTED: "VOTES_SUBMITTED",
    APPROVALS_SUBMITTED: "APPROVALS_SUBMITTED",
    MEETING_ENDED: "MEETING_ENDED",
}

/**  @readonly @enum {string} */
export const AgendaItemStatus = {
    PRESENTATION_ONGOING: "PRESENTATION ONGOING",
    VOTING_ONGOING: "VOTING ONGOING",
    APPROVAL_ONGOING: "APPROVAL ONGOING",
    COMPLETED: "COMPLETED"
}