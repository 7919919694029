import React, { useEffect, useState } from 'react'
import { callServerAPI, getNameFromTotalEnergiesEmail } from '../../helpers/Helpers';
import { AgendaItemType, globalStore, CpscDecisionType, GlobalRole, AgendaItemStatus } from '../../helpers/Constants';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Button, Radio } from '@mui/material'
import { Approval, Info } from '@mui/icons-material';


/**
 * @typedef {Object} MeetingApproval
 * @property {string} currentAgendaItemStatus
 * @property {Function} updateCurrentAgendaItemStatus
 * @property {AgendaItem} currentAgendaItem
 * @property {CpscRequestsForMeetingDto} meetingCpscRequestList
 * @property {CpscDecision[]} cpscDecisions
 */



/**
 * @param {MeetingApproval}  props
 * @return {import('react').ReactElement}
 */
function MeetingApproval(props) {

    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    /**  @type [CpscDecision[], import('react').Dispatch<import('react').SetStateAction<CpscDecision[]>>] */
    const [cpscDecisionsForCurrentAgendaItem, setCpscDecisionsForCurrentAgendaItem] = useState(null);
    /**  @type [CpscRequest[], import('react').Dispatch<import('react').SetStateAction<CpscRequest[]>>] */
    const [cspcRequestListForCurrentAgendaItem, setCspcRequestListForCurrentAgendaItem] = useState(null);


    useEffect(() => {
        const local_cspcRequestListForCurrentAgendaItem = props.currentAgendaItem.agendaItemType !== AgendaItemType.AGGREGATED_CPSC_REQUEST ?
            [props.meetingCpscRequestList.cpscRequestList.find(e => e.id === props.currentAgendaItem.cpscRequestId)] :
            props.meetingCpscRequestList.aggregatedCpscRequestList.find(e => e.id === props.currentAgendaItem.aggregatedCpscRequestId).cpscRequests

        setCspcRequestListForCurrentAgendaItem(local_cspcRequestListForCurrentAgendaItem)
        setCpscDecisionsForCurrentAgendaItem(props.cpscDecisions.filter(e => e.agendaItemId === props.currentAgendaItem.id))
    }, [props.currentAgendaItemStatus, props.currentAgendaItem, props.meetingCpscRequestList, props.cpscDecisions])

    /** @param {string} cpscRequestId */
    function getCpscRequestTitle(cpscRequestId) {
        return cspcRequestListForCurrentAgendaItem.find(e => e.id === cpscRequestId)?.contractTitle
    }

    const submitCpscDecisions = () => {
        callServerAPI('POST', '/api/MeetingPresentation/SubmitCpscDecisions', cpscDecisionsForCurrentAgendaItem, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscDecision[]>> } */ response) {
                props.updateCurrentAgendaItemStatus(AgendaItemStatus.COMPLETED)
            })
    }

    /** @param {string} cpscRequestId, @param {string} decision */
    function updateCpscDecision(cpscRequestId, decision) {
        setCpscDecisionsForCurrentAgendaItem(decisions => decisions.map(inst => inst.cpscRequestId === cpscRequestId ? { ...inst, decision: decision } : inst))
    }

    /** @param {string} decision */
    function updateAllCpscDecisionsForCurrentAgendaItem(decision) {
        setCpscDecisionsForCurrentAgendaItem(decisions => decisions.map(inst => ({ ...inst, decision: decision })))
    }

    /** @returns {boolean} */
    function cpscDecisionsExistsForAgendaItem() {
        return cpscDecisionsForCurrentAgendaItem.findIndex(e => e.decision !== CpscDecisionType.NotPresented) >= 0
    }

    /** @param {string} cpscRequestId, @param {string} comments */
    function updateCpscDecisionComment(cpscRequestId, comments) {
        setCpscDecisionsForCurrentAgendaItem(decisions => decisions.map(inst => inst.cpscRequestId !== cpscRequestId ? inst : { ...inst, comments: comments }))
    }


    return (cspcRequestListForCurrentAgendaItem && cpscDecisionsForCurrentAgendaItem &&
        <>
            {props.currentAgendaItemStatus !== AgendaItemStatus.APPROVAL_ONGOING && loggedOnTepngUser.roles.includes(GlobalRole.GCC) &&
                <div style={{ textAlign: 'center', padding: '15px' }}>
                    <Button className='bck' color='secondary' variant="contained" onClick={() => { props.updateCurrentAgendaItemStatus(AgendaItemStatus.APPROVAL_ONGOING) }} ><Approval />&nbsp;{cpscDecisionsExistsForAgendaItem() ? "Redo" : "Commence"} Approval</Button>
                </div>
            }

            {props.currentAgendaItemStatus !== AgendaItemStatus.APPROVAL_ONGOING && !cpscDecisionsExistsForAgendaItem() &&
                <div style={{ textAlign: 'center' }}>
                    <Approval style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                    <div className='not-started'>CPSC Approval not Started</div>
                </div>
            }

            {props.currentAgendaItemStatus === AgendaItemStatus.APPROVAL_ONGOING &&
                <p className='vtn_ongoing'> <Approval />&nbsp;CPSC Approval Ongoing</p>
            }

            <br /><br />
            {props.currentAgendaItemStatus === AgendaItemStatus.APPROVAL_ONGOING && loggedOnTepngUser.roles.includes(GlobalRole.CpscChairman) ?
                <>
                    {props.currentAgendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST &&
                        <>
                            <RadioGroup style={{ width: '100%' }} row={props.currentAgendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST && !(window.matchMedia('screen and (max-width: 768px)').matches)}>
                                <FormControlLabel value={CpscDecisionType.Approved} control={<Radio checked={cpscDecisionsForCurrentAgendaItem.find(e => e.decision !== CpscDecisionType.Approved) == null} onClick={() => updateAllCpscDecisionsForCurrentAgendaItem(CpscDecisionType.Approved)} />} label={`Select ${CpscDecisionType.Approved} for all ${cpscDecisionsForCurrentAgendaItem.length} dossiers`} />
                                <FormControlLabel value={CpscDecisionType.ApprovalDeferred} control={<Radio checked={cpscDecisionsForCurrentAgendaItem.find(e => e.decision !== CpscDecisionType.ApprovalDeferred) == null} onClick={() => updateAllCpscDecisionsForCurrentAgendaItem(CpscDecisionType.ApprovalDeferred)} />} label={`Select ${CpscDecisionType.ApprovalDeferred} for all ${cpscDecisionsForCurrentAgendaItem.length} dossiers`} />
                                <FormControlLabel value={CpscDecisionType.Rejected} control={<Radio checked={cpscDecisionsForCurrentAgendaItem.find(e => e.decision !== CpscDecisionType.Rejected) == null} onClick={() => updateAllCpscDecisionsForCurrentAgendaItem(CpscDecisionType.Rejected)} />} label={`Select ${CpscDecisionType.Rejected} for all ${cpscDecisionsForCurrentAgendaItem.length} dossiers`} />
                            </RadioGroup>
                            <div style={{ color: '#777777', fontSize: "12px" }} >
                                <Info style={{ color: '#f54a5a' }} />&nbsp;<em>Approval for the individual dossiers can be modified below</em>
                            </div>
                            <hr />
                        </>
                    }

                    {cpscDecisionsForCurrentAgendaItem.map((cpscDecision, index) => {
                        return <div key={index}>
                            <div style={{ color: '#f54a5a', fontSize: "12px" }} >
                                <b>{'(' + (index + 1) + ')'} &nbsp;&nbsp;<em>{getCpscRequestTitle(cpscDecision.cpscRequestId)}</em></b>
                            </div>
                            <FormControl style={{ width: '100%' }}>
                                <RadioGroup row={props.currentAgendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST && !(window.matchMedia('screen and (max-width: 768px)').matches)}>
                                    <FormControlLabel value={CpscDecisionType.Approved} control={<Radio checked={cpscDecision.decision === CpscDecisionType.Approved} onClick={() => updateCpscDecision(cpscDecision.cpscRequestId, CpscDecisionType.Approved)} />} label={CpscDecisionType.Approved} />
                                    <FormControlLabel value={CpscDecisionType.ApprovalDeferred} control={<Radio checked={cpscDecision.decision === CpscDecisionType.ApprovalDeferred} onClick={() => updateCpscDecision(cpscDecision.cpscRequestId, CpscDecisionType.ApprovalDeferred)} />} label={CpscDecisionType.ApprovalDeferred} />
                                    <FormControlLabel value={CpscDecisionType.Rejected} control={<Radio checked={cpscDecision.decision === CpscDecisionType.Rejected} onClick={() => updateCpscDecision(cpscDecision.cpscRequestId, CpscDecisionType.Rejected)} />} label={CpscDecisionType.Rejected} />
                                </RadioGroup>
                                <div className='row'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Comments </label>
                                        </div>
                                        <div className=''>
                                            <div className="form-group mb-0">
                                                <textarea rows={3} className='form-control' id="votecomments" value={cpscDecision.comments} onChange={(e) => updateCpscDecisionComment(cpscDecision.cpscRequestId, e.currentTarget.value)} style={{ width: '100%' }} required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormControl>
                            <br /><br />
                        </div>
                    })}
                    <Button color='secondary' variant="contained" disabled={cpscDecisionsForCurrentAgendaItem.find(e => e.decision === CpscDecisionType.NotPresented) != null} onClick={submitCpscDecisions}>Submit Approval</Button>
                </>
                :
                <>
                    {cpscDecisionsForCurrentAgendaItem.map(cpscDecision =>
                        <>
                            <div style={{ color: '#f54a5a', fontSize: "12px" }} > <b> Dossier: <em>{getCpscRequestTitle(cpscDecision.cpscRequestId)}</em>  </b> </div>
                            <div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group">
                                        <FormControlLabel value={cpscDecision.decision} control={<Radio disabled checked={cpscDecision.decision === CpscDecisionType.Approved} />} label={CpscDecisionType.Approved} />
                                        <FormControlLabel value={cpscDecision.decision} control={<Radio disabled checked={cpscDecision.decision === CpscDecisionType.ApprovalDeferred} />} label={CpscDecisionType.ApprovalDeferred} />
                                        <FormControlLabel value={cpscDecision.decision} control={<Radio disabled checked={cpscDecision.decision === CpscDecisionType.Rejected} />} label={CpscDecisionType.Rejected} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {cpscDecision.decision !== CpscDecisionType.NotPresented &&
                                <>
                                    <span style={{ color: '#777777', fontSize: "13px" }}>{cpscDecision.comments}</span>
                                    <br />
                                    <div className={cpscDecision.decision === CpscDecisionType.Approved ? 'ok_vote' : (cpscDecision.decision === CpscDecisionType.ApprovalDeferred ? 'further_vote' : 'abstain_vote')}> &nbsp;{cpscDecision.decision}&nbsp;By: {getNameFromTotalEnergiesEmail(cpscDecision.email)}</div>
                                </>
                            }
                            <hr style={{ color: 'red', height: "2px" }} />
                        </>)}
                </>
            }
        </>
    )
}

export default MeetingApproval