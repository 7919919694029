import React, { useEffect, useState } from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'
import { alertErrors, callServerAPI, getNameFromTotalEnergiesEmail, isTripletForRequest } from '../../helpers/Helpers';
import { preloader, ContractingStrategy, ContractValueCurrency, globalStore, CpscRequestStatus, CpscCategory, FormEditMode, TopNvalues, ContractModificationRequest, AssetShareSplitType } from '../../helpers/Constants';
import FlexBetween from "../../components/FlexBetween";
import '../../css/request.css';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  Grid,
  TextField,
  Stack,
  ButtonGroup,
  IconButton,
  Chip,
  Autocomplete,
} from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import RequestInfo from './RequestInfo';
import ContractorList from './ContractorList';
import { ArrowBackIos, Engineering, RemoveRedEye, Save } from '@mui/icons-material';
import { getCpscRequestValidationErrors } from '../../helpers/ValidationRules';


const MySwal = withReactContent(Swal);
const topValues = [TopNvalues[5], TopNvalues[10], TopNvalues[15], TopNvalues[20], TopNvalues[25], TopNvalues[30], TopNvalues[35], TopNvalues[40], TopNvalues[45], TopNvalues[50]]

/** @type CpscRequest */ const emptyCPSCRequest = {
  contractTitle: "",
  dossier: null,
  cpscCategory: CpscCategory.Strategy,
  isAbridgedDossier: false,
  contractingStrategy: ContractingStrategy.NUIMSCFT,
  initialSowOrMaterialDescription: "",
  contractEngineer: "",
  costController: "",
  entityRepresentative: "",
  auxiliaryEntityRepresentativesList: [],
  id: '',
  status: CpscRequestStatus.Draft,
  createdBy: null,
  createdDate: undefined,
  dossierPreparationInitiationDate: undefined,
  dossierCompletionDate: undefined,
  preliminaryQAQCDate: undefined,
  cpDomainManagerEndorsementDate: undefined,
  budgetOwnerEndorsementDate: undefined,
  gMApprovalDate: undefined,
  cpscSecretaryApprovalDate: undefined,
  entityDirectorEndorsementDate: undefined,
  tripletConfirmationDate: undefined,
  preliminaryQAQCBy: null,
  cpDomainManagerEndorsementBy: null,
  budgetOwnerEndorsementBy: null,
  gMApprovalBy: null,
  cpscSecretaryApprovalBy: null,
  entityDirectorEndorsementBy: null,
  rejectedDate: undefined,
  rejectedBy: undefined,
  rejectedByRole: undefined,
  contractors: [],
  businessArea: null,
  contractModificationRequest: ContractModificationRequest.Extension,
  dossierComments: [],
  hasBeenScheduled: undefined,
  cpscChairmanApprovalDate: undefined,
  cpscChairmanApprovalBy: null,
  entityRepresentativeConfirmationDate: undefined,
  contractEngineerConfirmationDate: undefined,
  costControllerConfirmationDate: undefined,
  commentsAndConsiderations: '',
  followOnAction: '',
  budgetConfirmationSlideUploaded: undefined,
  commercialEvaluationSlideUploaded: undefined,
  costAndMarketTrendChartUploaded: undefined,
  marketIntelligenceSlideUploaded: undefined,
  riskLevelAssessmentSlideUploaded: undefined,
  savingsCostOptimizationSlideUploaded: undefined,
  technicalEvaluationSlideUploaded: undefined,
  hseEvaluationSlideUploaded: undefined,
  proposedBiddersSlideUploaded: undefined,
  contractCurrency: ContractValueCurrency.NGN,
  contractValue: 0,
  otherContractCurrency: null,
  otherContractCurrencyValue: undefined,
  budgetCurrency: ContractValueCurrency.NGN,
  budget: 0,
  initialContractStartDate: undefined,
  initialContractEndDate: undefined,
  optionalExtensions: '',
  newContractEndDate: undefined,
  extensionConditions: '',
  additionalValue: undefined,
  adhocDossierSlides: [],
  projectOperationsPlanningSlideUploaded: false,
  catManInvolvementSlideUploaded: false,
  dossierSlideSequence: [],
  assetShareSplitType: AssetShareSplitType.DWCorporate,
  cfrInitiativesSlideUploaded: false,
  procurementStrategyHighlightsSlideUploaded: false,
  contractSpendEstimationSlideUploaded: false,
  contractualEvaluationCriteriaSlideUploaded: false,
  contractualEvaluationSlideUploaded: false,
  contractualProvisionsSlideUploaded: false,
  dwCorporateSharePercent: 0,
  jvSharePercent: 0,
  budgetConfirmationSlideContentType: '',
  commercialEvaluationSlideContentType: '',
  costAndMarketTrendChartContentType: '',
  marketIntelligenceSlideContentType: '',
  riskLevelAssessmentSlideContentType: '',
  savingsCostOptimizationSlideContentType: '',
  hseEvaluationSlideContentType: '',
  technicalEvaluationSlideContentType: '',
  proposedBiddersSlideContentType: '',
  projectOperationsPlanningSlideContentType: '',
  catManInvolvementSlideContentType: '',
  cfrInitiativesSlideContentType: '',
  procurementStrategyHighlightsSlideContentType: '',
  contractSpendEstimationSlideContentType: '',
  contractualEvaluationCriteriaSlideContentType: '',
  contractualEvaluationSlideContentType: '',
  contractualProvisionsSlideContentType: ''
}
/**
 * @typedef {Object} InitiateRequestFormProps
 * @property {Number} formEditMode
 */

/**
 * @param {InitiateRequestFormProps}  props
 * @return {import('react').ReactElement}
 */
function InitiateRequestForm(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery('(min-width: 600px)');
  const { enqueueSnackbar } = useSnackbar();
  /** @type [TepngUser, any, any] */
  const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
  /**  @type [string[], Function] */
  const [tepngEmails, setTepngEmails] = useState(null);
  /** @type [BusinessArea[],import('react').Dispatch<import('react').SetStateAction<BusinessArea[]>>] */
  const [businessAreas, setBusinessAreas] = useState([]);
  /** @type [CpscRequest,import('react').Dispatch<import('react').SetStateAction<CpscRequest>>] */
  const [cpscRequest, setCpscRequest] = useState(emptyCPSCRequest)
  /** @type [boolean,Function] */
  const [canUpdateRequest, setCanUpdateRequest] = useState(true)
  /** @type [boolean,Function] */
  const [canUpdateRoles, setCanUpdateRoles] = useState(true)
  /** @type [boolean,Function] */
  const [canUpdateCpscCategoryOrInitiateDossier, setCanUpdateCpscCategoryOrInitiateDossier] = useState(true)
  /** @type [CpscRequestSummaryDto[], import('react').Dispatch<import('react').SetStateAction<CpscRequestSummaryDto[]>>] */
  const [relatedRequests, setRelatedRequests] = useState([])
  /** @type [CpscRequestSummaryDto, import('react').Dispatch<import('react').SetStateAction<CpscRequestSummaryDto>>] */
  const [selectedRelatedRequest, setSelectedRelatedRequest] = useState()
  /** @type [number,Function] */
  const [topNRelatedRequests, setTopNRelatedRequests] = useState(10)
  const [useRelatedRequest, setUseRelatedRequest] = useState(false);
  const [relatedRequestsConfirmationBoxChecked, setRelatedRequestsConfirmationBoxChecked] = useState(false)
  const [includeFilesForRelatedChecked, setIncludeFilesForRelatedChecked] = useState(false)

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    const fetchEmails = async () => {
      callServerAPI('GET', '/api/User/GetAllTepngUsersEmail', null, true)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
          setTepngEmails(["", ...response.data.data])
        })
    }
    const fetchBusinessAreas = async () => {
      callServerAPI('GET', '/api/BusinessArea/GetAllBusinessAreas', null, true)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<BusinessArea[]>> } */ response) {
          setBusinessAreas(response.data.data.sort((a, b) => a.title < b.title ? -1 : 1));
        })
    }

    if (props.formEditMode === FormEditMode.CREATE) setCpscRequest(data => ({ ...data, entityRepresentative: loggedOnTepngUser.email }))

    fetchEmails();
    fetchBusinessAreas();

  }, [props.formEditMode, loggedOnTepngUser])

  useEffect(() => {
    const getCpscRequest = async () => {
      callServerAPI('GET', `/api/CpscRequest/GetARequest?id=${id}`, null, true)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
          setCpscRequest(response.data.data);
          setCanUpdateCpscCategoryOrInitiateDossier(isTripletForRequest(response.data.data, loggedOnTepngUser) && response.data.data.status === CpscRequestStatus.Draft)
          setCanUpdateRequest(isTripletForRequest(response.data.data, loggedOnTepngUser) && (response.data.data.status === CpscRequestStatus.Draft || response.data.data.status === CpscRequestStatus.DossierPreparationOngoing))
          setCanUpdateRoles(isTripletForRequest(response.data.data, loggedOnTepngUser))
        })
    }
    if (props.formEditMode === FormEditMode.EDIT) getCpscRequest();
  }, [props.formEditMode, id, loggedOnTepngUser])


  const handleHistory = () => {
    navigate('/home')
  }

  function updateDataFieldValue(name, value) {
    const newdata = { ...cpscRequest }
    newdata[name] = value
    setCpscRequest(newdata)
  }

  function updateDataFieldDateValue(name, value) {
    const newdata = { ...cpscRequest }
    newdata[name] = value === "" ? null : value
    setCpscRequest(newdata)
  }



  function createRequest(e) {
    const errors = getCpscRequestValidationErrors(cpscRequest)
    if (errors.length > 0) {
      MySwal.fire(alertErrors(errors))
      return
    }
    MySwal.fire(preloader)
    let requestDto = (/** @type CreateOrUpdateCpscRequestDto */ ({ ...cpscRequest, businessAreaId: cpscRequest.businessArea?.id, budgetEntityId: cpscRequest.budgetEntity?.id }))
    callServerAPI('POST', '/api/CpscRequest/CreateRequest', requestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
        enqueueSnackbar("CPSC Request Created", { variant: 'success' })
        navigate('/');
        MySwal.close()
      })
  }

  function updateRequest(e) {
    const errors = getCpscRequestValidationErrors(cpscRequest)
    if (errors.length > 0) {
      MySwal.fire(alertErrors(errors))
      return
    }
    MySwal.fire(preloader)
    let requestDto = (/** @type CreateOrUpdateCpscRequestDto */ ({ ...cpscRequest, businessAreaId: cpscRequest.businessArea?.id, budgetEntityId: cpscRequest.budgetEntity?.id }))
    callServerAPI('PATCH', `/api/CpscRequest/UpdateRequest?requestId=${id}`, requestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        MySwal.close()
        enqueueSnackbar("CPSC Request Updated", { variant: 'success' })
        navigate('/');
      })

  }
  function updateTriplet(e) {
    MySwal.fire(preloader)
    let requestDto = (/** @type UpdateTripletDto */ (cpscRequest))
    callServerAPI('PATCH', `/api/CpscRequest/UpdateTriplet?requestId=${id}`, requestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        MySwal.close()
        enqueueSnackbar("Triplet Updated", { variant: 'success' })
        navigate('/');
      })
  }

  function CreateAndInitiateDossierPreparation(e) {
    const errors = getCpscRequestValidationErrors(cpscRequest)
    if (errors.length > 0) {
      MySwal.fire(alertErrors(errors))
      return
    }
    MySwal.fire(preloader)
    let requestDto = (/** @type CreateOrUpdateCpscRequestDto */ ({ ...cpscRequest, businessAreaId: cpscRequest.businessArea?.id, budgetEntityId: cpscRequest.budgetEntity?.id }))
    callServerAPI('POST', '/api/CpscRequest/CreateRequest', requestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
        callServerAPI('PATCH', `/api/CpscRequest/InitiateDossierPreparation?requestId=${response.data.data.id}`, null, true)
          .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response2) {
            enqueueSnackbar("Dossier Preparation Initiated", { variant: 'success' })
            navigate(`/capture-dossier/${response.data.data.id}`);
            MySwal.close();
          })
      })

  }
  function CreateAndInitiateRelatedDossier(e) {
    const errors = getCpscRequestValidationErrors(cpscRequest)
    if (errors.length > 0) {
      MySwal.fire(alertErrors(errors))
      return
    }
    MySwal.fire(preloader)
    let requestDto = (/** @type CreateOrUpdateCpscRequestDto */ ({ ...cpscRequest, businessAreaId: cpscRequest.businessArea?.id, budgetEntityId: cpscRequest.budgetEntity?.id }))
    callServerAPI('POST', '/api/CpscRequest/CreateRequest', requestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
        callServerAPI('PATCH', `/api/CpscRequest/InitiateDossierPreparationFromRelatedDossier?requestId=${response.data.data.id}&sourceRequestId=${selectedRelatedRequest.id}&includeFiles=${includeFilesForRelatedChecked}`, null, true)
          .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response2) {
            enqueueSnackbar('Dossier Initiated using the selected related Dossier', { variant: 'success' })
            navigate(`/capture-dossier/${response.data.data.id}`);
            MySwal.close()
          })
      })

  }
  function UpdateAndInitiateDossierPreparation(e) {
    const errors = getCpscRequestValidationErrors(cpscRequest)
    if (errors.length > 0) {
      MySwal.fire(alertErrors(errors))
      return
    }
    MySwal.fire(preloader)
    let requestDto = (/** @type CreateOrUpdateCpscRequestDto */ ({ ...cpscRequest, businessAreaId: cpscRequest.businessArea?.id, budgetEntityId: cpscRequest.budgetEntity?.id }))
    callServerAPI('PATCH', `/api/CpscRequest/UpdateRequest?requestId=${id}`, requestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
        callServerAPI('PATCH', `/api/CpscRequest/InitiateDossierPreparation?requestId=${id}`, null, true)
          .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
            enqueueSnackbar("Dossier Preparation Initiated", { variant: 'success' })
            navigate(`/capture-dossier/${id}`);
            MySwal.close();
          })
      })
  }

  function UpdateAndInitiateRelatedDossier(e) {
    const errors = getCpscRequestValidationErrors(cpscRequest)
    if (errors.length > 0) {
      MySwal.fire(alertErrors(errors))
      return
    }
    MySwal.fire(preloader)
    let requestDto = (/** @type CreateOrUpdateCpscRequestDto */ ({ ...cpscRequest, businessAreaId: cpscRequest.businessArea?.id, budgetEntityId: cpscRequest.budgetEntity?.id }))
    callServerAPI('PATCH', `/api/CpscRequest/UpdateRequest?requestId=${id}`, requestDto, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
        callServerAPI('PATCH', `/api/CpscRequest/InitiateDossierPreparationFromRelatedDossier?requestId=${id}&sourceRequestId=${selectedRelatedRequest.id}&includeFiles=${includeFilesForRelatedChecked}`, null, true)
          .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
            enqueueSnackbar('Dossier Initiated using the selected related Dossier', { variant: 'success' })
            navigate(`/capture-dossier/${id}`);
            MySwal.close();
          })
      })
  }

  function viewDossier() {
    if (cpscRequest.status === CpscRequestStatus.CpscApprovalDeferred)
      navigate(`/capture-dossier-addendum/${cpscRequest.id}`)
    else
      navigate(`/capture-dossier/${cpscRequest.id}`)
  }

  return (
    tepngEmails && businessAreas && relatedRequests &&
    <Box m="1.0rem 1.0rem">
      <Box sx={{ width: '100%' }}>
        <Stack >
          <Paper>
            <FlexBetween>
              <FlexBetween>
                <IconButton onClick={handleHistory}>
                  <ArrowBackIos sx={{ fontSize: '13px' }} />
                </IconButton>
                <Typography variant="h6" color={theme.palette.text.secondary} fontWeight="bold" sx={{ margin: '1.0rem', }}>
                  {props.formEditMode === FormEditMode.CREATE ? "Initiate Request" : (isTripletForRequest(cpscRequest, loggedOnTepngUser) ? "Edit Request" : "View Request")}
                </Typography>
              </FlexBetween>
              {isDesktop &&
                <ButtonGroup sx={{ padding: '2px', height: '45px' }} >
                  {cpscRequest.status !== CpscRequestStatus.Draft && cpscRequest.status !== CpscRequestStatus.Cancelled && <Button onClick={viewDossier} > <RemoveRedEye />&nbsp; Dossier</Button>}
                  {props.formEditMode === FormEditMode.CREATE &&
                    <Button onClick={createRequest} ><Save />&nbsp;Save As Draft</Button>
                  }
                  {canUpdateRequest && props.formEditMode === FormEditMode.EDIT &&
                    <Button onClick={updateRequest} ><Save />&nbsp;Update Request</Button>
                  }
                  {canUpdateRoles && cpscRequest.status !== CpscRequestStatus.Draft && cpscRequest.status !== CpscRequestStatus.DossierPreparationOngoing &&
                    <Button onClick={updateTriplet} ><Save />&nbsp; Update Triplet</Button>
                  }
                  {canUpdateCpscCategoryOrInitiateDossier &&
                    <>
                      {useRelatedRequest === false && <Button color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? CreateAndInitiateDossierPreparation : UpdateAndInitiateDossierPreparation} >Initiate Dossier Preparation</Button>}
                      {useRelatedRequest === true && <Button color='secondary' disabled={!relatedRequestsConfirmationBoxChecked} onClick={props.formEditMode === FormEditMode.CREATE ? CreateAndInitiateRelatedDossier : UpdateAndInitiateRelatedDossier} >Adopt Related Dossier </Button>}
                    </>
                  }
                </ButtonGroup>
              }
            </FlexBetween>
          </Paper>
        </Stack>
        {!isDesktop &&
          <>
            <br />
            <Stack>
              <Paper>
                <ButtonGroup sx={{ padding: '2px', height: '45px' }} >
                  {cpscRequest.status !== CpscRequestStatus.Draft && cpscRequest.status !== CpscRequestStatus.Cancelled && <Button onClick={viewDossier} > <RemoveRedEye /> &nbsp;Dossier</Button>}
                  {props.formEditMode === FormEditMode.CREATE &&
                    <Button onClick={createRequest} ><Save />&nbsp;Save As Draft</Button>
                  }
                  {canUpdateRequest && props.formEditMode === FormEditMode.EDIT &&
                    <Button onClick={updateRequest} ><Save />&nbsp;Update Request</Button>
                  }
                  {canUpdateRoles && cpscRequest.status !== CpscRequestStatus.Draft && cpscRequest.status !== CpscRequestStatus.DossierPreparationOngoing &&
                    <Button onClick={updateTriplet} ><Save />&nbsp; Update Triplet</Button>
                  }
                  {canUpdateCpscCategoryOrInitiateDossier &&
                    <>
                      {useRelatedRequest === false && <Button color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? CreateAndInitiateDossierPreparation : UpdateAndInitiateDossierPreparation} >Initiate Dossier Preparation</Button>}
                      {useRelatedRequest === true && <Button color='secondary' disabled={!relatedRequestsConfirmationBoxChecked} onClick={props.formEditMode === FormEditMode.CREATE ? CreateAndInitiateRelatedDossier : UpdateAndInitiateRelatedDossier} >Adopt Related Dossier </Button>}
                    </>
                  }
                </ButtonGroup>
              </Paper>
            </Stack>
          </>

        }

      </Box>
      <Paper>
        <Box mt="20px" p='1.0rem' gap="20px">
          <Grid container>
            <Grid item xs={12} sm={12} lg={8}>
              <div className='' style={{ margin: '0px !important', padding: '5px' }}>
                <br />
                <div className=' px-2 py-4' style={{ border: '1px solid #ededed', paddingTop: '0px !important' }}>
                  <RequestInfo setRelatedRequestsConfirmationBoxChecked={setRelatedRequestsConfirmationBoxChecked} setIncludeFilesForRelatedChecked={setIncludeFilesForRelatedChecked} includeFilesForRelatedChecked={includeFilesForRelatedChecked} relatedRequestsConfirmationBoxChecked={relatedRequestsConfirmationBoxChecked} setSelectedRelatedRequest={setSelectedRelatedRequest} selectedRelatedRequest={selectedRelatedRequest} setRelatedRequests={setRelatedRequests} relatedRequests={relatedRequests} topValues={topValues} topNRelatedRequests={topNRelatedRequests} setTopNRelatedRequests={setTopNRelatedRequests} setUseRelatedRequest={setUseRelatedRequest} useRelatedRequest={useRelatedRequest} loggedOnTepngUser={loggedOnTepngUser} businessAreas={businessAreas} data={cpscRequest} readOnlyMode={!canUpdateRequest} updateDataFieldDateValue={updateDataFieldDateValue} updateDataFieldValue={updateDataFieldValue} canUpdateCpscCategory={canUpdateCpscCategoryOrInitiateDossier} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <div className=' ' style={{ margin: '0px !important', padding: '5px' }}>
                <div >
                  <br />
                  <div style={{ marginBottom: 10, width: '100%' }}>
                    <Chip color='default' style={{ fontWeight: 500, fontSize: 14, borderRadius: 5, border: '1px solid #b77f01', backgroundColor: 'white', color: '#b77f01', width: '100%' }} variant='outlined' title={cpscRequest.status} label={cpscRequest.status} />
                  </div>
                  <div className='mt-4 mb-4  bg-white px-2 py-2' >
                    <div style={{ border: '1px solid #ededed', padding: '10px' }}>
                      <p className='roles-header'>Assign Roles</p>
                      <div >
                        <label className='title-label'>Technical Administrator (TADM) <span className='important'>*</span></label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">
                          <Autocomplete disabled={!canUpdateRoles} id="entityRepresentative" options={tepngEmails} autoSelect multiple={false} size="small"
                            value={cpscRequest.entityRepresentative} onChange={(event, newValue) => updateDataFieldValue("entityRepresentative", newValue)}
                            getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                            renderInput={(params) => (<TextField {...params} margin="none" variant="outlined" size='small' />)}
                            className={!canUpdateRoles ? "react-select-disabled" : ""}
                          />
                        </div>
                      </div>
                      <br />
                      <div >
                        <label className='title-label'>Auxiliary Technical Administrators</label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">
                          <Autocomplete disabled={!canUpdateRoles} id="auxiliaryEntityRepresentativesList" options={tepngEmails} autoSelect multiple size="small"
                            value={cpscRequest.auxiliaryEntityRepresentativesList} onChange={(event, newValue) => updateDataFieldValue("auxiliaryEntityRepresentativesList", newValue)}
                            getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                            getOptionDisabled={(option) => option === tepngEmails[0]}
                            renderInput={(params) => (<TextField {...params} margin="none" variant="outlined" size='small' />)}
                            className={!canUpdateRoles ? "react-select-disabled" : ""}
                          />
                        </div>
                      </div>
                      <br />
                      <div >
                        <label className='title-label'>Contract Engineer <span className='important'>*</span></label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">
                          <div className="">
                            <Autocomplete disabled={!canUpdateRoles} id="contractEngineer" options={(cpscRequest.businessArea?.cpDomain?.contractEngineersList ?? []).sort((a, b) => a < b ? -1 : 1)} autoSelect multiple={false} size="small"
                              value={cpscRequest.contractEngineer} onChange={(event, newValue) => updateDataFieldValue("contractEngineer", newValue)}
                              getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                              renderInput={(params) => (<TextField {...params} margin="none" variant="outlined" size='small' disabled={!canUpdateRoles} />)}
                              className={!canUpdateRoles ? "react-select-disabled" : ""}
                            />

                          </div>
                        </div>
                      </div>
                      <br />
                      <div >
                        <label className='title-label'>{(cpscRequest.budgetEntity ?? cpscRequest.businessArea)?.title} Cost Controller <span className='important'>*</span></label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">
                          <div className="">
                            <Autocomplete disabled={!canUpdateRoles} id="costController" options={((cpscRequest.budgetEntity ?? cpscRequest.businessArea)?.costControllersList ?? []).sort((a, b) => a < b ? -1 : 1)} autoSelect multiple={false} size="small"
                              value={cpscRequest.costController} onChange={(event, newValue) => updateDataFieldValue("costController", newValue)}
                              getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                              renderInput={(params) => (<TextField {...params} margin="none" variant="outlined" size='small' disabled={!canUpdateRoles} />)}
                              className={!canUpdateRoles ? "react-select-disabled" : ""}
                            />

                          </div>
                        </div>
                      </div>
                      <br />
                      <div >
                        <label className='title-label'> C&P GM</label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">
                          <div className='inner-roles-border'>
                            {!cpscRequest.businessArea &&
                              <p style={{ fontWeight: '400', fontSize: "11px" }}>Business Area Not Selected</p>
                            }
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {cpscRequest.businessArea?.cpDomain?.generalManagersList?.map((generalManager, index) => {
                                return (
                                  <div key={index}>
                                    <Chip className='defaultChip' size='small' label={getNameFromTotalEnergiesEmail(generalManager)} />&nbsp;
                                    <br />
                                  </div>
                                )
                              })}
                            </Box>
                          </div>
                        </div>
                      </div>
                      <br /><div >
                        <label className='title-label'>{cpscRequest.businessArea?.cpDomain?.title} C&P Domain Manager</label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">
                          <div className='inner-roles-border'>
                            {!cpscRequest.businessArea &&
                              <p style={{ fontWeight: '400', fontSize: "11px" }}>Business Area Not Selected</p>
                            }
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {cpscRequest.businessArea?.cpDomain?.domainManagersList?.map((domainManager, index) => {
                                return (
                                  <div key={index}>
                                    <Chip className='defaultChip' size='small' label={getNameFromTotalEnergiesEmail(domainManager)} />&nbsp;
                                    <br />
                                  </div>
                                )
                              })}
                            </Box>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div >
                        <label className='title-label'>{(cpscRequest.budgetEntity ?? cpscRequest.businessArea)?.title} Budget Owner</label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">

                          <div className='inner-roles-border'>
                            {!cpscRequest.businessArea &&
                              <p style={{ fontWeight: '400', fontSize: "11px" }}>Business Area Not Selected</p>
                            }
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {(cpscRequest.budgetEntity ?? cpscRequest.businessArea)?.budgetOwnersList?.map((budgetOwner, index) => {
                                return (
                                  <div key={index}>
                                    <Chip className='defaultChip' size='small' label={getNameFromTotalEnergiesEmail(budgetOwner)} />&nbsp;
                                    <br />
                                  </div>
                                )
                              })}
                            </Box>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div >
                        <label className='title-label'>{(cpscRequest.budgetEntity ?? cpscRequest.businessArea)?.title} Entity Director </label>
                      </div>
                      <div className='col-12'>
                        <div className="form-group mb-0">
                          <div className='inner-roles-border'>
                            {!cpscRequest.businessArea &&
                              <p style={{ fontWeight: '400', fontSize: "11px" }}> Business Area Not Selected</p>
                            }
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {(cpscRequest.budgetEntity ?? cpscRequest.businessArea)?.directorsList?.map((director, index) => {
                                return (
                                  <div key={index}>
                                    <Chip className='defaultChip' size='small' label={getNameFromTotalEnergiesEmail(director)} />&nbsp;
                                    <br />
                                  </div>
                                )
                              })}
                            </Box>
                          </div>

                        </div>



                      </div>
                      <br />
                    </div>

                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper>
        <Box mt="20px" p='1.0rem' gap="20px">
          <div className=' px-2 py-4' style={{ border: '1px solid #ededed', paddingTop: '0px !important' }}>
            <p color={theme.palette.text.secondary} style={{ margin: '1.0rem', fontWeight: 'bold' }}> <Engineering /> Contractors</p>
            {cpscRequest.cpscCategory !== CpscCategory.Strategy && cpscRequest.contractors.length === 0 &&
              <div style={{ color: 'red', margin: '1.0rem', fontSize: '13px' }}>At least one Contractor is required</div>
            }
            <div style={{ color: 'red', margin: '1.0rem', fontSize: '13px' }}>Total contract value awarded to all the contractors should not exceed overall contract value for this CPSC request</div>
            <ContractorList formEditMode={props.formEditMode} cpscRequest={cpscRequest} setCpscRequest={setCpscRequest} readOnlyMode={!canUpdateRequest} />
          </div>
        </Box>
      </Paper>
    </Box>
  )
}

export default InitiateRequestForm