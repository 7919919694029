import React from 'react'
import Table from 'react-bootstrap/esm/Table';
import { Button, Grid } from '@mui/material';
import { HseEvaluationCriteriaSlideField, HseEvaluationCriteriaSlideTooltip } from '../../helpers/DossierFieldConstants';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

/**
 * @typedef {Object} HseEvaluationCriteriaSlideProps
* @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 * @property {number} dossierRefreshCount
 */

/**
 * @param {HseEvaluationCriteriaSlideProps}  props
 * @return {import('react').ReactElement}
 */
function HseEvaluationCriteria(props) {
    /** @type HseEvaluationCriteria */
    let emptyHseEvaluationCriterion = {
        serialNo: props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria == null || props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.length === 0 ? 1 : Math.max.apply(Math, props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.map(function (o) { return o.serialNo; })) + 1,
        description: "HSE Criteria",
        weight: 0,

    }
   
    let totalHseWeightSum = props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.reduce((partialSum, a) => partialSum + Number(a.weight), 0);


    const addNewHseCriterion = () => {
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationCriteriaSlide: { ...props.cpscRequest.dossier.hseEvaluationCriteriaSlide, hseEvaluationCriteria: [...props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria, emptyHseEvaluationCriterion] } })
    }

    /** @param {number} serialNo, @param {string} name, @param {any} value  */
    const updateHseCriterionDataField = (serialNo, name, value) => {
        props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationCriteriaSlide: { ...props.cpscRequest.dossier.hseEvaluationCriteriaSlide, hseEvaluationCriteria: props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.map(inst => inst.serialNo !== serialNo ? inst : { ...inst, [name]: value }) } })
    }

    /** @param {number} serialNo  */
    const deleteHseCriterion = (serialNo) => {
        props.setDossier({
            ...props.cpscRequest.dossier, hseEvaluationCriteriaSlide: {
                ...props.cpscRequest.dossier.hseEvaluationCriteriaSlide, hseEvaluationCriteria: props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.filter(inst => inst.serialNo !== serialNo).map((inst, index) => ({ ...inst, serialNo: index + 1 }))
            }
        })
    }

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationCriteriaSlide: { ...props.cpscRequest.dossier.hseEvaluationCriteriaSlide, [name]: value } })
  }
    return (
       
        <div style={{ padding: 10 }}>
            <span style={{ float: 'right' }}>
                <Button onClick={addNewHseCriterion} variant='contained' color='success' disabled={props.readOnly}>Add New HSE Criterion</Button>
            </span>
            <br />
            <br />
            <Grid container>
                <Grid item xs={12} sm={12} lg={12} >
                    <Table responsive striped bordered>
                        <thead>
                            <tr style={{ fontSize: '10px', }}>
                                <th >SN</th>
                                <th style={{ width: '60%' }}>Description</th>
                                <th style={{ width: '20%' }}>Weight%</th>

                                <th style={{ width: '20%', textAlign: 'center' }}>Actions</th>

                            </tr>
                        </thead>
                        <tbody>
                            {props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.sort((a, b) => a.serialNo < b.serialNo ? -1 : 1).map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.serialNo}</td>
                                    <td style={{ width: '60%' }}>
                                        <textarea id='description' onChange={(e) => updateHseCriterionDataField(item.serialNo, e.target.id, e.target.value)}
                                            value={item.description} className='form-control' style={{ width: '100%', height: '31px' }} name='description' required disabled={props.readOnly} />
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        <input id='weight' onChange={(e) => updateHseCriterionDataField(item.serialNo, e.target.id, Math.round(Number(e.target.value)))}
                                            value={item.weight} type="number" className="form-control input-font bold" placeholder="Weight" required max={100} min={1} disabled={props.readOnly} />
                                    </td>
                                    <td style={{ minWidth: 20, textAlign: 'center' }}>
                                        <Button variant='contained' color='info' disabled={props.readOnly} onClick={() => deleteHseCriterion(item.serialNo)}>Delete</Button>
                                    </td>
                                </tr>
                            })}
                            {props.cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.length === 0 ? <tr>
                                <td colSpan={4}><p className=' text-center'> No record available...</p></td>


                            </tr> : ''}

                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}> Total</td>
                                <td>
                                    {totalHseWeightSum}</td>
                                <td> </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Grid>
            </Grid>

            <br />
            <div className="form-group">
                <label className=' title-label' >{getSlideTooltip(props.cpscRequest, HseEvaluationCriteriaSlideTooltip.supportingInformation)}Supporting Information <span className='important'>*</span></label>
                <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.hseEvaluationCriteriaSlide.supportingInformation} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(HseEvaluationCriteriaSlideField.supportingInformation, editorHtmlString)} />  
            </div>
            <br />
            <div className="form-group">
                <label className=' title-label' > {getSlideTooltip(props.cpscRequest, HseEvaluationCriteriaSlideTooltip.additionalComments)}Additional Comments <span className='important'>*</span></label>
                <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.hseEvaluationCriteriaSlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(HseEvaluationCriteriaSlideField.additionalComments, editorHtmlString)} />  
            </div>
        </div>
    )
}

export default HseEvaluationCriteria