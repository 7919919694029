import React, { useState } from 'react'
import { DossierPdfFieldName } from '../../helpers/Constants';
import { Button } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
/**
 * @typedef {Object} ContractSpendEstimationUploadProps
  * @property {CpscRequest} cpscRequest
  * @property {downloadPdfCallback} downloadPdf
  * @property {downloadPdfCallback} mobileDownloadPdf
  * @property {uploadPdfCallback} uploadPdf
  * @property {deletePdfCallback} deletePdf
  * @property {boolean} readOnly
 */

/**
 * @param {ContractSpendEstimationUploadProps}  props
 * @return {import('react').ReactElement}
 */
function ContractSpendEstimationUpload(props) {
  const [contractSpendEstimationObjectUrl, setContractSpendEstimationObjectUrl] = useState(null);
  const handleFileChange = (event) => {
    event.persist()
    const file = event.target.files[0]
    let document = {}
    document.name = file.name;

    if (event.target.files.length === 0) return
    const formData = new FormData()
    formData.append("uploadedFile", event.target.files[0]);
    props.uploadPdf(formData, DossierPdfFieldName.ContractSpendEstimation);
    setContractSpendEstimationObjectUrl(undefined)
    event.target.value = null
  }

  const previewFile = async (event) => {
    const url = await props.downloadPdf(DossierPdfFieldName.ContractSpendEstimation)
    setContractSpendEstimationObjectUrl(url)
  }
  const mobilePreviewFile = async (event) => {
    const url = await props.mobileDownloadPdf(DossierPdfFieldName.ContractSpendEstimation)
    setContractSpendEstimationObjectUrl(url)
  }

  const deleteFile = async (event) => {
    await props.deletePdf(DossierPdfFieldName.ContractSpendEstimation)
    setContractSpendEstimationObjectUrl(undefined)

  }

  return (
    <>
      <div className='content-desktop'>
        <div className="file-card">

          <div className="file-inputs">
            <input className='upload-input' id="contractSpendEstimationSlideUploaded" type="file" onChange={handleFileChange} accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={props.readOnly} />
            <Button variant='contained' color='secondary' className='upload-btn ' disabled={props.readOnly} >
              <CloudUploadOutlinedIcon />&nbsp; Upload
            </Button>
          </div>

          <p className="main">Supported files</p>
          <p className="info">PDF, Excel</p>

        </div>
        <div>
          {props.cpscRequest.contractSpendEstimationSlideUploaded &&

            <div>

              <div className='files' >
                <div className='file-name'>Contract Spend Estimation Uploaded </div>
                <div className='dld-file'><Button variant='contained' color='success' disabled={!props.cpscRequest.contractSpendEstimationSlideUploaded}
                  onClick={previewFile}><RemoveRedEyeOutlinedIcon /> &nbsp; View</Button></div>
                <div className='del-file'>
                  <Button variant='contained' color='error' onClick={() => { deleteFile() }}
                    disabled={!props.cpscRequest.contractSpendEstimationSlideUploaded || props.readOnly}>
                    <DeleteSweepOutlinedIcon /> &nbsp;Delete</Button>
                </div>
              </div>


              {contractSpendEstimationObjectUrl && <iframe src={contractSpendEstimationObjectUrl} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>}
            </div>
          }
        </div>
      </div>
      <div className='content-mobile'>
        <div className="mobile-file-card">

          <div className="file-inputs">
            <input className='upload-input' id="contractSpendEstimationSlideUploaded" type="file" onChange={handleFileChange} accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={props.readOnly} />
            <Button variant='contained' color='secondary' className='upload-btn ' disabled={props.readOnly} >
              <CloudUploadOutlinedIcon />&nbsp; Upload
            </Button>
          </div>

          <p className="main">Supported files</p>
          <p className="info">PDF, Excel</p>

        </div>
        <div>
          {props.cpscRequest.contractSpendEstimationSlideUploaded &&
            <div>
              <div className='files' >
                <div className='mobile-file-name'>Contract Spend Estimation Uploaded</div><br />
                <div >
                  <div >
                    <Button style={{ width: '100%' }} variant='contained' color='success' disabled={!props.cpscRequest.contractSpendEstimationSlideUploaded} onClick={mobilePreviewFile}> <RemoveRedEyeOutlinedIcon /> &nbsp;View</Button></div>

                  <div >
                    <br />
                    <Button style={{ width: '100%' }} variant='contained' color='error' onClick={() => { deleteFile() }}
                      disabled={!props.cpscRequest.contractSpendEstimationSlideUploaded || props.readOnly}>
                      <DeleteSweepOutlinedIcon /> &nbsp; Delete </Button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

    </>
  )
}

export default ContractSpendEstimationUpload