import React, { useState } from 'react'
import { Button } from '@mui/material';
import '../../css/template.css'
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} RisksAndOpportunitiesProps
 * @property {Risk} risk
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Function} setDisplayRisksForm
 *
 */

/**
 * @param {RisksAndOpportunitiesProps}  props
 * @return {import('react').ReactElement}
 */
function RisksAndOpportunitiesForm(props) {
  /** @type [Risk, Function] */
  const [data, setData] = useState(props.risk)

  const updateRisk = (event) => {
    event.preventDefault();
    props.setDossier({ ...props.cpscRequest.dossier, risksAndOpportunitiesSlide: { ...props.cpscRequest.dossier.risksAndOpportunitiesSlide, risks: props.cpscRequest.dossier.risksAndOpportunitiesSlide.risks.map(item => item.riskType === props.risk.riskType ? data : item) } })
    props.setDisplayRisksForm(false);
  }

  /** @param {Risk} data  */
  const validate = (data) => {
    let notValid = (Boolean(data.riskType) === false)
    return !notValid
  }

  return (
    <div>
      <div className=''>
        <form >
          <div className="form-group">
            <label className='t-label'>Risk Identified <span className='important'>*</span></label>
            <MyRichTextEditor editorHtmlString={props.risk.risksIdentified} updateEditorHtmlString={editorHtmlString => setData({ ...data, risksIdentified: editorHtmlString })} />
          </div>
          <br />
          <div className="form-group">
            <label className='t-label' >Possible Mitigations<span className='important'>*</span></label>
            <MyRichTextEditor editorHtmlString={props.risk.possibleMitigations} updateEditorHtmlString={editorHtmlString => setData({ ...data, possibleMitigations: editorHtmlString })} />
          </div>
          <br />
          <div>
            <Button color='success' variant='contained' onClick={updateRisk} disabled={!validate(data)} >
              Update {props.risk.riskType} Risk
            </Button>
            <br />
          </div>
        </form>
        <br />
      </div>
    </div>
  )
}

export default RisksAndOpportunitiesForm