import React, { useEffect, useState } from 'react'
import { Button, MenuItem, Select, useMediaQuery } from '@mui/material'
import { callServerAPI, formatShortDateString } from '../../helpers/Helpers'
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'
import { preloader } from '../../helpers/Constants';
import { PictureAsPdf } from '@mui/icons-material';


const MySwal = withReactContent(Swal);

/**
 * @typedef {Object}  CompletedMeetingsProps
 * @property {string} completedMeetingID
 * @property {Function} setCompletedMeetingID
 * @property {Meeting[]} completedMeetings

 */

/**
 * @param { CompletedMeetingsProps}props
 * @return {import('react').ReactElement}
 */
function CompletedMeetings(props) {
    const isNonMobile = useMediaQuery('(min-width: 600px)');

    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [momPdfObjectUrl, setMoMPdfObjectUrl] = useState(null);
    

    useEffect(() => {
        const  displayMinuteOfMeetingPdf = async () => {
            MySwal.fire(preloader)
            callServerAPI('GET', `/api/MinuteOfMeeting/GetMinuteOfMeetingPdf?meetingId=${props.completedMeetingID}`, null, true, 'blob')
                .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                    setMoMPdfObjectUrl(URL.createObjectURL(response.data));
                    if (!isNonMobile) {
                        window.open(URL.createObjectURL(response.data));
                    }
                    MySwal.close();
                })
        }
        if (props.completedMeetingID !== 'None Selected') displayMinuteOfMeetingPdf();

      }, [isNonMobile, props.completedMeetingID])

    return (
        <>
            <div>
                <div >
                    <label className='title-label'>Select a Completed Meeting to view Minutes of Meeting</label>
                </div>
                <div className=''>
                    <div className="form-group mb-0">
                        <Select fullWidth variant="outlined" size="small" name="completedMeeting" value={props.completedMeetingID} onChange={(e) => { props.setCompletedMeetingID(e.target.value) }} >
                            <MenuItem value="None Selected" disabled>None Selected</MenuItem >
                            {props.completedMeetings.map((options, index) => {
                                return <MenuItem key={index} value={options.id}>Digital CPSC Meeting - {formatShortDateString(options.meetingDateTime)}</MenuItem >
                            })}
                        </Select>
                    </div>
                    <br />
                   
                </div>
            </div>
            <hr />
            {momPdfObjectUrl &&
                <div>
                    <div className='content-desktop'>
                        <iframe src={momPdfObjectUrl + '#view=FitH'} height="700" width="100%" title="MoMPDF" style={{ paddingBottom: '15px' }}></iframe>
                    </div>
                    <div className='content-mobile' >

                        <Button color='success' onClick={() => window.open(momPdfObjectUrl)} variant="contained" ><PictureAsPdf fontSize='medium' />&nbsp;View Minutes of Meeting Pdf</Button>
                    </div>

                </div>
            }
        </>
    )
}

export default CompletedMeetings