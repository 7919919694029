import * as React from "react";


const CustomTabPanel = ({ children, value, index, ...other }) => {

    return (
        <div style={{ paddingTop: 20 }}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >

            {children}
            {/* {value === index && children} // This was removed */}
        </div>
    );
};

export default CustomTabPanel;