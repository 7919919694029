import React, { useEffect, useState } from 'react';
import withReactContent from 'sweetalert2-react-content';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2'
import { callServerAPI, isTripletForRequest } from '../../helpers/Helpers';
import { preloader, ContractingStrategy, globalStore, CpscRequestStatus, CpscCategory, DossierAddendumStatus } from '../../helpers/Constants';
import Header from "../../components/TitleHeader";
import '../../css/request.css';
import { Box, Button, Paper, Grid, TextField, MenuItem, Stack, Pagination } from "@mui/material";
import { Search, } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

function DossierList() {


    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    /** @type [BusinessArea[], import('react').Dispatch<import('react').SetStateAction<BusinessArea[]>>] */
    let [businessAreas, setBusinessAreas] = useState([]);
    /** @type [CpscRequest[], import('react').Dispatch<import('react').SetStateAction<CpscRequest[]>>] */
    let [requests, setRequests] = useState([]);
    const [contractTitleFilter, setContractTitleFilter] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [statusFilter, setStatusFilter] = useState("All");
    const [businessAreaFilter, setBusinessAreaFilter] = useState("All");
    const [cpscCategoryFilter, setCpscCategoryFilter] = useState("All");
    const [proposedContractualStrategyFilter, setProposedContractualStrategyFilter] = useState("All");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [numOfPages, setNumOfPages] = useState(0);
    const [requestsLoading, setRequestsLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        callServerAPI('GET', '/api/BusinessArea/GetAllBusinessAreas', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<BusinessArea[]>> } */ response) {
                setBusinessAreas(response.data.data.sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : 1));
            })
    }, [])
    useEffect(() => {
        setRequestsLoading(true)
        MySwal.fire(preloader);
        callServerAPI('GET', `/api/CpscRequest/GetAllRequest?getDossiers=true&pageNumber=${currentPage}&pageSize=${recordsPerPage}&businessAreaId=${businessAreaFilter === "All" ? "" : businessAreaFilter}&cpscCategory=${cpscCategoryFilter === "All" ? "" : cpscCategoryFilter}&proposedContractualStrategy=${proposedContractualStrategyFilter === "All" ? "" : proposedContractualStrategyFilter}&status=${statusFilter === "All" ? "" : window.encodeURIComponent(statusFilter)}&contractTitleSearch=${window.encodeURIComponent(contractTitleFilter)}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<PaginatedCpscRequestsDto>> } */ response) {
                setRequests(response.data.data.cpscRequestList);
                setNumOfPages(Math.ceil(response.data.data.totalNumberOfRecords / recordsPerPage))
                setRequestsLoading(false)
                MySwal.close();
            })

    }, [contractTitleFilter, currentPage, recordsPerPage, businessAreaFilter, cpscCategoryFilter, proposedContractualStrategyFilter, statusFilter])

    function downloadDocumentPdf(id) {
        MySwal.fire(preloader)
        callServerAPI('GET', `/api/CpscRequest/GetDossierPdf?requestId=${id}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                window.open(URL.createObjectURL(response.data));
                MySwal.close();
            })


    }

    useEffect(() => {
        setCurrentPage(1);
    }, [contractTitleFilter, statusFilter, businessAreaFilter, proposedContractualStrategyFilter, cpscCategoryFilter])

    /** @param {CpscRequest} item */
    const handleCaptureDossier = (item) => {
        navigate(`/capture-dossier/${item.id}`)
    }
    /** @param {CpscRequest} item */
    const handleCaptureDossierAddendum = (item) => {
        navigate(`/capture-dossier-addendum/${item.id}`)
    }
    return (
        <Box m="1.0rem 1.0rem">
            <Header title="Dossier List" />
            <Box>
                <Paper>
                    <Box mt="20px" p='1.0rem' gap="20px">
                        <Grid container className=' d-flex  justify-content-between'>

                            <Grid item xs={12} sm={6} lg={6}  >
                                <div className=' d-flex  justify-content-between'>
                                    <input type="text" className="form-control input-font bold" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search by Contract Title...' /> &nbsp;
                                    <Button color='primary' variant="contained" onClick={() => setContractTitleFilter(searchQuery)} style={{ textTransform: 'none', fontSize: 14 }}><Search />Search</Button>

                                </div>

                            </Grid>

                        </Grid>
                        <div style={{ backgroundColor: "white", padding: 10, fontSize: '10px' }}>
                            <Grid container>
                                <Grid item xs={12} sm={4} lg={2}  >
                                    <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                                        <MenuItem sx={{ fontSize: '12px' }} value="All">All Status</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierPreparationOngoing}>{CpscRequestStatus.DossierPreparationOngoing}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierAwaitingTripletConfirmation}>{CpscRequestStatus.DossierAwaitingTripletConfirmation}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierAwaitingPreliminaryQAQC}>{CpscRequestStatus.DossierAwaitingPreliminaryQAQC}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierAwaitingCpDomainManager}>{CpscRequestStatus.DossierAwaitingCpDomainManager}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierAwaitingGM}>{CpscRequestStatus.DossierAwaitingGM}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierAwaitingBudgetOwner}>{CpscRequestStatus.DossierAwaitingBudgetOwner}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierAwaitingEntityDirector}>{CpscRequestStatus.DossierAwaitingEntityDirector}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.DossierFullyEndorsed}>{CpscRequestStatus.DossierFullyEndorsed}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.CpscApprovalDeferred}>{CpscRequestStatus.CpscApprovalDeferred}</MenuItem >
                                        <MenuItem value={CpscRequestStatus.CpscApproved}>{CpscRequestStatus.CpscApproved}</MenuItem >
                                    </TextField>
                                </Grid> &nbsp;&nbsp;
                                <Grid item xs={12} sm={4} lg={3}  >
                                    <TextField style={{ fontSize: '11px', }} fullWidth id="fullWidth" select variant="outlined" size="small" name="businessAreaFilter" value={businessAreaFilter} onChange={(e) => setBusinessAreaFilter(e.target.value)}>
                                        <MenuItem value="All">All Business Areas</MenuItem >
                                        {businessAreas.map((businessArea, index) => {
                                            return <MenuItem key={index} value={businessArea.id}>{businessArea.title}</MenuItem >
                                        })}

                                    </TextField>
                                </Grid> &nbsp;&nbsp;
                                <Grid item xs={12} sm={4} lg={3} >
                                    <TextField style={{ fontSize: '11px', }} fullWidth id="fullWidth" select variant="outlined" size="small" name="statusFilter" value={cpscCategoryFilter} onChange={(e) => setCpscCategoryFilter(e.target.value)}>
                                        <MenuItem value="All">All Proposed Cpsc Categories</MenuItem >
                                        <MenuItem value={CpscCategory.Strategy}>{CpscCategory.Strategy}</MenuItem >
                                        <MenuItem value={CpscCategory.Award}>{CpscCategory.Award}</MenuItem >
                                        <MenuItem value={CpscCategory.PostAward}>{CpscCategory.PostAward}</MenuItem >

                                    </TextField>
                                </Grid> &nbsp;&nbsp;
                                <Grid item xs={12} sm={4} lg={3} >
                                    <TextField style={{ fontSize: '11px', }} fullWidth id="fullWidth" select variant="outlined" size="small" name="statusFilter" value={proposedContractualStrategyFilter} onChange={(e) => setProposedContractualStrategyFilter(e.target.value)}>
                                        <MenuItem value="All">All Contracting Strategy</MenuItem >
                                        <MenuItem value={ContractingStrategy.NUIMSCFT}>{ContractingStrategy.NUIMSCFT}</MenuItem >
                                        <MenuItem value={ContractingStrategy.TEPNGCFT}>{ContractingStrategy.TEPNGCFT}</MenuItem >
                                        <MenuItem value={ContractingStrategy.NUIMSSingleSource}>{ContractingStrategy.NUIMSSingleSource}</MenuItem >
                                        <MenuItem value={ContractingStrategy.TEPNGSingleSource}>{ContractingStrategy.TEPNGSingleSource}</MenuItem >
                                    </TextField>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table responsive striped bordered>
                                    <thead style={{ fontSize: '10px' }}>
                                        <tr>
                                            <th className='text-center'>SN</th>

                                            <th>Contract Title</th>
                                            <th>Proposed Contracting Strategy  </th>
                                            <th> Proposed CPSC Category</th>
                                            <th>Business Area</th>
                                            <th>Status</th>
                                            <th>Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {requests.map((item, index) => {
                                            return <tr style={{ fontSize: '11px', }} key={index}>
                                                <td >{index + 1 + ((currentPage - 1) * recordsPerPage)}</td>
                                                <td title={item.id}>{item.contractTitle}</td>
                                                <td>{item.contractingStrategy}</td>
                                                <td>{item.cpscCategory}</td>
                                                <td>{item.businessArea.title}</td>
                                                <td>{item.status}</td>
                                                <td style={{ minWidth: 165, textAlign: 'center' }}>
                                                    {item.status !== CpscRequestStatus.CpscApprovalDeferred &&
                                                        <Button variant='contained' size='small' color={isTripletForRequest(item, loggedOnTepngUser) && item.status === CpscRequestStatus.DossierPreparationOngoing ? "secondary" : "primary"} onClick={(e) => { handleCaptureDossier(item) }}>
                                                            {isTripletForRequest(item, loggedOnTepngUser) && item.status === CpscRequestStatus.DossierPreparationOngoing ? "Edit" : "View"}
                                                        </Button>
                                                    }
                                                    {item.status === CpscRequestStatus.CpscApprovalDeferred &&
                                                        <Button variant='contained' size='small' color={isTripletForRequest(item, loggedOnTepngUser) && item.dossierAddendum?.status === DossierAddendumStatus.Draft ? "secondary" : "primary"} onClick={(e) => { handleCaptureDossierAddendum(item) }}>
                                                            {isTripletForRequest(item, loggedOnTepngUser) && item.dossierAddendum?.status === DossierAddendumStatus.Draft ? "Edit" : "View"}
                                                        </Button>
                                                    }
                                                    &nbsp;&nbsp;<Button variant='contained' size='small' color="secondary" onClick={(e) => downloadDocumentPdf(item.id)}  >View PDF</Button>&nbsp;&nbsp;
                                                </td>
                                            </tr>


                                        })}

                                        {requests.length === 0 && !requestsLoading &&
                                            <tr>
                                                <td colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></td>
                                            </tr>
                                        }

                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>
                        <br />
                        <nav>
                            <div className=" d-flex justify-content-between ">
                                <div className=" d-flex justify-content-between ">
                                    <div className='rowperpage'>Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} style={{ width: "60%" }}>
                                        <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </TextField>
                                    </Grid>
                                </div>
                                <Stack spacing={2}>
                                    <Pagination color="primary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>
                            </div>
                            <br />


                        </nav>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default DossierList