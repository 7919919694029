import React from 'react'
import { ContractValueCurrency } from '../../helpers/Constants';
import { SavingsCostOptimizationSlideField, SavingsCostOptimizationSlideTooltip } from '../../helpers/DossierFieldConstants';
import SavingsUpload from './SavingsUpload';
import CustomTabPanel from '../tabs/CustomTabPanel';
import { Tab, Tabs, MenuItem, Select, TextField } from '@mui/material';
import '../../css/template.css'
import Table from 'react-bootstrap/Table';
import MyNumericFormat from '../MyNumericFormat';
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

const CurrencyOptions = [{ value: ContractValueCurrency.EUR, label: "EUR" }, { value: ContractValueCurrency.NGN, label: "NGN" }, { value: ContractValueCurrency.USD, label: "USD" }, { value: ContractValueCurrency.GBP, label: "GBP" }]

/**
  * @typedef {Object} SavingsSlideProps
  * @property {downloadPdfCallback} downloadPdf
  * @property {uploadPdfCallback} uploadPdf
  * @property {deletePdfCallback} deletePdf
  * @property {CpscRequest} cpscRequest
  * @property {downloadPdfCallback} mobileDownloadPdf
  * @property {updateDossierCallback} setDossier
  * @property {boolean} readOnly
   * @property {number} dossierRefreshCount

 */

/**
 * @param {SavingsSlideProps}  props
 * @return {import('react').ReactElement}
 */
function SavingsSlide(props) {

  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);
 

  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, savingsCostOptimizationSlide: { ...props.cpscRequest.dossier.savingsCostOptimizationSlide, [name]: value } })
  }

  return (
    <>

      <div className='col-12  ' style={{ margin: '0px !important' }}>
        <div className='mt-2 mb-2 shadow-sm bg-white px-4 py-2' style={{ paddingTop: '0px !important' }}>
          <div className='content-desktop'>
            <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
              allowScrollButtonsMobile>
              <Tab label="Savings & Cost Optimization" value={1} />

              <Tab label="Savings & Cost Optimization Upload" value={2} />

            </Tabs>
          </div>
          <div className='content-mobile'>
            <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
              allowScrollButtonsMobile>
              <Tab label="Savings & Cost Optimization" value={1} />

              <Tab label="Savings & Cost Optimization Upload" value={2} />

            </Tabs>
          </div>

          <CustomTabPanel value={selectedTabValue} index={1} >
            <div style={{ padding: '5px' }}>
              <Table responsive bordered>
                <thead>
                  <tr style={{ fontSize: '10px', }}>
                    <th ></th>
                    <th> Economic Impact </th>
                    <th> Performance</th>
                    <th> 3C Savings</th>
                   
                  </tr>

                </thead>
                <tbody style={{ fontSize: '12px' }}>
                  <tr >

                    <td valign='bottom'>Currency:
                      <Select style={{ height: '35px', width: '100%' }} disabled={props.readOnly} variant="outlined" size="small" name="currency" className='' value={props.cpscRequest.dossier.savingsCostOptimizationSlide.currency}
                        onChange={(e) => updateDataFieldValue("currency", e.target.value)} >
                        {CurrencyOptions.map((options, index) => {
                          return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                        })}
                      </Select>
                    </td>
                    <td valign='bottom'>
                      <TextField InputProps={{ inputComponent: MyNumericFormat }} size="small" value={props.cpscRequest.dossier.savingsCostOptimizationSlide.economicImpact} name='economicImpact' onChange={(e) => updateDataFieldValue("economicImpact", e.target.value)} className='form-control input-font bold' placeholder='Economic Impact' />
                    </td>
                    <td valign='bottom'>
                      <TextField InputProps={{ inputComponent: MyNumericFormat }} size="small" value={props.cpscRequest.dossier.savingsCostOptimizationSlide.performance} name='performance' onChange={(e) => updateDataFieldValue("performance", e.target.value)} className='form-control input-font bold' placeholder='Performance' />
                    </td>
                    <td valign='bottom'>
                      <TextField InputProps={{ inputComponent: MyNumericFormat }} size="small" value={props.cpscRequest.dossier.savingsCostOptimizationSlide.threeCSavings} name='threeCSavings' onChange={(e) => updateDataFieldValue("threeCSavings", e.target.value)} className='form-control input-font bold' placeholder='3C Savings' />
                    </td>
                   
                  </tr>
                  <tr>
                    <td valign='middle'>Percentage</td>
                    <td valign='middle'>
                      <TextField InputProps={{ inputComponent: MyNumericFormat }} size="small" value={props.cpscRequest.dossier.savingsCostOptimizationSlide.economicImpactPercent} name='economicImpactPercent' onChange={(e) => updateDataFieldValue("economicImpactPercent", e.target.value)} className='form-control input-font bold' placeholder='Economic Impact%' />
                    </td>
                    <td valign='middle'>
                      <TextField InputProps={{ inputComponent: MyNumericFormat }} size="small" value={props.cpscRequest.dossier.savingsCostOptimizationSlide.performancePercent} name='performancePercent' onChange={(e) => updateDataFieldValue("performancePercent", e.target.value)} className='form-control input-font bold' placeholder='Performance %' />
                    </td>
                    <td valign='middle'>
                      <TextField InputProps={{ inputComponent: MyNumericFormat }} size="small" value={props.cpscRequest.dossier.savingsCostOptimizationSlide.threeCSavingsPercent} name='threeCSavingsPercent' onChange={(e) => updateDataFieldValue("threeCSavingsPercent", e.target.value)} className='form-control input-font bold' placeholder='3C Savings %' />
                    </td>
                   
                  </tr>


                </tbody>
                <tfoot style={{ fontSize: '12px' }}>
                  <tr>
                    <td> Agora Number# :</td>
                    <td colSpan={4}>
                      <TextField size="small" value={props.cpscRequest.dossier.savingsCostOptimizationSlide.savingsAgoraNumber} name='savingsAgoraNumber' onChange={(e) => updateDataFieldValue("savingsAgoraNumber", e.target.value)} className='form-control input-font bold' placeholder='Savings Agora Number' />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
            <div style={{ width: '100%', }}>

              <div className="form-group">
                <label className=' title-label' >{getSlideTooltip(props.cpscRequest, SavingsCostOptimizationSlideTooltip.savingsContext)}Savings Context<span className='important'>*</span></label>
               
                <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.savingsCostOptimizationSlide.savingsContext} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(SavingsCostOptimizationSlideField.savingsContext, editorHtmlString)} />

              </div>
              <br />

            </div>
          </CustomTabPanel>
          <CustomTabPanel value={selectedTabValue} index={2} >
            <div >
              <SavingsUpload readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} mobileDownloadPdf={props.mobileDownloadPdf} deletePdf={props.deletePdf} dossier={props.cpscRequest} />
            </div>
          </CustomTabPanel>



        </div>
      </div>
    </>

  )
}

export default SavingsSlide