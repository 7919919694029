import React, { useEffect, useState } from 'react'
import { Button,TextField, Autocomplete, } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { callServerAPI, getNameFromTotalEnergiesEmail } from '../../helpers/Helpers';
import axios from 'axios';
import CustomDialog from '../../components/dialogs/CustomDialog';



axios.defaults.withCredentials = true;
/**
 * @typedef {Object} CreateUserProps
 * @property {String} userItemEmail
 * @property {Number} formEditMode
 * @property {Function} setFormEditMode
 * @property {User[]} users
 * @property {Boolean} openUserForm
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenUserForm
 * @property {Function} setUser
 * @property {Function} getAllUsers
 * @property {String[]} departments
 * @property {String[]} employeeType

 */

/**
 * @param {CreateUserProps}  props
 * @return {import('react').ReactElement}
 */
function CreateUser(props) {
  /** @type User */


  /**  @type [string, Function] */
  let [newUserEmail, setNewUserEmail] = useState("");
  /**  @type [string[], Function] */
  const [tepngEmails, setTepngEmails] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchEmails = async () => {
      callServerAPI('GET', '/api/User/GetAllTepngUsersEmail', null, true)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
          setTepngEmails(["", ...response.data.data])

        })

    }


    fetchEmails();


  }, [props.userItemEmail,])

  function createUser(e) {

    callServerAPI('POST', `/api/User/CreateUser?email=${newUserEmail}`, null, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
        props.setOpenUserForm(false)
        enqueueSnackbar('User Added Successfully', { variant: 'success' })
        props.getAllUsers()
      })
      .catch(function (/** @type { import("axios").AxiosError<any> } */error) {
        props.setOpenUserForm(false);
      })
  }

  return (
    tepngEmails &&
    <>
        <CustomDialog dialogTitle='Add User' setOpenDialog={props.setOpenUserForm} openDialog={props.openUserForm}>
          <form>
            <div >
              <label className='title-label'>Select User Email <span className='important'>*</span></label>
            </div>
            <div className='col-12'>
              <div className="form-group mb-0">
                <div className="">
                  <Autocomplete id="newUserEmail" options={tepngEmails} autoSelect multiple={false} size="small"
                    value={newUserEmail} onChange={(event, newValue) => setNewUserEmail(newValue)}
                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                    renderInput={(params) => (<TextField {...params} margin="none" variant="outlined" size='small' />)}
                  />



                </div>
              </div>
            </div>
            <br />

            <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
              <Button disabled={!newUserEmail} variant="contained" color='secondary' onClick={createUser} ><Add />&nbsp;Add User </Button>
            </div>
          </form >


        </CustomDialog>
      
    </>
  )
}

export default CreateUser