import React from 'react';
import { NumericFormat } from 'react-number-format';

const MyNumericFormat = React.forwardRef(function NumericFormatCustom(/** @type { import ('react-number-format').NumericFormatProps} */ props, ref,) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    // @ts-ignore
                    target: {
                        name: props.name,
                        value: values.value === "" ? null : values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

export default MyNumericFormat