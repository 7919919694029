import { Typography, Box, useTheme,  } from "@mui/material";
import React from "react";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

/**
 * @typedef {Object} HeaderProps
 * @property {string} title
 */

/**
 * @param {HeaderProps}  props
 * @return {import('react').ReactElement}
 */
const Header = (props) => {
    const theme = useTheme();
    return (
        <Box>
            <Stack>
                <Paper>
                    
                    <Typography
                        variant="h6"
                        color={theme.palette.text.secondary}
                        fontWeight="bold"
                        sx={{
                            margin: '1.0rem',

                        }}
                    >
                        {props.title}
                    </Typography></Paper>
            </Stack>

           
        </Box>
    );
};

export default Header;