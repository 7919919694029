import React from 'react'
import { ContractSpendEstimationSlideField, ContractSpendEstimationSlideTooltip } from '../../helpers/DossierFieldConstants';
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import { Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../tabs/CustomTabPanel';
import ContractSpendEstimationUpload from './ContractSpendEstimationUpload';
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} ContractSpendEstimationSlideProps
 * @property {downloadPdfCallback} downloadPdf
 * @property {uploadPdfCallback} uploadPdf
 * @property {downloadPdfCallback} mobileDownloadPdf
 * @property {deletePdfCallback} deletePdf
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {number} dossierRefreshCount
* @property {Boolean} readOnly
 */

/**
 * @param {ContractSpendEstimationSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ContractSpendEstimationSlide(props) {

  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);


  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, contractSpendEstimationSlide: { ...props.cpscRequest.dossier.contractSpendEstimationSlide, [name]: value } })
  }

  return (

    <>
      <div className='content-desktop'>
        <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label=" Contract Spend Estimation" value={1} />
          <Tab label="Contract Spend Estimation Upload" value={2} />
        </Tabs>
      </div>
      <div className='content-mobile'>
        <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label=" Contract Spend Estimation" value={1} />
          <Tab label="Contract Spend Estimation Upload" value={2} />

        </Tabs>
      </div>
      <CustomTabPanel value={selectedTabValue} index={1} >
        <form style={{ fontSize: '12px' }}>
          <div className="form-group">
            <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ContractSpendEstimationSlideTooltip.contractSpendEstimation)}Contract Spend Estimation Details <span className='important'>*</span></label>
            <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.contractSpendEstimationSlide.contractSpendEstimation} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ContractSpendEstimationSlideField.contractSpendEstimation, editorHtmlString)} />
          </div>
          <br />
          <div className="form-group">
            <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ContractSpendEstimationSlideTooltip.additionalComments)}Additional Comments  <span className='important'>*</span></label>
            <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.contractSpendEstimationSlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ContractSpendEstimationSlideField.additionalComments, editorHtmlString)} />
          </div>
        </form>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabValue} index={2} >
        <div >
          <ContractSpendEstimationUpload mobileDownloadPdf={props.mobileDownloadPdf} readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
        </div>
      </CustomTabPanel>
    </>

  )
}

export default ContractSpendEstimationSlide