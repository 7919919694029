import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2'
import { callServerAPI } from '../../helpers/Helpers';
import { preloader, FormEditMode, MeetingStatus, AgendaItemType } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useParams } from 'react-router-dom';
import AddUpdateAgendaItem from './AddUpdateAgendaItem';
import Table from 'react-bootstrap/Table';

const MySwal = withReactContent(Swal);
axios.defaults.withCredentials = true;
/**
 * @typedef {Object} AgendaListProps
 * @property {Meeting} meeting
 */

/**
 * @param {AgendaListProps}  props
 * @return {import('react').ReactElement}
 */

function AgendaList(props) {

    /** @type [AgendaItem[], import('react').Dispatch<import('react').SetStateAction<AgendaItem[]>>] */
    const [agenda, setAgenda] = useState(props.meeting.agenda)
    /** @type [string,Function] */
    const [agendaItemId, setAgendaItemId] = useState("")
    /** @type [Number, Function] */
    const [agendaItemEditMode, setAgendaItemEditMode] = useState(FormEditMode.CREATE);
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [openAgendaItemForm, setOpenAgendaItemForm] = React.useState(false);
    const [triggerUpdateAgendaSequence, setTriggerUpdateAgendaSequence] = React.useState(false);


    const handleOpenAgendaItemForm = () => {
        setOpenAgendaItemForm(true);
    };

    useEffect(() => {
        setAgenda(props.meeting.agenda.sort((a, b) => (a.serialNumber > b.serialNumber) ? 1 : -1));
    }, [props.meeting])

    function downloadAgendaPdf() {
        MySwal.fire(preloader)
        callServerAPI('GET', `/api/Meeting/GetMeetingAgendaPdf?meetingId=${id}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                window.open(URL.createObjectURL(response.data));
                MySwal.close();
            })
    }



    const handleDelete = (agendaItemId) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Do you want to delete this Agenda Item ?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'

        }).then((result) => {
            if (result.isConfirmed) {
                deleteAgendaItem(agendaItemId);
            }
        })
    };

    const deleteAgendaItem = (agendaItemId) => {
        MySwal.fire(preloader);
        callServerAPI('DELETE', `/api/Meeting/DeleteAgendaItem?meetingId=${props.meeting.id}&agendaItemId=${agendaItemId}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setAgenda(agenda => agenda.filter(r => r.id !== agendaItemId).map((e, index) => ({ ...e, serialNumber: index + 1 })))
                MySwal.close();
                enqueueSnackbar("Agenda Item Deleted", { variant: 'success' });
                setTriggerUpdateAgendaSequence(true)
            })

    }

    /** @param {AgendaItem} agendaItem */
    const handleEdit = (agendaItem) => {
        setAgendaItemId(agendaItem.id)
        setAgendaItemEditMode(FormEditMode.EDIT);
        setOpenAgendaItemForm(true);
    }

    //save reference for dragItem and dragOverItem
    const draggedItemIndex = useRef(null);
    const draggedOverItemIndex = useRef(null);
    //handle drag sorting
    const handleSort = () => {
        //duplicate agendaitems
        let _agendItems = [...agenda]
        //remove and save the dragItem content
        const dragItemContent = _agendItems.splice(draggedItemIndex.current, 1)[0]
        //switch position
        _agendItems.splice(draggedOverItemIndex.current, 0, dragItemContent)
        //reset positon
        draggedItemIndex.current = null
        draggedOverItemIndex.current = null
        //update agenda
        setAgenda(_agendItems.map((e, index) => ({ ...e, serialNumber: index + 1 })))
        setTriggerUpdateAgendaSequence(true)
    }

    useEffect(() => {
        if (triggerUpdateAgendaSequence) {
        /** @type UpdateAgendaSequenceDto[] */ const dataToSend = agenda.map(e => ({ agendaItemId: e.id, serialNumber: e.serialNumber }))
            callServerAPI('PATCH', `/api/Meeting/UpdateAgendaSequence?meetingId=${id}`, dataToSend, true)
                .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                    enqueueSnackbar("Order of Agenda Items updated", { variant: 'success' });
                    setTriggerUpdateAgendaSequence(false)
                })
        }
    }, [triggerUpdateAgendaSequence, id, enqueueSnackbar, agenda])

    return (
        <div>

            <div className='col-12 ' style={{ margin: '0px !important' }}>
                <div className='mt-4 mb-4 shadow-sm bg-white px-4 py-4 add-height' >
                    <span className=' d-flex d-flex flex-row-reverse'>
                        <div>
                            <Button variant="contained" color='success' onClick={downloadAgendaPdf}> <PictureAsPdfIcon /> &nbsp;View PDF</Button>
                        </div> &nbsp;
                        <div >
                            <Button variant="contained" color='secondary' onClick={handleOpenAgendaItemForm} disabled={props.meeting.status !== MeetingStatus.Scheduled} ><Add />&nbsp; Agenda Item</Button>
                        </div>

                    </span>
                    <br />

                    {openAgendaItemForm &&
                        <AddUpdateAgendaItem meetingId={props.meeting.id} updateAgenda={agenda => setAgenda(agenda)} agendaItemId={agendaItemId} agenda={agenda} openAgendaItemForm={openAgendaItemForm} setOpenAgendaItemForm={setOpenAgendaItemForm} formEditMode={agendaItemEditMode} setFormEditMode={setAgendaItemEditMode} />
                    }


                    <div>
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table responsive striped bordered>
                                    <thead className='thead-text'>
                                        <tr style={{ fontSize: '10px', }}>

                                            <th >SN</th>
                                            <th>Title </th>
                                            <th>Dossier Type</th>
                                            <th>Planned Duration Minute  </th>
                                            <th style={{ minWidth: 140, textAlign: 'center' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agenda.sort((a, b) => a.serialNumber > b.serialNumber ? 1 : -1).map((agendaItem, index) => {
                                            return <tr style={{ fontSize: '10px', }} className='agendaRow trtext' key={index} draggable
                                                onDragStart={(e) => draggedItemIndex.current = index}
                                                onDragEnter={(e) => draggedOverItemIndex.current = index}
                                                onDragEnd={handleSort}
                                                onDragOver={(e) => e.preventDefault}>
                                                <td title='Drag to move agenda item'>{agendaItem.serialNumber}</td>
                                                <td title='Drag to move agenda item' >{agendaItem.title}</td>
                                                <td >{agendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT ? 'Not Required' : (agendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST ? 'Aggregate Request' : 'CPSC Request')}</td>
                                                <td>{agendaItem.plannedDurationMinute} minutes</td>
                                                <td style={{ minWidth: 152, textAlign: 'center' }}>
                                                    <Button variant='contained' size='small' color="primary" onClick={() => handleEdit(agendaItem)} disabled={props.meeting.status !== MeetingStatus.Scheduled}>Edit</Button>&nbsp;&nbsp;
                                                    <Button variant='contained' size='small' color='error' onClick={() => handleDelete(agendaItem.id)} disabled={props.meeting.status !== MeetingStatus.Scheduled} >Delete</Button>
                                                </td>

                                            </tr>
                                        })}
                                        {agenda.length === 0 ? <tr>
                                            <td colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>Please create a Agenda....</p></td>
                                        </tr> : ''}

                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default AgendaList
