import React from "react";
import '../../css/header.css'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { callServerAPI, callServerTepngUserLogout } from '../../helpers/Helpers';
import { APP_LAST_UPDATED, APP_VERSION, ENVIRONMENT, GlobalRole, SERVER_URL, globalStore, preloader } from '../../helpers/Constants';
import { Menu as MenuIcon, } from "@mui/icons-material";
import FlexBetween from "../FlexBetween";
import QuizIcon from '@mui/icons-material/Quiz';
import Fade from '@mui/material/Fade';
import { AppBar, Typography, IconButton, Toolbar, useTheme, Button, Menu, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

/**
 * @typedef {Object} NavProps
 * @property {GlobalSettingDto} globalSetting
 * @property {Boolean} isSidebarOpen,
 * @property {Function}  setIsSidebarOpen,
 * @property {Boolean}  isNonMobile,

 */

/**
 * @param {NavProps}  props
 * @return {import('react').ReactElement}
 */

const MySwal = withReactContent(Swal);

function Navigationbar(props) {
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    /** @type TepngUser */
    let user = loggedOnTepngUser;

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const logout = async (event) => {
        await callServerTepngUserLogout()
    }

    const regenerateAllActiveDossierPdf = async () => {
        MySwal.fire(preloader)
        callServerAPI('PATCH', '/api/CpscRequest/RegenerateAllActiveDossierPdf', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                MySwal.close();
                enqueueSnackbar("All Active Dossiers PDF regenerated", { variant: 'success' });
            })
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar
            sx={{
                position: 'static',
                background: 'white'
            }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* left side */}
                <FlexBetween>
                    <IconButton onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}>
                        <MenuIcon />
                    </IconButton>
                    {props.isNonMobile && (
                        <img src='../../assets/images/DcpscLogo.png' alt="logo" />
                    )}
                    {!props.isNonMobile && (
                        <Typography
                            variant="h6"
                            color={theme.palette.text.secondary}
                            fontWeight="bold"
                            sx={{
                                margin: '1.0rem',

                            }}
                        >
                            Digital CPSC
                        </Typography>
                    )}
                </FlexBetween>

                {/* right side */}
                <FlexBetween gap='1.5rem'>
                    {props.isNonMobile &&
                        <>
                            <div>
                                <Button style={{ color: 'black', fontSize: '12px', textTransform: 'capitalize' }}
                                    id="demo-positioned-button"
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    User Guide <QuizIcon style={{ color: '#f54a5a', }} />
                                </Button>
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                >
                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Initiation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Initiation Manual</MenuItem>
                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Preparation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Preparation Manual</MenuItem>
                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Aggregation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Aggregation Manual</MenuItem>
                                    {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                        <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Meeting Scheduling Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Meeting Scheduling Manual</MenuItem>
                                    }
                                    {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                        <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Meeting Administration Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Meeting Administration Manual</MenuItem>
                                    }
                                    {(loggedOnTepngUser?.roles.includes(GlobalRole.CPSCMember) || loggedOnTepngUser?.roles.includes(GlobalRole.GCC)) &&
                                        <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Meeting Presentation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Meeting Presentation Manual</MenuItem>
                                    }
                                    {(loggedOnTepngUser?.roles.includes(GlobalRole.GCC) || loggedOnTepngUser?.roles.includes(GlobalRole.CpscChairman) || loggedOnTepngUser?.roles.includes(GlobalRole.CpscSecretary)) &&
                                        <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Minutes of Meeting Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Minutes of Meeting Manual</MenuItem>
                                    }
                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Addendum Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Addendum Manual</MenuItem>
                                    {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                        <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Admin Manual.pdf?chromeless=true', '_blank')?.focus(); }}><QuizIcon style={{ color: 'gray', }} /> &nbsp;Admin Manual</MenuItem>
                                    }
                                </Menu>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography
                                    title={
                                        "Client Environment: " + ENVIRONMENT +
                                        "\nServer Environment: " + props.globalSetting?.environment +
                                        "\nClient App Version: " + APP_VERSION +
                                        "\nServer App Version: " + props.globalSetting?.appVersion +
                                        "\nClient Last Updated: " + new Date(APP_LAST_UPDATED) +
                                        "\nServer Last Updated: " + (props.globalSetting == null ? "" : new Date(props.globalSetting.appLastUpdated))}
                                >
                                    <Typography variant="caption" style={{ width: '100%', color: '#023361', textTransform: 'uppercase', fontWeight: '600', fontSize: '12px' }}>{ENVIRONMENT}</Typography>
                                </Typography >
                            </div>
                        </>
                    }
                    <Navbar className='mr-13'>
                        <Navbar.Toggle aria-controls="navbar-dark-example" />
                        <Navbar.Collapse id="navbar-dark-example">
                            <Nav>
                                {props.isNonMobile &&
                                    <>
                                        {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                            <div className='badge'>GCC</div>
                                        }&nbsp;
                                        {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                            <div className='badge'>Admin</div>
                                        }
                                    </>
                                }
                                <NavDropdown align={"end"} id="nav-dropdown-dark-example" title={`Hello  ${user?.firstName}`} menuVariant="light">
                                    <NavDropdown.Item href="#!" onClick={e => logout()}>Log Out</NavDropdown.Item>
                                    {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                        <>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="#!" onClick={e => regenerateAllActiveDossierPdf()}>Regenerate Dossier Pdf</NavDropdown.Item>
                                        </>
                                    }
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                </FlexBetween>

            </Toolbar>
        </AppBar>
    )
}

export default Navigationbar