import React, { useEffect, useState } from 'react'
import { callServerAPI, getNameFromTotalEnergiesEmail, isNullOrWhiteSpace } from '../../helpers/Helpers';
import { AgendaItemType, globalStore, CpscVoteType, GlobalRole, AgendaItemStatus } from '../../helpers/Constants';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Button, Radio } from '@mui/material'
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { Info } from '@mui/icons-material';
import CustomDialog from '../../components/dialogs/CustomDialog';





/**
 * @typedef {Object} MeetingVotingProps
 * @property {string} currentAgendaItemStatus
 * @property {Function} updateCurrentAgendaItemStatus
 * @property {AgendaItem} currentAgendaItem
 * @property {CpscRequestsForMeetingDto} meetingCpscRequestList
 * @property {CpscVote[]} cpscVotes
 */



/**
 * @param {MeetingVotingProps}  props
 * @return {import('react').ReactElement}
 */
function MeetingVoting(props) {
    console.log(`MeetingVoting: props.currentAgendaItem.title = ${props.currentAgendaItem.title}`)
    console.log(`MeetingVoting: props.cpscVotes.length = ${props.cpscVotes.length}`)

    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");

    const [openComments, setOpenComments] = React.useState(false);

    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [selectedComment, setSelectedComment] = useState("")

    /**  @type [CpscVote[], import('react').Dispatch<import('react').SetStateAction<CpscVote[]>>] */
    const [userVotesForCurrentAgendaItem, setUserVotesForCurrentAgendaItem] = useState(null);

    /**  @type [CpscRequest[], import('react').Dispatch<import('react').SetStateAction<CpscRequest[]>>] */
    const [cspcRequestListForCurrentAgendaItem, setCspcRequestListForCurrentAgendaItem] = useState(null);

    const [userVotesSubmitted, setUserVotesSubmitted] = React.useState(false);



    useEffect(() => {
        const local_cspcRequestListForCurrentAgendaItem = props.currentAgendaItem.agendaItemType !== AgendaItemType.SAFETY_MOMENT && props.currentAgendaItem.agendaItemType !== AgendaItemType.AGGREGATED_CPSC_REQUEST ?
            [props.meetingCpscRequestList.cpscRequestList.find(e => e.id === props.currentAgendaItem.cpscRequestId)] :
            props.meetingCpscRequestList.aggregatedCpscRequestList.find(e => e.id === props.currentAgendaItem.aggregatedCpscRequestId).cpscRequests

        /** @type CpscVote[] */
        const initialUserVotesForCurrentAgendaItem = local_cspcRequestListForCurrentAgendaItem.map(e => ({ id: undefined, agendaItemId: props.currentAgendaItem.id, email: loggedOnTepngUser.email, cpscRequestId: e.id, vote: null, comments: "" }))

        setCspcRequestListForCurrentAgendaItem(local_cspcRequestListForCurrentAgendaItem)
        setUserVotesForCurrentAgendaItem(initialUserVotesForCurrentAgendaItem)
        setUserVotesSubmitted(false)

    }, [props.currentAgendaItemStatus, props.currentAgendaItem, loggedOnTepngUser.email, props.meetingCpscRequestList])

    /** @param {string} cpscRequestId */
    function getCpscRequestTitle(cpscRequestId) {
        return cspcRequestListForCurrentAgendaItem.find(e => e.id === cpscRequestId)?.contractTitle
    }

    /** @param {CpscVote} cpscVote */
    const handleClickOpen = (cpscVote) => {
        setSelectedComment(cpscVote.comments)
        setOpenComments(true);
    };
  

    const submitVote = () => {
        callServerAPI('POST', '/api/MeetingPresentation/SubmitCpscVotes', userVotesForCurrentAgendaItem, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscVote[]>> } */ response) {
                setUserVotesSubmitted(true)
            })

    }

    /** @param {string} cpscRequestId, @param {string} vote */
    function updateCpscVote(cpscRequestId, vote) {
        setUserVotesForCurrentAgendaItem(previousCpscVotes => previousCpscVotes.map(inst => inst.cpscRequestId !== cpscRequestId ? inst : { ...inst, vote: vote }))
    }

    /** @param {string} vote */
    function updateAllCpscVotes(vote) {
        setUserVotesForCurrentAgendaItem(previousCpscVotes => previousCpscVotes.map(inst => ({ ...inst, vote: vote })))
    }

    /** @param {string} cpscRequestId, @param {string} comments */
    function updateCpscVoteComment(cpscRequestId, comments) {
        setUserVotesForCurrentAgendaItem(previousCpscVotes => previousCpscVotes.map(inst => inst.cpscRequestId !== cpscRequestId ? inst : { ...inst, comments: comments }))
    }

    /** @returns {boolean} */
    function cpscVoteExistsForAgendaItem() {
        return props.cpscVotes.findIndex(v => v.agendaItemId === props.currentAgendaItem.id) >= 0
    }

    /** @returns {boolean} */
    function userCpscVoteExistsForAgendaItem() {
        const result = props.cpscVotes.findIndex(v => v.agendaItemId === props.currentAgendaItem.id && v.email.toUpperCase() === loggedOnTepngUser.email.toUpperCase()) >= 0
        return result
    }

    return (cspcRequestListForCurrentAgendaItem && userVotesForCurrentAgendaItem &&
        <>
            {props.currentAgendaItemStatus !== AgendaItemStatus.VOTING_ONGOING && loggedOnTepngUser.roles.includes(GlobalRole.GCC) &&
                <div style={{ textAlign: 'center', padding: '15px' }}>
                    <Button className='bck ' color='secondary' variant="contained" onClick={() => { props.updateCurrentAgendaItemStatus(AgendaItemStatus.VOTING_ONGOING) }} ><HowToVoteIcon />&nbsp;Commence Voting</Button>
                </div>
            }
            {props.currentAgendaItemStatus !== AgendaItemStatus.VOTING_ONGOING && !cpscVoteExistsForAgendaItem() &&
                <div style={{ textAlign: 'center' }}>
                    <HowToVoteIcon style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                    <div className='not-started'>CPSC Voting not Started</div>
                </div>
            }
            {props.currentAgendaItemStatus === AgendaItemStatus.VOTING_ONGOING &&
                <p className='vtn_ongoing'> <HowToVoteIcon />&nbsp;CPSC Voting Ongoing</p>
            }
            <br />  <br />
            {props.currentAgendaItemStatus === AgendaItemStatus.VOTING_ONGOING && loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember) && !userVotesSubmitted && !userCpscVoteExistsForAgendaItem() ?
                <>
                    {props.currentAgendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST &&
                        <>
                            <RadioGroup row={props.currentAgendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST && !(window.matchMedia('screen and (max-width: 768px)').matches)}>
                                <FormControlLabel value={CpscVoteType.Ok} control={<Radio checked={userVotesForCurrentAgendaItem.find(e => e.vote !== CpscVoteType.Ok) == null} onClick={() => updateAllCpscVotes(CpscVoteType.Ok)} />} label={`Select ${CpscVoteType.Ok} for all ${userVotesForCurrentAgendaItem.length} dossiers`} />
                                <FormControlLabel value={CpscVoteType.NotOk} control={<Radio checked={userVotesForCurrentAgendaItem.find(e => e.vote !== CpscVoteType.NotOk) == null} onClick={() => updateAllCpscVotes(CpscVoteType.NotOk)} />} label={`Select ${CpscVoteType.NotOk} for all ${userVotesForCurrentAgendaItem.length} dossiers`} />
                            </RadioGroup>
                            <div style={{ color: '#777777', fontSize: "12px" }} >
                                <Info style={{ color: '#f54a5a' }} />&nbsp;<em>Votes for the individual dossiers can be modified below</em>
                            </div>
                            <hr />
                        </>
                    }

                    {userVotesForCurrentAgendaItem.map((cpscVote, index) => {
                        return <div key={index}>
                            <div style={{ color: '#f54a5a', fontSize: "12px" }} >
                                <b>  {'(' + (index + 1) + ')'} &nbsp;&nbsp;<em>{getCpscRequestTitle(cpscVote.cpscRequestId)}</em>  </b>
                            </div>
                            <FormControl style={{ width: '100%' }}>
                                <RadioGroup row={props.currentAgendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST && !(window.matchMedia('screen and (max-width: 768px)').matches)}>
                                    <FormControlLabel value={CpscVoteType.Ok} control={<Radio checked={cpscVote.vote === CpscVoteType.Ok} onClick={() => updateCpscVote(cpscVote.cpscRequestId, CpscVoteType.Ok)} />} label={CpscVoteType.Ok} />
                                    <FormControlLabel value={CpscVoteType.OkClarificationsRequired} control={<Radio checked={cpscVote.vote === CpscVoteType.OkClarificationsRequired} onClick={() => updateCpscVote(cpscVote.cpscRequestId, CpscVoteType.OkClarificationsRequired)} />} label={CpscVoteType.OkClarificationsRequired} />
                                    <FormControlLabel value={CpscVoteType.NotOk} control={<Radio checked={cpscVote.vote === CpscVoteType.NotOk} onClick={() => updateCpscVote(cpscVote.cpscRequestId, CpscVoteType.NotOk)} />} label={CpscVoteType.NotOk} />
                                </RadioGroup>
                                <div className='row'>
                                    <div >
                                        <div >
                                            <label className=' title-label'>Comments </label>
                                            {cpscVote.vote !== CpscVoteType.Ok && <span className='important'>*</span>}
                                        </div>
                                        <div className=''>
                                            <div className="form-group mb-0">
                                                <textarea rows={3} className='form-control' id="votecomments" value={cpscVote.comments} onChange={(e) => updateCpscVoteComment(cpscVote.cpscRequestId, e.currentTarget.value)} style={{ width: '100%' }} required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormControl>
                            <br /><br />
                        </div>
                    })}
                    <Button color='secondary' disabled={userVotesForCurrentAgendaItem.find(e => e.vote == null || (e.vote !== CpscVoteType.Ok && Boolean(e.comments) === false)) != null} variant="contained" onClick={submitVote}>Submit Vote</Button>

                </>
                :
                <>
                    <div className='content-desktop'>
                        {cpscVoteExistsForAgendaItem() && cspcRequestListForCurrentAgendaItem.map(cpscRequest =>
                            <>
                                <div style={{ color: '#f54a5a', fontSize: "12px" }} > <b> Dossier: <em>{cpscRequest.contractTitle}</em>  </b> </div>
                                <br />
                                {props.cpscVotes.filter(e => e.cpscRequestId === cpscRequest.id).map(cpscVote =>
                                    <>
                                        <div className='user'> {getNameFromTotalEnergiesEmail(cpscVote.email)}</div>
                                        <div className='d-flex  justify-content-between'>
                                            <div className={cpscVote.vote === CpscVoteType.Ok ? 'ok_vote' : (cpscVote.vote === CpscVoteType.OkClarificationsRequired ? 'further_vote' : 'abstain_vote')}> &nbsp;{cpscVote.vote}&nbsp;</div>
                                            {!isNullOrWhiteSpace(cpscVote.comments) &&
                                                <Button className='cmmtbtn' variant="contained" onClick={() => handleClickOpen(cpscVote)}>View Comments</Button>
                                            }

                                            {openComments &&
                                                <>
                                                    <CustomDialog dialogTitle='Comments' openDialog={openComments} setOpenDialog={setOpenComments}>
                                                     <div>{selectedComment}</div>
                                                 </CustomDialog>
                                                        
                                                </>
                                            }
                                        </div>
                                        <br />
                                    </>)}
                                <hr style={{ color: 'red', height: "2px" }} />
                            </>
                        )}
                    </div>
                    <div className='content-mobile'>
                        {cpscVoteExistsForAgendaItem() && cspcRequestListForCurrentAgendaItem.map(cpscRequest =>
                            <>

                                <div style={{ color: '#f54a5a', fontSize: "12px" }} > <b> Dossier: <em>{cpscRequest.contractTitle}</em>  </b> </div>
                                <br />
                                {props.cpscVotes.filter(e => e.cpscRequestId === cpscRequest.id).map(cpscVote =>
                                    <>
                                        <div className='user'> {getNameFromTotalEnergiesEmail(cpscVote.email)}</div>

                                        <div >
                                            <div className={cpscVote.vote === CpscVoteType.Ok ? 'ok_vote' : (cpscVote.vote === CpscVoteType.OkClarificationsRequired ? 'further_vote' : 'abstain_vote')}> &nbsp;{cpscVote.vote}&nbsp;</div>
                                            <br />
                                            {!isNullOrWhiteSpace(cpscVote.comments) &&
                                                <div className='d-flex flex-row-reverse' style={{ float: 'right', }} >

                                                    <Button className='cmmtbtn' variant="contained" onClick={() => handleClickOpen(cpscVote)} size='small'>View Comments</Button>
                                                    <br />
                                                </div>
                                            }
                                            {openComments &&
                                                <>
                                                 <CustomDialog dialogTitle='Comments' openDialog={openComments} setOpenDialog={setOpenComments}>
                                                     <div>{selectedComment}</div>
                                                 </CustomDialog>
                                                        
                                                   
                                                </>
                                            }
                                        </div>
                                        <br />
                                    </>)}
                                <hr style={{ color: 'red', height: "2px" }} />
                            </>
                        )}
                    </div>
                </>

            }
        </>
    )
}

export default MeetingVoting