import React, { useState } from 'react'
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { preloader } from '../../helpers/Constants';
import { callServerAPI, isNullOrWhiteSpace } from '../../helpers/Helpers';
import { useSnackbar } from 'notistack';
import CustomDialog from '../../components/dialogs/CustomDialog';


const MySwal = withReactContent(Swal);
/**
 * @typedef {Object} AdhocDossierSlideCreateFormProps
 * @property {CpscRequest} cpscRequest
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequest>>} setCpscRequest
 * @property {boolean} openAdhocDossierSlideCreateForm
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenAdhocDossierSlideCreateForm
 */

/**
 * @param {AdhocDossierSlideCreateFormProps}  props
 * @return {import('react').ReactElement}
 */
function AdhocDossierSlideCreateForm(props) {
    const { enqueueSnackbar } = useSnackbar();
    /** @type AdhocDossierSlide */
    let emptyAdhocDossierSlide = { slideName: "" }
    /** @type [AdhocDossierSlide,import('react').Dispatch<import('react').SetStateAction<AdhocDossierSlide>>] ] */
    const [data, setData] = useState(emptyAdhocDossierSlide)
    /** @type [FormData,import('react').Dispatch<import('react').SetStateAction<FormData>>] ] */
    const [uploadFileData, setUploadFileData] = useState(null)

    async function UploadAdhocDossierSlide() {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/CpscRequest/UploadAdhocDossierSlide?requestId=${props.cpscRequest.id}&slideName=${data.slideName}`, uploadFileData, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                props.setCpscRequest(response.data.data)
                setUploadFileData(null)
                setData(emptyAdhocDossierSlide)
                MySwal.close();
                props.setOpenAdhocDossierSlideCreateForm(false)
                enqueueSnackbar(`${data.slideName} slide uploaded Successfully`, { variant: 'success' });
            })
    }

    /** @param {React.ChangeEvent<HTMLInputElement>} event */
    const handleFileChange = (event) => {
        event.persist()
        if (event.target.files.length === 0) return
        const formData = new FormData()
        formData.append("uploadedFile", event.target.files[0]);
        setUploadFileData(formData)
        event.target.value = null
    }

    return (
        <CustomDialog dialogTitle='Add Adhoc Dossier Slide' setOpenDialog={props.setOpenAdhocDossierSlideCreateForm} openDialog={props.openAdhocDossierSlideCreateForm}>
            <>
                <div className='row'>
                    <div className='col1'>
                        <div >
                            <label className='title-label'>Slide Name <span className='important'>*</span></label>
                        </div>
                        <div className=''>
                            <TextField fullWidth size="small" id='slideName' name='slideName' value={data.slideName} placeholder="Slide Name" onChange={(e) => setData(previousData => ({ ...previousData, slideName: e.target.value }))} />
                        </div>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col'>
                        <div >
                            <label className='title-label'>Upload File<span className='important'>*</span></label>
                        </div>
                        <div className=''>
                            {uploadFileData == null && <input id="slideUploaded" type="file" onChange={(e) => handleFileChange(e)} accept="application/pdf" />}
                            {uploadFileData != null &&
                                <>
                                    <span>{(/** @type File */ (uploadFileData.get("uploadedFile"))).name}</span> &nbsp;
                                    <Button onClick={() => setUploadFileData(null)} color='secondary' style={{ textTransform: 'none', fontSize: 13 }} size='large' variant='text' >Delete File</Button>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <br />
                <div>
                    <Button color='success' variant='contained' disabled={isNullOrWhiteSpace(data?.slideName) || uploadFileData == null} onClick={() => UploadAdhocDossierSlide()} >
                        Add New Adhoc Slide
                    </Button>
                </div>
            </>
        </CustomDialog>

    )
}

export default AdhocDossierSlideCreateForm