// mui theme settings
export function themeSettings() {
    return {
        palette: {
            primary: {
                main: "#0288D1",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#f54a5a",
                contrastText: "#ffffff"
            },
            error: {
                main: "#ff0000",
                contrastText: "#ffffff"
            },
            success: {
                main: "#0f940f",
                contrastText: "#ffffff"
            },
            warning: {
                main: '#e65100',
                contrastText: "#ffffff"
            },
            info: {
                main: "#0288D1",
                contrastText: "#ffffff"
            },
            action: {
                selected: "#D9D9D9", focus: "#0288D1"
            },
            background: {
                default: "#ffffff"
            },
            text: {
                primary: "#222222", secondary: "#023361", disabled: "#777777",
            },
            headerColor: {
                primary: '#023361'
            }
        },
        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
            fontSize: 12,
            pageheader: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 14,
            },
            td: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 10,
            },
            h1: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 12,
            },
        },
    };
};