import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import { callServerTepngUserLogin, fireAxiosErrorMySwal } from '../../helpers/Helpers';
import { preloader, SERVER_URL } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import '../../App.css';

const { useNavigate } = require("react-router-dom");
const MySwal = withReactContent(Swal);

function Login() {
  const routerNavigate = useNavigate()

  useEffect(() => {

    const loginuser = () => {
      MySwal.fire(preloader);
      callServerTepngUserLogin()
        .then(function (response) {
          MySwal.close();
          const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
          localStorage.removeItem('loginRedirectedFromUrl');
          routerNavigate(loginRedirectedFromUrl ?? "/home")
        })
        .catch(function (/** @type import('axios').AxiosError<any> */ error) {
          let queryParams = new URLSearchParams(window.location.search);
          // microsoft login redirect - the frond end url with the /login-tepnguser needs to be added to Azure App Service authentication Allowed external redirect URLs to allow for login redirect

          if (queryParams.get("azureauthredirect") !== '1' && error?.response?.status === 401) {
            let url = new URL(window.location.origin + '/login-tepnguser')
            url.searchParams.append('azureauthredirect', '1')
            window.location.replace(SERVER_URL + '/.auth/login/aad?post_login_redirect_uri=' + window.encodeURIComponent(url.href));
          }
          else
            fireAxiosErrorMySwal(error)
        })
    }

    loginuser();
  }, [routerNavigate])


  return (<div></div>)
}

export default Login;