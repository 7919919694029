import React, { useEffect, useState } from 'react'
import { callServerAPI, getNameFromTotalEnergiesEmail } from '../../helpers/Helpers';

import axios from 'axios';
import CustomDialog from '../../components/dialogs/CustomDialog';


axios.defaults.withCredentials = true;
/**
 * @typedef {Object} UserRolesProps
 * @property {String} roleName
 * @property {Boolean} openUserForRole
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenUserForRole
 */

/**
 * @param {UserRolesProps}  props
 * @return {import('react').ReactElement}
 */
function UserPerRole(props) {
    const [userPerRole, setUserPerRole] = useState([]);

    useEffect(() => {
        const getUserPerRole = async () => {
            callServerAPI('GET', `/api/Roles/GetAllUserEmailsForARole?roleName=${window.encodeURIComponent(props.roleName)}`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                    setUserPerRole(response.data.data);


                })

        }
        getUserPerRole();

    }, [props.roleName])

    return (
        <>
            <CustomDialog dialogTitle={`Users Assigned to ${props.roleName}`} openDialog={props.openUserForRole} setOpenDialog={props.setOpenUserForRole}>

                <>
                    <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <thead className='thead-text'>
                            <tr>
                                <th >SN</th>
                                <th>Full Name</th>

                            </tr>
                        </thead>
                        <tbody>
                            {userPerRole.map((item, index) => {
                                return (
                                    <>

                                        <tr className='trtext' key={index}>
                                            <td>{index + 1}</td>
                                            <td>{getNameFromTotalEnergiesEmail(item)}</td>
                                        </tr>

                                    </>
                                )
                            })}
                            {userPerRole.length === 0 ? <tr>
                                <td colSpan={2}><p className=' text-center'>No users have been assigned to the {props.roleName} role ....</p></td>
                            </tr> : ''}

                        </tbody>
                    </table>



                </>



            </CustomDialog>

        </>
    )
}

export default UserPerRole