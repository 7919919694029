import { ExpandMore, Delete, Edit, Reply } from '@mui/icons-material';
import { Fab, MenuItem, Select } from '@mui/material';
import { Avatar, Button } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { callServerAPI, canPostNewComment, commentCanBeEditOrDeleted, formatShortDateString, getNameFromTotalEnergiesEmail, getNameInitialsFromTotalEnergiesEmail, getSlideName, rejectionCommentExistsForUser } from '../../helpers/Helpers';
import { CpscRequestStatus, globalStore } from '../../helpers/Constants';
import '../../css/comment.css'

/**
 * @typedef {Object} CommentsProps
 * @property {CpscRequest} cpscRequest
 * @property {CpscRequest} dossier
 * @property {number} currentSlideNumber
 * @property {number} dossierRefreshCount
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequest>>} setCpscRequest
 */




/**
 * @param {CommentsProps} props
 * @return {import('react').ReactElement}
 */
function DossierCommentsEditor(props) {

  const [isOverflow,] = useState(false);
  const heightRef = useRef(null);

  /*  useEffect(() => {
     const el = heightRef.current;
     if(el.offsetWidth < el.scrollWidth){
       setIsOverflow(true);
     }
   }, []); */

  const fabStyle = {
    position: 'fixed',
    bottom: 0,
    right: 16,
  };
  const bottomref = useRef(null);

  const scrolToBottom = () => {
    bottomref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  /** @type [TepngUser, any, any] */
  const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");

/** @type DossierComment */ let emptyDossierComment = {
    id: undefined,
    email: undefined,
    role: undefined,
    comments: "",
    slideId: props.cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing ? getSlideName(props.cpscRequest, props.currentSlideNumber) : null,
    parentId: null,
    createdDate: undefined,
    updatedDate: undefined,
  }


  /** @type [DossierComment, import('react').Dispatch<import('react').SetStateAction<DossierComment>>] */
  const [dossierCommentToEdit, setDossierCommentToEdit] = useState(emptyDossierComment);
  /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
  const [commentIdToReply, setCommentIdToReply] = useState(null);
  /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
  const [commentIdToEdit, setCommentIdToEdit] = useState(null);

  /** @param {DossierComment} dossierComment, @returns string */
  function getRootId(dossierComment) {
    if (dossierComment.parentId == null) return dossierComment.id
    return getRootId(props.cpscRequest.dossierComments.find(e => e.id === dossierComment.parentId))
  }

  useEffect(() => {
    setDossierCommentToEdit(dossierCommentToEdit => ({ ...dossierCommentToEdit, slideId: props.cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing ? getSlideName(props.cpscRequest, props.currentSlideNumber) : null }))
  }, [props.currentSlideNumber, props.cpscRequest])

  function postComment(e) {
    if (dossierCommentToEdit.id == null) {
      callServerAPI('POST', `/api/DossierComment/CreateDossierComment?requestId=${props.cpscRequest.id}`, dossierCommentToEdit, true)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<DossierComment>> } */ response) {
          props.setCpscRequest(cpscRequest => ({ ...cpscRequest, dossierComments: [...cpscRequest.dossierComments, response.data.data] }))
          clearCommentEdit()
        })
    }
    else {
      callServerAPI('PATCH', `/api/DossierComment/UpdateDossierComment?requestId=${props.cpscRequest.id}&commentId=${dossierCommentToEdit.id}`, dossierCommentToEdit, true)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
          props.setCpscRequest(cpscRequest => ({ ...cpscRequest, dossierComments: cpscRequest.dossierComments.map(inst => inst.id !== dossierCommentToEdit.id ? inst : dossierCommentToEdit) }))
          clearCommentEdit()
        })

    }
  }

  /** @param {string} commentId */
  const deleteComment = (commentId) => {
    callServerAPI('DELETE', `/api/DossierComment/DeleteDossierComment?requestId=${props.cpscRequest.id}&commentId=${commentId}`, null, true)
      .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<DossierComment>> } */ response) {
        props.setCpscRequest(cpscRequest => ({ ...cpscRequest, dossierComments: cpscRequest.dossierComments.filter(inst => inst.id !== commentId) }))
      })
  }

  const updateDossierCommentDataField = (name, value) => {
    setDossierCommentToEdit(dossierCommentToEdit => ({ ...dossierCommentToEdit, [name]: value }));
  }

  /** @param {DossierComment} dossierComment */
  const editComment = (dossierComment) => {
    setDossierCommentToEdit(dossierComment);
    setCommentIdToEdit(dossierComment.id)
  }

  const clearCommentEdit = () => {
    setDossierCommentToEdit({ ...emptyDossierComment })
    setCommentIdToReply(null)
    setCommentIdToEdit(null)
  }

  /** @param {DossierComment} dossierComment */
  const replyComment = (dossierComment) => {
    setDossierCommentToEdit(dossierCommentToEdit => ({ ...dossierCommentToEdit, parentId: dossierComment.id, slideId: dossierComment.slideId }));
    setCommentIdToReply(dossierComment.id)
  }

  const getReplyCommentBox = () => {
    return (
      <div key={dossierCommentToEdit.id} className='px-4 py-4' >
        <div className='close'> <i className="fa fa-close" onClick={clearCommentEdit}></i></div>
        <Avatar sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(loggedOnTepngUser.email), }}> {getNameInitialsFromTotalEnergiesEmail(loggedOnTepngUser.email)}</Avatar>&nbsp;&nbsp;
        <textarea style={{ fontSize: 12 }} value={dossierCommentToEdit.comments} onChange={(e) => updateDossierCommentDataField(e.target.id, e.target.value)}
          className="message-input" id="comments" />
        <Button variant='contained' size="small" style={{ float: 'right' }} onClick={postComment}> Reply </Button>
      </div>
    )
  }



  const getEditCommentBox = () => {
    return (
      <div key={dossierCommentToEdit.id} className='px-4 py-4'>
        <div className='close'> <i className="fa fa-close" onClick={clearCommentEdit}></i></div>
        <Avatar sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(loggedOnTepngUser.email), }}> {getNameInitialsFromTotalEnergiesEmail(loggedOnTepngUser.email)}</Avatar>&nbsp;&nbsp;
        <textarea style={{ fontSize: 12 }} value={dossierCommentToEdit.comments} onChange={(e) => updateDossierCommentDataField(e.target.id, e.target.value)}
          className="message-input" id="comments" />
        <Button variant='contained' size="small" style={{ float: 'right' }} onClick={postComment}> Update</Button>
      </div>
    )
  }

  function stringToColor(text) {
    let hash = 0; let i; let color = '#';
    for (i = 0; i < text.length; i += 1)
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  return (
    <>
      {props.cpscRequest.status === CpscRequestStatus.DossierPreparationOngoing &&
        <>
          {canPostNewComment(props.cpscRequest, loggedOnTepngUser) &&
            <div className='comment-single px-4 py-4' >

              <div className="comment-form-row">
                <div className='init'>
                  <Avatar sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(loggedOnTepngUser.email), }}> {getNameInitialsFromTotalEnergiesEmail(loggedOnTepngUser.email)}</Avatar>&nbsp;&nbsp;
                  <textarea style={{ fontSize: 12 }} value={commentIdToReply != null || commentIdToEdit != null ? '' : dossierCommentToEdit.comments}
                    onChange={(e) => updateDossierCommentDataField(e.target.id, e.target.value)} disabled={commentIdToReply != null || commentIdToEdit != null}
                    className="message-input" id="comments" />

                </div>
                <input type="checkbox" checked={dossierCommentToEdit.slideId == null} disabled={dossierCommentToEdit.id != null || dossierCommentToEdit.parentId != null}
                  onChange={() => updateDossierCommentDataField("slideId", dossierCommentToEdit.slideId == null ? getSlideName(props.cpscRequest, props.currentSlideNumber) : null)} />
                <span style={{ fontSize: '11px' }}>&nbsp;<i>Global Comment <span style={{ fontSize: '11px', color: 'red' }}>(comment is applicable to all slides)</span></i></span>
                <br />
                <br />
                <div className='d-flex flex-row-reverse'>
                  <Button variant='contained' size="small" onClick={postComment} disabled={commentIdToReply != null || commentIdToEdit != null}> Post </Button>
                </div>
              </div>
            </div>
          }

          <div className="error-msg">{ }</div>

          <div className='comment-feed'>
            {props.cpscRequest.dossierComments.filter(e => e.parentId == null && (e.slideId == null || e.slideId === getSlideName(props.cpscRequest, props.currentSlideNumber))).sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : 1).map(dossierComment =>
              <Fragment key={dossierComment.id}>
                <br />
                {commentIdToEdit === dossierComment.id ? getEditCommentBox() :
                  <div key={dossierComment.id} className='comment-single-reply px-2 py-2 comment-text'>
                    <div className='init'>
                      <Avatar title={dossierComment.role} sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(dossierComment.email), }}> {getNameInitialsFromTotalEnergiesEmail(dossierComment.email)}</Avatar>&nbsp;
                      <a href={'mailto:' + dossierComment.email}><b className='comment-name'>{getNameFromTotalEnergiesEmail(dossierComment.email)}</b> &nbsp;</a>
                      &nbsp; <span title={formatShortDateString(dossierComment.createdDate, true)} className='date'>{formatShortDateString(dossierComment.createdDate)}</span><br />

                    </div>

                    {dossierComment.comments} <br /><br />
                    <div style={{ textAlign: 'right' }}>
                      <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'edit_' + dossierComment.id} onClick={() => editComment(dossierComment)} hidden={!commentCanBeEditOrDeleted(dossierComment, props.cpscRequest, loggedOnTepngUser)}><Edit fontSize="inherit" />&nbsp;Edit</Button>&nbsp;
                      <Button color='secondary' style={{ textTransform: 'none' }} variant='text' size="small" key={'delete_' + dossierComment.id} onClick={() => deleteComment(dossierComment.id)} hidden={!commentCanBeEditOrDeleted(dossierComment, props.cpscRequest, loggedOnTepngUser)}><Delete fontSize="inherit" />&nbsp;Delete</Button>&nbsp;
                      <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'reply_' + dossierComment.id} onClick={() => replyComment(dossierComment)}><Reply fontSize="inherit" />&nbsp;Reply</Button>&nbsp;
                    </div>
                    {commentIdToReply === dossierComment.id && getReplyCommentBox()}
                  </div>
                }

                {props.cpscRequest.dossierComments.filter(e => getRootId(e) === dossierComment.id && e.parentId != null).sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? 1 : -1).map(dossierChildComment => {
                  const dossierCommentParent = props.cpscRequest.dossierComments.find(e => e.id === dossierChildComment.parentId)
                  return (
                    <Fragment key={dossierChildComment.id}>
                      <br />
                      <div key={dossierChildComment.id} className='comment-single-reply-child px-2 py-2 comment-text'>
                        {commentIdToEdit === dossierChildComment.id ? getEditCommentBox() :
                          <>
                            <div className='init'>
                              <Avatar title={dossierChildComment.role} sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(dossierChildComment.email), }}> {getNameInitialsFromTotalEnergiesEmail(dossierChildComment.email)}</Avatar>&nbsp;
                              <a href={'mailto:' + dossierChildComment.email}><b className='comment-name'>{getNameFromTotalEnergiesEmail(dossierChildComment.email)}</b> &nbsp;</a>
                              &nbsp;  <span title={formatShortDateString(dossierChildComment.createdDate, true)} className='date'>{formatShortDateString(dossierChildComment.createdDate)}</span><br />
                            </div>


                            {dossierChildComment.parentId && <a href={'mailto:' + dossierCommentParent.email}>{'@' + getNameFromTotalEnergiesEmail(dossierCommentParent.email)} &nbsp;</a>}
                            {dossierChildComment.comments} <br />  <br />
                            <div style={{ textAlign: 'right' }}>
                              <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'edit_' + dossierChildComment.id} onClick={() => editComment(dossierChildComment)} hidden={!commentCanBeEditOrDeleted(dossierChildComment, props.cpscRequest, loggedOnTepngUser)}> <Edit fontSize="inherit" />&nbsp;Edit</Button>&nbsp;
                              <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'delete_' + dossierChildComment.id} onClick={() => deleteComment(dossierChildComment.id)} hidden={!commentCanBeEditOrDeleted(dossierChildComment, props.cpscRequest, loggedOnTepngUser)}> <Delete fontSize="inherit" /> &nbsp;Delete</Button>&nbsp;
                              <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'reply_' + dossierChildComment.id} onClick={() => replyComment(dossierChildComment)}> <Reply fontSize="inherit" />&nbsp;Reply</Button>&nbsp;
                            </div>
                            {commentIdToReply === dossierChildComment.id && getReplyCommentBox()}
                          </>
                        }
                      </div>
                    </Fragment>
                  )
                })}
              </Fragment>
            )}
          </div>
        </>
      }
      {props.cpscRequest.status !== CpscRequestStatus.DossierPreparationOngoing &&
        <>
          {canPostNewComment(props.cpscRequest, loggedOnTepngUser) &&
            <div className='comment-single px-4 py-4' >
              <div className='comment-form-row'>
                {!rejectionCommentExistsForUser(props.cpscRequest, loggedOnTepngUser) && <div style={{ marginBottom: 10, textAlign: 'center', color: 'red' }}><em>Comment is required to reject dossier</em></div>}
                <div >
                  <label className='title-label'>Comment applies to:<span className='important'>*</span></label>
                </div>
                <div className=''>
                  <div className="form-group mb-0">
                    <Select fullWidth variant="outlined" size="small" name="slideId" value={dossierCommentToEdit.slideId == null ? "All Slides" : dossierCommentToEdit.slideId} onChange={(e) => setDossierCommentToEdit(dossierCommentToEdit => ({ ...dossierCommentToEdit, slideId: e.target.value === "All Slides" ? null : e.target.value }))}>
                      <MenuItem value="All Slides">All Slides</MenuItem >
                      {props.cpscRequest.dossierSlideSequence.map((slide, index) => {
                        return <MenuItem key={index} value={slide.dossierSlideName}>{slide.dossierSlideName}</MenuItem >
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <br />
              <div className='init'>
                <Avatar sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(loggedOnTepngUser.email), }}> {getNameInitialsFromTotalEnergiesEmail(loggedOnTepngUser.email)}</Avatar>&nbsp;&nbsp;
                <textarea style={{ fontSize: 12 }} value={commentIdToReply != null || commentIdToEdit != null ? '' : dossierCommentToEdit.comments}
                  onChange={(e) => updateDossierCommentDataField(e.target.id, e.target.value)} disabled={commentIdToReply != null || commentIdToEdit != null}
                  className="message-input" id="comments" />
              </div>
              <br />
              <div className='d-flex flex-row-reverse'>
                <Button variant='contained' size="small" onClick={postComment} disabled={commentIdToReply != null || commentIdToEdit != null}> Post </Button>
              </div>

            </div>
          }
          <div className='comment-feed'>
            <div ref={heightRef}>
              {props.cpscRequest.dossierComments.filter(e => e.parentId == null).sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : 1).map(dossierComment =>
                <Fragment key={dossierComment.id}>
                  <br />
                  {commentIdToEdit === dossierComment.id ? getEditCommentBox() :
                    <div key={dossierComment.id} className='comment-single-reply px-2 py-2 comment-text'>
                      <div className='init'>
                        <Avatar title={dossierComment.role} sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(dossierComment.email), }}> {getNameInitialsFromTotalEnergiesEmail(dossierComment.email)}</Avatar>&nbsp;
                        <a href={'mailto:' + dossierComment.email}><b className='comment-name'>{getNameFromTotalEnergiesEmail(dossierComment.email)}</b> &nbsp;</a>
                        &nbsp; <span title={formatShortDateString(dossierComment.createdDate, true)} className='date'>{formatShortDateString(dossierComment.createdDate)}</span><br />

                      </div>
                      <div style={{ fontSize: 11, color: 'blue', textAlign: 'right' }}><i>{dossierComment.slideId}</i></div>
                      {dossierComment.comments} <br /><br />
                      <div style={{ textAlign: 'right' }}>
                        <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'edit_' + dossierComment.id} onClick={() => editComment(dossierComment)} hidden={!commentCanBeEditOrDeleted(dossierComment, props.cpscRequest, loggedOnTepngUser)}><Edit fontSize="inherit" />&nbsp;Edit</Button>&nbsp;
                        <Button color='secondary' style={{ textTransform: 'none' }} variant='text' size="small" key={'delete_' + dossierComment.id} onClick={() => deleteComment(dossierComment.id)} hidden={!commentCanBeEditOrDeleted(dossierComment, props.cpscRequest, loggedOnTepngUser)}><Delete fontSize="inherit" />&nbsp;Delete</Button>&nbsp;
                        <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'reply_' + dossierComment.id} onClick={() => replyComment(dossierComment)}><Reply fontSize="inherit" />&nbsp;Reply</Button>&nbsp;
                      </div>
                      {commentIdToReply === dossierComment.id && getReplyCommentBox()}
                    </div>
                  }

                  {props.cpscRequest.dossierComments.filter(e => getRootId(e) === dossierComment.id && e.parentId != null).sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? 1 : -1).map(dossierChildComment => {
                    const dossierCommentParent = props.cpscRequest.dossierComments.find(e => e.id === dossierChildComment.parentId)
                    return (
                      <Fragment key={dossierChildComment.id}>
                        <br />
                        <div key={dossierChildComment.id} className='comment-single-reply-child px-2 py-2 comment-text'>
                          {commentIdToEdit === dossierChildComment.id ? getEditCommentBox() :
                            <>
                              <div className='init'>
                                <Avatar title={dossierChildComment.role} sx={{ fontSize: 12, width: 24, height: 24, bgcolor: stringToColor(dossierChildComment.email), }}> {getNameInitialsFromTotalEnergiesEmail(dossierChildComment.email)}</Avatar>&nbsp;
                                <a href={'mailto:' + dossierChildComment.email}><b className='comment-name'>{getNameFromTotalEnergiesEmail(dossierChildComment.email)}</b> &nbsp;</a>
                                &nbsp;  <span title={formatShortDateString(dossierChildComment.createdDate, true)} className='date'>{formatShortDateString(dossierChildComment.createdDate)}</span><br />
                              </div>


                              {dossierChildComment.parentId && <a href={'mailto:' + dossierCommentParent.email}>{'@' + getNameFromTotalEnergiesEmail(dossierCommentParent.email)} &nbsp;</a>}
                              {dossierChildComment.comments} <br />  <br />
                              <div style={{ textAlign: 'right' }}>
                                <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'edit_' + dossierChildComment.id} onClick={() => editComment(dossierChildComment)} hidden={!commentCanBeEditOrDeleted(dossierChildComment, props.cpscRequest, loggedOnTepngUser)}> <Edit fontSize="inherit" />&nbsp;Edit</Button>&nbsp;
                                <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'delete_' + dossierChildComment.id} onClick={() => deleteComment(dossierChildComment.id)} hidden={!commentCanBeEditOrDeleted(dossierChildComment, props.cpscRequest, loggedOnTepngUser)}> <Delete fontSize="inherit" /> &nbsp;Delete</Button>&nbsp;
                                <Button style={{ textTransform: 'none' }} variant='text' size="small" key={'reply_' + dossierChildComment.id} onClick={() => replyComment(dossierChildComment)}> <Reply fontSize="inherit" />&nbsp;Reply</Button>&nbsp;
                              </div>
                              {commentIdToReply === dossierChildComment.id && getReplyCommentBox()}
                            </>
                          }
                        </div>
                      </Fragment>
                    )
                  })}
                </Fragment>
              )}
            </div>
            <br />
            {isOverflow &&
              <Fab size='small' sx={fabStyle} color="default" onClick={scrolToBottom} aria-label="add">
                <ExpandMore />
              </Fab>
            }
            <div ref={bottomref} />
          </div>
        </>
      }
    </>

  )
}

export default DossierCommentsEditor