const { DossierSlideName, CpscCategory, DossierSlideRequirement, HseRiskLevel, ContractModificationRequest } = require("./Constants");
const { isNullOrWhiteSpace } = require("./Helpers");

/* @param {CpscRequest} cpscRequest, @param {string} dossierSlideName, @return {string} */
export function getDossierSlideRequirement(cpscRequest, dossierSlideName) {
    if (cpscRequest.isAbridgedDossier)
        return getDossierSlideRequirementAbridged(cpscRequest, dossierSlideName);
    else
        return getDossierSlideRequirementNormal(cpscRequest, dossierSlideName);
}

/** @param {CpscRequest} cpscRequest, @param {string} dossierSlideName, @return {string} */
function getDossierSlideRequirementAbridged(cpscRequest, dossierSlideName) {
    if (cpscRequest.cpscCategory === CpscCategory.Strategy) {
        if (dossierSlideName === DossierSlideName.PurposeAndDescription) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProjectOperationsPlanning) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ProcurementStrategy) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProcurementStrategyHighlights) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractHistory) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProposedBidders) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.RisksAndOpportunities) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.CfrInitiatives) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.SavingsCostOptimization) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.MarketIntelligence) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluationCriteria) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.HseEvaluationCriteria) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.HseEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.CommercialEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluationCriteria) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ContractualEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualProvisions) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractSpendEstimation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ApprovalGovernance) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.Recommendation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.BudgetPlanningInformation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.BudgetConfirmation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.RiskLevelAssessment) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.HighRiskServices) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.DueDiligenceHumanRights) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.NigerianContent) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.CatManInvolvement) return DossierSlideRequirement.Optional;
    }
    else if (cpscRequest.cpscCategory === CpscCategory.Award) {
        if (dossierSlideName === DossierSlideName.PurposeAndDescription) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProjectOperationsPlanning) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ProcurementStrategy) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProcurementStrategyHighlights) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ContractHistory) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProposedBidders) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.RisksAndOpportunities) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.CfrInitiatives) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.SavingsCostOptimization) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.MarketIntelligence) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.CommercialEvaluation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ContractualEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ContractualProvisions) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractSpendEstimation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ApprovalGovernance) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.Recommendation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.BudgetPlanningInformation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.BudgetConfirmation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.RiskLevelAssessment) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.HighRiskServices) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.DueDiligenceHumanRights) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.NigerianContent) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.CatManInvolvement) return DossierSlideRequirement.Optional;
    }
    else if (cpscRequest.cpscCategory === CpscCategory.PostAward) {
        if (dossierSlideName === DossierSlideName.PurposeAndDescription) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProjectOperationsPlanning) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ProcurementStrategy) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProcurementStrategyHighlights) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractHistory) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProposedBidders) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.RisksAndOpportunities) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.CfrInitiatives) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.SavingsCostOptimization) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.MarketIntelligence) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.CommercialEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualProvisions) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ContractSpendEstimation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ApprovalGovernance) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.Recommendation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.BudgetPlanningInformation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.BudgetConfirmation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.RiskLevelAssessment) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.HighRiskServices) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.DueDiligenceHumanRights) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.NigerianContent) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.CatManInvolvement) return DossierSlideRequirement.Optional;
    }
    if (cpscRequest.adhocDossierSlides.find(e => e.slideName === dossierSlideName) != null) return DossierSlideRequirement.Optional;
    alert(`Invalid Dossier Slide Name: ${dossierSlideName}`);
}


/** @param {CpscRequest} cpscRequest, @param {string} dossierSlideName, @return {string} */
function getDossierSlideRequirementNormal(cpscRequest, dossierSlideName) {
    if (cpscRequest.cpscCategory === CpscCategory.Strategy) {
        if (dossierSlideName === DossierSlideName.PurposeAndDescription) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProjectOperationsPlanning) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ProcurementStrategy) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProcurementStrategyHighlights) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractHistory) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProposedBidders) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.RisksAndOpportunities) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.CfrInitiatives) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.SavingsCostOptimization) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.MarketIntelligence) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluationCriteria) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.HseEvaluationCriteria) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.HseEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.CommercialEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluationCriteria) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ContractualEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualProvisions) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractSpendEstimation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ApprovalGovernance) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.Recommendation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.BudgetPlanningInformation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.BudgetConfirmation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.RiskLevelAssessment) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.HighRiskServices) return cpscRequest.dossier.approvalGovernanceSlide.hseRiskLevelStatus === HseRiskLevel.High ? DossierSlideRequirement.Mandatory : DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.DueDiligenceHumanRights) return cpscRequest.contractors.length > 0 ? DossierSlideRequirement.Mandatory : DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.NigerianContent) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.CatManInvolvement) return DossierSlideRequirement.Optional;
    }
    else if (cpscRequest.cpscCategory === CpscCategory.Award) {
        if (dossierSlideName === DossierSlideName.PurposeAndDescription) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProjectOperationsPlanning) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ProcurementStrategy) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProcurementStrategyHighlights) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ContractHistory) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProposedBidders) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.RisksAndOpportunities) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.CfrInitiatives) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.SavingsCostOptimization) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.MarketIntelligence) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.CommercialEvaluation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ContractualEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ContractualProvisions) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractSpendEstimation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ApprovalGovernance) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.Recommendation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.BudgetPlanningInformation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.BudgetConfirmation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.RiskLevelAssessment) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.HighRiskServices) return cpscRequest.dossier.approvalGovernanceSlide.hseRiskLevelStatus === HseRiskLevel.High ? DossierSlideRequirement.Mandatory : DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.DueDiligenceHumanRights) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.NigerianContent) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.CatManInvolvement) return DossierSlideRequirement.Optional;
    }
    else if (cpscRequest.cpscCategory === CpscCategory.PostAward) {
        if (dossierSlideName === DossierSlideName.PurposeAndDescription) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProjectOperationsPlanning) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.ProcurementStrategy) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ProcurementStrategyHighlights) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractHistory) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ProposedBidders) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.RisksAndOpportunities) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.CfrInitiatives) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.SavingsCostOptimization) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.MarketIntelligence) return cpscRequest.contractModificationRequest === ContractModificationRequest.Extension ? DossierSlideRequirement.Optional : DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.HseEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.TechnicalEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.CommercialEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluationCriteria) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualEvaluation) return DossierSlideRequirement.NotApplicable;
        if (dossierSlideName === DossierSlideName.ContractualProvisions) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ContractSpendEstimation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.ApprovalGovernance) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.Recommendation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.BudgetPlanningInformation) return DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.BudgetConfirmation) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.RiskLevelAssessment) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.HighRiskServices) return cpscRequest.dossier.approvalGovernanceSlide.hseRiskLevelStatus === HseRiskLevel.High ? DossierSlideRequirement.Mandatory : DossierSlideRequirement.Optional;
        if (dossierSlideName === DossierSlideName.DueDiligenceHumanRights) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.NigerianContent) return DossierSlideRequirement.Mandatory;
        if (dossierSlideName === DossierSlideName.CatManInvolvement) return DossierSlideRequirement.Optional;
    }
    if (cpscRequest.adhocDossierSlides.find(e => e.slideName === dossierSlideName) != null) return DossierSlideRequirement.Optional;
    alert(`Invalid Dossier Slide Name: ${dossierSlideName}`);
}

/** @param {CpscRequest} cpscRequest, @param {string} dossierSlideName, @return {boolean} */
export function isSlideEmpty(cpscRequest, dossierSlideName) {
    if (dossierSlideName === DossierSlideName.PurposeAndDescription) return false; //never empty
    if (dossierSlideName === DossierSlideName.ProjectOperationsPlanning) return isNullOrWhiteSpace(cpscRequest.dossier.projectOperationsPlanningSlide.details);
    if (dossierSlideName === DossierSlideName.ProcurementStrategy) return isNullOrWhiteSpace(cpscRequest.dossier.procurementStrategySlide.details);
    if (dossierSlideName === DossierSlideName.ProcurementStrategyHighlights) return isNullOrWhiteSpace(cpscRequest.dossier.procurementStrategyHighlightsSlide.details);
    if (dossierSlideName === DossierSlideName.ContractHistory) return cpscRequest.dossier.contractHistorySlide.contractHistories.length === 0;
    if (dossierSlideName === DossierSlideName.ProposedBidders) return !cpscRequest.proposedBiddersSlideUploaded && cpscRequest.dossier.proposedBiddersSlide.bidders.length === 0;
    if (dossierSlideName === DossierSlideName.RisksAndOpportunities) return cpscRequest.dossier.risksAndOpportunitiesSlide.risks.length === 0;
    if (dossierSlideName === DossierSlideName.CfrInitiatives) return isNullOrWhiteSpace(cpscRequest.dossier.cfrInitiativesSlide.details);
    if (dossierSlideName === DossierSlideName.SavingsCostOptimization) return !cpscRequest.savingsCostOptimizationSlideUploaded && isNullOrWhiteSpace(cpscRequest.dossier.savingsCostOptimizationSlide.savingsContext);
    if (dossierSlideName === DossierSlideName.MarketIntelligence) return !cpscRequest.marketIntelligenceSlideUploaded && !cpscRequest.costAndMarketTrendChartUploaded && isNullOrWhiteSpace(cpscRequest.dossier.marketIntelligenceSlide.costDetails) && isNullOrWhiteSpace(cpscRequest.dossier.marketIntelligenceSlide.keyCostDrivers) && isNullOrWhiteSpace(cpscRequest.dossier.marketIntelligenceSlide.benchmark);
    if (dossierSlideName === DossierSlideName.TechnicalEvaluationCriteria) return cpscRequest.dossier.technicalEvaluationCriteriaSlide.technicalEvaluationCriteria.length === 0;
    if (dossierSlideName === DossierSlideName.HseEvaluationCriteria) return cpscRequest.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria.length === 0;
    if (dossierSlideName === DossierSlideName.HseEvaluation) return !cpscRequest.hseEvaluationSlideUploaded && cpscRequest.dossier.hseEvaluationSlide.hseEvaluationRatings.length === 0;
    if (dossierSlideName === DossierSlideName.TechnicalEvaluation) return !cpscRequest.technicalEvaluationSlideUploaded && cpscRequest.dossier.technicalEvaluationSlide.technicalEvaluationRatings.length === 0;
    if (dossierSlideName === DossierSlideName.CommercialEvaluation) return !cpscRequest.commercialEvaluationSlideUploaded && cpscRequest.dossier.commercialEvaluationSlide.commercialEvaluationRatings.length === 0;
    if (dossierSlideName === DossierSlideName.ContractualEvaluationCriteria) return isNullOrWhiteSpace(cpscRequest.dossier.contractualEvaluationCriteriaSlide.details);
    if (dossierSlideName === DossierSlideName.ContractualEvaluation) return isNullOrWhiteSpace(cpscRequest.dossier.contractualEvaluationSlide.details);
    if (dossierSlideName === DossierSlideName.ContractualProvisions) return isNullOrWhiteSpace(cpscRequest.dossier.contractualProvisionsSlide.details);
    if (dossierSlideName === DossierSlideName.ContractSpendEstimation) return isNullOrWhiteSpace(cpscRequest.dossier.contractSpendEstimationSlide.contractSpendEstimation) && isNullOrWhiteSpace(cpscRequest.dossier.contractSpendEstimationSlide.additionalComments);
    if (dossierSlideName === DossierSlideName.ApprovalGovernance) return false; //never empty
    if (dossierSlideName === DossierSlideName.Recommendation) return false; //never empty
    if (dossierSlideName === DossierSlideName.BudgetPlanningInformation) return Number(cpscRequest.dossier.budgetPlanningInformationSlide.currentYearNUIMSBudget) === 0 && Number(cpscRequest.dossier.budgetPlanningInformationSlide.currentYearCorporateBudget) === 0 && isNullOrWhiteSpace(cpscRequest.dossier.budgetPlanningInformationSlide.noBudget) && isNullOrWhiteSpace(cpscRequest.dossier.budgetPlanningInformationSlide.noBudget);
    if (dossierSlideName === DossierSlideName.BudgetConfirmation) return !cpscRequest.budgetConfirmationSlideUploaded;
    if (dossierSlideName === DossierSlideName.RiskLevelAssessment) return !cpscRequest.riskLevelAssessmentSlideUploaded;
    if (dossierSlideName === DossierSlideName.HighRiskServices) return cpscRequest.dossier.highRiskServicesSlide.highRisks.length === 0;
    if (dossierSlideName === DossierSlideName.DueDiligenceHumanRights) return cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.length === 0;
    if (dossierSlideName === DossierSlideName.NigerianContent) return cpscRequest.dossier.nigerianContentSlide.availableEquipmentInCountryRating === 0 && cpscRequest.dossier.nigerianContentSlide.availableMaterialInCountryRating === 0 && cpscRequest.dossier.nigerianContentSlide.availableNigerianVendorsRating === 0 && cpscRequest.dossier.nigerianContentSlide.expectedInCountryRetentionRating === 0 && cpscRequest.dossier.nigerianContentSlide.jobComplexityRating === 0 && cpscRequest.dossier.nigerianContentSlide.requiredForeignExpertiseRating === 0;
    if (dossierSlideName === DossierSlideName.CatManInvolvement) return !cpscRequest.catManInvolvementSlideUploaded;
    if (cpscRequest.adhocDossierSlides.find(e => e.slideName === dossierSlideName) != null) return false;
    alert(`Invalid Dossier Slide Name: ${dossierSlideName}`);
}