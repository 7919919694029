import React, { useEffect, useState } from 'react';
import { Button, Stack, Pagination, Grid, MenuItem, TextField, Box, Paper, } from '@mui/material';
import Swal from 'sweetalert2'
import { getNameFromTotalEnergiesEmail, callServerAPI, canEditCPDomain } from '../../helpers/Helpers';
import { preloader, FormEditMode, globalStore, GlobalRole } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';

import Table from 'react-bootstrap/Table';
import Replay from '@mui/icons-material/Replay';
import { Add } from '@mui/icons-material';
import CreateEditCPDomain from './CreateEditCPDomain';
import Header from '../../components/TitleHeader';

const MySwal = withReactContent(Swal);
function CPDomainList() {
    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [cpDomainsIdToEdit, setCpDomainsIdToEdit] = useState(null)
    /** @type [CPDomain[],import('react').Dispatch<import('react').SetStateAction<CPDomain[]>>] ] */
    const [cpDomains, setCpDomains] = useState([]);
    const [cpDomainsEditMode, setCpDomainsEditMode] = useState(FormEditMode.CREATE);
    /** @type [CPDomain[],import('react').Dispatch<import('react').SetStateAction<CPDomain[]>>] ] */
    const [filteredCpDomains, setFilteredCpDomains] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [numOfPages, setNumOfPages] = useState(0);
    /** @type [CPDomain[],import('react').Dispatch<import('react').SetStateAction<CPDomain[]>>] ] */
    const [currentPageRecords, setCurrentPageRecords] = useState([]);
    const [openCreateCpDomainsForm, setOpenCreateCpDomainsForm] = React.useState(false);

    const handleOpenCreateCPDomainForm = () => {
        setCpDomainsEditMode(FormEditMode.CREATE);
        setOpenCreateCpDomainsForm(true);
    };
    const getAllCPDomains = async () => {
        MySwal.fire(preloader);
        callServerAPI('GET', '/api/CPDomain/GetAllCPDomains', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CPDomain[]>> } */ response) {
                setCpDomains(response.data.data);
                setFilteredCpDomains(response.data.data);
                MySwal.close();
            })

    }
    function refreshRequests() {
        getAllCPDomains();
    }
    useEffect(() => {
        getAllCPDomains();
    }, [])

    useEffect(() => {
        setFilteredCpDomains(cpDomains.filter(e =>

            (searchQuery === "" || e.title.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, cpDomains])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredCpDomains.length / recordsPerPage))
    }, [recordsPerPage, filteredCpDomains])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredCpDomains.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredCpDomains])

    /** @param {CPDomain} cpDomain */
    const handleEdit = (cpDomain) => {
        setCpDomainsIdToEdit(cpDomain.id)
        setCpDomainsEditMode(FormEditMode.EDIT);
        setOpenCreateCpDomainsForm(true);
    }
    return (
        <Box m="1.0rem 1.0rem">
            <Header title="C&P Domain List" />
            <Box>
                <Paper>
                    <Box mt="20px" p='1.0rem' gap="20px">
                        <Grid container className=' d-flex  justify-content-between'>

                            <Grid item xs={12} sm={6} lg={8}  >
                                <div className='mb-2 mt-1' >
                                    <input type="text" className="form-control input-font bold" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search by Title...' />
                                </div>

                            </Grid>
                            <Grid style={{ float: 'right' }} item xs={12} sm={4} lg={4} >
                                <div className=' d-flex  justify-content-end'>
                                    <Button variant="contained" onClick={refreshRequests} size='medium' style={{ textTransform: 'none', fontSize: 13 }}> <Replay />&nbsp; Refresh</Button>&nbsp;
                                    <Button disabled={!loggedOnTepngUser?.roles?.includes(GlobalRole.Admin)} variant="contained" color='secondary' style={{ textTransform: 'none', fontSize: 13 }} onClick={handleOpenCreateCPDomainForm} > <Add /> &nbsp;New C&P Domain</Button>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table className='table-res' responsive striped bordered >
                                    <thead style={{ fontSize: '11px', }}>
                                        <tr>
                                            <th >SN</th>
                                            <th>Title</th>
                                            <th>Contract Engineers</th>
                                            <th>Domain Manager</th>
                                            <th>General Manager</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageRecords.map((cpDomain, index) => {
                                            return <tr style={{ fontSize: '11px', }} className='trtext' key={index}>
                                                <td>{index + 1 + ((currentPage - 1) * recordsPerPage)}</td>
                                                <td >{cpDomain.title}</td>

                                                <td>{cpDomain.contractEngineersList.map((name, index) => {
                                                    return (
                                                        <div key={index}>{getNameFromTotalEnergiesEmail(name)}</div>
                                                    )
                                                })}</td>
                                                <td>{cpDomain.domainManagersList.map((name, index) => {
                                                    return (
                                                        <div key={index}>{getNameFromTotalEnergiesEmail(name)}</div>
                                                    )
                                                })}</td>
                                                <td>{cpDomain.generalManagersList.map((name, index) => {
                                                    return (
                                                        <div key={index}>{getNameFromTotalEnergiesEmail(name)}</div>
                                                    )
                                                })}</td>
                                                <td> <Button disabled={!canEditCPDomain(cpDomain, loggedOnTepngUser)} variant='contained' size='small' color="secondary" onClick={() => handleEdit(cpDomain)} >Edit</Button>&nbsp;&nbsp;</td>

                                            </tr>
                                        })}
                                        {currentPageRecords.length === 0 ? <tr>
                                            <td colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No records....</p></td>
                                        </tr> : ''}


                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>

                        <br />
                        <nav>
                            <div className=" d-flex justify-content-between ">
                                <div className=" d-flex justify-content-between ">
                                    <div className='rowperpage'>Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} style={{ width: "60%" }}>
                                        <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </TextField>
                                    </Grid>
                                </div>
                                <Stack spacing={2}>
                                    <Pagination color="primary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>
                            </div>
                            <br />


                        </nav>

                        {openCreateCpDomainsForm &&
                            <CreateEditCPDomain updateCPDomains={cpDomains => setCpDomains(cpDomains)} cpDomains={cpDomains} cpDomainsIdToEdit={cpDomainsIdToEdit} formEditMode={cpDomainsEditMode} openCreateCpDomainsForm={openCreateCpDomainsForm} setOpenCreateCpDomainsForm={setOpenCreateCpDomainsForm} />
                        }

                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default CPDomainList