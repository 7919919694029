import React, { } from 'react'
import { MarketIntelligenceActor } from '../../helpers/Constants';
import { MarketIntelligenceSlideField, MarketIntelligenceSlideTooltip } from '../../helpers/DossierFieldConstants';
import CostAndMarketTrendUpload from './CostAndMarketTrendUpload';
import MarketIntelligenceUpload from './MarketIntelligenceUpload';
import Select from 'react-select';
import { Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../tabs/CustomTabPanel';
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

const MarketIntelligenceActors = [{ value: MarketIntelligenceActor.Triplet, label: MarketIntelligenceActor.Triplet }, { value: MarketIntelligenceActor.MarketIntelligenceRep, label: MarketIntelligenceActor.MarketIntelligenceRep }]



/**
 * @typedef {Object} MarketIntelligenceSlideProps
 * @property {downloadPdfCallback} downloadPdf
 * @property {uploadPdfCallback} uploadPdf
 * @property {downloadPdfCallback} mobileDownloadPdf
 * @property {deletePdfCallback} deletePdf
* @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
  * @property {number} dossierRefreshCount
 */

/**
 * @param {MarketIntelligenceSlideProps}  props
 * @return {import('react').ReactElement}
 */
function MarketIntelligenceSlide(props) {

  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);
 

  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, marketIntelligenceSlide: { ...props.cpscRequest.dossier.marketIntelligenceSlide, [name]: value } })
  }

  return (
   
    <>
      <div className='content-desktop'>
        <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label=" Market Intelligence Form" value={1} />
          <Tab label="Market Intelligence Upload" value={2} />
          <Tab label="Cost & Market Trend Chart Upload" value={3} />

        </Tabs>
      </div>
      <div className='content-mobile'>
        <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label=" Market Intelligence Form" value={1} />
          <Tab label="Market Intelligence Upload" value={2} />
          <Tab label="Cost & Market Trend Chart Upload" value={3} />

        </Tabs>
      </div>
      <CustomTabPanel value={selectedTabValue} index={1} >
        <div>
          <form>
            <div >
              <label className=' title-label'>Who performed the Market Intelligence? <span className='important'>*</span></label>
            </div>
            <div className=''>
              <div className="form-group mb-0">
                <Select required isDisabled={props.readOnly} classNames={{ control: (state) => state.isDisabled ? 'react-select-disabled' : '', singleValue: (state) => state.isDisabled ? 'react-select-disabled' : '' }}
                  options={MarketIntelligenceActors} value={{ label: props.cpscRequest.dossier.marketIntelligenceSlide.marketIntelligencePerformedBy, value: props.cpscRequest.dossier.marketIntelligenceSlide.marketIntelligencePerformedBy }}
                  onChange={(newValue) => updateDataFieldValue("marketIntelligencePerformedBy", newValue.value)} />
              </div>
            </div>
            <br />
            <div className="form-group">
              <label className=' title-label' >{getSlideTooltip(props.cpscRequest, MarketIntelligenceSlideTooltip.keyCostDrivers)}What are the key Cost Drivers?<span className='important'>*</span></label>
              <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.marketIntelligenceSlide.keyCostDrivers} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(MarketIntelligenceSlideField.keyCostDrivers, editorHtmlString)} />

            </div>
            <br />

            <div className="form-group">
              <label className=' title-label' >{getSlideTooltip(props.cpscRequest, MarketIntelligenceSlideTooltip.benchmark)}What is the Benchmark with current Contracts<span className='important'>*</span></label>
              <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.marketIntelligenceSlide.benchmark} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(MarketIntelligenceSlideField.benchmark, editorHtmlString)} />

            </div>
            <br />

            <div className="form-group">
              <label className=' title-label' >{getSlideTooltip(props.cpscRequest, MarketIntelligenceSlideTooltip.costDetails)}What are the Cost details requested in Contract <span className='important'>*</span></label>
              <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.marketIntelligenceSlide.costDetails} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(MarketIntelligenceSlideField.costDetails, editorHtmlString)} />

            </div>
            <br />


          </form>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabValue} index={2} >
        <div >
          <MarketIntelligenceUpload mobileDownloadPdf={props.mobileDownloadPdf} readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabValue} index={3} >
        <div >
          <CostAndMarketTrendUpload mobileDownloadPdf={props.mobileDownloadPdf} readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
        </div>
      </CustomTabPanel>

    </>

  )
}

export default MarketIntelligenceSlide
