import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import { callServerAPI, getNameFromTotalEnergiesEmail } from '../../helpers/Helpers';
import axios from 'axios';
import { Checkbox } from '@mui/material';
import CustomDialog from '../../components/dialogs/CustomDialog';
import { useSnackbar } from 'notistack';


axios.defaults.withCredentials = true;
/**
 * @typedef {Object} UserRolesProps

 * @property {String} userItemEmail
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenUserRoleForm
 * @property {Boolean} OpenUserRoleForm
 * @property {Number} formEditMode
 * @property {Function} setFormEditMode


 */

/**
 * @param {UserRolesProps}  props
 * @return {import('react').ReactElement}
 */
function UserRoles(props) {


    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [userRoles, setUserRoles] = useState([]);
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [roles, setRoles] = useState([]);
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [availableRoles, setAvailableRoles] = useState([]);
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [selectedAvailableRoles, setSelectedAvailableRoles] = useState([]);
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [selectedUserRoles, setSelectedUserRoles] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        callServerAPI('GET', '/api/Roles/GetAllRoles', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
                setRoles(response.data.data);
            })

    }, [])

    useEffect(() => {
        callServerAPI('GET', `/api/Roles/GetAllRolesForAUser?email=${props.userItemEmail}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
                setUserRoles(response.data.data);
                setAvailableRoles(roles.filter(roleName => !response.data.data.includes(roleName)))
            })

    }, [props.userItemEmail, roles])

    const assignUserToRole = async () => {
        /** @type AssignOrRemoveUserFromRolesDto **/ const dataToSend = { email: props.userItemEmail, roleNames: selectedAvailableRoles }
        callServerAPI('PATCH', '/api/Roles/AssignUserToRoles', dataToSend, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setAvailableRoles(rolesParam => rolesParam.filter(roleName => !selectedAvailableRoles.includes(roleName)));
                setUserRoles(rolesParam => [...rolesParam, ...selectedAvailableRoles]);
                setSelectedUserRoles([])
                setSelectedAvailableRoles([])
                enqueueSnackbar('User Role Updated', { variant: 'success' })
            })

    }
    const removeUserFromRole = async () => {
         /** @type AssignOrRemoveUserFromRolesDto **/ const dataToSend = { email: props.userItemEmail, roleNames: selectedUserRoles }
        callServerAPI('PATCH', '/api/Roles/RemoveUserFromRoles', dataToSend, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setUserRoles(rolesParam => rolesParam.filter(roleName => !selectedUserRoles.includes(roleName)));
                setAvailableRoles(rolesParam => [...rolesParam, ...selectedUserRoles]);
                setSelectedUserRoles([])
                setSelectedAvailableRoles([])
                enqueueSnackbar('User Role Updated', { variant: 'success' })
            })

    }

    const changeSelectedAvailableRoleNames = (event) => {
        let value = event.target.value
        let checked = event.target.checked
        if (checked === true) {
            setSelectedAvailableRoles(pre => [...pre, value])
        }
        else {
            setSelectedAvailableRoles(pre => pre.filter(e => e !== value))
        }
    }

    const changeSelectedUserRoleNames = (event) => {
        let value = event.target.value
        let checked = event.target.checked
        if (checked === true) {
            setSelectedUserRoles(pre => [...pre, value])
        }
        else {
            setSelectedUserRoles(pre => pre.filter(e => e !== value))
        }
    }

    return (
        roles && userRoles && availableRoles &&
        <>
            <CustomDialog dialogTitle={`Roles Assigned to ${getNameFromTotalEnergiesEmail(props.userItemEmail)}`} openDialog={props.OpenUserRoleForm} setOpenDialog={props.setOpenUserRoleForm}>

                <>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <td >
                                <table style={{ width: '360px' }}>
                                    <tr>
                                        <th colSpan={2} style={{ fontSize: '12px' }} >Available Roles</th>
                                    </tr>
                                    <tbody>
                                        <Paper style={{ border: '1px solid #d7d7d7', height: '200px', width: '100%', paddingRight: '5px', overflowY: 'scroll' }}>
                                            {availableRoles.map((availableRoleName, index) => {
                                                return <tr key={index} className='trtext'>
                                                    <td><Checkbox disabled={selectedUserRoles.length > 0} value={availableRoleName} checked={selectedAvailableRoles.includes(availableRoleName)} onChange={changeSelectedAvailableRoleNames} /></td>
                                                    <td>{availableRoleName}</td>
                                                </tr>
                                            })}
                                            {availableRoles.length === 0 ? <tr>
                                                <td colSpan={3}>
                                                    <div className=' text-center' style={{ height: '200px', width: '100%', overflow: 'hidden' }}>
                                                        No records....</div></td>
                                            </tr> : ''}
                                        </Paper>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                <Button variant='contained' disabled={selectedAvailableRoles.length === 0} onClick={assignUserToRole}>Add &nbsp; &gt;&gt; </Button>
                                <br />&nbsp;<br />
                                <Button color='secondary' disabled={selectedUserRoles.length === 0} variant='contained' onClick={removeUserFromRole}> &lt;&lt;&nbsp;Remove </Button>
                            </td>
                            <td >
                                <table style={{ width: '360px' }}>
                                    <tr>
                                        <th colSpan={2} style={{ fontSize: '12px' }} >User Roles</th>
                                    </tr>
                                    <tbody>
                                        <Paper style={{ border: '1px solid #d7d7d7', height: '200px', width: '100%', paddingRight: '5px', overflowY: 'scroll' }}>
                                            {userRoles.map((userRoleName, index) => {
                                                return <tr key={index} className='trtext'>

                                                    <td ><Checkbox disabled={selectedAvailableRoles.length > 0} value={userRoleName} checked={selectedUserRoles.includes(userRoleName)} onChange={changeSelectedUserRoleNames} /></td>
                                                    <td>{userRoleName}</td>

                                                </tr>
                                            })}
                                            {userRoles.length === 0 ? <tr style={{ paddingRight: '5px' }}>
                                                <td colSpan={3}>
                                                    <div className=' text-center' style={{ height: '200px', width: '100%', overflow: 'hidden' }}>
                                                        No records....</div></td>
                                            </tr> : ''}
                                        </Paper>
                                    </tbody>

                                </table>
                            </td>
                        </tr>
                    </table>

                </>

            </CustomDialog>

        </>
    )
}

export default UserRoles