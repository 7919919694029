import React, { useState } from 'react'
import { Button, MenuItem, Select, TextField } from '@mui/material';
import { YesNo, DueDiligenceStatus, YesNoOngoingNA, FormEditMode } from '../../helpers/Constants';
import { formatDateyyyymmdd } from '../../helpers/Helpers';


const mitigationMeasuresOptions = [{ value: YesNoOngoingNA.Yes, label: "Yes" }, { value: YesNoOngoingNA.No, label: "No" }, { value: YesNoOngoingNA.Ongoing, label: "Ongoing" }, { value: YesNoOngoingNA.NA, label: "NA" }]
const dueDiligenceStatusOptions = [{ value: DueDiligenceStatus.Valid }, { value: DueDiligenceStatus.ReviewOngoing }, { value: DueDiligenceStatus.RenewalOngoing }, { value: DueDiligenceStatus.NotStarted }, { value: DueDiligenceStatus.NA }]
const incumbentSupplierOptions = [{ value: YesNo.Yes, label: YesNo.Yes }, { value: YesNo.No, label: YesNo.No }]
/**
 * @typedef {Object} ProposedBiddersListFormProps
 * @property {CpscRequest} cpscRequest
 * @property {Number} formEditMode
 * @property {Bidder} bidders
 * @property {updateDossierCallback} setDossier
 * @property {Function} setDisplayBiddersForm
 * @property {Function} setDisplayAddBidders
 */

/**
 * @param {ProposedBiddersListFormProps}  props
 * @return {import('react').ReactElement}
 */

function ProposedBiddersListForm(props) {
    /** @type [Bidder, Function] */
    const [data, setData] = useState(props.bidders)


    const addBidders = (event) => {
        event.preventDefault();
        props.setDossier({ ...props.cpscRequest.dossier, proposedBiddersSlide: { ...props.cpscRequest.dossier.proposedBiddersSlide, bidders: [...props.cpscRequest.dossier.proposedBiddersSlide.bidders, data] } })
        props.setDisplayBiddersForm(false);
        props.setDisplayAddBidders(true);
    }

    const updateBidders = (event) => {
        event.preventDefault();
        props.setDossier({ ...props.cpscRequest.dossier, proposedBiddersSlide: { ...props.cpscRequest.dossier.proposedBiddersSlide, bidders: props.cpscRequest.dossier.proposedBiddersSlide.bidders.map(item => item.serialNo === props.bidders.serialNo ? data : item) } })
        props.setDisplayBiddersForm(false);
        props.setDisplayAddBidders(true);
    }

    /** @param {string} name, @param {any} value  */
    const updateDataFieldValue = (name, value) => {
        setData({ ...data, [name]: value })
    }


    /** @param {string} name, @param {any} value  */
    const updateDataFieldDateValue = (name, value) => {
        setData({ ...data, [name]: value === "" ? null : value })
    }

    const validate = (data) => {
        let notValid = (Boolean(data.vendorName) === false || Boolean(data.incumbentSupplier) === false || Boolean(data.basisOfSelection) === false || Boolean(data.qualified) === false || Boolean(data.dueDiligenceStatus) === false || Boolean(data.dueDiligenceMitigationsClosed) === false || Boolean(data.dueDiligenceExpiryDate) === false)
        return !notValid
    }
    return (
        <form>
            <div className='row'>
                <div className='col1'>
                    <div >
                        <label className='title-label'>Registered Company name <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" value={data.vendorName} onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} id='vendorName' placeholder=" Full Name of the Supplier " className='form-control input-font bold' />

                        </div>
                    </div>
                </div>
                <div className='col2'>
                    <div >
                        <label className='title-label'>Incumbent Supplier  <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <Select fullWidth variant="outlined" size="small" name="incumbentSupplier" value={data.incumbentSupplier}
                                onChange={(e) => updateDataFieldValue("incumbentSupplier", e.target.value)} >
                                {incumbentSupplierOptions.map((options, index) => {
                                    return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col1'>
                    <div >
                        <label className='title-label'>Basis of Selection <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <TextField size="small" value={data.basisOfSelection} onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} placeholder=" Basis of Selection " id='basisOfSelection' className='form-control input-font bold' />

                        </div>
                    </div>
                </div>
                <div className='col2'>
                    <div >
                        <label className='title-label'>Qualified - Sequana / AVL<span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <Select fullWidth variant="outlined" size="small" name="qualified" value={data.qualified}
                                onChange={(e) => updateDataFieldValue("qualified", e.target.value)} >
                                {incumbentSupplierOptions.map((options, index) => {
                                    return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col1'>

                    <div >
                        <label className='title-label'>Due Diligence Status<span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <Select fullWidth variant="outlined" size="small" name="dueDiligenceStatus" value={data.dueDiligenceStatus}
                                onChange={(e) => updateDataFieldValue("dueDiligenceStatus", e.target.value)} >
                                {dueDiligenceStatusOptions.map((options, index) => {
                                    return <MenuItem key={index} value={options.value}>{options.value}</MenuItem >
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='col2'>

                    <div >
                        <label className='title-label'>Due Diligence Mitigation Measures Closed?<span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <div className="form-group mb-0">
                            <Select fullWidth variant="outlined" size="small" name="dueDiligenceMitigationsClosed" value={data.dueDiligenceMitigationsClosed}
                                onChange={(e) => updateDataFieldValue("dueDiligenceMitigationsClosed", e.target.value)} >
                                {mitigationMeasuresOptions.map((options, index) => {
                                    return <MenuItem key={index} value={options.value}>{options.label}</MenuItem >
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div className=''>
                <div >
                    <label className='title-label'>Status validity end date <span className='important'>*</span></label>
                </div>
                <div className=''>
                    <div className="form-group mb-0">
                        <TextField size="small" type='date' value={formatDateyyyymmdd(data.dueDiligenceExpiryDate)} name='dueDiligenceExpiryDate' onChange={(e) => updateDataFieldDateValue("dueDiligenceExpiryDate", e.target.value)} className='form-control input-font bold' />

                    </div>
                </div>
            </div>
            <br />
            <div>
                <Button variant='contained' disabled={!validate(data)} color='success' onClick={props.formEditMode === FormEditMode.CREATE ? addBidders : updateBidders} >
                    {props.formEditMode === FormEditMode.CREATE ? "Save New Bidder record" : "Update Bidder record"}
                </Button>
            </div>
        </form>
    )
}

export default ProposedBiddersListForm