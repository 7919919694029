import React, { useEffect, useState } from 'react';
import { Button, Stack, Pagination, Grid, MenuItem, TextField, Box, Paper, Menu, } from '@mui/material';
import Swal from 'sweetalert2'
import {  callServerAPI } from '../../helpers/Helpers';
import { preloader, FormEditMode } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';

import Table from 'react-bootstrap/Table';
import Replay from '@mui/icons-material/Replay';
import { Add, Delete, RemoveRedEye } from '@mui/icons-material';

import Header from '../../components/TitleHeader';
import { useSnackbar } from 'notistack';
import CreateUser from './CreateUser';
import UserRoles from './UserRoles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

const MySwal = withReactContent(Swal);
function UsersList() {

    const { enqueueSnackbar } = useSnackbar();
    const [openUserForm, setOpenUserForm] = React.useState(false);
    const [userItemEmail, setUserItemEmail] = React.useState("");
    const [openUserRoleForm, setOpenUserRoleForm] = React.useState(false);
    const handleOpenUserForm = () => {
        setOpenUserForm(true);
    };
    /** @type [Number, Function] */
    const [userRolesEditMode, setUserRolesEditMode] = useState(FormEditMode.VIEW);
    const [userEditMode, setUserEditMode] = useState(FormEditMode.CREATE);
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    let [departments, setDepartments] = useState([]);
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    let [employeeType, setEmployeeType] = useState([]);
    /** @type [User[], import('react').Dispatch<import('react').SetStateAction<User[]>>] */
    let [users, setUser] = useState([]);

    /** @type [User[], import('react').Dispatch<import('react').SetStateAction<User[]>>] */
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [departmentsFilter, setDepartmentsFilter] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [numOfPages, setNumOfPages] = useState(0);
    /** @type [User[], import('react').Dispatch<import('react').SetStateAction<User[]>>] */
    const [currentPageRecords, setCurrentPageRecords] = useState([]);

    const getAllUsers = async () => {
        MySwal.fire(preloader);
        callServerAPI('GET', '/api/User/GetAllUsers', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<User[]>> } */ response) {
                setUser(response.data.data.sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : 1));
                setFilteredRequests(response.data.data.sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate)) ? -1 : 1));
                setDepartments([...new Set(response.data.data.map(e => e.department.toUpperCase()))])
                setEmployeeType([...new Set(response.data.data.map(e => e.employeeType.toUpperCase()))])
                MySwal.close();
            })

    }
    function refreshRequests() {
        getAllUsers();
    }
    useEffect(() => {
        getAllUsers();
    }, [])

    useEffect(() => {
        setFilteredRequests(users.filter(e =>

            (departmentsFilter === "All" || e.department.toLowerCase() === departmentsFilter.toLowerCase()) &&

            (searchQuery === "" || e.firstName.toLowerCase().includes(searchQuery.toLowerCase()) || e.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, departmentsFilter, users])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredRequests.length / recordsPerPage))
    }, [recordsPerPage, filteredRequests])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredRequests.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredRequests])

    /** @param {User} item */
    const handleUserRoles = (item) => {
        setUserItemEmail(item.email)
        setOpenUserRoleForm(true)
    }

    const handleDelete = (email) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Do you want to delete this User ?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'

        })
            .then((result) => {
                if (result.isConfirmed) {
                    deleteMeeting(email);

                }
            })
    };
    const deleteMeeting = (email) => {
        callServerAPI('DELETE', `/api/User/DeleteUser?email=${email}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setUser(users.filter(r => r.email !== email));

                enqueueSnackbar('User Deleted Successfully', { variant: 'success' })
            })

    }
    return (
        <Box m="1.0rem 1.0rem">
            <Header title="Users List" />
            <Box>
                <Paper>
                    <Box mt="20px" p='1.0rem' gap="20px">
                        <Grid container className=' d-flex  justify-content-between'>

                            <Grid item xs={12} sm={6} lg={8}  >
                                <div className='mb-2 mt-1' >
                                    <input type="text" className="form-control input-font bold" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search by Name...' />
                                </div>

                            </Grid>
                            <Grid style={{ float: 'right' }} item xs={12} sm={4} lg={4} >
                                <div className=' d-flex  justify-content-end'>
                                    <Button variant="contained" onClick={refreshRequests} size='medium' style={{ textTransform: 'none', fontSize: 13 }}> <Replay />&nbsp; Refresh</Button>&nbsp;
                                    <Button variant="contained" color='secondary' style={{ textTransform: 'none', fontSize: 13 }} onClick={handleOpenUserForm}><Add />&nbsp;Create New User</Button>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12} lg={3} style={{ width: "100%" }} >
                            <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="businessAreaFilter" value={departmentsFilter} onChange={(e) => setDepartmentsFilter(e.target.value)}>
                                <MenuItem value="All">All Departments</MenuItem >
                                {departments.map((businessArea, index) => {
                                    return <MenuItem key={index} value={businessArea}>{businessArea}</MenuItem >
                                })}

                            </TextField>
                        </Grid> &nbsp;&nbsp;
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table className='table-res' responsive striped bordered >
                                    <thead  style={{ fontSize: '11px', }}>
                                        <tr>
                                            <th >SN</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th> Email</th>
                                            <th>Department</th>

                                            <th>Action  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageRecords.map((item, index) => {
                                            return <tr  style={{ fontSize: '11px', }} key={index}>
                                                <td>{index + 1 + ((currentPage - 1) * recordsPerPage)}</td>
                                                <td >{item.firstName}</td>
                                                <td >{item.lastName}</td>
                                                <td>{item.email}</td>
                                                <td>{item.department}</td>

                                                <td>

                                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                                        {(popupState) => (
                                                            <React.Fragment>
                                                                <Button variant="contained" color='secondary' {...bindTrigger(popupState)}>
                                                                    Actions
                                                                </Button>
                                                                <Menu {...bindMenu(popupState)}>
                                                                    <MenuItem onClick={() => { handleUserRoles(item); popupState.close() }} ><RemoveRedEye />&nbsp; &nbsp;View User Roles</MenuItem>
                                                                    <MenuItem onClick={() => { handleDelete(item.email); popupState.close() }} ><Delete /> &nbsp;&nbsp;Delete</MenuItem>


                                                                </Menu>
                                                            </React.Fragment>
                                                        )}
                                                    </PopupState>
                                                </td>
                                            </tr>
                                        })}
                                        {currentPageRecords.length === 0 ? <tr>
                                            <td colSpan={8}><p style={{ fontSize: '11px', textAlign:'center'}}>No records....</p></td>
                                        </tr> : ''}
                                        {users.length === 0 ? <tr>
                                            <td colSpan={8}><p style={{ fontSize: '11px', textAlign:'center'}}>Please create a request....</p></td>
                                        </tr> : ''}

                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>

                        <br />
                        <nav>
                            <div className=" d-flex justify-content-between ">
                                <div className=" d-flex justify-content-between ">
                                    <div className='rowperpage'>Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} style={{ width: "60%" }}>
                                        <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </TextField>
                                    </Grid>
                                </div>
                                <Stack spacing={2}>
                                    <Pagination color="primary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>
                            </div>
                            <br />


                        </nav>
                        {openUserForm &&
                            <CreateUser getAllUsers={getAllUsers} setFormEditMode={setUserEditMode} formEditMode={userEditMode} employeeType={employeeType} departments={departments} setUser={setUser} users={users} openUserForm={openUserForm} setOpenUserForm={setOpenUserForm} userItemEmail={userItemEmail} />
                        }
                        {openUserRoleForm &&
                            <UserRoles formEditMode={userRolesEditMode} setFormEditMode={setUserRolesEditMode} userItemEmail={userItemEmail} setOpenUserRoleForm={setOpenUserRoleForm} OpenUserRoleForm={openUserRoleForm} />
                        }
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}
export default UsersList