import { DossierSlideName, YesNo, DueDiligenceStatus, YesNoOngoingNA, YesNoNA, AnticipatedContractorMarginScopeName, DossierSlideRequirement } from "./Constants"
import { getDossierSlideRequirement } from "./DossierSlideRequirementRules"
import { isNullOrWhiteSpace } from "./Helpers"


/**
 * @param {CpscRequest} cpscRequest
 * @return {boolean}
 */
export function isDossierCompleted(cpscRequest) {
    let result = true
    cpscRequest.dossierSlideSequence.forEach(slide => {
        result = result && (getDossierSlideRequirement(cpscRequest, slide.dossierSlideName) !== DossierSlideRequirement.Mandatory || isDossierSlideCompleted(slide.dossierSlideName, cpscRequest))
    })
    return result
}


/**
 * @param {string} slideName
 * @param {CpscRequest} request
 * @return {boolean}
 */
export function isDossierSlideCompleted(slideName, request) {
    switch (slideName) {
        case DossierSlideName.ApprovalGovernance:
            return ApprovalGovernanceSlideCompletionRule(request).length === 0
        case DossierSlideName.BudgetConfirmation:
            return BudgetConfirmationSlideCompletionRule(request).length === 0
        case DossierSlideName.BudgetPlanningInformation:
            return BudgetPlanningInformationSlideCompletionRule(request).length === 0
        case DossierSlideName.CfrInitiatives:
            return CfrInitiativesSlideCompletionRule(request).length === 0
        case DossierSlideName.CommercialEvaluation:
            return CommercialEvaluationSlideCompletionRule(request).length === 0
        case DossierSlideName.ContractHistory:
            return ContractHistorySlideCompletionRule(request).length === 0
        case DossierSlideName.ContractSpendEstimation:
            return ContractSpendEstimationSlideCompletionRule(request).length === 0
        case DossierSlideName.ContractualEvaluationCriteria:
            return ContractualEvaluationCriteriaSlideCompletionRule(request).length === 0
        case DossierSlideName.ContractualEvaluation:
            return ContractualEvaluationSlideCompletionRule(request).length === 0
        case DossierSlideName.ContractualProvisions:
            return ContractualProvisionsSlideCompletionRule(request).length === 0
        case DossierSlideName.DueDiligenceHumanRights:
            return DueDiligenceHumanRightsSlideCompletionRule(request).length === 0
        case DossierSlideName.HighRiskServices:
            return HighRiskServicesSlideCompletionRule(request).length === 0
        case DossierSlideName.HseEvaluationCriteria:
            return HseEvaluationCriteriaSlideCompletionRule(request).length === 0
        case DossierSlideName.HseEvaluation:
            return HseEvaluationSlideCompletionRule(request).length === 0
        case DossierSlideName.MarketIntelligence:
            return MarketIntelligenceSlideCompletionRule(request).length === 0
        case DossierSlideName.NigerianContent:
            return NigerianContentSlideCompletionRule(request).length === 0
        case DossierSlideName.ProcurementStrategyHighlights:
            return ProcurementStrategyHighlightsSlideCompletionRule(request).length === 0
        case DossierSlideName.ProcurementStrategy:
            return ProcurementStrategySlideCompletionRule(request).length === 0
        case DossierSlideName.ProjectOperationsPlanning:
            return ProjectOperationsPlanningSlideCompletionRule(request).length === 0
        case DossierSlideName.ProposedBidders:
            return ProposedBiddersSlideCompletionRule(request).length === 0
        case DossierSlideName.PurposeAndDescription:
            return PurposeAndDescriptionSlideCompletionRule(request).length === 0
        case DossierSlideName.Recommendation:
            return RecommendationSlideCompletionRule(request).length === 0
        case DossierSlideName.RiskLevelAssessment:
            return RiskLevelAssessmentSlideCompletionRule(request).length === 0
        case DossierSlideName.RisksAndOpportunities:
            return RisksAndOpportunitiesSlideCompletionRule(request).length === 0
        case DossierSlideName.SavingsCostOptimization:
            return SavingsCostOptimizationSlideCompletionRule(request).length === 0
        case DossierSlideName.TechnicalEvaluationCriteria:
            return TechnicalEvaluationCriteriaSlideCompletionRule(request).length === 0
        case DossierSlideName.TechnicalEvaluation:
            return TechnicalEvaluationSlideCompletionRule(request).length === 0
        case DossierSlideName.CatManInvolvement:
            return CatManInvolvementSlideCompletionRule(request).length === 0
        default:
            if (request.adhocDossierSlides.find(e => e.slideName === slideName) != null) return true;
            alert(`Invalid Slide Name: ${slideName}`)
            return false
    }


}

/** @param {CpscRequest} entity, @return {string[]} */
export function ApprovalGovernanceSlideCompletionRule(entity) {
    var errors = []
    if (entity.dossier.approvalGovernanceSlide.catManStatus === YesNoNA.Yes && isNullOrWhiteSpace(entity.dossier.approvalGovernanceSlide.categoryName))
        errors.push("CategoryName is required in approvalGovernanceSlide");
    if (entity.dossier.approvalGovernanceSlide.catManStatus === YesNoNA.Yes && isNullOrWhiteSpace(entity.dossier.approvalGovernanceSlide.catManName))
        errors.push("CatManName is required in approvalGovernanceSlide");
    if (entity.dossier.approvalGovernanceSlide.catBoardStatus === YesNoNA.Yes && entity.dossier.approvalGovernanceSlide.catBoardPlannedDate == null)
        errors.push("CatBoardPlannedDate is required in approvalGovernanceSlide");
    if (entity.dossier.approvalGovernanceSlide.ccepStatus === YesNoNA.Yes && entity.dossier.approvalGovernanceSlide.ccepPlannedDate == null)
        errors.push("CCEPPlannedDate is required in approvalGovernanceSlide");
    if (entity.dossier.approvalGovernanceSlide.nuimsStatus === YesNoNA.Yes && entity.dossier.approvalGovernanceSlide.nuimsPlannedDateStrategy == null)
        errors.push("NUIMSPlannedDateStrategy is required in approvalGovernanceSlide");
    if (entity.dossier.approvalGovernanceSlide.nuimsStatus === YesNoNA.Yes && entity.dossier.approvalGovernanceSlide.nuimsPlannedDateAward == null)
        errors.push("NUIMSPlannedDateAward is required in approvalGovernanceSlide");
    if (entity.dossier.approvalGovernanceSlide.ncdmbStatus === YesNoNA.Yes && entity.dossier.approvalGovernanceSlide.ncdmbPlannedDateStrategy == null)
        errors.push("NCDMBPlannedDateStrategy is required in approvalGovernanceSlide");
    if (entity.dossier.approvalGovernanceSlide.ncdmbStatus === YesNoNA.Yes && entity.dossier.approvalGovernanceSlide.ncdmbPlannedDateAward == null)
        errors.push("NCDMBPlannedDateAward is required in approvalGovernanceSlide");
    // if (entity.dossier.approvalGovernanceSlide.synergyWithAssetsStatus === YesNoNA.Yes && isNullOrWhiteSpace(entity.dossier.approvalGovernanceSlide.synergyWithAssetsValidity))
    //     errors.push("SynergyWithAssetsValidity is required in approvalGovernanceSlide");
    // if (isNullOrWhiteSpace(entity.dossier.approvalGovernanceSlide.hseRiskLevelValidity))
    //     errors.push("HseRiskLevelValidity is required in approvalGovernanceSlide");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function BudgetConfirmationSlideCompletionRule(entity) {
    var errors = []
    if (!entity.budgetConfirmationSlideUploaded) errors.push("Budget Confirmation not Uploaded");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function BudgetPlanningInformationSlideCompletionRule(entity) {
    var errors = []
    if (Number(entity.dossier.budgetPlanningInformationSlide.currentYearCorporateBudget) === 0.0 && Number(entity.dossier.budgetPlanningInformationSlide.currentYearNUIMSBudget) === 0.0 && isNullOrWhiteSpace(entity.dossier.budgetPlanningInformationSlide.noBudget))
        errors.push("Reasons must be provided if no Budget in Budget & Planning Information Slide");
    if (entity.dossier.budgetPlanningInformationSlide.currentYearCorporateBudget > 0 && isNullOrWhiteSpace(entity.dossier.budgetPlanningInformationSlide.budgetLine))
        errors.push("Budget Line must be provided for specified Current Year Corporate Budget in Budget & Planning Information Slide");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function CatManInvolvementSlideCompletionRule(entity) {
    var errors = []
    if (!entity.catManInvolvementSlideUploaded) errors.push("CATMAN Involvement not Uploaded");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function CfrInitiativesSlideCompletionRule(entity) {
    var errors = []
    if (entity.cfrInitiativesSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.cfrInitiativesSlide.details)) errors.push("CFR Initiatives Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function CommercialEvaluationSlideCompletionRule(entity) {
    var errors = []
    if (!entity.commercialEvaluationSlideUploaded) errors.push("Commercial Evaluation not Uploaded");
    var ratings = entity.dossier.commercialEvaluationSlide.commercialEvaluationRatings;
    if (ratings.length === 0)
        errors.push("Commercial Evaluation Ratings not provided");
    else {
        if (ratings.find(e => e.vendor != null) == null) errors.push("Commercial Evaluation must have at least one Vendor");
        if (ratings.find(e => e.isCompanyEstimate && e.scope === AnticipatedContractorMarginScopeName) == null) errors.push(`Commercial Evaluation must have ${AnticipatedContractorMarginScopeName}`);
    }
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ContractHistorySlideCompletionRule(entity) {
    var errors = []
    if (entity.dossier.contractHistorySlide.contractHistories.length === 0) errors.push("At least one Contract History record must be provided");
    if (isNullOrWhiteSpace(entity.dossier.contractHistorySlide.currentPerformance)) errors.push("Contract History Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ContractSpendEstimationSlideCompletionRule(entity) {
    var errors = []
    if (entity.contractSpendEstimationSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.contractSpendEstimationSlide.contractSpendEstimation)) errors.push("Contract Spend Estimation Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ContractualEvaluationCriteriaSlideCompletionRule(entity) {
    var errors = []
    if (entity.contractualEvaluationCriteriaSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.contractualEvaluationCriteriaSlide.details)) errors.push("Contractual Evaluation Criteria Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ContractualEvaluationSlideCompletionRule(entity) {
    var errors = []
    if (entity.contractualEvaluationSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.contractualEvaluationSlide.details)) errors.push("Contractual Evaluation Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ContractualProvisionsSlideCompletionRule(entity) {
    var errors = []
    if (entity.contractualProvisionsSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.contractualProvisionsSlide.details)) errors.push("Contractual Provisions Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function DueDiligenceHumanRightsSlideCompletionRule(entity) {
    var errors = []
    entity.contractors.forEach(contractor => {
        var contractorDueDiligenceHumanRights = entity.dossier.dueDiligenceHumanRightsSlide.contractors.find(e => e.contractorName.toUpperCase() === contractor.contractorName.toUpperCase());
        if (contractorDueDiligenceHumanRights == null)
            errors.push(`No Due Diligence or Human Rights record for ${contractor.contractorName}`);
        else {
            if (contractorDueDiligenceHumanRights.hasDueDiligenceMitigations === YesNo.Yes && isNullOrWhiteSpace(contractorDueDiligenceHumanRights.dueDiligenceMitigations))
                errors.push(`Due Diligence Mitigations required for ${contractor.contractorName}`);
            if (contractorDueDiligenceHumanRights.dueDiligenceExpiryDate == null && (contractorDueDiligenceHumanRights.dueDiligenceStatus === DueDiligenceStatus.Valid || contractorDueDiligenceHumanRights.dueDiligenceStatus === DueDiligenceStatus.RenewalOngoing))
                errors.push(`Due Diligence Expiry Date required for ${contractor.contractorName}`);
            if (!contractor.dueDiligenceSlideUploaded && (contractorDueDiligenceHumanRights.dueDiligenceStatus === DueDiligenceStatus.Valid || contractorDueDiligenceHumanRights.dueDiligenceStatus === DueDiligenceStatus.RenewalOngoing))
                errors.push(`'Due Diligence not uploaded for ${contractor.contractorName}`);
            if (!contractor.humanRightsSlideUploaded && contractorDueDiligenceHumanRights.humanRightsAuditStatus === YesNoOngoingNA.Yes)
                errors.push(`Human Rights not uploaded for ${contractor.contractorName}`);
        }
    });
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function HighRiskServicesSlideCompletionRule(entity) {
    var errors = []
    if (entity.dossier.highRiskServicesSlide.highRisks == null || entity.dossier.highRiskServicesSlide.highRisks.length === 0)
        errors.push("High Risks Services Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function HseEvaluationCriteriaSlideCompletionRule(entity) {
    var errors = []
    var hseEvaluationCriteria = entity.dossier.hseEvaluationCriteriaSlide.hseEvaluationCriteria;
    let totalHseWeights = 0;
    hseEvaluationCriteria.forEach(e => totalHseWeights += Number(e.weight));
    if (totalHseWeights !== 100.0) errors.push("HSE Evaluation Criteria weight must sum up to 100%");

    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function HseEvaluationSlideCompletionRule(entity) {
    var errors = []
    if (!entity.hseEvaluationSlideUploaded) errors.push("HSE Evaluation not Uploaded");
    var ratings = entity.dossier.hseEvaluationSlide.hseEvaluationRatings;
    if (ratings.length === 0) errors.push("HSE Evaluation Ratings not provided");
    return errors;
}


/** @param {CpscRequest} entity, @return {string[]} */
export function MarketIntelligenceSlideCompletionRule(entity) {
    var errors = []
    if (!entity.marketIntelligenceSlideUploaded && (isNullOrWhiteSpace(entity.dossier.marketIntelligenceSlide.keyCostDrivers) || isNullOrWhiteSpace(entity.dossier.marketIntelligenceSlide.costDetails) || isNullOrWhiteSpace(entity.dossier.marketIntelligenceSlide.benchmark)))
        errors.push("Market Intelligence Slide not completed");
    if (!entity.costAndMarketTrendChartUploaded)
        errors.push("Cost & Market Trend Chart not Uploaded");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function NigerianContentSlideCompletionRule(entity) {
    var errors = []
    if (entity.dossier.nigerianContentSlide.availableEquipmentInCountryRating === 0 || entity.dossier.nigerianContentSlide.availableMaterialInCountryRating === 0 || entity.dossier.nigerianContentSlide.availableNigerianVendorsRating === 0 || entity.dossier.nigerianContentSlide.expectedInCountryRetentionRating === 0 || entity.dossier.nigerianContentSlide.jobComplexityRating === 0 || entity.dossier.nigerianContentSlide.requiredForeignExpertiseRating === 0)
        errors.push("Nigerian Content Slide not completed");
    if (Number(entity.dossier.nigerianContentSlide.currencySplitForeignPercent) + Number(entity.dossier.nigerianContentSlide.currencySplitNairaPercent) !== 100.0)
        errors.push(`Currency Split total must be 100%. Current split total is currently ${entity.dossier.nigerianContentSlide.currencySplitForeignPercent + entity.dossier.nigerianContentSlide.currencySplitNairaPercent}`);
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ProcurementStrategyHighlightsSlideCompletionRule(entity) {
    var errors = []
    if (entity.procurementStrategyHighlightsSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.procurementStrategyHighlightsSlide.details)) errors.push("Procurement Strategy Highlights Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ProcurementStrategySlideCompletionRule(entity) {
    var errors = []
    if (isNullOrWhiteSpace(entity.dossier.procurementStrategySlide.details)) errors.push("Procurement Strategy Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ProjectOperationsPlanningSlideCompletionRule(entity) {
    var errors = []
    if (entity.projectOperationsPlanningSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.projectOperationsPlanningSlide.details)) errors.push("Project Operations Planning Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function ProposedBiddersSlideCompletionRule(entity) {
    var errors = []
    if (entity.proposedBiddersSlideUploaded) return errors;
    if (entity.dossier.proposedBiddersSlide.bidders.length === 0) errors.push("No bidder added to Proposed Bidders List");
    for (var bidder of entity.dossier.proposedBiddersSlide.bidders) {
        if ((bidder.dueDiligenceStatus === DueDiligenceStatus.Valid || bidder.dueDiligenceStatus === DueDiligenceStatus.RenewalOngoing) && bidder.dueDiligenceExpiryDate == null) errors.push("DueDiligenceExpiryDate is required for valid or expired Due Diligence in Bidders");
    }
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function PurposeAndDescriptionSlideCompletionRule(entity) {
    var errors = []
    if (
        isNullOrWhiteSpace(entity.dossier.purposeAndDescriptionSlide.objective) ||
        isNullOrWhiteSpace(entity.dossier.purposeAndDescriptionSlide.detailedGeographicScope) ||
        isNullOrWhiteSpace(entity.dossier.purposeAndDescriptionSlide.background) ||
        isNullOrWhiteSpace(entity.dossier.purposeAndDescriptionSlide.sowOrMaterialDescription)
    )
        errors.push("Purpose And Description Slide  not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function RecommendationSlideCompletionRule(entity) {
    var errors = []
    if (isNullOrWhiteSpace(entity.dossier.recommendationSlide.recommendation) || isNullOrWhiteSpace(entity.dossier.recommendationSlide.nextSteps))
        errors.push("Purpose And Description Slide  not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function RiskLevelAssessmentSlideCompletionRule(entity) {
    var errors = []
    if (!entity.riskLevelAssessmentSlideUploaded) errors.push("Risk Level Assessment not Uploaded");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function RisksAndOpportunitiesSlideCompletionRule(entity) {
    var errors = []
    if (entity.dossier.risksAndOpportunitiesSlide.risks.find(e => !isNullOrWhiteSpace(e.risksIdentified)) == null) errors.push("At least one Risk must be identified in the Risks & Opportunities Slide");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function SavingsCostOptimizationSlideCompletionRule(entity) {
    var errors = []
    if (entity.savingsCostOptimizationSlideUploaded) return errors;
    if (isNullOrWhiteSpace(entity.dossier.savingsCostOptimizationSlide.savingsContext)) errors.push("Savings Cost Optimization Slide not completed");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function TechnicalEvaluationCriteriaSlideCompletionRule(entity) {
    var errors = []
    var technicalEvaluationCriteria = entity.dossier.technicalEvaluationCriteriaSlide.technicalEvaluationCriteria;
    if (technicalEvaluationCriteria.length === 0) errors.push("Technical Evaluation Criteria Slide not completed");
    let totalTechnicalWeights = 0;
    technicalEvaluationCriteria.forEach(e => totalTechnicalWeights += Number(e.weight));
    if (totalTechnicalWeights !== 100.0) errors.push("Technical Evaluation Criteria weight must sum up to 100%");
    return errors;
}

/** @param {CpscRequest} entity, @return {string[]} */
export function TechnicalEvaluationSlideCompletionRule(entity) {
    var errors = []
    if (!entity.technicalEvaluationSlideUploaded) errors.push("Technical Evaluation not Uploaded");
    var ratings = entity.dossier.technicalEvaluationSlide.technicalEvaluationRatings;
    if (ratings.length === 0) errors.push("Technical Evaluation Ratings not provided");
    return errors;
}