import React, { } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack } from '@mui/material';


/**
 * @typedef {Object} CustomDialogProps
 * @property {string} dialogTitle
 * @property {import('react').ReactElement} children
 * @property {boolean} openDialog
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenDialog
 */

/**
 * @param {CustomDialogProps}  props
 * @return {import('react').ReactElement}
 */
function CustomDialog(props) {
    return (
        <>
            <Dialog fullWidth maxWidth='md' open={props.openDialog} style={{ height: '100%' }} >
                <Stack direction='row' >
                    <DialogTitle style={{fontSize:14, fontWeight:'bolder', width: '50%' }} > {props.dialogTitle}</DialogTitle>
                    <DialogActions style={{ width: '50%' }}> <Button style={{ marginRight: 20 }} onClick={() => props.setOpenDialog(false)}>Close</Button></DialogActions>
                </Stack>
                <DialogContent>
                    {props.children}
                    <br />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CustomDialog