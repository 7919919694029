import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, MenuItem, Menu, Stack, Pagination, Grid,  Box, Paper } from '@mui/material';
import Swal from 'sweetalert2'
import { callServerAPI, formatShortDateString, formatTimeString } from '../../helpers/Helpers';
import { preloader, MeetingStatus } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { Add, Replay, Delete, Send, RemoveRedEye } from '@mui/icons-material';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Table from 'react-bootstrap/Table';
import Header from '../../components/TitleHeader';

const MySwal = withReactContent(Swal);
axios.defaults.withCredentials = true;

function MeetingList() {

    /** @type { [Meeting[], Function] } */
    let [meetings, setMeetings] = useState([]);

    /** @type { [ Meeting[], Function] } */
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [numOfPages, setNumOfPages] = useState(0);
    /** @type [Meeting[], import('react').Dispatch<import('react').SetStateAction<Meeting[]>>] */
    const [currentPageRecords, setCurrentPageRecords] = useState([]);
    const { enqueueSnackbar } = useSnackbar();


    const getMeetings = async () => {
      MySwal.fire(preloader);
        callServerAPI('GET', '/api/Meeting/GetAllMeetings', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting[]>> } */ response) {
              MySwal.close();
                setMeetings(response.data.data.sort((a, b) => (new Date(a.meetingDateTime) > new Date(b.meetingDateTime)) ? -1 : 1));
                setFilteredRequests(response.data.data.sort((a, b) => (new Date(a.meetingDateTime) > new Date(b.meetingDateTime)) ? -1 : 1));
            })
    }
    function refreshMeetingList() {
        getMeetings();
    }
    useEffect(() => {
        getMeetings();
    }, [])

    useEffect(() => {
        setNumOfPages(Math.ceil(filteredRequests.length / recordsPerPage))
    }, [recordsPerPage, filteredRequests])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredRequests.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredRequests])

    const handleDelete = (id) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Do you want to delete this Meeting ?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'

        })
            .then((result) => {
                if (result.isConfirmed) {
                    deleteMeeting(id);
                }
            })
    };
    const deleteMeeting = (id) => {
        callServerAPI('DELETE', `/api/Meeting/DeleteMeeting?meetingId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setMeetings(meetings.filter(r => r.id !== id));
                enqueueSnackbar(response.data.message, { variant: 'success' })
            })

    }

    const handleSendInvite = (id) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Do you want to send an Invite to this Meeting ?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'

        })
            .then((result) => {
                if (result.isConfirmed) {
                    sendMeetingInvite(id);

                }
            })
    };
    function sendMeetingInvite(id) {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/Meeting/SendAgendaToCpscMembers?meetingId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                MySwal.close();
                enqueueSnackbar('Meeting Invite Sent', { variant: 'success' })
            })

    }
    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/create-meeting');
    }



    return (
      meetings && currentPageRecords &&
        <Box m="1.0rem 1.0rem">
            <Header title="Meeting List" />
            <Box>
                <Paper>
                    <Box mt="20px" p='1.0rem' gap="20px">
                        <Grid  container className=' d-flex d-flex flex-row-reverse'>

                            <Grid item xs={12} sm={6} lg={2}  >
                            <Button color='secondary' variant="contained" onClick={routeChange} style={{ textTransform: 'none', fontSize: 13 }}><Add />&nbsp;New Meeting</Button>

                            </Grid> &nbsp;&nbsp;
                            <Grid  item xs={12} sm={4} lg={1} >
                            <Button variant="contained" onClick={refreshMeetingList} style={{ textTransform: 'none', fontSize: 13 }}> <Replay /> &nbsp;Refresh</Button>
                            </Grid>
                        </Grid>
                        <br />
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} >
                    <Table responsive striped bordered>
                      <thead style={{ fontSize: '10px', }} className='thead-text'>
                        <tr>
                          <th >SN</th>
                          <th> Venue</th>
                          <th>Format</th>
                          <th>Meeting Date</th>
                          <th>Status  </th>

                          <th ></th>

                        </tr>
                      </thead>
                      <tbody>
                        {currentPageRecords.map((item, index) => {
                          return <tr style={{ fontSize: '11px', }} className='trtext' key={index}>
                            <td>{index + 1 + ((currentPage - 1) * recordsPerPage)}</td>
                            <td style={{ minWidth: 200 }} >{item.venue}</td>
                            <td >{item.format}</td>
                            <td>{formatShortDateString(item.meetingDateTime)} {formatTimeString(item.meetingDateTime)}</td>
                            <td>{item.status}</td>

                            <td >
                              <PopupState variant="popover" popupId="demo-popup-menu">
                                {(popupState) => (
                                  <React.Fragment>
                                    <Button variant="contained" color='secondary' {...bindTrigger(popupState)}>
                                      Actions
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                      <MenuItem onClick={(e) => { navigate(`/edit-meeting/${item.id}`) }}><RemoveRedEye />&nbsp; &nbsp;View Meeting Details</MenuItem>
                                      <MenuItem onClick={() => { handleSendInvite(item.id) }}> <Send />&nbsp; &nbsp;{item.inviteStatusSent ? 'Re-Send Invite' : "Send Invite"}</MenuItem>

                                      <MenuItem onClick={(e) => { navigate(`/meetingpresentation/${item.id}`) }} ><SlideshowIcon /> &nbsp;&nbsp;{item.status !== MeetingStatus.Completed ? 'Join Meeting': 'View Meeting Summary' }</MenuItem>

                                      {item.status === MeetingStatus.Scheduled &&
                                        <MenuItem onClick={() => { handleDelete(item.id) }} ><Delete /> &nbsp;&nbsp;Delete</MenuItem>
                                      }

                                    </Menu>
                                  </React.Fragment>
                                )}
                              </PopupState>
                            </td>


                          </tr>


                        })}
                        {currentPageRecords.length === 0 ? <tr>
                          <td colSpan={6}><p style={{ fontSize: '11px', textAlign:'center'}}>No records....</p></td>
                        </tr> : ''}
                        {meetings.length === 0 ? <tr>
                          <td colSpan={8}><p style={{ fontSize: '11px', textAlign:'center'}}>Please create a Meeting....</p></td>
                        </tr> : ''}

                      </tbody>

                    </Table>
                  </Grid>
                </Grid>
                <br />
                <nav>
                  <div className=" d-flex justify-content-between ">
                    <div className=" d-flex justify-content-between ">
                      <div className='rowperpage'>Rows per page</div>&nbsp;
                      <select id="RequestsNumbers" name="" className="select form-control" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} required >
                        <option value='5'>5</option>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='40'>40</option>
                        <option value='80'>80</option>
                        <option value='120'>120</option>
                        <option value='200'>200</option>
                        <option value='250'>250</option>
                        <option value='300'>300</option>
                        <option value='500'>500</option>

                      </select>
                    </div>
                    <Stack spacing={2}>
                      <Pagination color="primary" count={numOfPages} siblingCount={1} boundaryCount={1}
                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                    </Stack>
                  </div>
                  <br />


                </nav>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default MeetingList