import React from 'react'
import {  TechnicalEvaluationSlideField, TechnicalEvaluationSlideTooltip } from '../../helpers/DossierFieldConstants';
import TechnicalEvaluationFormSlide from './TechnicalEvaluationForm';
import CustomTabPanel from '../tabs/CustomTabPanel';
import { Tab, Tabs } from '@mui/material';
import '../../css/template.css'
import TechnicalEvaluationUpload from './TechnicalEvaluationUpload';
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

/**
 * @typedef {Object} TechnicalEvaluationSlideProps

 * @property {boolean} readOnly
  * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
  * @property {downloadPdfCallback} downloadPdf
  * @property {uploadPdfCallback} uploadPdf
  * @property {deletePdfCallback} deletePdf
  *  @property {number} dossierRefreshCount
  * @property {downloadPdfCallback} mobileDownloadPdf
 */

/**
 * @param {TechnicalEvaluationSlideProps}  props
 * @return {import('react').ReactElement}
 */
function TechnicalEvaluationSlide(props) {
  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, technicalEvaluationSlide: { ...props.cpscRequest.dossier.technicalEvaluationSlide, [name]: value } })
}
  return (
    
    <>
      <div className='content-desktop'>
        <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Technical Evaluation " value={1} />

          <Tab label="Technical Evaluation Upload" value={2} />

        </Tabs>
      </div>
      <div className='content-mobile'>
        <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Technical Evaluation " value={1} />

          <Tab label="Technical Evaluation Upload" value={2} />

        </Tabs>
      </div>
      <CustomTabPanel value={selectedTabValue} index={1} >
        <TechnicalEvaluationFormSlide setDossier={props.setDossier} cpscRequest={props.cpscRequest} readOnly={props.readOnly} />
        <br />
      <div className="form-group">
        <label className=' title-label'>{getSlideTooltip(props.cpscRequest, TechnicalEvaluationSlideTooltip.supportingInformation)}Supporting Information<span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.technicalEvaluationSlide.supportingInformation} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(TechnicalEvaluationSlideField.supportingInformation, editorHtmlString)} />  
       </div>
      <br />
      <div className="form-group">
        <label className=' title-label'>Additional Comments <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.technicalEvaluationSlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(TechnicalEvaluationSlideField.additionalComments, editorHtmlString)} />  
      </div>
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabValue} index={2} >
       <TechnicalEvaluationUpload readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} mobileDownloadPdf={props.mobileDownloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
      </CustomTabPanel>


    </>
  )
}

export default TechnicalEvaluationSlide