import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react'
import { CpscVoteType, GlobalRole, globalStore } from '../../helpers/Constants';
import { callServerAPI, getNameFromTotalEnergiesEmail, isNullOrWhiteSpace } from '../../helpers/Helpers';
import CustomDialog from '../../components/dialogs/CustomDialog';

/**
 * @typedef {Object} DossierAddendumVotingProps
 * @property {updateCpscRequestCallback} updateCpscRequest
 * @property {CpscRequest} cpscRequest
 */

/**
 * @param {DossierAddendumVotingProps}  props
 * @return {import('react').ReactElement}
 */
function DossierAddendumVoting(props) {
    /** @type DossierAddendumCpscVote */
    const emptyDossierAddendumCpscVote = {
        id: "",
        email: '',
        cpscRequestId: '',
        vote: '',
        comments: ''
    }

    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    /** @type [DossierAddendumCpscVote,import('react').Dispatch<import('react').SetStateAction<DossierAddendumCpscVote>>] */
    const [addendumCpscVote, setAddendumCpscVote] = useState(emptyDossierAddendumCpscVote);
    const [openComments, setOpenComments] = React.useState(false);
    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [selectedComment, setSelectedComment] = useState("")

    const submitVote = () => {
        callServerAPI('PATCH', `/api/DossierAddendum/DossierAddendumVote?requestId=${props.cpscRequest.id}&vote=${addendumCpscVote.vote ?? ''}&comments=${addendumCpscVote.comments ?? ''}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                props.updateCpscRequest(response.data.data)
            })
    }

    /** @param {string} name, @param {any} value  */
    const updateDossierAddendumComments = (name, value) => {
        setAddendumCpscVote({ ...addendumCpscVote, [name]: value })
    }

    /** @param {DossierAddendumCpscVote} cpscVote */
    const handleClickOpen = (cpscVote) => {
        setSelectedComment(cpscVote.comments)
        setOpenComments(true);
    };


    return (
        <div style={{ padding: '10px' }}>
            {loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember) && props.cpscRequest.dossierAddendum.cpscVotes.find(e => e.email.toLowerCase() === loggedOnTepngUser.email.toLowerCase()) == null ?
                <>
                    <FormControl style={{ width: '100%' }}>
                        <RadioGroup >
                            <FormControlLabel value={CpscVoteType.Ok} control={<Radio checked={addendumCpscVote.vote === CpscVoteType.Ok} onChange={(event) => setAddendumCpscVote(addendumCpscVote => ({ ...addendumCpscVote, vote: event.target.value }))} />} label={CpscVoteType.Ok} />
                            <FormControlLabel value={CpscVoteType.OkClarificationsRequired} control={<Radio checked={addendumCpscVote.vote === CpscVoteType.OkClarificationsRequired} onChange={(event) => setAddendumCpscVote(addendumCpscVote => ({ ...addendumCpscVote, vote: event.target.value }))} />} label={CpscVoteType.OkClarificationsRequired} />
                            <FormControlLabel value={CpscVoteType.NotOk} control={<Radio checked={addendumCpscVote.vote === CpscVoteType.NotOk} onChange={(event) => setAddendumCpscVote(addendumCpscVote => ({ ...addendumCpscVote, vote: event.target.value }))} />} label={CpscVoteType.NotOk} />
                        </RadioGroup>
                        <div className='row'>
                            <div >
                                <div >
                                    <label className=' title-label'>Comments </label>
                                </div>
                                <div className=''>

                                    <div className="form-group mb-0">
                                        <textarea className='form-control' onChange={(e) => updateDossierAddendumComments(e.target.id, e.target.value)} id="comments" value={addendumCpscVote.comments} style={{ width: '100%', height: '80px' }} name='comments' required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <Button disabled={addendumCpscVote.vote === CpscVoteType.OkClarificationsRequired && (addendumCpscVote.comments == null || addendumCpscVote.comments.trim() === '')}
                            variant='contained' size='small' color="secondary" style={{ width: '40%', }} onClick={submitVote} >Submit Vote</Button>&nbsp;&nbsp;
                    </FormControl>
                </>
                :
                <>
                    {props.cpscRequest.dossierAddendum.cpscVotes.map(cpscVote =>
                        <>
                            <div className='user'> {getNameFromTotalEnergiesEmail(cpscVote.email)}</div>
                            <div >
                                <div className={cpscVote.vote === CpscVoteType.Ok ? 'ok_vote' : (cpscVote.vote === CpscVoteType.OkClarificationsRequired ? 'further_vote' : 'abstain_vote')}> &nbsp;{cpscVote.vote}&nbsp;</div>
                                <br />
                                {!isNullOrWhiteSpace(cpscVote.comments) &&
                                    <div className='d-flex flex-row-reverse' style={{ float: 'right', }} >
                                        <Button className='cmmtbtn' variant="contained" onClick={() => handleClickOpen(cpscVote)}>View Comments</Button>


                                    </div>
                                }<br />
                                {openComments &&
                                    <>
                                        <CustomDialog dialogTitle='Comments' openDialog={openComments} setOpenDialog={setOpenComments}>
                                            <div>{selectedComment}</div>
                                        </CustomDialog>

                                    </>
                                }
                            </div>
                            <br />
                        </>)}
                </>
            }
        </div>
    )
}

export default DossierAddendumVoting