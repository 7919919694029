import { BudgetPlanningInformationSlideTooltip, BudgetPlanningSlideField } from '../../helpers/DossierFieldConstants';
import React from 'react'
import { Grid, TextField } from '@mui/material';
import '../../css/template.css'
import MyNumericFormat from '../MyNumericFormat';
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';



/**
 * @typedef {Object} BudgetPlanningSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {boolean} readOnly
 * @property {number} dossierRefreshCount
 */

/**
 * @param {BudgetPlanningSlideProps}  props
 * @return {import('react').ReactElement}
 */
function BudgetPlanningSlide(props) {
  

  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, budgetPlanningInformationSlide: { ...props.cpscRequest.dossier.budgetPlanningInformationSlide, [name]: value } })
  }

  return (
   
    <div>
      <form>

        <Grid container>
          <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>
            <div >
              <label className='title-label'> Current year NUIMS Budget ({props.cpscRequest.budgetCurrency})<span className='important'>*</span></label>
            </div>
            <div className=''>
              <div className="form-group mb-0">
                <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }} onChange={(e) => { updateDataFieldValue("currentYearNUIMSBudget", e.target.value) }} style={{ height: "40px" }} value={props.cpscRequest.dossier.budgetPlanningInformationSlide.currentYearNUIMSBudget} className='form-control input-font bold' disabled={props.readOnly} />
              </div>
            </div>

          </Grid>

          <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>
            <div >
              <label className='title-label'>Current year Corporate Budget ({props.cpscRequest.budgetCurrency})<span className='important'>*</span></label>
            </div>
            <div className=''>
              <div className="form-group mb-0">
                <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }} style={{ height: "40px" }} onChange={(e) => { updateDataFieldValue("currentYearCorporateBudget", e.target.value) }} value={props.cpscRequest.dossier.budgetPlanningInformationSlide.currentYearCorporateBudget} className='form-control input-font bold' disabled={props.readOnly} />
              </div>
            </div>
          </Grid>
        </Grid>
        <br />

        <div className="form-group">
          <label className=' title-label' >If there is no Budget, please state<span className='important'>*</span></label>
          
              <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.budgetPlanningInformationSlide.noBudget} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(BudgetPlanningSlideField.noBudget, editorHtmlString)} />

        </div>
        <br />
        <Grid container>
          <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>
            <div >
              <label className='title-label'> Budget line (Cost Centre / WBS)<span className='important'>*</span></label>
            </div>
            <div className=''>
              <div className="form-group mb-0">
                <TextField fullWidth size="small" onChange={(e) => { updateDataFieldValue("budgetLine", e.target.value) }} placeholder='State budget line if known' style={{ height: "40px", paddingTop: 13 }} value={props.cpscRequest.dossier.budgetPlanningInformationSlide.budgetLine} className='form-control input-font bold' disabled={props.readOnly} />
              </div>
            </div>

          </Grid>

          <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>
            <div >
              <label className='title-label'>{getSlideTooltip(props.cpscRequest, BudgetPlanningInformationSlideTooltip.budgetLineRelevantPart)} Actual budget within budget line ({props.cpscRequest.budgetCurrency})</label>
            </div>
            <div className=''>
              <div className="form-group mb-0">
                <TextField fullWidth size="small" InputProps={{ inputComponent: MyNumericFormat }} onChange={(e) => { updateDataFieldValue("budgetLineRelevantPart", e.target.value) }} style={{ height: "40px" }} value={props.cpscRequest.dossier.budgetPlanningInformationSlide.budgetLineRelevantPart} className='form-control input-font bold' disabled={props.readOnly} />
              </div>
            </div>
          </Grid>
        </Grid>
        <br />

        <div className="form-group">
          <label className=' title-label' >What is the first year spend projection<span className='important'>*</span></label>
         
            <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.budgetPlanningInformationSlide.firstYearSpendProjection} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(BudgetPlanningSlideField.firstYearSpendProjection, editorHtmlString)} />

        </div>
        <br />

        <div className="form-group">
          <label className=' title-label' >What is the phasing per year of Contract Spend<span className='important'>*</span></label>
          
             <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.budgetPlanningInformationSlide.phasingPerYearOfContractSpend} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(BudgetPlanningSlideField.phasingPerYearOfContractSpend, editorHtmlString)} />

        </div>
        <br />
      </form>
    </div>
  )
}

export default BudgetPlanningSlide