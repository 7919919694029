import React, { useEffect } from 'react'
import { callServerAPI } from '../../helpers/Helpers';
import { preloader } from '../../helpers/Constants';
import { useParams, useSearchParams } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';


const MySwal = withReactContent(Swal);

function ViewDossierFieldDocument() {
  const { cpscRequestId } = useParams();
  const { dossierFieldDocumentName } = useParams();
  const [searchParams] = useSearchParams();
  const contractorName = searchParams.get("contractorName");


  useEffect(() => {
    MySwal.fire(preloader)
    callServerAPI('GET', `/api/CpscRequest/DownloadDossierFieldDocument?requestId=${cpscRequestId}&fieldName=${dossierFieldDocumentName}&contractorName=${contractorName ?? ''}`, null, true, 'blob')
      .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
        window.open(URL.createObjectURL(response.data));
        MySwal.close();
      })
  }, [cpscRequestId, dossierFieldDocumentName, contractorName])

  return (
    <div>
    </div >
  )
}

export default ViewDossierFieldDocument