import React, { useEffect, useState } from 'react';
import { Button, Stack, Pagination, Grid, MenuItem, TextField, Box, Paper, } from '@mui/material';
import Swal from 'sweetalert2'
import { callServerAPI } from '../../helpers/Helpers';
import { preloader } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import Replay from '@mui/icons-material/Replay';
import UserPerRole from './UserPerRole';
import Header from '../../components/TitleHeader';
import Table from 'react-bootstrap/Table';

const MySwal = withReactContent(Swal);
axios.defaults.withCredentials = true;
function Roles() {
    const [openUserForRole, setOpenUserForRole] = React.useState(false);
    const [roleName, setRoleName] = React.useState("");
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    let [roles, setRoles] = useState([]);

    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [numOfPages, setNumOfPages] = useState(0);
    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [currentPageRecords, setCurrentPageRecords] = useState([]);


    const getAllRoles = async () => {
        MySwal.fire(preloader);
        callServerAPI('GET', '/api/Roles/GetAllRoles', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
                setRoles(response.data.data);
                setFilteredRoles(response.data.data);
                MySwal.close();

            })

    }
    function refreshRequests() {
        getAllRoles();
    }
    useEffect(() => {
        getAllRoles();
    }, [])

    useEffect(() => {
        setFilteredRoles(roles.filter(e =>

            (searchQuery === "" || e.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, roles])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredRoles.length / recordsPerPage))
    }, [recordsPerPage, filteredRoles])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredRoles.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredRoles])

    /** @param {string} item */
    const handleViewUsersForRole = (item) => {
        setRoleName(item)
        setOpenUserForRole(true)
    }
    return (
        <Box m="1.0rem 1.0rem">
            <Header title="Roles List" />
            <Box>
                <Paper>
                    <Box mt="20px" p='1.0rem' gap="20px">
                        <Grid container className=' d-flex  justify-content-between'>

                            <Grid item xs={12} sm={6} lg={8}  >
                                <div className='mb-2 mt-1' >
                                    <input type="text" className="form-control input-font bold" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search by Name...' />
                                </div>

                            </Grid>
                            <Grid style={{ float: 'right' }} item xs={12} sm={4} lg={4} >
                                <div className=' d-flex  justify-content-end'>
                                    <Button variant="contained" onClick={refreshRequests} size='medium' style={{ textTransform: 'none', fontSize: 13 }}> <Replay />&nbsp; Refresh</Button>&nbsp;

                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table className='table-res' responsive striped bordered >
                                    <thead style={{ fontSize: '11px', }}>
                                        <tr>
                                            <th >SN</th>
                                            <th>Role Name</th>
                                            <th>Action  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageRecords.map((roleName, index) => {
                                            return <tr style={{ fontSize: '11px', }} key={index}>
                                                <td>{index + 1 + ((currentPage - 1) * recordsPerPage)}</td>
                                                <td >{roleName}</td>

                                                <td>
                                                    <Button variant='contained' size='small' color="primary" onClick={() => handleViewUsersForRole(roleName)} >View Members</Button>
                                                </td>
                                            </tr>
                                        })}
                                        {currentPageRecords.length === 0 ? <tr>
                                            <td colSpan={3}><p style={{ fontSize: '11px', textAlign:'center'}}>No records....</p></td>
                                        </tr> : ''}


                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>

                        <br />
                        <nav>
                            <div className=" d-flex justify-content-between ">
                                <div className=" d-flex justify-content-between ">
                                    <div className='rowperpage'>Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} style={{ width: "60%" }}>
                                        <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </TextField>
                                    </Grid>
                                </div>
                                <Stack spacing={2}>
                                    <Pagination color="primary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>
                            </div>
                            <br />


                        </nav>
                        {openUserForRole &&
                            <UserPerRole roleName={roleName} openUserForRole={openUserForRole} setOpenUserForRole={setOpenUserForRole} />
                        }

                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default Roles