import Login from './components/login/Login';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import React, { useEffect, useState, useMemo } from 'react';
import { callServerAPI, callServerRefreshToken, initializeGlobalStates } from './helpers/Helpers';
import { FormEditMode, globalStore } from './helpers/Constants';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import { SnackbarProvider } from 'notistack';
import Layout from './views/layout';
import RequestList from './views/requests/RequestList';
import InitiateRequestForm from './views/requests/InitiateRequestForm';
import DossierList from './views/dossiers/DossierList';
import DossierTemplate from './views/dossiers/DossierTemplate';
import AggregateDossierList from './views/aggregateDossier/AggregateDossierList';
import CreateAggregateRequests from './views/aggregateDossier/CreateAggregateRequests';
import MeetingList from './views/meeting/MeetingList';
import CreateMeetings from './views/meeting/CreateMeetings';
import CPDomainList from './views/cpdomains/CPDomainList';
import BusinessArea from './views/businessAreas/BusinessAreas';
import UsersList from './views/users/UsersList';
import Roles from './views/roles/Roles';
import AgendaPDF from './views/meeting/AgendaPDF';
import MeetingPresentation from './views/meetingPresentation/MeetingPresentation';
import DossierAddendum from './views/addendum/DossierAddendum';
import Logout from './components/logout/Logout';
import ViewDossierFieldDocument from './views/dossiers/ViewDossierFieldDocument';


initializeGlobalStates()

function ProtectedRoute({ children }) {

  const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
  let location = useLocation();
  if (loggedOnTepngUser == null) {
    localStorage.setItem('loginRedirectedFromUrl', location.pathname + location.search);
    return <Navigate to="/login-tepnguser" replace />
  }
  else {
    console.log("called ProtectedRoute children")
    return children
  }
};

function NoRouteMatch() {
  let location = useLocation();
  return (<div> <h3> No Route match for <code>{location.pathname}</code> </h3> </div>);
}


function App() {
  const theme = useMemo(() => createTheme(themeSettings()), [])
  /** @type [boolean, Function] */
  const [callServerRefreshTokenCompleted, setCallServerRefreshTokenCompleted] = useState(false)
  /** @type [GlobalSettingDto, import('react').Dispatch<import('react').SetStateAction<GlobalSettingDto>>] */
  const [globalSetting, setGlobalSetting] = useState(null)


  const tryRefreshToken = async () => {
    callServerRefreshToken(false)
      .then(function (/** @type boolean */ response) {
        setCallServerRefreshTokenCompleted(true)
      })
      .catch(function (/** @type { import("axios").AxiosError<any> } */error) {
        setCallServerRefreshTokenCompleted(true)
      })
  }


  useEffect(() => {
    const getServerGlobalSettings = async () => {
      callServerAPI('GET', `/api/AppConfig`, null, false)
        .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<GlobalSettingDto>> } */ response) {
          setGlobalSetting(response.data.data);
          tryRefreshToken();
        })
    }
    getServerGlobalSettings();
  }, [])

  return (
    callServerRefreshTokenCompleted &&
    <div className="app">
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <SnackbarProvider>

            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/login-tepnguser' element={<Login />} />
              <Route path='/logout' element={<Logout />} />
              <Route element={<Layout globalSetting={globalSetting} />}>

                <Route path='/home' element={
                  <ProtectedRoute>
                    <RequestList />
                  </ProtectedRoute>
                } />
                {/*  <Route path='/initiate-request' element={
                <ProtectedRoute>
                  <RequestForm formEditMode={FormEditMode.CREATE} />
                </ProtectedRoute>
              } /> */}
                <Route path='/initiate-dossier-request' element={
                  <ProtectedRoute>
                    <InitiateRequestForm formEditMode={FormEditMode.CREATE} />
                  </ProtectedRoute>
                } />
                <Route path='/edit-request/:id' element={
                  <ProtectedRoute>
                    <InitiateRequestForm formEditMode={FormEditMode.EDIT} />
                  </ProtectedRoute>
                } />

                <Route path='/dossier' element={
                  <ProtectedRoute>
                    <DossierList />
                  </ProtectedRoute>
                } />
                <Route path='/capture-dossier/:id' element={
                  <ProtectedRoute>
                    <DossierTemplate />
                  </ProtectedRoute>
                } />
                <Route path='/capture-dossier-addendum/:id' element={
                  <ProtectedRoute>
                    <DossierAddendum />
                  </ProtectedRoute>
                } />
                <Route path='/view-dossier-field-document/:cpscRequestId/:dossierFieldDocumentName' element={
                  <ProtectedRoute>
                    <ViewDossierFieldDocument />
                  </ProtectedRoute>
                } />
                <Route path='/aggregate-dossier' element={
                  <ProtectedRoute>
                    <AggregateDossierList />
                  </ProtectedRoute>
                } />
                <Route path='/create-aggregate-dossier' element={
                  <ProtectedRoute>
                    <CreateAggregateRequests />
                  </ProtectedRoute>
                } />
                <Route path='/meeting-list' element={
                  <ProtectedRoute>
                    <MeetingList />
                  </ProtectedRoute>
                } />
                <Route path='/create-meeting' element={
                  <ProtectedRoute>
                    <CreateMeetings formEditMode={FormEditMode.CREATE} />
                  </ProtectedRoute>
                } />
                <Route path='/view-agenda-item-pdf/:meetingid/:agendaid' element={
                  <ProtectedRoute>
                    <AgendaPDF />
                  </ProtectedRoute>
                } />
                <Route path='/edit-meeting/:id' element={
                  <ProtectedRoute>
                    <CreateMeetings formEditMode={FormEditMode.EDIT} />
                  </ProtectedRoute>
                } />



                <Route path='/users' element={
                  <ProtectedRoute>
                    <UsersList />
                  </ProtectedRoute>
                } />

                <Route path='/roles' element={
                  <ProtectedRoute>
                    <Roles />
                  </ProtectedRoute>
                } />
                <Route path='/business-areas' element={
                  <ProtectedRoute>
                    <BusinessArea />
                  </ProtectedRoute>
                } />
                <Route path='/cpdomains' element={
                  <ProtectedRoute>
                    <CPDomainList />
                  </ProtectedRoute>
                } />
                <Route path="/serverError" />
                <Route path="*" element={<NoRouteMatch />} />
              </Route>
              <Route path='/meetingpresentation/:id' element={
                <ProtectedRoute>
                  <MeetingPresentation />
                </ProtectedRoute>
              } />
            </Routes>

          </SnackbarProvider>

        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
