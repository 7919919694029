import React from 'react'
import { ContractualProvisionsSlideField, ContractualProvisionsSlideTooltip } from '../../helpers/DossierFieldConstants';

import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} ContractualProvisionsSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly'
  * @property {number} dossierRefreshCount
 */

/**
 * @param {ContractualProvisionsSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ContractualProvisionsSlide(props) {

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, contractualProvisionsSlide: { ...props.cpscRequest.dossier.contractualProvisionsSlide, [name]: value } })
}

  return (
   
    <form style={{ fontSize: '12px' }}>
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ContractualProvisionsSlideTooltip.details)}Contractual Provisions Details <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.contractualProvisionsSlide.details} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ContractualProvisionsSlideField.details, editorHtmlString)} />

      </div>
    </form>
      )
}

      export default ContractualProvisionsSlide