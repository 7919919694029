import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navigationbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { ENVIRONMENT, Environment } from "../../helpers/Constants";
import { Info } from "@mui/icons-material";
/**
 * @typedef {Object} LayoutProps
 * @property {GlobalSettingDto} globalSetting
 */

/**
 * @param {LayoutProps}  props
 * @return {import('react').ReactElement}
 */
function Layout(props) {
  const isNonMobile = useMediaQuery('(min-width: 600px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(isNonMobile);

  return (
    <>
   
   
      { ENVIRONMENT === Environment.Test &&
        <>
        <div style={{ fontSize: 13, width: '100%',  textAlign:'center', background:'red', padding:'5px'}} >
          <div style={{color:'white', fontWeight:'600'}}> <Info />&nbsp;This is the Test Environment - Dossiers submited on this link will not be treated</div>
          </div>
          </>
      }
       {ENVIRONMENT === Environment.Dev &&
        <>
        <div style={{ fontSize: 13, width: '100%',  textAlign:'center', background:'red', padding:'5px'}} >
          <div style={{color:'white', fontWeight:'600'}}> <Info />&nbsp;This is the DEV Environment - Dossiers submited on this link will not be treated</div>
          </div>
          </>
      }
      
      <Box display={isNonMobile ? "flex" : "block"} width='100%' height='90%'>
        <Sidebar
          isNonMobile={isNonMobile}
          drawerWidth={isNonMobile ? "100px" : "100%"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          globalSetting={props.globalSetting} 
          
        />
        <Box flexGrow={1}>
          <Navigationbar
            isNonMobile={isNonMobile}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            globalSetting={props.globalSetting} />
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export default Layout