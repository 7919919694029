import React from 'react'
import {  HseEvaluationSlideField, HseEvaluationSlideTooltip } from '../../helpers/DossierFieldConstants';
import CustomTabPanel from '../tabs/CustomTabPanel';
import { Tab, Tabs } from '@mui/material';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import HseEvaluationUpload from './HseEvaluationUpload';
import HseEvaluationFormSlide from './HseEvaluationForm';
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

/**
 * @typedef {Object} HseEvaluationSlideProps

 * @property {boolean} readOnly
  * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
  * @property {downloadPdfCallback} downloadPdf
  * @property {uploadPdfCallback} uploadPdf
  * @property {deletePdfCallback} deletePdf
  * @property {downloadPdfCallback} mobileDownloadPdf
   * @property {number} dossierRefreshCount
 */

/**
 * @param {HseEvaluationSlideProps}  props
 * @return {import('react').ReactElement}
 */
function HseEvaluationSlide(props) {
  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, hseEvaluationSlide: { ...props.cpscRequest.dossier.hseEvaluationSlide, [name]: value } })
}


  return (
   
    <>
      <div className='content-desktop'>
        <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Hse Evaluation " value={1} />

          <Tab label="Hse Evaluation Upload" value={2} />

        </Tabs>
      </div>
      <div className='content-mobile'>
        <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Hse Evaluation " value={1} />

          <Tab label="Hse Evaluation Upload" value={2} />

        </Tabs>
      </div>
      <CustomTabPanel value={selectedTabValue} index={1} >
        <HseEvaluationFormSlide setDossier={props.setDossier} cpscRequest={props.cpscRequest} readOnly={props.readOnly} />
        <br />
      <div className="form-group">
        <label className=' title-label'>{getSlideTooltip(props.cpscRequest, HseEvaluationSlideTooltip.supportingInformation)}Supporting Information<span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.hseEvaluationSlide.supportingInformation} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(HseEvaluationSlideField.supportingInformation, editorHtmlString)} />  
      </div>
      <br />
      <div className="form-group">
        <label className=' title-label'>Additional Comments <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.hseEvaluationSlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(HseEvaluationSlideField.additionalComments, editorHtmlString)} />  
      </div>
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabValue} index={2} >
       <HseEvaluationUpload readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} mobileDownloadPdf={props.mobileDownloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
      </CustomTabPanel>


    </>
  )
}

export default HseEvaluationSlide