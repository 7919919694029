import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { callServerAPI } from '../../helpers/Helpers';
import { preloader } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import { useSnackbar } from 'notistack';
import Replay from '@mui/icons-material/Replay';
import { Button, Stack, Pagination, Grid, MenuItem, TextField, Box, Paper, } from '@mui/material';
import { Add } from '@mui/icons-material';
import Table from 'react-bootstrap/Table';
import Header from '../../components/TitleHeader';


const MySwal = withReactContent(Swal);

function AggregateDossierList() {
    const { enqueueSnackbar } = useSnackbar();
    /** @type { [AggregatedCpscRequest[], Function] } */
    let [aggregatedRequests, setAggregatedRequests] = useState([]);
    /** @type { [AggregatedCpscRequest[], Function] } */
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [numOfPages, setNumOfPages] = useState(0);
    /** @type [AggregatedCpscRequest[], import('react').Dispatch<import('react').SetStateAction<AggregatedCpscRequest[]>>] */
    const [currentPageRecords, setCurrentPageRecords] = useState([]);

    const getAggregatedRequests = async () => {
        callServerAPI('GET', '/api/CpscRequest/GetAllAggregatedRequest', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<AggregatedCpscRequest[]>> } */ response) {
                setAggregatedRequests(response.data.data);
                setFilteredRequests(response.data.data);
            })

    }
    function refreshRequests() {
        getAggregatedRequests();
    }
    useEffect(() => {
        getAggregatedRequests();
    }, [])
    useEffect(() => {
        setFilteredRequests(aggregatedRequests.filter(e =>

            (searchQuery === "" || e.aggregatedTitle.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, aggregatedRequests])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredRequests.length / recordsPerPage))
    }, [recordsPerPage, filteredRequests])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredRequests.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredRequests])

    const handleDelete = (e, id) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Do you want to Unmerge the Dossiers ?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    deleteAggregatedRequest(id);
                    enqueueSnackbar("Aggregated Dossier unmerged Successfully", { variant: 'success' })
                  
                
                }
            })
    };
    const deleteAggregatedRequest = (id) => {
        callServerAPI('DELETE', `/api/CpscRequest/DeleteAggregatedRequest?requestId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setAggregatedRequests(aggregatedRequests.filter(r => r.id !== id));
                enqueueSnackbar("Aggregated Dossier Deleted Successfully", { variant: 'success' })
            })

    }
    function downloadDocumentPdf(id) {
        MySwal.fire(preloader)
        callServerAPI('GET', `/api/CpscRequest/GetAggregatedDossierPdf?aggregateRequestId=${id}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                window.open(URL.createObjectURL(response.data));
                MySwal.close();
            })


    }


    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/create-aggregate-dossier');
    }


    return (
        <Box m="1.0rem 1.0rem">
            <Header title="Aggregate Dossier List" />
            <Box>
                <Paper>
                    <Box mt="20px" p='1.0rem' gap="20px">
                        <Grid container className=' d-flex  justify-content-between'>

                            <Grid item xs={12} sm={6} lg={8}  >
                                <div className='mb-2 mt-1' >
                                    <input type="text" className="form-control input-font bold" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search by Aggregated Title...' />
                                </div>

                            </Grid>
                            <Grid style={{ float: 'right' }} item xs={12} sm={4} lg={4} >
                                <div className=' d-flex  justify-content-end'>
                                    <Button variant="contained" onClick={refreshRequests} size='medium' style={{ textTransform: 'none', fontSize: 14 }}> <Replay />&nbsp; Refresh</Button>&nbsp;
                                    <Button variant="contained" color='secondary' onClick={routeChange} size='medium' style={{ textTransform: 'none', fontSize: 14 }}><Add />&nbsp;New Aggregate Request</Button>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} >
                                <Table responsive striped bordered>
                                    <thead className='thead-text'>
                                        <tr style={{ fontSize: '10px', }}>
                                            <th >SN</th>
                                            <th>Aggregated Title</th>
                                            <th>Cpsc Requests  </th>
                                            <th>Objective</th>
                                            <th>Contractor </th>
                                            <th style={{ minWidth: 140, textAlign: 'center' }}>Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageRecords.map((item, index) => {
                                            return <tr style={{ fontSize: '11px', }} className='trtext' key={index}>
                                                <>
                                                    <td>{index + 1}</td>
                                                    <td title={item.id}>{item.aggregatedTitle}</td>
                                                    <td>
                                                        {item.cpscRequests.map((request, index) => {
                                                            return <div key={index}>{request.contractTitle}</div>
                                                        })



                                                        }
                                                        {item.cpscRequests.length === 0 ?
                                                            <td colSpan={1}><p className=' text-center'>No request....</p></td> : ''}
                                                    </td>
                                                    <td>
                                                        {item.objective}
                                                    </td>
                                                    <td>
                                                        {item.serviceProvider}
                                                    </td>
                                                    <td style={{ minWidth: 140, textAlign: 'center' }} >
                                                        {item.hasBeenScheduled ?
                                                            "Scheduled for Presentation"
                                                            :
                                                            <Button variant='contained' size='small' color='secondary' onClick={(e) => handleDelete(e, item.id)} >Unmerge</Button>


                                                        } &nbsp;
                                                        <Button variant='contained' size='small' color="secondary" onClick={(e) => downloadDocumentPdf(item.id)}  >View PDF</Button>&nbsp;&nbsp;
                                                    </td>
                                                </>
                                            </tr>


                                        })}
                                        {currentPageRecords.length === 0 ? <tr>
                                            <td colSpan={6}><p className=' text-center'>No records....</p></td>
                                        </tr> : ''}
                                        {aggregatedRequests.length === 0 ? <tr>
                                            <td colSpan={6}><p className=' text-center'>Please create an Aggregated CPSC request....</p></td>
                                        </tr> : ''}

                                    </tbody>

                                </Table>
                            </Grid>
                        </Grid>
                        <br />
                        <nav>
                            <div className=" d-flex justify-content-between ">
                                <div className=" d-flex justify-content-between ">
                                    <div className='rowperpage'>Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} style={{ width: "60%" }}>
                                        <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </TextField>
                                    </Grid>
                                </div>
                                <Stack spacing={2}>
                                    <Pagination color="primary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>
                            </div>
                            <br />


                        </nav>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default AggregateDossierList