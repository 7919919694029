import React from 'react'
import Select from 'react-select'
import Table from 'react-bootstrap/Table';
const scoreOptions = [{ value: 0, label: "Select" }, { value: 1, label: "1" }, { value: 2, label: "2" }, { value: 3, label: "3" }]

/**
 * @typedef {Object} NigerianContentSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 */

/**
 * @param {NigerianContentSlideProps}  props
 * @return {import('react').ReactElement}
 */

function NigerianContentSlide(props) {

    /** @param {string} name, @param {any} value  */
    const updateDataFieldValue = (name, value) => {
        props.setDossier({ ...props.cpscRequest.dossier, nigerianContentSlide: { ...props.cpscRequest.dossier.nigerianContentSlide, [name]: value } })

    }
    function scoreSum() {

        var total = Number(props.cpscRequest.dossier.nigerianContentSlide.jobComplexityRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.availableNigerianVendorsRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.availableEquipmentInCountryRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.availableMaterialInCountryRating)
            + Number(props.cpscRequest.dossier.nigerianContentSlide.requiredForeignExpertiseRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.expectedInCountryRetentionRating)

        return total
    }

    function checkStatus() {
        var total = Number(props.cpscRequest.dossier.nigerianContentSlide.jobComplexityRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.availableNigerianVendorsRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.availableEquipmentInCountryRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.availableMaterialInCountryRating)
            + Number(props.cpscRequest.dossier.nigerianContentSlide.requiredForeignExpertiseRating) + Number(props.cpscRequest.dossier.nigerianContentSlide.expectedInCountryRetentionRating)


        if (Number(total < 8)) {
            return <p style={{ color: 'red', fontWeight: 'bold' }}> Low </p>
        }

        if (Number(total >= 9 && total <= 12)) {
            return <p style={{ color: 'blue', fontWeight: 'bold' }}> Medium </p>
        }


        if (Number(total >= 13 || total <= 18)) {
            return <p style={{ color: 'green', fontWeight: 'bold' }}> High </p>
        }



        return false;

    }
    return (
        <>
            <Table responsive striped bordered>
                <thead >
                    <tr>

                        <th>Scores</th>
                        <th>3</th>
                        <th>2</th>
                        <th>1</th>
                    </tr>
                </thead>
                <tbody style={{ fontSize: '12px' }}>
                    <tr>

                        <td> What is the Job Complexity Rating? </td>
                        <td> 0 {'-'} 10% </td>
                        <td> 11 {'-'} 50% </td>
                        <td> 51 {'-'} 100% </td>
                        <td style={{ minWidth: 120 }}>
                            <Select classNames={{ control: (state) => state.isDisabled ? 'react-select-disabled' : '', singleValue: (state) => state.isDisabled ? 'react-select-disabled' : '' }} isDisabled={props.readOnly}
                                name="jobComplexityRating" value={{ label: scoreOptions.find(e => e.value === props.cpscRequest.dossier.nigerianContentSlide.jobComplexityRating).label, value: props.cpscRequest.dossier.nigerianContentSlide.jobComplexityRating }}
                                onChange={(newValue) => updateDataFieldValue("jobComplexityRating", newValue?.value)} required options={scoreOptions}
                            />
                        </td>

                    </tr>
                    <tr>

                        <td>How many Nigerian Vendors can do this?    </td>
                        <td> {'>'}10% </td>
                        <td> 4 - 10% </td>
                        <td> 0 - 3% </td>
                        <td>
                            <Select classNames={{ control: (state) => state.isDisabled ? 'react-select-disabled' : '', singleValue: (state) => state.isDisabled ? 'react-select-disabled' : '' }} isDisabled={props.readOnly}
                                name="availableNigerianVendorsRating" value={{ label: scoreOptions.find(e => e.value === props.cpscRequest.dossier.nigerianContentSlide.availableNigerianVendorsRating).label, value: props.cpscRequest.dossier.nigerianContentSlide.availableNigerianVendorsRating }}
                                onChange={(newValue) => updateDataFieldValue("availableNigerianVendorsRating", newValue?.value)} required options={scoreOptions}
                            />

                        </td>

                    </tr>
                    <tr>

                        <td> What is the required foreign expertise impute for this job?     </td>
                        <td> 0% {'-'} 10% </td>
                        <td> 11% {'-'} 50%  </td>
                        <td> 51% - 100% </td>
                        <td>
                            <Select classNames={{ control: (state) => state.isDisabled ? 'react-select-disabled' : '', singleValue: (state) => state.isDisabled ? 'react-select-disabled' : '' }} isDisabled={props.readOnly}
                                name="requiredForeignExpertiseRating" value={{ label: scoreOptions.find(e => e.value === props.cpscRequest.dossier.nigerianContentSlide.requiredForeignExpertiseRating).label, value: props.cpscRequest.dossier.nigerianContentSlide.requiredForeignExpertiseRating }}
                                onChange={(newValue) => updateDataFieldValue("requiredForeignExpertiseRating", newValue?.value)} required options={scoreOptions}
                            />

                        </td>

                    </tr>
                    <tr>

                        <td>  What % of material for this job can be sourced in-country? </td>
                        <td> 51% {'-'} 100%  </td>
                        <td> 11% {'-'} 50% </td>
                        <td> 0% {'-'} 10% </td>
                        <td>
                            <Select classNames={{ control: (state) => state.isDisabled ? 'react-select-disabled' : '', singleValue: (state) => state.isDisabled ? 'react-select-disabled' : '' }} isDisabled={props.readOnly}
                                name="availableMaterialInCountryRating" value={{ label: scoreOptions.find(e => e.value === props.cpscRequest.dossier.nigerianContentSlide.availableMaterialInCountryRating).label, value: props.cpscRequest.dossier.nigerianContentSlide.availableMaterialInCountryRating }}
                                onChange={(newValue) => updateDataFieldValue("availableMaterialInCountryRating", newValue?.value)} required options={scoreOptions}
                            />


                        </td>

                    </tr>
                    <tr>

                        <td>What % of equipment  for this job can be sourced in-country?   </td>
                        <td> 51% {'-'} 100%  </td>
                        <td> 11% {'-'} 50% </td>
                        <td> 0% {'-'} 10% </td>
                        <td>
                            <Select classNames={{ control: (state) => state.isDisabled ? 'react-select-disabled' : '', singleValue: (state) => state.isDisabled ? 'react-select-disabled' : '' }} isDisabled={props.readOnly}
                                name="availableEquipmentInCountryRating" value={{ label: scoreOptions.find(e => e.value === props.cpscRequest.dossier.nigerianContentSlide.availableEquipmentInCountryRating).label, value: props.cpscRequest.dossier.nigerianContentSlide.availableEquipmentInCountryRating }}
                                onChange={(newValue) => updateDataFieldValue("availableEquipmentInCountryRating", newValue?.value)} required options={scoreOptions}
                            />

                        </td>

                    </tr>
                    <tr>

                        <td>  What is the expected in country retention?   </td>
                        <td> 71% {'-'} 100% </td>
                        <td> 41% {'-'} 70%  </td>
                        <td> 0% {'-'}40% </td>
                        <td>

                            <Select classNames={{ control: (state) => state.isDisabled ? 'react-select-disabled' : '', singleValue: (state) => state.isDisabled ? 'react-select-disabled' : '' }} isDisabled={props.readOnly}
                                name="expectedInCountryRetentionRating" value={{ label: scoreOptions.find(e => e.value === props.cpscRequest.dossier.nigerianContentSlide.expectedInCountryRetentionRating).label, value: props.cpscRequest.dossier.nigerianContentSlide.expectedInCountryRetentionRating }}
                                onChange={(newValue) => updateDataFieldValue("expectedInCountryRetentionRating", newValue?.value)} required options={scoreOptions}
                            />


                        </td>

                    </tr>
                    <tr>

                        <td >What is the appropriate Currency  split </td>

                        <td colSpan={2}>NGN %
                            <input id='currencySplitNairaPercent' type="number" className="form-control input-font bold" onChange={(e) => { updateDataFieldValue("currencySplitNairaPercent", e.target.value) }}
                                value={props.cpscRequest.dossier.nigerianContentSlide.currencySplitNairaPercent}
                                required max={100} min={0} step={1} disabled={props.readOnly} /> </td>
                        <td colSpan={2}>USD %
                            <input id='currencySplitForeignPercent' type="number" className="form-control input-font bold" onChange={(e) => { updateDataFieldValue("currencySplitForeignPercent", e.target.value) }}
                                value={props.cpscRequest.dossier.nigerianContentSlide.currencySplitForeignPercent}
                                required max={100} min={0} step={1} disabled={props.readOnly} />
                        </td>
                    </tr>
                </tbody>

            </Table>
            <div style={{ display: 'flex', float: 'right', padding: '2px' }}>
                <div>{checkStatus()}</div>:&nbsp;<span className='total' > {scoreSum()}</span>

            </div>
            <br></br>
            <div style={{ color: '#43cbb8;', fontWeight: 'bold' }}>LEGEND</div>
            <table className=" " style={{ border: '1px solid grey' }}>


                <tbody>
                    <tr>
                        <td style={{ color: 'green', fontWeight: 'bold' }}>High</td>
                        <td>13 - 18  </td>
                    </tr>
                    <tr>
                        <td style={{ color: 'blue', fontWeight: 'bold' }}>Medium</td>
                        <td>&nbsp; 9 - 12  </td>
                    </tr>
                    <tr>
                        <td style={{ color: 'red', fontWeight: 'bold' }}>Low</td>
                        <td> &lt; 8 </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default NigerianContentSlide