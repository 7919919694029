import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'
import { preloader } from '../../helpers/Constants';
import { callServerAPI } from '../../helpers/Helpers';
import { useSnackbar } from 'notistack';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';

const MySwal = withReactContent(Swal);
/**
 * @typedef {Object} AdhocDossierSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {string} slideName
 * @property {boolean} readOnly
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequest>>} setCpscRequest
 * @property {import('react').Dispatch<import('react').SetStateAction<number>>} setCurrentSlideNumber
 */

/**
 * @param {AdhocDossierSlideProps}  props
 * @return {import('react').ReactElement}
 */
function AdhocDossierSlide(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [adhocSlideObjectUrl, setAdhocSlideObjectUrl] = useState(null);

    useEffect(() => {
        setAdhocSlideObjectUrl(null)
    }, [props.slideName, props.cpscRequest])

    function deleteAdhocDossierSlideOnServer() {
        MySwal.fire(preloader)
        callServerAPI('DELETE', `/api/CpscRequest/DeleteAdhocDossierSlide?requestId=${props.cpscRequest.id}&slideName=${props.slideName}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest>> } */ response) {
                props.setCurrentSlideNumber(1)
                props.setCpscRequest(response.data.data)
                MySwal.close()
                enqueueSnackbar(`${props.slideName} Slide  Deleted Successfully`, { variant: 'success' });
            })
    }

    const deleteAdhocDossierSlide = () => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Do you want to delete ${props.slideName} slide?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            showCancelButton: true,
            confirmButtonColor: '#07bf69',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) deleteAdhocDossierSlideOnServer()
        })
    };

    async function mobileDownloadAdhocDossierSlide() {
        await callServerAPI('GET', `/api/CpscRequest/DownloadAdhocDossierSlide?requestId=${props.cpscRequest.id}&slideName=${props.slideName}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                window.open(URL.createObjectURL(response.data));
            })
    }

    async function downloadAdhocDossierSlide() {
        await callServerAPI('GET', `/api/CpscRequest/DownloadAdhocDossierSlide?requestId=${props.cpscRequest.id}&slideName=${props.slideName}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                const url = URL.createObjectURL(response.data);
                setAdhocSlideObjectUrl(url)
            })
    }

    return (
        <>
            <div className='content-desktop'>
                <div>
                    <div>
                        <div className='files' >
                            <div className='file-name'>{props.slideName} Slide</div>
                            <div className='dld-file'> <Button variant='contained' color='success'
                                onClick={() => downloadAdhocDossierSlide()}><RemoveRedEyeOutlinedIcon /> &nbsp;View</Button></div>
                            <div className='del-file'>
                                <Button variant='contained' color='error' onClick={() => { deleteAdhocDossierSlide() }}
                                    disabled={props.readOnly}>
                                    <DeleteSweepOutlinedIcon /> &nbsp;Delete</Button>
                            </div>
                        </div>
                        {adhocSlideObjectUrl && <iframe src={adhocSlideObjectUrl} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>}
                    </div>
                </div>
            </div>
            <div className='content-mobile'>
                <div>
                    <div>
                        <div className='files' >
                            <div className='mobile-file-name'>{props.slideName} Slide</div><br />
                            <div >
                                <div >
                                    <Button style={{ width: '100%' }} variant='contained' color='success' onClick={() => mobileDownloadAdhocDossierSlide()}> <RemoveRedEyeOutlinedIcon /> &nbsp;View</Button></div>
                                <div >
                                    <br />
                                    <Button style={{ width: '100%' }} variant='contained' color='error' onClick={() => { deleteAdhocDossierSlide() }} disabled={props.readOnly}> <DeleteSweepOutlinedIcon /> &nbsp; Delete </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AdhocDossierSlide