import React from 'react'
import { useRef } from 'react';
import '../../css/sidebar.css';
import { Box, Button, Drawer, Grid, IconButton, Menu, MenuItem, Typography, } from "@mui/material";
import { ChevronLeft, Person, AdminPanelSettings, FolderCopy, Folder, Groups, Article } from "@mui/icons-material";
import { useParams } from 'react-router-dom';
import { GlobalRole, globalStore } from '../../helpers/Constants';
import { APP_LAST_UPDATED, ENVIRONMENT, APP_VERSION, SERVER_URL } from '../../helpers/Constants';
import QuizIcon from '@mui/icons-material/Quiz';
import Fade from '@mui/material/Fade';

/**
 * @typedef {Object} SidebarProps
 * @property {GlobalSettingDto} globalSetting
 * @property {String} drawerWidth,
 * @property {Boolean} isSidebarOpen,
 * @property {Function}  setIsSidebarOpen,
 * @property {Boolean}  isNonMobile,

 */

/**
 * @param {SidebarProps}  props
 * @return {import('react').ReactElement}
 */


function Sidebar(props) {


    const path = window.location.pathname.replace('/', '');

    const { id } = useParams();

    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");




    const topref = useRef(null);
    const bottomref = useRef(null);

    /* const scrolToTop = () => {
        topref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const scrolToBottom = () => {
        bottomref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const fabStyle = {
        position: 'fixed',
        bottom: 0,
        left: 16,
    }; */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box component='nav'>
            {props.isSidebarOpen && (
                <Drawer
                    open={props.isSidebarOpen}
                    onClose={() => props.setIsSidebarOpen(false)}
                    variant="persistent"
                    anchor="left"
                    sx={{
                        width: props.drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: props.drawerWidth,
                            boxSizing: 'border-box',
                            overflowY: 'hidden'
                        },
                    }}
                >
                    <Box width='100%'>
                        <Box >
                            {!props.isNonMobile && (
                                <IconButton onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}>
                                    <ChevronLeft />
                                </IconButton>
                            )}

                            {
                                props.isNonMobile && (
                                    <div className='' >
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <img src="../../assets/images/logo.png" alt='logo' style={{  width: '100%', objectFit: 'cover' }} />
                                        </div>
                                    </div>
                                )
                            }

                        </Box>
                    </Box>

                    <div>
                        {props.isNonMobile &&
                            <div className='side-nav-area' style={{ overflowY: 'auto', height: 'calc(100vh - 10px)' }}>
                                <ul >
                                    <div ref={topref} />
                                    {/*  <Fab size="small"color="default" onClick={scrolToTop} aria-label="add">
                                    <ArrowUpward />
                                </Fab> */}
                                    <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                        <a href="/home" className={path === 'home' ? 'isactive' : path === 'initiate-request' ? 'isactive' : path === `edit-request/${id}` ? 'isactive' : ''}>
                                            <div className='sidebar-options'>
                                                <div className='icon-card' style={{ padding: '1px' }}>
                                                    <Article fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                </div>

                                                <div><b>Requests</b></div>
                                            </div>
                                        </a>
                                    </li>

                                    <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                        <a href="/dossier" className={path === 'dossier' ? 'isactive' : path === `capture-dossier/${id}` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                            <div className='sidebar-options'>
                                                <div className='icon-card'>
                                                    <Folder fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                </div>

                                                <div><b>Dossier</b></div>
                                            </div>
                                        </a>
                                    </li>

                                    <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                        <a href="/aggregate-dossier" className={path === 'aggregate-dossier' ? 'isactive' : path === `create-aggregate-dossier` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                            <div className='sidebar-options'>
                                                <div className='icon-card'>
                                                    <FolderCopy fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                </div>

                                                <div><b>Aggregation</b></div>
                                            </div>
                                        </a>
                                    </li>

                                    {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                        <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                            <a href="/meeting-list" className={path === 'meeting-list' ? 'isactive' : path === `create-meeting` ? 'isactive' : path === `edit-meeting/${id}` ? 'isactive' : ''}>
                                                <div className='sidebar-options'>
                                                    <div className='icon-card'>
                                                        <Groups fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                    </div>

                                                    <div><b>Meeting</b></div>
                                                </div>
                                            </a>
                                        </li>
                                    }


                                    <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                        <a href="/business-areas" className={path === 'business-areas' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                            <div className='sidebar-options'>
                                                <div className='icon-card'>
                                                    <Folder fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                </div>

                                                <div><b>Business Areas</b></div>
                                            </div>
                                        </a>
                                    </li>

                                    <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                        <a href="/cpdomains" className={path === 'cpdomains' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                            <div className='sidebar-options'>
                                                <div className='icon-card'>
                                                    <Folder fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                </div>

                                                <div><b>C&P Domains</b></div>
                                            </div>
                                        </a>
                                    </li>

                                    {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                        <>
                                            <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                <a href="/users" className={path === 'users' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                    <div className='sidebar-options'>
                                                        <div className='icon-card'>
                                                            <Person fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                        </div>

                                                        <div><b>Users</b></div>
                                                    </div>
                                                </a>
                                            </li>

                                            <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                <a href="/roles" className={path === 'roles' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                    <div className='sidebar-options'>
                                                        <div className='icon-card'>
                                                            <AdminPanelSettings fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                        </div>

                                                        <div><b>Roles</b></div>
                                                    </div>
                                                </a>
                                            </li>


                                            <br />
                                        </>

                                    }
                                    {/* <Fab size="small" color="default" onClick={scrolToTop} aria-label="add">
                                    <ArrowUpward />
                                </Fab> */}
                                    <div ref={bottomref} />
                                    <br />
                                    <br />
                                    {/* <Fab sx={fabStyle} color="default" onClick={scrolToBottom} aria-label="add">
                                    <ExpandMore />
                                </Fab> */}
                                </ul>
                            </div>
                        }
                        {!props.isNonMobile &&

                            <div className='side-nav-area' style={{ overflowY: 'auto', height: 'calc(100vh - 10px)' }}>
                                {(loggedOnTepngUser?.roles.includes(GlobalRole.GCC) || loggedOnTepngUser?.roles.includes(GlobalRole.Admin)) &&
                               <>
                               <div style={{float:'left' , marginLeft:'50px' }}>
                                    Role: &nbsp;
                                        {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                            <div className='badge'>GCC</div>
                                        }&nbsp;
                                        {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                            <div className='badge'>Admin</div>
                                        }
                                  </div>
                                  </>
}
                                <Grid container >
                                    <Grid item xs={6} sm={6}  >
                                        <div>
                                            <Button style={{ color: 'black', fontSize: '12px', textTransform: 'capitalize' }}
                                                id="demo-positioned-button"
                                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                            >
                                                User Guide <QuizIcon style={{ color: '#f54a5a', }} />
                                            </Button>
                                            <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'fade-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                            >
                                                <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Initiation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Initiation Manual</MenuItem>
                                                <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Preparation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Preparation Manual</MenuItem>
                                                <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Aggregation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Aggregation Manual</MenuItem>
                                                {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Meeting Scheduling Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Meeting Scheduling Manual</MenuItem>
                                                }
                                                {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Meeting Administration Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Meeting Administration Manual</MenuItem>
                                                }
                                                {(loggedOnTepngUser?.roles.includes(GlobalRole.CPSCMember) || loggedOnTepngUser?.roles.includes(GlobalRole.GCC)) &&
                                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Meeting Presentation Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Meeting Presentation Manual</MenuItem>
                                                }
                                                {(loggedOnTepngUser?.roles.includes(GlobalRole.GCC) || loggedOnTepngUser?.roles.includes(GlobalRole.CpscChairman) || loggedOnTepngUser?.roles.includes(GlobalRole.CpscSecretary)) &&
                                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Minutes of Meeting Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Minutes of Meeting Manual</MenuItem>
                                                }
                                                <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Dossier Addendum Manual.pdf?chromeless=true', '_blank')?.focus(); }}> <QuizIcon style={{ color: 'gray', }} /> &nbsp;Dossier Addendum Manual</MenuItem>
                                                {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                                    <MenuItem onClick={() => { window.open(SERVER_URL + '/UserGuides/Admin Manual.pdf?chromeless=true', '_blank')?.focus(); }}><QuizIcon style={{ color: 'gray', }} /> &nbsp;Admin Manual</MenuItem>
                                                }
                                            </Menu>
                                        </div>
                                    </Grid>
                                    <Grid xs={6} item sm={6}  >
                                        <div style={{ textAlign: 'center' }}>
                                            <Typography
                                                title={
                                                    "Client Environment: " + ENVIRONMENT +
                                                    "\nServer Environment: " + props.globalSetting?.environment +
                                                    "\nClient App Version: " + APP_VERSION +
                                                    "\nServer App Version: " + props.globalSetting?.appVersion +
                                                    "\nClient Last Updated: " + new Date(APP_LAST_UPDATED) +
                                                    "\nServer Last Updated: " + (props.globalSetting == null ? "" : new Date(props.globalSetting.appLastUpdated))}
                                            >
                                                <Button style={{ width: '100%', color: '#023361', textTransform: 'uppercase', fontWeight: '600', fontSize: '12px' }}>{ENVIRONMENT}</Button>
                                            </Typography >
                                        </div>
                                    </Grid>
                                </Grid>
                                <hr />
                                <ul >
                                    <Grid container >
                                        <Grid item xs={6} sm={6}  >
                                            <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                <a href="/home" className={path === 'home' ? 'isactive' : path === 'initiate-request' ? 'isactive' : path === `edit-request/${id}` ? 'isactive' : ''}>
                                                    <div className='sidebar-options'>
                                                        <div className='icon-card' style={{ padding: '1px' }}>
                                                            <Article fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                        </div>

                                                        <div><b>Requests</b></div>
                                                    </div>
                                                </a>
                                            </li>
                                        </Grid>
                                        <Grid xs={6} item sm={6}  >
                                            <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                <a href="/dossier" className={path === 'dossier' ? 'isactive' : path === `capture-dossier/${id}` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                    <div className='sidebar-options'>
                                                        <div className='icon-card'>
                                                            <Folder fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                        </div>

                                                        <div><b>Dossier</b></div>
                                                    </div>
                                                </a>
                                            </li>
                                        </Grid>
                                    </Grid>
                                    <Grid container >
                                        <Grid xs={6} item sm={6}  >
                                            <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                <a href="/aggregate-dossier" className={path === 'aggregate-dossier' ? 'isactive' : path === `create-aggregate-dossier` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                    <div className='sidebar-options'>
                                                        <div className='icon-card'>
                                                            <FolderCopy fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                        </div>

                                                        <div><b>Aggregation</b></div>
                                                    </div>
                                                </a>
                                            </li>
                                        </Grid>
                                        <Grid xs={6} item sm={6}  >
                                            {loggedOnTepngUser?.roles.includes(GlobalRole.GCC) &&
                                                <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                    <a href="/meeting-list" className={path === 'meeting-list' ? 'isactive' : path === `create-meeting` ? 'isactive' : path === `edit-meeting/${id}` ? 'isactive' : ''}>
                                                        <div className='sidebar-options'>
                                                            <div className='icon-card'>
                                                                <Groups fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                            </div>

                                                            <div><b>Meeting</b></div>
                                                        </div>
                                                    </a>
                                                </li>
                                            }
                                        </Grid>

                                    </Grid>
                                    <Grid container >
                                        <Grid xs={6} item sm={6}  >
                                            <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                <a href="/business-areas" className={path === 'business-areas' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                    <div className='sidebar-options'>
                                                        <div className='icon-card'>
                                                            <Folder fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                        </div>

                                                        <div><b>Business Areas</b></div>
                                                    </div>
                                                </a>
                                            </li>
                                        </Grid>
                                        <Grid xs={6} item sm={6}  >
                                            <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                <a href="/cpdomains" className={path === 'cpdomains' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                    <div className='sidebar-options'>
                                                        <div className='icon-card'>
                                                            <Folder fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                        </div>

                                                        <div><b>C&P Domains</b></div>
                                                    </div>
                                                </a>
                                            </li>
                                        </Grid>
                                    </Grid>

                                    {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                        <>
                                            <Grid container >
                                                <Grid xs={6} item sm={6}  >
                                                    <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                        <a href="/users" className={path === 'users' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                            <div className='sidebar-options'>
                                                                <div className='icon-card'>
                                                                    <Person fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                                </div>

                                                                <div><b>Users</b></div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </Grid>
                                                <Grid xs={6} item sm={6}  >
                                                    <li style={{ textAlign: 'center', marginBottom: '10px' }} >
                                                        <a href="/roles" className={path === 'roles' ? 'isactive' : path === `` ? 'isactive' : path === '' ? 'isactive' : ''}>
                                                            <div className='sidebar-options'>
                                                                <div className='icon-card'>
                                                                    <AdminPanelSettings fontSize='large' style={{ margin: '5px', fontSize: '24px' }} />
                                                                </div>

                                                                <div><b>Roles</b></div>
                                                            </div>
                                                        </a>
                                                    </li>

                                                </Grid>
                                            </Grid>
                                            <br />
                                        </>

                                    }



                                </ul>
                            </div>
                        }

                    </div>
                </Drawer>
            )
            }
        </Box >
    )
}

export default Sidebar