import React, { useState } from 'react'
import {  FormEditMode } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'
import { useSnackbar } from 'notistack';
import HighRiskForm from './HighRiskForm';
import Table from 'react-bootstrap/esm/Table';
import { Button, Grid } from '@mui/material';

const MySwal = withReactContent(Swal);

/**
 * @typedef {Object} HighRiskIdentificationSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 */

/**
 * @param {HighRiskIdentificationSlideProps}  props
 * @return {import('react').ReactElement}
 */
function HighRiskIdentificationSlide(props) {

    /** @type HighRisk */ let emptyHighRisk = {
    serialNo: props.cpscRequest.dossier.highRiskServicesSlide.highRisks == null || props.cpscRequest.dossier.highRiskServicesSlide.highRisks.length === 0 ? 1 : Math.max.apply(Math, props.cpscRequest.dossier.highRiskServicesSlide.highRisks.map(function (o) { return o.serialNo; })) + 1,
    riskIdentified: "",
    mitigationMeasure: "",
    responsible: "",
    when: "",

  }


  /** @type [Boolean, Function] */
  const [displayHighRiskForm, setDisplayHighRiskForm] = useState(false);
  /** @type [Boolean, Function] */
  const [displayAddHighRisk, setDisplayAddHighRisk] = useState(true);
  /** @type [Number, Function] */
  const [highRiskFormEditMode, setHighRiskFormEditMode] = useState(FormEditMode.CREATE);
  /** @type [HighRisk, Function] */
  const [highRiskToEdit, setHighRiskToEdit] = useState(emptyHighRisk);
  const { enqueueSnackbar } = useSnackbar();

  const cancelRecordCreation = () => {
    MySwal.fire({
      title: 'Alert',
      html: `<small> Do you want to cancel this record creation?</small>`,
      icon: 'info',
      cancelButtonColor: '#DC143C',
      showCancelButton: true,
      confirmButtonColor: '#07bf69',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.isConfirmed) {
          setDisplayAddHighRisk(true);
          setDisplayHighRiskForm(false);
          
          enqueueSnackbar('Record Creation Canceled', { variant: 'success' });
        } 
      })
  };

  const handleDelete = (serialNo) => {
    MySwal.fire({
      title: 'Alert',
      html: `<small> Do you want to delete this record?</small>`,
      icon: 'info',
      cancelButtonColor: '#DC143C',
      showCancelButton: true,
      confirmButtonColor: '#07bf69',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.isConfirmed) {
          props.setDossier({
            ...props.cpscRequest.dossier, highRiskServicesSlide: {
              ...props.cpscRequest.dossier.highRiskServicesSlide, highRisks: props.cpscRequest.dossier.highRiskServicesSlide.highRisks.filter(inst => inst.serialNo !== serialNo).map((inst, index) => ({ ...inst, serialNo: index + 1 }))
         } })
         enqueueSnackbar('Record Deleted', { variant: 'success' });
        
        } 
      })
  };


  return (
    <div>
      <div className='table-top  '>
        <div className='table-top-left' >
          <div className='mb-2 mt-1' style={{ position: 'relative', width: '80%' }}>
          </div>
        </div>
        <span className='table-top-right'>
          {displayAddHighRisk ?
            <button style={{float:'right'}} onClick={() => { setDisplayAddHighRisk(false); setDisplayHighRiskForm(true); setHighRiskFormEditMode(FormEditMode.CREATE); setHighRiskToEdit(emptyHighRisk); }}
              className=' addbtn btn'  disabled={props.readOnly}>Add High Risk Identity</button> : ""}&nbsp;&nbsp;
          {displayHighRiskForm ? <button onClick={() => { cancelRecordCreation() }} className=' clsbtn' >Cancel</button> : ""}
        </span>

      </div>
      {displayHighRiskForm &&
        <HighRiskForm setDisplayAddHighRisk={setDisplayAddHighRisk} setDossier={props.setDossier}  cpscRequest={props.cpscRequest} highRisk={highRiskToEdit} formEditMode={highRiskFormEditMode} setDisplayHighRiskForm={setDisplayHighRiskForm} />
      }

      <br />
      <br />
      <Grid container>
        <Grid item xs={12} sm={12} lg={12} >
          <Table responsive striped bordered>
        <thead style={{ fontSize: '10px', }}>
          <tr>
            <th >SN</th>
            <th>Risk Identified</th>
            <th>Mitigation Measure</th>
            <th> Responsible  </th>
            <th> When</th>

            <th style={{ minWidth: 100, textAlign: 'center' }}>Actions</th>

          </tr>
        </thead>
        <tbody>
          {props.cpscRequest.dossier.highRiskServicesSlide.highRisks.sort((a, b) => a.serialNo < b.serialNo ? -1 : 1).map((item, index) => {
            return <tr style={{fontSize:'11px'}} key={index}>
              <td>{item.serialNo}</td>
              <td>{item.riskIdentified}</td>
              <td>{item.mitigationMeasure}</td>
              <td>{item.responsible}</td>
              <td>{item.when}</td>


              <td style={{ minWidth: 100, textAlign: 'center' }}>
                <Button variant='contained' 
                 disabled={props.readOnly}
                  onClick={() => { setDisplayAddHighRisk(false); setDisplayHighRiskForm(true); setHighRiskFormEditMode(FormEditMode.EDIT); setHighRiskToEdit(item); }}>
                  Edit
                </Button> &nbsp;&nbsp;&nbsp;
                <Button className='delbtn btn'variant='contained' color='secondary'
                 disabled={props.readOnly}
                  onClick={() => {
                    handleDelete(item.serialNo)
                  }}>
                  Delete
                </Button>
              </td>
            </tr>


          })}
          {props.cpscRequest.dossier.highRiskServicesSlide.highRisks.length === 0 ? <tr>
            <td colSpan={6}><p className=' text-center'> No record available...</p></td>


          </tr> : ''}

        </tbody>

      </Table>
      </Grid>
      </Grid>
    </div>
  )
}

export default HighRiskIdentificationSlide