import React, { } from 'react'
import { Button, TextField, MenuItem, Checkbox, } from '@mui/material';
import { ContractingStrategy, CpscCategory, ContractValueCurrency as Currency, CpscRequestStatus, ContractModificationRequest, AssetShareSplitType } from '../../helpers/Constants';
import { callServerAPI, formatDateyyyymmdd, getCPSCRequestTooltip, isTripletForRequest } from '../../helpers/Helpers';
import { ContentCopy } from '@mui/icons-material';
import '../../css/request.css'
import MyNumericFormat from '../../components/MyNumericFormat';
import CustomDialog from '../../components/dialogs/CustomDialog';
import { CpscRequestFields } from '../../helpers/DossierFieldConstants';

/**
 * @typedef {Object} RequestInfoProps
 * @property {CpscRequest} data
 * @property {BusinessArea[]} businessAreas
 * @property {boolean} readOnlyMode
 * @property {boolean} canUpdateCpscCategory
 * @property {Function} updateDataFieldValue
 * @property {Function} updateDataFieldDateValue
 * @property {TepngUser} loggedOnTepngUser
 * @property {Function} setTopNRelatedRequests
 * @property {number} topNRelatedRequests
 * @property {number[]} topValues
 * @property {Function} setUseRelatedRequest
 * @property {boolean} useRelatedRequest
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequestSummaryDto[]>>} setRelatedRequests
 * @property {CpscRequestSummaryDto[]} relatedRequests
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequestSummaryDto>>} setSelectedRelatedRequest
 * @property {CpscRequestSummaryDto} selectedRelatedRequest
 * @property {Function} setRelatedRequestsConfirmationBoxChecked
 * @property {boolean} relatedRequestsConfirmationBoxChecked
 * @property {Function} setIncludeFilesForRelatedChecked
 * @property {boolean} includeFilesForRelatedChecked
 */

/**
 * @param {RequestInfoProps}  props
 * @return {import('react').ReactElement}
 */
function RequestInfo(props) {
    const [displayRelatedDossierList, setDisplayRelatedDossierList] = React.useState(false);


    const getTopNRelatedRequests = () => {
        /** @type GetTopNRelatedRequestsDto */ const apiCallBody = { contractTitle: props.data.contractTitle, initialSowOrMaterialDescription: props.data.initialSowOrMaterialDescription, topNRelated: props.topNRelatedRequests }
        callServerAPI('PATCH', `/api/CpscRequest/GetTopNRelatedRequests`, apiCallBody, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequestSummaryDto[]>> } */ response) {
                props.setRelatedRequests(response.data.data);
                setDisplayRelatedDossierList(true)
            })
    }


    return (
        < >
            <div style={{ padding: 10 }}>
                <div >
                    <div >
                        <label className=' title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractTitle)}Contract Title <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <TextField fullWidth className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} name='contractTitle' value={props.data.contractTitle} placeholder="Contract Title" onChange={(newValue) => props.updateDataFieldValue("contractTitle", newValue?.target.value)} />
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div >
                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.initialSowOrMaterialDescription)}Summary Scope of Work / Material Description <span className='important'>*</span></label>
                    </div>
                    <div className="form-group mb-0">
                        <TextField fullWidth multiline minRows={4} maxRows={6} className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder="Summary Scope of Work " size="small" disabled={props.readOnlyMode} name='initialSowOrMaterialDescription' value={props.data.initialSowOrMaterialDescription} onChange={(newValue) => props.updateDataFieldValue("initialSowOrMaterialDescription", newValue?.target.value)} />
                    </div>
                </div>
                <br />

                <div className='row'>
                    <div className='col1'>
                        <div >
                            <div >
                                <label className=' title-label'>{getCPSCRequestTooltip(CpscRequestFields.cpscCategory)}Proposed CPSC Category <span className='important'>*</span></label>
                            </div>
                            <div className=''>
                                <TextField fullWidth select className={!props.canUpdateCpscCategory ? "react-select-disabled" : ""} size="small" disabled={!props.canUpdateCpscCategory} name='cpscCategory' value={props.data.cpscCategory} onChange={(newValue) => props.updateDataFieldValue("cpscCategory", newValue?.target.value)}>
                                    <MenuItem value={CpscCategory.Strategy}>{CpscCategory.Strategy}</MenuItem >
                                    <MenuItem value={CpscCategory.Award}>{CpscCategory.Award}</MenuItem >
                                    <MenuItem value={CpscCategory.PostAward}>{CpscCategory.PostAward}</MenuItem >
                                </TextField>
                            </div>
                        </div>
                    </div>
                    <div className='col2'>
                        <div>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.businessArea)}Business Area <span className='important'>*</span></label>
                            </div>
                            <div className=''>
                                <div className="form-group mb-0">
                                    <TextField fullWidth className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} select variant="outlined" name="businessAreaId" value={props.data.businessArea?.id == null ? "Not Selected" : props.data.businessArea.id}
                                        onChange={(e) => props.updateDataFieldValue("businessArea", e?.target.value === "Not Selected" ? null : props.businessAreas.find(x => x.id === e?.target.value))}>
                                        <MenuItem value='Not Selected'>Select Business Area</MenuItem >
                                        {props.businessAreas.filter(e => e.disabled === false).map((businessArea, index) => {
                                            return <MenuItem key={index} value={businessArea.id}>{businessArea.title}</MenuItem >
                                        })}
                                    </TextField>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col1'>
                        <div  >
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.budgetEntity)}Budget Entity </label>
                            </div>
                            <div className=''>
                                <div className="form-group mb-0">
                                    <TextField fullWidth className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} select variant="outlined" name="budgetEntityId" value={props.data.budgetEntity?.id == null ? "Not Selected" : props.data.budgetEntity.id}
                                        onChange={(e) => props.updateDataFieldValue("budgetEntity", e?.target.value === "Not Selected" ? null : props.businessAreas.find(x => x.id === e?.target.value))}>
                                        <MenuItem value='Not Selected'>No Separate Budget Entity</MenuItem >
                                        {props.businessAreas.filter(e => e.disabled === false).map((businessArea, index) => {
                                            return <MenuItem key={index} value={businessArea.id}>{businessArea.title}</MenuItem >
                                        })}
                                    </TextField>

                                </div>
                            </div>
                        </div>
                    </div>
                    {props.data.cpscCategory === CpscCategory.Strategy &&
                        <>
                            <div className='col2'>
                                <div >
                                    <label className=' title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractingStrategy)}Contracting Strategy <span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <TextField fullWidth select className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} name='contractingStrategy' value={props.data.contractingStrategy} onChange={(newValue) => props.updateDataFieldValue("contractingStrategy", newValue?.target.value)}>
                                        <MenuItem value={ContractingStrategy.NUIMSCFT}>{ContractingStrategy.NUIMSCFT}</MenuItem >
                                        <MenuItem value={ContractingStrategy.TEPNGCFT}>{ContractingStrategy.TEPNGCFT}</MenuItem >
                                        <MenuItem value={ContractingStrategy.NUIMSSingleSource}>{ContractingStrategy.NUIMSSingleSource}</MenuItem >
                                        <MenuItem value={ContractingStrategy.TEPNGSingleSource}>{ContractingStrategy.TEPNGSingleSource}</MenuItem >
                                    </TextField>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <br />
                <div className='row'>
                    <div className='col1'>
                        <div >
                            <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractCurrency)}Contract Currency <span className='important'>*</span></label>
                        </div>
                        <div className="form-group mb-0">
                            <div className=''>
                                <TextField fullWidth select className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} name='contractCurrency' value={props.data.contractCurrency} onChange={(newValue) => props.updateDataFieldValue("contractCurrency", newValue?.target.value)}>
                                    <MenuItem value={Currency.NGN}>{Currency.NGN}</MenuItem >
                                    <MenuItem value={Currency.EUR}>{Currency.EUR}</MenuItem >
                                    <MenuItem value={Currency.GBP}>{Currency.GBP}</MenuItem >
                                    <MenuItem value={Currency.USD}>{Currency.USD}</MenuItem >
                                </TextField>
                            </div>
                        </div>
                    </div>

                    <div className='col2' >
                        <div>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractValue)} {props.data.cpscCategory === CpscCategory.Strategy ? 'Estimated ' + props.data.contractCurrency + ' Contract Value' : props.data.contractCurrency + ' Contract Value'} <span className='important'>*</span></label>
                            </div>
                            <div className=''>
                                <div className="form-group mb-0">
                                    <TextField InputProps={{ inputComponent: MyNumericFormat }} fullWidth className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder={props.data.cpscCategory === CpscCategory.Strategy ? 'Estimated Contract Value' : 'Contract Value'} size="small" disabled={props.readOnlyMode} name='contractValue' value={props.data.contractValue} onChange={(newValue) => props.updateDataFieldValue("contractValue", newValue?.target.value)} />
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>

                </div>


                <div className='row'>

                    <div className='col1' >
                        <div >
                            <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.otherContractCurrency)}Additional Contract Currency <span className='important'>*</span></label>
                        </div>
                        <div className="form-group mb-0">
                            <div className=''>
                                <TextField fullWidth select className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} name='otherContractCurrency' value={props.data.otherContractCurrency == null ? "Not Selected" : props.data.otherContractCurrency} onChange={(e) => props.updateDataFieldValue("otherContractCurrency", e?.target.value === "Not Selected" ? null : e?.target.value)}>
                                    <MenuItem value='Not Selected'>No Additional Contract Currency</MenuItem >
                                    <MenuItem disabled={props.data.contractCurrency === Currency.NGN} value={Currency.NGN}>{Currency.NGN}</MenuItem >
                                    <MenuItem disabled={props.data.contractCurrency === Currency.EUR} value={Currency.EUR}>{Currency.EUR}</MenuItem >
                                    <MenuItem disabled={props.data.contractCurrency === Currency.GBP} value={Currency.GBP}>{Currency.GBP}</MenuItem >
                                    <MenuItem disabled={props.data.contractCurrency === Currency.USD} value={Currency.USD}>{Currency.USD}</MenuItem >
                                </TextField>
                            </div>
                        </div>
                    </div>
                    {props.data.otherContractCurrency != null &&
                        <div className='col2'>
                            <div>
                                <div >
                                    <label className='title-label'> {getCPSCRequestTooltip(CpscRequestFields.otherContractCurrencyValue)} {props.data.cpscCategory === CpscCategory.Strategy ? 'Estimated ' + props.data.otherContractCurrency + ' Contract Value' : props.data.otherContractCurrency + ' Contract Value'} <span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <TextField fullWidth InputProps={{ inputComponent: MyNumericFormat }} className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder={props.data.cpscCategory === CpscCategory.Strategy ? 'Estimated Contract Value' : 'Contract Value'} size="small" disabled={props.readOnlyMode} name='otherContractCurrencyValue' value={props.data.otherContractCurrencyValue} onChange={(newValue) => props.updateDataFieldValue("otherContractCurrencyValue", newValue?.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <br />
                {props.data.cpscCategory !== CpscCategory.Strategy &&
                    <>
                        <div className='row' >

                            <div className='col1'>

                                <div  >
                                    <div >
                                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.initialContractStartDate)}Initial Contract Start Date<span className='important'>*</span></label>
                                    </div>
                                    <div className=''>
                                        <div className="form-group mb-0">
                                            <TextField fullWidth type='date' className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder="Initial Contract Start Date" size="small" disabled={props.readOnlyMode} name='initialContractStartDate' value={formatDateyyyymmdd(props.data.initialContractStartDate)} onChange={(newValue) => props.updateDataFieldDateValue("initialContractStartDate", newValue?.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col2' >
                                <div>
                                    <div >
                                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.initialContractEndDate)}Initial Contract End Date <span className='important'>*</span></label>
                                    </div>
                                    <div className=''>
                                        <div className="form-group mb-0">
                                            <TextField InputProps={{ inputProps: { min: formatDateyyyymmdd(props.data.initialContractStartDate) } }} fullWidth type='date' className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder="Initial Contract End Date" size="small" disabled={props.readOnlyMode} name='initialContractEndDate' value={formatDateyyyymmdd(props.data.initialContractEndDate)} onChange={(newValue) => props.updateDataFieldDateValue("initialContractEndDate", newValue?.target.value)} />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <br />
                    </>
                }
                <div className='row'>

                    <div className='col1'>
                        <div >
                            <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.budgetCurrency)}Budget Currency <span className='important'>*</span></label>
                        </div>
                        <div className="form-group mb-0">
                            <div className=''>
                                <TextField fullWidth select className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} name='budgetCurrency' value={props.data.budgetCurrency} onChange={(newValue) => props.updateDataFieldValue("budgetCurrency", newValue?.target.value)}>
                                    <MenuItem value={Currency.NGN}>{Currency.NGN}</MenuItem >
                                    <MenuItem value={Currency.EUR}>{Currency.EUR}</MenuItem >
                                    <MenuItem value={Currency.GBP}>{Currency.GBP}</MenuItem >
                                    <MenuItem value={Currency.USD}>{Currency.USD}</MenuItem >
                                </TextField>
                            </div>
                        </div>
                    </div>
                    <div className='col2'>
                        <div >
                            <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.budget)}Budget  <span className='important'>*</span> </label>
                        </div>
                        <div className="form-group mb-0">
                            <div className=''>
                                <TextField fullWidth InputProps={{ inputComponent: MyNumericFormat }} className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder='Budget' size="small" disabled={props.readOnlyMode} name='budget' value={props.data.budget} onChange={(newValue) => props.updateDataFieldValue("budget", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
                <div>
                    <div >
                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.assetShareSplitType)}Asset Share Split Type <span className='important'>*</span></label>
                    </div>
                    <div className=''>
                        <TextField fullWidth select className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} name='assetShareSplitType' value={props.data.assetShareSplitType} onChange={(newValue) => props.updateDataFieldValue("assetShareSplitType", newValue?.target.value)}>
                            <MenuItem value={AssetShareSplitType.JV}>{AssetShareSplitType.JV}</MenuItem >
                            <MenuItem value={AssetShareSplitType.DWCorporate}>{AssetShareSplitType.DWCorporate}</MenuItem >
                            <MenuItem value={AssetShareSplitType.Synergy}>{AssetShareSplitType.Synergy}</MenuItem >

                        </TextField>

                    </div>
                </div>
                <br />
                {props.data.assetShareSplitType === AssetShareSplitType.Synergy &&
                    <div className='row'>

                        <div className='col1'>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.dwCorporateSharePercent)}DW & Corporate Share Percent <span className='important'>*</span></label>
                            </div>
                            <div className="form-group mb-0">
                                <div className=''>
                                    <input id='dwCorporateSharePercent' type="number" className="form-control input-font bold" onChange={(newValue) => props.updateDataFieldValue("dwCorporateSharePercent", Number(newValue?.target.value))}
                                        value={props.data.dwCorporateSharePercent ?? 0}
                                        required max={100} min={0} step={1} disabled={props.readOnlyMode} />
                                </div>
                            </div>
                        </div>
                        <div className='col2'>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.jvSharePercent)}JV Share Percent  <span className='important'>*</span> </label>
                            </div>
                            <div className="form-group mb-0">
                                <div className=''>
                                    <input id='jvSharePercent' type="number" className="form-control input-font bold" onChange={(newValue) => props.updateDataFieldValue("jvSharePercent", Number(newValue?.target.value))}
                                        value={props.data.jvSharePercent ?? 0}
                                        required max={100} min={0} step={1} disabled={props.readOnlyMode} />
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                }

                <br />
                <div className='col1'>

                    <Checkbox disabled={props.readOnlyMode} checked={props.data.isAbridgedDossier} onChange={(e, checked) => props.updateDataFieldValue("isAbridgedDossier", checked)} />&nbsp;
                    <label style={{ fontSize: 13 }} className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.isAbridgedDossier)}Abridged Dossier?</label>
                    <br />
                </div>
                <br />

                {props.data.cpscCategory === CpscCategory.PostAward &&
                    <>
                        <div>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.contractModificationRequest)}Contract Modification Request <span className='important'>*</span></label>
                            </div>
                            <div className=''>
                                <TextField fullWidth select className={props.readOnlyMode ? "react-select-disabled" : ""} size="small" disabled={props.readOnlyMode} name='contractModificationRequest' value={props.data.contractModificationRequest} onChange={(newValue) => props.updateDataFieldValue("contractModificationRequest", newValue?.target.value)}>
                                    <MenuItem value={ContractModificationRequest.Extension}>{ContractModificationRequest.Extension}</MenuItem >
                                    <MenuItem value={ContractModificationRequest.ExtensionAndVariation}>{ContractModificationRequest.ExtensionAndVariation}</MenuItem >
                                    <MenuItem value={ContractModificationRequest.Variation}>{ContractModificationRequest.Variation}</MenuItem >
                                    <MenuItem value={ContractModificationRequest.Others}>{ContractModificationRequest.Others}</MenuItem >
                                </TextField>

                            </div>
                        </div>
                        <br />

                        {(props.data.contractModificationRequest === ContractModificationRequest.Extension || props.data.contractModificationRequest === ContractModificationRequest.ExtensionAndVariation) &&
                            <>
                                <div className='row'>
                                    <div >
                                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.newContractEndDate)}New Contract End Date <span className='important'>*</span></label>
                                    </div>
                                    <div className="form-group mb-0">
                                        <TextField fullWidth type='date' className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder="New Contract End Date" size="small" disabled={props.readOnlyMode} name='newContractEndDate' value={formatDateyyyymmdd(props.data.newContractEndDate)} onChange={(newValue) => props.updateDataFieldDateValue("newContractEndDate", newValue?.target.value)} />
                                    </div>

                                </div>
                                <br />
                            </>
                        }

                        {(props.data.contractModificationRequest === ContractModificationRequest.Variation || props.data.contractModificationRequest === ContractModificationRequest.ExtensionAndVariation) &&
                            <>
                                <div className='row'>
                                    <div >
                                        <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.additionalValue)}Additional Contract Value Requested<span className='important'>*</span></label>
                                    </div>
                                    <div className="form-group mb-0">
                                        <TextField fullWidth InputProps={{ inputComponent: MyNumericFormat }} className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder="Additional Value" size="small" disabled={props.readOnlyMode} name='additionalValue' value={props.data.additionalValue} onChange={(newValue) => props.updateDataFieldValue("additionalValue", newValue?.target.value)} />
                                    </div>
                                </div>
                                <br />
                            </>
                        }

                        <div className='row'>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.optionalExtensions)}Optional Extensions </label>
                            </div>
                            <div className="form-group mb-0">
                                <TextField fullWidth multiline minRows={4} maxRows={6} className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder="Optional Extensions " size="small" disabled={props.readOnlyMode} name='optionalExtensions' value={props.data.optionalExtensions} onChange={(newValue) => props.updateDataFieldValue("optionalExtensions", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div >
                                <label className='title-label'>{getCPSCRequestTooltip(CpscRequestFields.extensionConditions)}Extension Conditions  </label>
                            </div>

                            <div className="form-group mb-0">
                                <TextField fullWidth multiline minRows={4} maxRows={6} className={props.readOnlyMode ? "react-select-disabled" : ""} placeholder="Extension Conditions" size="small" disabled={props.readOnlyMode} name='extensionConditions' value={props.data.extensionConditions} onChange={(newValue) => props.updateDataFieldValue("extensionConditions", newValue?.target.value)} />
                            </div>
                        </div>
                        <br />

                    </>
                }


                {props.data.status === CpscRequestStatus.Draft && props.data.contractTitle && props.data.initialSowOrMaterialDescription && (props.data.createdBy == null || isTripletForRequest(props.data, props.loggedOnTepngUser)) &&
                    <div>
                        <Checkbox checked={props.useRelatedRequest} onChange={(e, checked) => props.setUseRelatedRequest(checked)} />&nbsp;
                        <label style={{ fontSize: 13 }} className='title-label'>Do you want to initialise this dossier from a related CPSC Request?</label>
                        <br />
                    </div>
                }
                {props.useRelatedRequest &&
                    <>
                        <hr />
                        <div className='row'>
                            <div >
                                <label className='title-label'>Select the number of related CPSC Dossiers to retrieve <span className='important'>*</span></label>
                            </div>
                            <div className='col1'>
                                <div className="form-group mb-0">
                                    <TextField disabled={props.readOnlyMode} fullWidth id="fullWidth" select variant="outlined" size="small" name="topNRelatedRequests" value={props.topNRelatedRequests} onChange={(e) => props.setTopNRelatedRequests(Number(e.target.value))}>

                                        {props.topValues.map((item, index) => {
                                            return <MenuItem key={index} value={item}>{item}</MenuItem >
                                        })}

                                    </TextField>
                                </div>
                            </div>
                            <div style={{ width: '28%' }} >

                                <Button variant='contained' disabled={!Boolean(props.data.contractTitle)} onClick={getTopNRelatedRequests} > <ContentCopy /> &nbsp;Get Related Dossiers </Button>
                            </div>
                        </div>
                        {
                            displayRelatedDossierList &&

                            <CustomDialog dialogTitle='Select Related Requests' openDialog={displayRelatedDossierList} setOpenDialog={setDisplayRelatedDossierList}>

                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                    <thead className='thead-text'>
                                        <tr>
                                            <th>Action</th>
                                            <th>Contract Title</th>
                                            <th>Business Area</th>
                                            <th>CPSC Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.relatedRequests.map((item, index) =>
                                            <tr className='trtext' key={index}>
                                                <td valign='middle'>
                                                    <Button variant='contained' size='small' color='primary' onClick={() => { props.setSelectedRelatedRequest(item); setDisplayRelatedDossierList(false) }} >Select</Button>
                                                </td>
                                                <td valign='middle'>{item.contractTitle}</td>
                                                <td valign='middle'>{item.businessArea.title}</td>
                                                <td valign='middle'>{item.cpscCategory}</td>
                                            </tr>
                                        )}
                                        {props.relatedRequests.length === 0 ? <tr>
                                            <td colSpan={4}><span className=' text-center'>....</span></td>
                                        </tr> : ''}
                                    </tbody>

                                </table>

                            </CustomDialog>

                        }
                        <br />

                        <label className='title-label'>Selected Related Dossier<span className='important'>*</span></label>
                        <br />

                        <div style={{ borderStyle: 'solid ', borderColor: '#D9D9D9', borderWidth: '1px' }}>
                            <div style={{ padding: '10px' }}>

                                <br />
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                    <thead className='title-label'>
                                        <tr>
                                            <th>Contract Title</th>
                                            <th>Business Area</th>
                                            <th>CPSC Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.selectedRelatedRequest != null &&
                                            <tr className='trtext'>
                                                <td>{props.selectedRelatedRequest.contractTitle}</td>
                                                <td>{props.selectedRelatedRequest.businessArea.title}</td>
                                                <td>{props.selectedRelatedRequest.cpscCategory}</td>
                                            </tr>
                                        }
                                        {props.selectedRelatedRequest == null &&
                                            <tr>
                                                <td colSpan={3}><span className=' text-center'>....</span></td>
                                            </tr>
                                        }

                                    </tbody>

                                </table>
                                {props.selectedRelatedRequest &&
                                    <>
                                        <br />
                                        <Checkbox checked={props.relatedRequestsConfirmationBoxChecked} onChange={(e, checked) => props.setRelatedRequestsConfirmationBoxChecked(checked)} /> <span style={{ fontSize: '13px' }}><i>I hereby confirm that I understand the dossier will be populated with details from the related dossier. I also confirm that I will update the necessary fields upon dossier Initiation</i></span>
                                        <br />
                                        <br />
                                        <Checkbox checked={props.includeFilesForRelatedChecked} onChange={(e, checked) => props.setIncludeFilesForRelatedChecked(checked)} /> <span style={{ fontSize: '13px' }}>Include files from selected related dossier</span>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default RequestInfo