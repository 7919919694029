import React, {   } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ProjectOperationsPlanningSlideField, ProjectOperationsPlanningSlideTooltip } from '../../helpers/DossierFieldConstants'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import CustomTabPanel from '../tabs/CustomTabPanel';
import ProjectOperationsPlanningUpload from './ProjectOperationsPlanningUpload';
import { Tab, Tabs } from '@mui/material';
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';



/**
 * @typedef {Object} ProjectOperationsPlanningSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
 * @property {downloadPdfCallback} downloadPdf
 * @property {downloadPdfCallback} mobileDownloadPdf
 * @property {uploadPdfCallback} uploadPdf
 * @property {deletePdfCallback} deletePdf
 * @property {number} dossierRefreshCount
 */

/**
 * @param {ProjectOperationsPlanningSlideProps}  props
 * @return {import('react').ReactElement}
 */
function ProjectOperationsPlanningSlide(props) {

  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);



  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, projectOperationsPlanningSlide: { ...props.cpscRequest.dossier.projectOperationsPlanningSlide, [name]: value } })
  }
  return (
  
    <>

      <div className='col-12  ' style={{ margin: '0px !important' }}>
        <div className='mt-2 mb-2 shadow-sm bg-white px-4 py-2' style={{ paddingTop: '0px !important' }}>
          <div className='content-desktop'>
            <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
              allowScrollButtonsMobile>
              <Tab label="Project & Operations Planning" value={1} />
              <Tab label="Project & Operations Planning Upload" value={2} />
            </Tabs>
          </div>
          <div className='content-mobile'>
            <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
              allowScrollButtonsMobile>
              <Tab label="Project & Operations Planning" value={1} />
              <Tab label="Project & Operations Planning Upload" value={2} />
            </Tabs>
          </div>

          <CustomTabPanel value={selectedTabValue} index={1}>
            <form style={{ fontSize: '12px' }}>
              <div className="form-group">
                <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ProjectOperationsPlanningSlideTooltip.details)}Need with Respect to Planning <span className='important'>*</span></label>
                <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.projectOperationsPlanningSlide.details} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ProjectOperationsPlanningSlideField.details, editorHtmlString)} />
              </div>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={selectedTabValue} index={2}>
            <div >
              <ProjectOperationsPlanningUpload readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} mobileDownloadPdf={props.mobileDownloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
            </div>
          </CustomTabPanel>
        </div>
      </div>
    </>
  )
}

export default ProjectOperationsPlanningSlide