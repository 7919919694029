import React from 'react'
import { RecommendationSlideField, RecommendationSlideTooltip } from '../../helpers/DossierFieldConstants';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import { getSlideTooltip } from '../../helpers/Helpers';
import MyRichTextEditor from '../MyRichTextEditor';

/**
 * @typedef {Object} RecommendationSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
  * @property {number} dossierRefreshCount
 */

/**
 * @param {RecommendationSlideProps}  props
 * @return {import('react').ReactElement}
 */
function RecommendationSlide(props) {

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, recommendationSlide: { ...props.cpscRequest.dossier.recommendationSlide, [name]: value } })
}

  return (
   
    <form style={{ fontSize: '12px' }}>
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, RecommendationSlideTooltip.recommendation)}Our Recommendation <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.recommendationSlide.recommendation} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(RecommendationSlideField.recommendation, editorHtmlString)} />
      </div>
      <br/>
      <div className="form-group">
        <label className=' title-label' >{getSlideTooltip(props.cpscRequest, RecommendationSlideTooltip.nextSteps)}Next Steps/ Way Forward  <span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.recommendationSlide.nextSteps} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(RecommendationSlideField.nextSteps, editorHtmlString)} />  
      </div>
    </form>
      )
}

      export default RecommendationSlide