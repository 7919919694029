import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { callServerAPI } from '../../helpers/Helpers';
import { globalStore, preloader } from '../../helpers/Constants';
import withReactContent from 'sweetalert2-react-content';

import { Box, Grid, IconButton, MenuItem, Paper, Stack, TextField, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { Add, ArrowBackIos, ContentCopy } from '@mui/icons-material';
import MyRichTextEditor from '../../components/MyRichTextEditor';
import { useSnackbar } from 'notistack';
import Table from 'react-bootstrap/Table';


const MySwal = withReactContent(Swal);


function CreateAggregateRequests() {
    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    /** @type [BusinessArea[],import('react').Dispatch<import('react').SetStateAction<BusinessArea[]>>] ] */
    const [businessAreas, setBusinessAreas] = useState([]);
    /** @type [string,Function] */
    const [businessAreaId, setBusinessAreaId] = useState("None")
    /** @type [string,Function] */
    const [businessAreaTitle, setBusinessAreaTitle] = useState("")
    /** @type { [CpscRequest[], Function] } */
    const [requestsForAggregation, setRequestsForAggregation] = useState([])
    /**@type {[string[], Function]} */
    const [cpscRequestIds, setCpscRequestIds] = useState([])
    /**@type {[string, Function]} */
    const [aggregatedTitle, setAggregatedTitle] = useState("")
    /**@type {[string, Function]} */
    const [objective, setObjective] = useState("")
    /**@type {[string, Function]} */
    const [serviceProvider, setServiceProvider] = useState("")
    /**@type {[string, Function]} */
    const [background, setBackground] = useState("")
    /**@type {[string, Function]} */
    const [recommendation, setRecommendation] = useState("")

    function handleChange(event) {
        let value = event.target.value
        let checked = event.target.checked

        if (checked === true) {
            setCpscRequestIds(pre => [...pre, value])

        }
    }

    useEffect(() => {
        callServerAPI('GET', '/api/BusinessArea/GetAllBusinessAreas', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<BusinessArea[]>> } */ response) {
                setBusinessAreas(response.data.data.filter(e => e.cpDomain.domainManagersList.find(x => x.toUpperCase() === loggedOnTepngUser.email.toUpperCase())));
            })
    }, [loggedOnTepngUser.email])

    const fetchRequestsForAggregation = () => {
        MySwal.fire(preloader);
        callServerAPI('GET', `/api/CpscRequest/GetRequestsForAggregation?businessAreaId=${businessAreaId}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequest[]>> } */ response) {
                setRequestsForAggregation(response.data.data);
                MySwal.close();
                if (response.data.data.length === 0) {
                    enqueueSnackbar(`There are no fully endorsed dossiers for ${businessAreaTitle} available for scheduling`, { variant: 'info' });
                }
            })

    }

    function CreateAggregatedCpscRequest(e) {
        MySwal.fire(preloader);
    /** @type CreateAggregatedCpscRequestDto */ const data = {
            businessAreaId: businessAreaId,
            aggregatedTitle: aggregatedTitle,
            cpscRequestIds: cpscRequestIds,
            serviceProvider: serviceProvider,
            objective: objective,
            recommendation: recommendation,
            background: background

        }
        callServerAPI('POST', '/api/CpscRequest/CreateAggregatedCpscRequest', data, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<AggregatedCpscRequest>> } */ response) {
                MySwal.close();
                enqueueSnackbar("Aggregated Cpsc Request created", { variant: 'success' });
                navigate('/aggregate-dossier')
            })

    }
    const navigate = useNavigate();

    const handleHistory = () => {
        navigate('/aggregate-dossier')
    }

    /**  @return {Boolean} */
    const validate = () => {
        if (!(businessAreaId !== 'None' && Boolean(aggregatedTitle) && Boolean(background) && Boolean(objective) && Boolean(serviceProvider) && Boolean(recommendation) && Boolean(cpscRequestIds) && cpscRequestIds.length > 1))
            return false
        return true
    }

    return (
        businessAreas &&

        <Box m="1.0rem 1.0rem">
            <Box sx={{ width: '100%' }}>
                <Stack >
                    <Paper className='d-flex' >

                        <IconButton onClick={handleHistory}>
                            <ArrowBackIos sx={{ fontSize: '13px' }} />
                        </IconButton>

                        <Typography variant="h6" color={theme.palette.text.secondary} fontWeight="bold" sx={{ margin: '1.0rem', }}>
                            Aggregate Request Form
                        </Typography>

                    </Paper>
                </Stack>


            </Box>
            <Paper   >
                <Box mt="20px" p='1.0rem' gap="20px">
                    {businessAreas.length === 0 &&
                        <><div style={{textAlign:'center'}}>
                            <label ><span className='important'>You must be a C&P Domain Manager to aggregate dossiers in a Business Area </span></label>
                        </div>
                            <br />
                        </>
                    }
                    <form >
                        <div style={{ padding: '5px' }}>
                            <Grid container>
                                <Grid item xs={12} sm={12} lg={12} style={{ padding: '2px' }}>
                                    <div >
                                        <label className='title-label'>Aggregated Title <span className='important'>*</span></label>
                                    </div>
                                    <div className=''>
                                        <div className="form-group mb-0">
                                            <TextField fullWidth onChange={(e) => setAggregatedTitle(e.target.value)} id="aggregatedTitle" value={aggregatedTitle} className=" input-font bold" placeholder="Aggregated Title" size='small' required />

                                        </div>
                                    </div>
                                    <br />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>

                                    <div >
                                        <label className='title-label'>Business Area <span className='important'>*</span></label>
                                    </div>

                                    <div className=''  >
                                        <div className="form-group mb-0" >
                                            <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="businessAreaId" value={businessAreaId}
                                                onChange={(event) => setBusinessAreaId(event?.target.value)}>
                                                <MenuItem key={0} value='None'>Select Business Area</MenuItem >
                                                {businessAreas.map((businessArea, index) => {
                                                    return <MenuItem key={index + 1} value={businessArea.id} onClick={() => setBusinessAreaTitle(businessArea.title)}>{businessArea.title}</MenuItem >
                                                })}

                                            </TextField>

                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} style={{ padding: '2px' }}>
                                    <div >
                                        <label className='title-label'>Contractor <span className='important'>*</span></label>
                                    </div>
                                    <div className=''>
                                        <div className="form-group mb-0">
                                            <TextField fullWidth onChange={(e) => setServiceProvider(e.target.value)} id="serviceProvider" value={serviceProvider} className=" input-font bold" placeholder="Contractor" size='small' required />

                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <br />
                            <div>
                                <div className='row'>
                                    <div >
                                        <label className='title-label'>Objective  <span className='important'>*</span></label>
                                    </div>
                                    <div className="form-group mb-0">
                                        <textarea className='' onChange={(e) => setObjective(e.target.value)}
                                            id="objective" style={{ width: '100%', height: '80px' }} name='objective' value={objective} required />


                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <div className='title-label'>Background And Purpose </div>
                                <div>
                                    <MyRichTextEditor editorHtmlString={background} updateEditorHtmlString={(editorHtmlString) => setBackground(editorHtmlString)} />
                                </div>
                            </div>
                            <br />
                            <div>
                                <div className='title-label'>Request To CPSC </div>
                                <div>
                                    <MyRichTextEditor editorHtmlString={recommendation} updateEditorHtmlString={(editorHtmlString) => setRecommendation(editorHtmlString)} />
                                </div>
                            </div>
                            <br />

                            <div className='d-flex flex-row-reverse' style={{ float: 'right', }} >

                                <Button color='primary' variant="contained" disabled={businessAreaId === "None"} onClick={fetchRequestsForAggregation}> <ContentCopy /> &nbsp;Get List </Button>

                            </div>

                            <br /><br />

                            <div >
                                <label className='title-label'>Aggregation Candidates</label>
                                <Grid container>
                                    <Grid item xs={12} sm={12} lg={12} >
                                        <Table responsive striped bordered>
                                            <thead style={{ fontSize: '10px', }}>
                                                <tr>
                                                    <th></th>
                                                    <th>Contract Title</th>
                                                    <th>Business Area</th>
                                                    <th>Proposed Contracting Strategy  </th>
                                                    <th> Proposed CPSC Category</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {requestsForAggregation.map((item, index) => {
                                                    return <tr style={{ fontSize: '11px', }} key={index}>
                                                        <td ><input type="checkbox" value={item.id} onChange={handleChange} /></td>
                                                        <td>{item.contractTitle}</td>
                                                        <td>{item.businessArea.title}</td>
                                                        <td>{item.contractingStrategy}</td>
                                                        <td>{item.cpscCategory}</td>


                                                    </tr>


                                                })}
                                                {requestsForAggregation.length === 0 ? <tr>
                                                    <td colSpan={5} style={{ fontSize: '11px', textAlign: 'center' }} >
                                                        No records...

                                                    </td>
                                                </tr> : ''}

                                            </tbody>

                                        </Table>


                                    </Grid>

                                </Grid>

                            </div>
                        </div>
                        <div className='form-group mt-3 mb-5 d-flex flex-row-reverse'>
                            <Button variant="contained" disabled={!validate()} color='secondary' onClick={CreateAggregatedCpscRequest}><Add />&nbsp; Create Aggregate Request</Button>
                        </div>


                    </form>

                </Box>
            </Paper>
        </Box>
    )
}

export default CreateAggregateRequests