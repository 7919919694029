import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../../css/header.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { callServerTepngUserLogout } from '../../helpers/Helpers';
import { GlobalRole, globalStore } from '../../helpers/Constants';
import FlexBetween from "../FlexBetween";
import { AppBar, Typography, Toolbar, useTheme, useMediaQuery, } from "@mui/material";

function MeetingNav() {
    const isNonMobile = useMediaQuery('(min-width: 600px)');
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    /** @type TepngUser */
    let user = loggedOnTepngUser;

    const theme = useTheme();
    const logout = async (event) => {
        await callServerTepngUserLogout()
    }
    return (
        <AppBar
            sx={{
                position: 'static',
                background: 'white'
            }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* left side */}
                <FlexBetween>
                    {isNonMobile && (
                        <img src='../../assets/images/DcpscLogo.png' alt="logo" />
                    )}
                    {!isNonMobile && (
                        <Typography
                            variant="h6"
                            color={theme.palette.text.secondary}
                            fontWeight="bold"
                            sx={{
                                margin: '1.0rem',

                            }}
                        >
                            Digital CPSC
                        </Typography>
                    )}
                </FlexBetween>

                {/* right side */}
                <FlexBetween gap='1.5rem'>
                    <Navbar className='mr-13'>
                        <Navbar.Toggle aria-controls="navbar-dark-example" />
                        <Navbar.Collapse id="navbar-dark-example">
                            <Nav>
                                {loggedOnTepngUser?.roles.includes(GlobalRole.Admin) &&
                                    <div className='badge'>Admin</div>
                                }
                                <NavDropdown id="nav-dropdown-dark-example" title={`Hello  ${user?.firstName} `} menuVariant="light">

                                    <NavDropdown.Item href="#!" onClick={e => logout()}>Log Out</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </FlexBetween>

            </Toolbar>
        </AppBar>
    )
}

export default MeetingNav