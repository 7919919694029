import React, {  useState } from 'react'
import { FormEditMode, YesNo, DueDiligenceStatus, YesNoOngoingNA } from '../../helpers/Constants';
import { ProposedBiddersSlideField, ProposedBiddersSlideTooltip } from '../../helpers/DossierFieldConstants';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, ButtonGroup, Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../tabs/CustomTabPanel';
import Table from 'react-bootstrap/Table';
import '../../css/template.css'
import { formatShortDateString, getSlideTooltip, } from '../../helpers/Helpers';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'
import { useSnackbar } from 'notistack';
import ProposedBiddersListForm from './ProposedBiddersListForm';
import ProposedBiddersUpload from './ProposedBiddersUpload';
import { Delete, Edit } from '@mui/icons-material';
import MyRichTextEditor from '../MyRichTextEditor';


const MySwal = withReactContent(Swal);

/**
 * @typedef {Object}  ProposedBiddersSlideProps
 * @property {downloadPdfCallback} downloadPdf
 * @property {uploadPdfCallback} uploadPdf
 * @property {downloadPdfCallback} mobileDownloadPdf
 * @property {deletePdfCallback} deletePdf
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
  * @property {number} dossierRefreshCount
 */

/**
 * @param { ProposedBiddersSlideProps}  props
 * @return {import('react').ReactElement}
 */

function ProposedBiddersList(props) {

  /** @type Bidder */ let emptyBidders = {
    serialNo: props.cpscRequest.dossier.proposedBiddersSlide.bidders == null || props.cpscRequest.dossier.proposedBiddersSlide.bidders.length === 0 ? 1 : Math.max.apply(Math, props.cpscRequest.dossier.proposedBiddersSlide.bidders.map(function (o) { return o.serialNo; })) + 1,
    vendorName: "",
    incumbentSupplier: YesNo.No,
    basisOfSelection: "",
    qualified: YesNo.No,
    dueDiligenceStatus: DueDiligenceStatus.NA,
    dueDiligenceMitigationsClosed: YesNoOngoingNA.NA,
    dueDiligenceExpiryDate: undefined,

  }

  /** @type [Boolean, Function] */
  const [displayBiddersForm, setDisplayBiddersForm] = useState(false);
  /** @type [Boolean, Function] */
  const [displayAddBidders, setDisplayAddBidders] = useState(true);
  /** @type [Number, Function] */
  const [biddersFormEditMode, setBiddersFormEditMode] = useState(FormEditMode.CREATE);
  /** @type [Bidder, Function] */
  const [biddersToEdit, setBiddersToEdit] = useState(emptyBidders);

  /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
  const [selectedTabValue, setSelectedTabValue] = React.useState(1);
  
  const { enqueueSnackbar } = useSnackbar();

 
  const cancelRecordCreation = () => {
    MySwal.fire({
      title: 'Alert',
      html: `<small> Do you want to cancel this record creation?</small>`,
      icon: 'info',
      cancelButtonColor: '#DC143C',
      showCancelButton: true,
      confirmButtonColor: '#07bf69',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.isConfirmed) {
          setDisplayAddBidders(true);
          setDisplayBiddersForm(false);
          enqueueSnackbar('Record Creation Canceled', { variant: 'success' });
        }
      })
  };
  const handleDelete = (serialNo) => {
    MySwal.fire({
      title: 'Alert',
      html: `<small> Do you want to delete this record?</small>`,
      icon: 'info',
      cancelButtonColor: '#DC143C',
      showCancelButton: true,
      confirmButtonColor: '#07bf69',
      confirmButtonText: 'Yes'
    })
      .then((result) => {
        if (result.isConfirmed) {
          props.setDossier({
            ...props.cpscRequest.dossier, proposedBiddersSlide: {
              ...props.cpscRequest.dossier.proposedBiddersSlide, bidders: props.cpscRequest.dossier.proposedBiddersSlide.bidders.filter(inst => inst.serialNo !== serialNo).map((inst, index) => ({ ...inst, serialNo: index + 1 }))
            }
          })
          enqueueSnackbar('Record Deleted', { variant: 'success' });

        }
      })
  };
  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, proposedBiddersSlide: { ...props.cpscRequest.dossier.proposedBiddersSlide, [name]: value } })
  }
  return (
    
    <>
      <div className='content-desktop'>
        <Tabs variant="fullWidth" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Proposed Bidders List" value={1} />
          <Tab label="Proposed Bidders List Upload" value={2} />
        </Tabs>
      </div>
      <div className='content-mobile'>
        <Tabs variant="scrollable" value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
          allowScrollButtonsMobile>
          <Tab label="Proposed Bidders List" value={1} />
          <Tab label="Proposed Bidders List Upload" value={2} />

        </Tabs>
      </div>
      <CustomTabPanel value={selectedTabValue} index={1} >
        <span style={{ float: 'right' }}>
          {displayAddBidders ?
            <Button onClick={() => { setDisplayAddBidders(false); setDisplayBiddersForm(true); setBiddersFormEditMode(FormEditMode.CREATE); setBiddersToEdit(emptyBidders); }}
              variant='contained' color='secondary' disabled={props.readOnly} >Add new Bidder </Button> : ""}&nbsp;&nbsp;
          {displayBiddersForm ? <button onClick={() => { cancelRecordCreation() }} className=' clsbtn' >Cancel</button> : ""}

        </span>
        <br />
        <br />
        {displayBiddersForm &&
          <>
            <ProposedBiddersListForm cpscRequest={props.cpscRequest} setDossier={props.setDossier} bidders={biddersToEdit} formEditMode={biddersFormEditMode} setDisplayBiddersForm={setDisplayBiddersForm} setDisplayAddBidders={setDisplayAddBidders} />          </>

        }
        <br />
        <br />
        <div className='newtable'>
          <Table responsive striped bordered>
            <thead>
              <tr style={{ fontSize: '10px', }}>
                <th colSpan={4}>SUPPLIER(S)</th>
                <th colSpan={4}>DUE DILIGENCE</th>
              </tr>
              <tr style={{ fontSize: '9px', }}>
                <th>Registered Company name</th>
                <th>Incumbent Supplier</th>
                <th>Basis of Selection</th>
                <th>Qualified Sequana / AVL</th>
                <th>Status</th>
                <th>Mitigation Measures Closed?</th>
                <th>Status validity end date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {props.cpscRequest.dossier.proposedBiddersSlide.bidders.map((item, index) => {
                return <tr style={{ fontSize: '11px', }} key={index}>
                  <td>{item.vendorName}</td>
                  <td>{item.incumbentSupplier}</td>
                  <td>{item.basisOfSelection}</td>
                  <td>{item.qualified}</td>
                  <td>{item.dueDiligenceStatus}</td>
                  <td>{item.dueDiligenceMitigationsClosed}</td>
                  <td>{formatShortDateString(item.dueDiligenceExpiryDate)}</td>
                  <td>
                    <ButtonGroup>
                      <Button style={{ height: '30px' }} variant='contained' color="primary" size='small' disabled={props.readOnly} onClick={() => { setDisplayAddBidders(false); setDisplayBiddersForm(true); setBiddersFormEditMode(FormEditMode.EDIT); setBiddersToEdit(item); }}><Edit /></Button>
                      <Button style={{ height: '30px' }} variant='contained' color="secondary" size='small' disabled={props.readOnly} onClick={() => handleDelete(item.serialNo)}><Delete /></Button>
                    </ButtonGroup>
                  </td>
                </tr>


              })}
            </tbody>
          </Table>
        </div>
        <br />
        <input type="checkbox" checked={props.cpscRequest.dossier.proposedBiddersSlide.preliminaryPlaceholder} onChange={() => updateDataFieldValue("preliminaryPlaceholder", !props.cpscRequest.dossier.proposedBiddersSlide.preliminaryPlaceholder)} /> <span style={{ fontSize: '12px' }}><i>Bidders List provided is preliminary placeholder</i></span>
        <br />
        <br />
        <div className="form-group">
          <label className=' title-label' >{getSlideTooltip(props.cpscRequest, ProposedBiddersSlideTooltip.additionalComments)}Additional Details <span className='important'>*</span></label>
          
              <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.proposedBiddersSlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(ProposedBiddersSlideField.additionalComments, editorHtmlString)} />

        </div>
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabValue} index={2} >
        <div >
          <ProposedBiddersUpload mobileDownloadPdf={props.mobileDownloadPdf} readOnly={props.readOnly} uploadPdf={props.uploadPdf} downloadPdf={props.downloadPdf} deletePdf={props.deletePdf} cpscRequest={props.cpscRequest} />
        </div>
      </CustomTabPanel>
    </>
  )
}

export default ProposedBiddersList