import React, { useEffect, useState } from 'react'
import { Autocomplete, Button, MenuItem, TextField } from '@mui/material';
import { getNameFromTotalEnergiesEmail, callServerAPI } from '../../helpers/Helpers';
import { preloader, FormEditMode } from '../../helpers/Constants';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CustomDialog from '../../components/dialogs/CustomDialog';



const MySwal = withReactContent(Swal);


/**
 * @typedef {Object} CreateEditBusinessAreaProps
 * @property {Function} getAllBusinessAreas
 * @property {Number} formEditMode
 * @property {string} businessAreaIdToEdit
 * @property {BusinessArea[]} businessAreas
 * @property {updateBusinessAreasCallback}  updateBusinessAreas
 * @property {boolean} openCreateBusinessAreaForm
 * @property {import('react').Dispatch<import('react').SetStateAction<boolean>>} setOpenCreateBusinessAreaForm
 */


/**
 * @param {CreateEditBusinessAreaProps}  props
 * @return {import('react').ReactElement}
 */
function CreateEditBusinessArea(props) {
    /** @type BusinessArea */
    const emptyBusinessArea = {
        id: '',
        cpDomain: null,
        title: '',
        budgetOwnersList: [],
        costControllersList: [],
        directorsList: [],
        disabled: false,
        createdDate: undefined,
        lastUpdatedDate: undefined,
        createdBy: '',
        lastUpdatedBy: ''
    }

    const { enqueueSnackbar } = useSnackbar();
    /** @type [BusinessArea,import('react').Dispatch<import('react').SetStateAction<BusinessArea>>] */
    const [businessAreaItem, setBusinessAreaItem] = useState(emptyBusinessArea);
    /** @type [CPDomain[],import('react').Dispatch<import('react').SetStateAction<CPDomain[]>>] */
    const [cpDomains, setCpDomains] = useState(null);
    /** @type [string[],import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [tepngEmails, setTepngEmails] = useState([]);

    useEffect(() => {
        callServerAPI('GET', '/api/CPDomain/GetAllCPDomains', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CPDomain[]>> } */ response) {
                setCpDomains(response.data.data);
            })

        callServerAPI('GET', '/api/User/GetAllTepngUsersEmail', null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
                setTepngEmails(["", ...response.data.data])
            })
    }, [])


    useEffect(() => {
        if (props.formEditMode === FormEditMode.EDIT) {
            var businessArea = props.businessAreas.find(e => e.id === props.businessAreaIdToEdit)
            setBusinessAreaItem(businessArea)
        }
    }, [props.formEditMode, props.businessAreaIdToEdit, props.businessAreas])


    function CreateBusinessArea(e) {
        MySwal.fire(preloader);
        let requestDto = (/** @type CreateOrUpdateBusinessAreaDto */ ({ ...businessAreaItem, cpDomainId: businessAreaItem.cpDomain?.id }))
        callServerAPI('POST', '/api/BusinessArea/CreateBusinessArea', requestDto, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<BusinessArea>> } */ response) {
                props.updateBusinessAreas([...props.businessAreas, response.data.data]);
                MySwal.close();
                handleCloseBusinessAreaForm();
                props.getAllBusinessAreas();
                enqueueSnackbar(response?.data?.message ?? "Business Area created", { variant: 'success' });
            })
    }

    function UpdateBusinessArea(e) {
        handleCloseBusinessAreaForm();
        let requestDto = (/** @type CreateOrUpdateBusinessAreaDto */ ({ ...businessAreaItem, cpDomainId: businessAreaItem.cpDomain?.id }))
        callServerAPI('PATCH', `/api/BusinessArea/UpdateBusinessArea?businessAreaId=${businessAreaItem.id}`, requestDto, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<BusinessArea>> } */ response) {
                props.updateBusinessAreas(props.businessAreas.map(e => e.id === businessAreaItem.id ? businessAreaItem : e));
                handleCloseBusinessAreaForm();
                props.getAllBusinessAreas();
                enqueueSnackbar(response?.data?.message ?? "Business Area updated", { variant: 'success' });
            })
    }

    const handleCloseBusinessAreaForm = () => {
        props.setOpenCreateBusinessAreaForm(false);
    };

    /** @param {BusinessArea}  businessAreaItem,  @return {Boolean} */
    const validate = (businessAreaItem) => {
        if (!(Boolean(businessAreaItem.title) && businessAreaItem.cpDomain != null && Boolean(businessAreaItem.costControllersList) && Boolean(businessAreaItem.budgetOwnersList) && Boolean(businessAreaItem.directorsList)))
            return false


        return true
    }
    return (
        tepngEmails && businessAreaItem && cpDomains && props.businessAreas &&
        <>
            <CustomDialog dialogTitle={props.formEditMode === FormEditMode.CREATE ? 'Add New Business Area' : 'Update Business Area'} setOpenDialog={props.setOpenCreateBusinessAreaForm} openDialog={props.openCreateBusinessAreaForm}>
                <div className='col-12 page' style={{ margin: '0px !important' }}>
                    <div >
                        <div >
                            <div >
                                <div >
                                    <label className='title-label'>Business Area Title <span className='important'>*</span></label>
                                </div>
                                <div className=''>
                                    <div className="form-group mb-0">
                                        <input type="text" onChange={(event) => setBusinessAreaItem(businessArea => ({ ...businessArea, title: event.target.value }))} id="title" value={businessAreaItem.title} className="form-control input-font bold" placeholder="" required />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div  >
                                <div >
                                    <label className='title-label'>C&P Domain <span className='important'>*</span></label>
                                </div>
                                <div className=''  >
                                    <div className="form-group mb-0" >
                                        <TextField fullWidth id="fullWidth" select variant="outlined" size="small" name="cpDomainId" value={businessAreaItem.cpDomain?.id}
                                            onChange={(event) => setBusinessAreaItem(businessArea => ({ ...businessArea, cpDomain: cpDomains.find(x => x.id === event.target.value) }))}>
                                            {cpDomains.filter(e => e.disabled === false).map((cpDomains, index) => {
                                                return <MenuItem key={index + 1} value={cpDomains.id}>{cpDomains.title}</MenuItem >
                                            })}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div style={{ width: '100%', margin: '0px !important' }}>
                                <div >
                                    <label className='title-label'>Cost Controllers <span className='important'>*</span></label>
                                </div>
                                <Autocomplete className='businessareaOptions ' id="costControllersList" options={tepngEmails} autoSelect multiple size="small"
                                    value={businessAreaItem.costControllersList}
                                    onChange={(e, newValue) => setBusinessAreaItem(businessArea => ({ ...businessArea, costControllersList: newValue }))}
                                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                                    getOptionDisabled={(option) => option === tepngEmails[0]}
                                    renderInput={(params) => (<TextField {...params} placeholder='Select Email' margin="none" variant="outlined" size='small' />)}
                                />

                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div style={{ width: '100%', margin: '0px !important' }}>
                                <div >
                                    <label className='title-label'>Budget Owners<span className='important'>*</span></label>
                                </div>
                                <Autocomplete id="budgetOwnersList" options={tepngEmails} autoSelect multiple size="small"
                                    value={businessAreaItem.budgetOwnersList}
                                    onChange={(e, newValue) => setBusinessAreaItem(businessArea => ({ ...businessArea, budgetOwnersList: newValue }))}
                                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                                    getOptionDisabled={(option) => option === tepngEmails[0]}
                                    renderInput={(params) => (<TextField {...params} placeholder='Select Email' margin="none" variant="outlined" size='small' />)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div style={{ width: '100%', margin: '0px !important' }}>
                                <div >
                                    <label className='title-label'>Directors<span className='important'>*</span></label>
                                </div>
                                <Autocomplete id="directorsList" options={tepngEmails} autoSelect multiple size="small" className='businessareaOptions'
                                    value={businessAreaItem.directorsList}
                                    onChange={(e, newValue) => setBusinessAreaItem(businessArea => ({ ...businessArea, directorsList: newValue }))}
                                    getOptionLabel={(option) => getNameFromTotalEnergiesEmail(option) + (Boolean(option) ? ' (' + option + ')' : 'Email not Selected')}
                                    getOptionDisabled={(option) =>
                                        option === tepngEmails[0]
                                    }
                                    renderInput={(params) => (<TextField   {...params} placeholder='Select Email' margin="none" variant="outlined" size='small' />)}

                                />
                            </div>
                        </div>

                        <br />
                        <div style={{ width: '100%' }} >


                            <div >
                                <label className='title-label'>Enabled <span className='important'>*</span></label>
                            </div>

                            <div className=''  >
                                <div className="form-group mb-0" >
                                    <div className='d-flex'>
                                        <div className='related_req_opt'> Yes &nbsp;<input name="enabled" type="radio" checked={!businessAreaItem.disabled} onClick={() => setBusinessAreaItem(businessArea => ({ ...businessArea, disabled: false }))} /></div>&nbsp;&nbsp;
                                        <div className='related_req_opt'> No  &nbsp;<input name="enabled" type="radio" checked={businessAreaItem.disabled} onClick={() => setBusinessAreaItem(businessArea => ({ ...businessArea, disabled: true }))} /></div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
                            <Button variant="contained" disabled={!validate(businessAreaItem)} color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? CreateBusinessArea : UpdateBusinessArea} ><Add />&nbsp;{props.formEditMode === FormEditMode.CREATE ? 'Create Business Area' : 'Update Business Area'} </Button>
                        </div>
                        <br />

                    </div>
                </div>
            </CustomDialog>

        </>
    )
}

export default CreateEditBusinessArea