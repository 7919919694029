
import React from 'react'
import '../../css/logout.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const { useNavigate } = require("react-router-dom");

function Logout() {

    const routerNavigate = useNavigate()
    return (
        <div style={{ background: 'white', height: '100vh' }} >
            <div className='center'>
                <Card sx={{ maxWidth: 600, textAlign: 'center' }}>
                    <br />
                    <img src='../assets/images/DcpscLogo.png' style={{ marginRight: '20px' }} alt="logo" />
                    <div className='line' />
                    <CardContent>
                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '500' }}>
                            You have been logged out of Digital CPSC portal
                        </Typography>
                        <br />
                        <Button variant='contained' onClick={(e) => { routerNavigate('/login-tepnguser') }} style={{ fontWeight: 'bold' }}> Click to  Login</Button>
                        <br />
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Logout