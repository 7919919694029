import React, { useState } from 'react'
import { Button, Grid } from '@mui/material';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2'
import ContractorDetailsForm from './ContractorDetailsForm';
import Table from 'react-bootstrap/Table';
import { FormEditMode, preloader } from '../../helpers/Constants';
import { callServerAPI, getCPSCRequestTooltip } from '../../helpers/Helpers';
import { useSnackbar } from 'notistack';
import { CpscRequestFields } from '../../helpers/DossierFieldConstants';


const MySwal = withReactContent(Swal);
/**
 * @typedef {Object} ContractorListProps
 * @property {CpscRequest} cpscRequest
 * @property {boolean} readOnlyMode
 * @property {import('react').Dispatch<import('react').SetStateAction<CpscRequest>>} setCpscRequest
 * @property {Number} formEditMode
 */

/**
 * @param {ContractorListProps}  props
 * @return {import('react').ReactElement}
 */
function ContractorList(props) {
    const { enqueueSnackbar } = useSnackbar();

    /** @type [Boolean, Function] */
    const [displayContractorDetailsForm, setDisplayContractorDetailsForm] = useState(false);
    /** @type [Boolean, Function] */
    const [displayAddContractorDetails, setDisplayAddContractorDetails] = useState(true);
    const [contractorNameToEdit, setContractorNameToEdit] = useState("");
    const [contractorFormEditMode, setContractorFormEditMode] = useState(FormEditMode.CREATE);

    /** @param {string} contractorName  */
    function deleteContractorOnServer(contractorName) {
        MySwal.fire(preloader)
        callServerAPI('PATCH', `/api/CpscRequest/DeleteContractor?requestId=${props.cpscRequest.id}&contractorName=${window.encodeURIComponent(contractorName)}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                MySwal.close()
                enqueueSnackbar('Request Deleted Successfully', { variant: 'success' });
            })
    }

    const deleteContractor = (/** @type {string} */ contractorName) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Do you want to delete this record?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            showCancelButton: true,
            confirmButtonColor: '#07bf69',
            confirmButtonText: 'Yes'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    props.setCpscRequest(data => ({
                        ...data, contractors: data.contractors.filter(inst => inst.contractorName !== contractorName)
                    }))
                    if (props.formEditMode !== FormEditMode.CREATE)
                        deleteContractorOnServer(contractorName)
                }

            })
    };

    return (
        <>
            <div style={{ padding: 10 }} >

                <span style={{ float: 'right' }}>
                    {displayAddContractorDetails &&
                        <Button onClick={() => { setContractorNameToEdit(""); setContractorFormEditMode(FormEditMode.CREATE); setDisplayAddContractorDetails(false); setDisplayContractorDetailsForm(true); }}
                            variant='contained' color='primary' disabled={props.readOnlyMode} >Add new Contractor </Button>
                    }
                </span>
                <br />

                {displayContractorDetailsForm &&
                    <>
                        <ContractorDetailsForm contractorNameToEdit={contractorNameToEdit} contractorFormEditMode={contractorFormEditMode} cpscRequestFormEditMode={props.formEditMode} setCpscRequest={props.setCpscRequest} setDisplayContractorDetailsForm={setDisplayContractorDetailsForm} setDisplayAddContractorDetails={setDisplayAddContractorDetails} cpscRequest={props.cpscRequest} />
                    </>
                }
                <br />
                <br />

                <Grid container>
                    <Grid item xs={12} sm={12} lg={12} >
                        <Table responsive striped bordered>
                            <thead style={{ fontSize: '10px', }}>
                                <tr>
                                    <th>{getCPSCRequestTooltip(CpscRequestFields.contractorName)} Contractor Name</th>
                                    <th>{getCPSCRequestTooltip(CpscRequestFields.contractNumber)}Contract Number</th>
                                    <th>{getCPSCRequestTooltip(CpscRequestFields.AgoraRef)}Agora Ref.  </th>
                                    <th>{getCPSCRequestTooltip(CpscRequestFields.contractorContractValue)}Contract Value ({props.cpscRequest.contractCurrency}) </th>
                                    {props.cpscRequest.otherContractCurrency != null &&
                                        <th>{getCPSCRequestTooltip(CpscRequestFields.contractorContractValue)}Contract Value  ({props.cpscRequest.otherContractCurrency})</th>
                                    }
                                    <th style={{ minWidth: 140, textAlign: 'center' }}>Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.cpscRequest.contractors.map((item, index) => {
                                    return <tr style={{ fontSize: '11px', }} key={index}>
                                        <td>{item.contractorName}</td>
                                        <td>{item.contractNumber}</td>
                                        <td>{item.agoraRef}</td>
                                        <td>{item.contractValue == null ? "" : Number(item.contractValue).toLocaleString('en-US')}</td>
                                        {props.cpscRequest.otherContractCurrency != null &&
                                            <td>{item.otherContractCurrencyValue == null ? "" : Number(item.otherContractCurrencyValue).toLocaleString('en-US')}</td>
                                        }
                                        <td style={{ minWidth: 140, textAlign: 'center' }}>
                                            {displayAddContractorDetails &&
                                                <Button onClick={() => { setContractorNameToEdit(item.contractorName); setContractorFormEditMode(FormEditMode.EDIT); setDisplayAddContractorDetails(false); setDisplayContractorDetailsForm(true); }}
                                                    variant='contained' color='primary' disabled={props.readOnlyMode} >Edit </Button>
                                            }
                                            &nbsp;
                                            <Button variant='contained' color='secondary' disabled={props.readOnlyMode} onClick={() => { deleteContractor(item.contractorName) }}>
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>


                                })}
                                {props.cpscRequest.contractors.length === 0 ? <tr>
                                    <td colSpan={6}><p style={{ fontSize: '11px', textAlign: 'center' }}> Please add a Contractor....</p></td>


                                </tr> : ''}

                            </tbody>

                        </Table>
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

export default ContractorList