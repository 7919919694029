import React, {  useState } from 'react'
import { RisksAndOpportunitiesSlideField } from '../../helpers/DossierFieldConstants';
import Table from 'react-bootstrap/Table';
import RisksAndOpportunitiesForm from './RisksAndOpportunitiesForm';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/template.css'
import { Button } from '@mui/material';
import DOMPurify from 'dompurify';
import MyRichTextEditor from '../MyRichTextEditor';


/**
 * @typedef {Object} RisksAndOpportunitiesSlideProps
 * @property {CpscRequest} cpscRequest
 * @property {updateDossierCallback} setDossier
 * @property {Boolean} readOnly
  * @property {number} dossierRefreshCount
 */

/**
 * @param {RisksAndOpportunitiesSlideProps}  props
 * @return {import('react').ReactElement}
 */


function RisksAndOpportunitiesSlide(props) {

 /** @param {string} name, @param {any} value  */
 const updateDataFieldValue = (name, value) => {
  props.setDossier({ ...props.cpscRequest.dossier, risksAndOpportunitiesSlide: { ...props.cpscRequest.dossier.risksAndOpportunitiesSlide, [name]: value } })
}

  /** @type [Boolean, Function] */
  const [displayRisksForm, setDisplayRisksForm] = useState(false);
  /** @type [Risk, Function] */
  const [risksToEdit, setRisksToEdit] = useState(null);

  return (
   
    <div>
      {displayRisksForm &&
        <RisksAndOpportunitiesForm cpscRequest={props.cpscRequest} setDossier={props.setDossier} risk={risksToEdit} setDisplayRisksForm={setDisplayRisksForm} />
      }

      <br />
      <Table responsive striped bordered>
        <thead>
          <tr style={{ fontSize: '10px', }} >
            <th>Risk Type</th>
            <th>Risks Identified</th>
            <th>Possible Mitigations </th>
            <th style={{ width: 50, textAlign: 'center' }}>Actions</th>

          </tr>
        </thead>
        <tbody>
          {props.cpscRequest.dossier.risksAndOpportunitiesSlide.risks.map((item, index) => {
            return <tr className='trtext' key={index}>
              <td style={{ minWidth: 150 }}>{item.riskType}</td>
              <td style={{ minWidth: 150 }}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.risksIdentified) }}></div></td>
              <td style={{ minWidth: 150 }} > <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.possibleMitigations) }}></div></td>


              <td style={{ minWidth: 150, textAlign: 'center' }}>
                <Button style={{ height: '30px' }} variant='contained' color="primary" size='small'
                  disabled={props.readOnly}
                  onClick={() => { setDisplayRisksForm(true); setRisksToEdit(item); }}>
                  Edit
                </Button>
              </td>
            </tr>


          })}
          {props.cpscRequest.dossier.risksAndOpportunitiesSlide.risks.length === 0 ? <tr>
            <td colSpan={10}><p className=' text-center'> No record available...</p></td>


          </tr> : ''}

        </tbody>

      </Table>

      <br />
      <div className="form-group">
        <label className='t-label' >Additional Comments<span className='important'>*</span></label>
        <MyRichTextEditor readOnly={props.readOnly} editorHtmlString={props.cpscRequest.dossier.risksAndOpportunitiesSlide.additionalComments} updateEditorHtmlString={editorHtmlString => updateDataFieldValue(RisksAndOpportunitiesSlideField.additionalComments, editorHtmlString)} />
        
      </div>
    </div>
  )
}

export default RisksAndOpportunitiesSlide