import React, { useState } from 'react'
import { DueDiligenceStatus, YesNo, YesNoOngoingNA } from '../../helpers/Constants';
import { convertToDateTimeLocalString } from '../../helpers/Helpers';
import { Button } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { DossierPdfFieldName } from '../../helpers/Constants';


const HasMitigationsOptions = [{ value: YesNo.Yes, label: YesNo.Yes }, { value: YesNo.No, label: YesNo.No }]
const DueDiligenceStatusOptions = [{ value: DueDiligenceStatus.Valid }, { value: DueDiligenceStatus.ReviewOngoing }, { value: DueDiligenceStatus.RenewalOngoing }, { value: DueDiligenceStatus.NotStarted }, { value: DueDiligenceStatus.NA }]
const HumanRightsAuditStatusOptions = [{ value: YesNoOngoingNA.Yes, label: "Yes" }, { value: YesNoOngoingNA.No, label: "No" }, { value: YesNoOngoingNA.Ongoing, label: "Ongoing" }, { value: YesNoOngoingNA.NA, label: "NA" }]
/**
  * @typedef {Object} DueDiligenceHumanRightsSlideProps
  * @property {downloadPdfCallback} downloadPdf
  * @property {uploadPdfCallback} uploadPdf
  * @property {deletePdfCallback} deletePdf
  * @property {CpscRequest} cpscRequest
  * @property {updateDossierCallback} setDossier
  * @property {boolean} readOnly

 */

/**
 * @param {DueDiligenceHumanRightsSlideProps}  props
 * @return {import('react').ReactElement}
 */
function DueDiligenceHumanRightsSlide(props) {

  const [, setDueDiligenceObjectUrl] = useState(null);

  /** @param {string} contractorName, @param {string} name, @param {any} value  */
  const updateDueDiligenceDataField = (contractorName, name, value) => {
    props.setDossier({ ...props.cpscRequest.dossier, dueDiligenceHumanRightsSlide: { ...props.cpscRequest.dossier.dueDiligenceHumanRightsSlide, contractors: props.cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.map(inst => inst.contractorName !== contractorName ? inst : { ...inst, [name]: value }) } })
  }
  /** @param {ContractorDueDiligenceHumanRights} item */
  const handleDueDiligenceFileChange = (item, event) => {
    event.persist()
    const file = event.target.files[0]
    let document = {}
    document.name = file.name;

    if (event.target.files.length === 0) return
    const formData = new FormData()
    formData.append("uploadedFile", event.target.files[0]);
    props.uploadPdf(formData, DossierPdfFieldName.DueDiligence, item.contractorName);
    setDueDiligenceObjectUrl(undefined)
    event.target.value = null
  }

  /** @param {ContractorDueDiligenceHumanRights} item */
  const previewFile = async (item, event) => {
    const url = await props.downloadPdf(DossierPdfFieldName.DueDiligence, item.contractorName)
    setDueDiligenceObjectUrl(url)
  }
  /** @param {ContractorDueDiligenceHumanRights} item */
  const handleHumanRightsFileChange = (item, event) => {
    event.persist()
    const file = event.target.files[0]
    let document = {}
    document.name = file.name;
    if (event.target.files.length === 0) return
    const formData = new FormData()
    formData.append("uploadedFile", event.target.files[0]);
    props.uploadPdf(formData, DossierPdfFieldName.HumanRights, item.contractorName);
    setDueDiligenceObjectUrl(undefined)
    event.target.value = null

  }
  /** @param {ContractorDueDiligenceHumanRights} item */
  const previewHumanRightsFile = async (item, event) => {
    const url = await props.downloadPdf(DossierPdfFieldName.HumanRights, item.contractorName)
    setDueDiligenceObjectUrl(url)
  }
  return (
    <>


      <div>
        {props.cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.map((item, index) => {
          return (
            <table key={index} className="table font-12  mb-2 table-bordered">
              <tbody>
                <tr>
                  <td className='sideheader'>Contractor Name</td>
                  <td className='trtext'>{item.contractorName}</td>
                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence ExpiryDate</td>
                  <td className='trtext'>
                    <input type='datetime-local' onChange={(e) => updateDueDiligenceDataField(item.contractorName, "dueDiligenceExpiryDate", e.target.value)} id="dueDiligenceExpiryDate" value={convertToDateTimeLocalString(item.dueDiligenceExpiryDate)} className="form-control input-font bold" placeholder='Date' required />
                  </td>
                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence Status</td>
                  <td className='trtext'>
                    <select id="dueDiligenceStatus" name="" className="select form-control" value={item.dueDiligenceStatus}
                      onChange={(e) => updateDueDiligenceDataField(item.contractorName, "dueDiligenceStatus", e.target.value)} required>
                      {DueDiligenceStatusOptions.map((item, index) => {
                        return <option key={index} className='form-control' value={item.value}>{item.value}</option>

                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className='sideheader'>Has Due Diligence Mitigations </td>
                  <td className='trtext'>
                    <select id="hasDueDiligenceMitigations" name="" className="select form-control" value={item.hasDueDiligenceMitigations}
                      onChange={(e) => updateDueDiligenceDataField(item.contractorName, "hasDueDiligenceMitigations", e.target.value)} required>
                      {HasMitigationsOptions.map((item, index) => {
                        return <option key={index} className='form-control' value={item.value}>{item.value}</option>

                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence Mitigations </td>
                  <td className='trtext'>
                    <textarea id='dueDiligenceMitigations' onChange={(e) => updateDueDiligenceDataField(item.contractorName, 'dueDiligenceMitigations', e.target.value)}
                      value={item.dueDiligenceMitigations} className='form-control' style={{ width: '100%', height: '101px' }} name='dueDiligenceMitigations' required disabled={props.readOnly} />
                  </td>
                </tr>
                <tr>
                  <td className='sideheader'>Human Rights Audit Status </td>
                  <td className='trtext'>
                    <select id="humanRightsAuditStatus" name="" className="select form-control" value={item.humanRightsAuditStatus}
                      onChange={(e) => updateDueDiligenceDataField(item.contractorName, "humanRightsAuditStatus", e.target.value)} required>
                      {HumanRightsAuditStatusOptions.map((item, index) => {
                        return <option key={index} className='form-control' value={item.value}>{item.value}</option>

                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence Upload </td>
                  <td className='trtext'>
                    {props.cpscRequest.contractors.find(e => e.contractorName === item.contractorName).dueDiligenceSlideUploaded ?
                      <Button variant='contained' onClick={(e) => { previewFile(item, e) }} ><RemoveRedEye />&nbsp;View Due Diligence Document</Button>
                      : <input id="dueDiligenceSlideUploaded" type="file" onChange={(e) => { handleDueDiligenceFileChange(item, e) }} accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={props.readOnly} />
                    }

                  </td>
                </tr>
                <tr>
                  <td className='sideheader'>Human Rights Upload </td>
                  <td className='trtext'>  {props.cpscRequest.contractors.find(e => e.contractorName === item.contractorName).humanRightsSlideUploaded ?
                    <Button variant='contained' onClick={(e) => { previewHumanRightsFile(item, e) }}  ><RemoveRedEye /> &nbsp; View Human Rights Document</Button>
                    : <input id="dueDiligenceSlideUploaded" type="file" onChange={(e) => { handleHumanRightsFileChange(item, e) }} accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={props.readOnly} />
                  }

                  </td>

                </tr>

              </tbody>

            </table>


          )
        })}
        {props.cpscRequest.dossier.dueDiligenceHumanRightsSlide.contractors.length === 0 ?
          <table className="table font-12  mb-2 table-bordered">
            <tbody>
                <tr>
                  <td className='sideheader'>Contractor Name</td>
                  <td rowSpan={8} className='trtext' style={{verticalAlign:'middle',textAlign:'center'}} > No Contractor specified for this Dossier</td>
                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence ExpiryDate</td>

                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence Status</td>

                </tr>
                <tr>
                  <td className='sideheader'>Has Due Diligence Mitigations </td>

                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence Mitigations </td>

                </tr>
                <tr>
                  <td className='sideheader'>Human Rights Audit Status </td>

                </tr>
                <tr>
                  <td className='sideheader'>Due Diligence Upload </td>

                </tr>
                <tr>
                  <td className='sideheader'>Human Rights Upload </td>


                </tr>

              </tbody>
          </table> : ''}
      </div>
    </>

  )
}

export default DueDiligenceHumanRightsSlide