import React, { useState } from 'react'
import { FormEditMode } from '../../helpers/Constants';

/**
 * @typedef {Object} highRiskFormProps
 * @property {Number} formEditMode
 * @property {CpscRequest} cpscRequest
 * @property {HighRisk} highRisk
 * @property {updateDossierCallback} setDossier
 * @property {Function} setDisplayHighRiskForm
 * @property {Function} setDisplayAddHighRisk
 */


/**
 * @param {highRiskFormProps}  props
 * @return {import('react').ReactElement}
 */

function HighRiskForm(props) {


  /** @type [HighRisk, Function] */
  const [data, setData] = useState(props.highRisk)

  /** @param {string} name, @param {any} value  */
  const updateDataFieldValue = (name, value) => {
    setData({ ...data, [name]: value })
  }


  const addHighRisk = (event) => {
    event.preventDefault();
    props.setDossier({ ...props.cpscRequest.dossier, highRiskServicesSlide: { ...props.cpscRequest.dossier.highRiskServicesSlide, highRisks: [...props.cpscRequest.dossier.highRiskServicesSlide.highRisks, data] } })
    props.setDisplayHighRiskForm(false);
    props.setDisplayAddHighRisk(true);
  }

  const updateHighRisk = (event) => {
    event.preventDefault();
    props.setDossier({ ...props.cpscRequest.dossier, highRiskServicesSlide: { ...props.cpscRequest.dossier.highRiskServicesSlide, highRisks: props.cpscRequest.dossier.highRiskServicesSlide.highRisks.map(item => item.serialNo === props.highRisk.serialNo ? data : item) } })
    props.setDisplayHighRiskForm(false);
    props.setDisplayAddHighRisk(true);
  }


  const validate = (data) => {
    let notValid = (Boolean(data.riskIdentified) === false || Boolean(data.mitigationMeasure) === false || Boolean(data.responsible) === false || Boolean(data.when) === false)
    return !notValid
  }


  return (
    <div>
      <form style={{ fontSize: '12px' }}>

        <div className='row'>
          <div >
            <label className='t-label'>Risk Identified<span className='important'>*</span></label>
          </div>
          <div className="form-group mb-0">
            <textarea id='riskIdentified' onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={data.riskIdentified} className='form-control' style={{ width: '100%', height: '80px' }} name='riskIdentified' required />

          </div>
        </div>
        <br />
        <div className='row'>
          <div >
            <label className='t-label'>Mitigation Measure<span className='important'>*</span></label>
          </div>
          <div className="form-group mb-0">
            <textarea id='mitigationMeasure' onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={data.mitigationMeasure} className='form-control' style={{ width: '100%', height: '80px' }} name='mitigationMeasure' required />


          </div>
        </div>
        <br />

        <div className='row'>
          <div >
            <label className='t-label'>Responsible<span className='important'>*</span></label>
          </div>
          <div className="form-group mb-0">
            <textarea id='responsible' onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={data.responsible} className='form-control' style={{ width: '100%', height: '80px' }} name='responsible' required />


          </div>
        </div>
        <br />
        <div className="form-group">

          <div className='row'>
            <div >
              <label className='t-label'>When<span className='important'>*</span></label>
            </div>
            <div className="form-group mb-0">
              <textarea id='when' onChange={(e) => updateDataFieldValue(e.target.id, e.target.value)} value={data.when} className='form-control' style={{ width: '100%', height: '80px' }} name='when' required />


            </div>
          </div>
        </div>
        <br />
        <div>
          <button disabled={!validate(data)} type='submit' className=' addcfr btn' onClick={props.formEditMode === FormEditMode.CREATE ? addHighRisk : updateHighRisk} >
            {props.formEditMode === FormEditMode.CREATE ? "Save High Risk" : "Update High Risk"}
          </button>
        </div>
      </form>
      <br></br>
    </div>
  )
}

export default HighRiskForm